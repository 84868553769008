import React from "react";
import noAppAccess from "../../styles/images/No-app-access.svg";

const SmallScreenSizes = () => {
  return (
    <div
      style={{
        marginTop: "75px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {" "}
      <div style={{ width: "50%", textAlign: "center" }}>
        We're sorry! XOCal is currently supported on desktop only and not
        available on smaller devices.
      </div>
      <img
        style={{ height: "215px", width: "215px", marginTop: "10px" }}
        src={noAppAccess}
        alt="No access"
      />
    </div>
  );
};

export default SmallScreenSizes;
