// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentNoteOutput,
    AppointmentNoteOutputFromJSON,
    AppointmentNoteOutputToJSON,
} from './AppointmentNoteOutput';
import {
    DailyScheduleResponseCopayCharge,
    DailyScheduleResponseCopayChargeFromJSON,
    DailyScheduleResponseCopayChargeToJSON,
} from './DailyScheduleResponseCopayCharge';
import {
    DailyScheduleResponseCovidRiskScore,
    DailyScheduleResponseCovidRiskScoreFromJSON,
    DailyScheduleResponseCovidRiskScoreToJSON,
} from './DailyScheduleResponseCovidRiskScore';
import {
    UnpaidCharge,
    UnpaidChargeFromJSON,
    UnpaidChargeToJSON,
} from './UnpaidCharge';
import {
    VideoConferenceOutput,
    VideoConferenceOutputFromJSON,
    VideoConferenceOutputToJSON,
} from './VideoConferenceOutput';


/**
 * 
 * @export
 * @interface DailyScheduleResponseAppointments
 */
export interface DailyScheduleResponseAppointments  {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    providerId?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleResponseAppointments
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    client?: string;
    /**
     * 
     * @type {Date}
     * @memberof DailyScheduleResponseAppointments
     */
    appointmentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DailyScheduleResponseAppointments
     */
    appointmentAtUtc?: Date;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleResponseAppointments
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    insuranceId?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleResponseAppointments
     */
    patientId?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    insurancePlan?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    owed?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    paid?: string;
    /**
     * 
     * @type {DailyScheduleResponseCopayCharge}
     * @memberof DailyScheduleResponseAppointments
     */
    copayCharge?: DailyScheduleResponseCopayCharge;
    /**
     * 
     * @type {object}
     * @memberof DailyScheduleResponseAppointments
     */
    mode?: object;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    summarySub?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    sexage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    rookie?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    startsAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleResponseAppointments
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    notes?: string;
    /**
     * 
     * @type {Array<AppointmentNoteOutput>}
     * @memberof DailyScheduleResponseAppointments
     */
    appointmentNotes?: Array<AppointmentNoteOutput>;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    display?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    locked?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    canCheckin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    canRevokeCheckin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    restrictedDependent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    actionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    ehrSyncStatus?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DailyScheduleResponseAppointments
     */
    accommodations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {Array<UnpaidCharge>}
     * @memberof DailyScheduleResponseAppointments
     */
    unpaidCharges?: Array<UnpaidCharge>;
    /**
     * 
     * @type {DailyScheduleResponseCovidRiskScore}
     * @memberof DailyScheduleResponseAppointments
     */
    covidRiskScore?: DailyScheduleResponseCovidRiskScore;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    xop?: boolean;
    /**
     * 
     * @type {VideoConferenceOutput}
     * @memberof DailyScheduleResponseAppointments
     */
    videoConference?: VideoConferenceOutput;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    hraBalance?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    checkinHl7Generated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    pronouns?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    xoid?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseAppointments
     */
    employer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyScheduleResponseAppointments
     */
    canSeeSogi?: boolean;
}

export function DailyScheduleResponseAppointmentsFromJSON(json: any): DailyScheduleResponseAppointments {
    return {
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'appointmentAt': !exists(json, 'appointment_at') ? undefined : new Date(json['appointment_at']),
        'appointmentAtUtc': !exists(json, 'appointment_at_utc') ? undefined : new Date(json['appointment_at_utc']),
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'insuranceId': !exists(json, 'insurance_id') ? undefined : json['insurance_id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'insurancePlan': !exists(json, 'insurance_plan') ? undefined : json['insurance_plan'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'owed': !exists(json, 'owed') ? undefined : json['owed'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'copayCharge': !exists(json, 'copay_charge') ? undefined : DailyScheduleResponseCopayChargeFromJSON(json['copay_charge']),
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'summarySub': !exists(json, 'summary_sub') ? undefined : json['summary_sub'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'sexage': !exists(json, 'sexage') ? undefined : json['sexage'],
        'rookie': !exists(json, 'rookie') ? undefined : json['rookie'],
        'startsAt': !exists(json, 'starts_at') ? undefined : json['starts_at'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'appointmentNotes': !exists(json, 'appointment_notes') ? undefined : (json['appointment_notes'] as Array<any>).map(AppointmentNoteOutputFromJSON),
        'display': !exists(json, 'display') ? undefined : json['display'],
        'locked': !exists(json, 'locked') ? undefined : json['locked'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'canCheckin': !exists(json, 'can_checkin') ? undefined : json['can_checkin'],
        'canRevokeCheckin': !exists(json, 'can_revoke_checkin') ? undefined : json['can_revoke_checkin'],
        'restrictedDependent': !exists(json, 'restricted_dependent') ? undefined : json['restricted_dependent'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'actionStatus': !exists(json, 'action_status') ? undefined : json['action_status'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'ehrSyncStatus': !exists(json, 'ehr_sync_status') ? undefined : json['ehr_sync_status'],
        'accommodations': !exists(json, 'accommodations') ? undefined : json['accommodations'],
        'preferredLanguage': !exists(json, 'preferred_language') ? undefined : json['preferred_language'],
        'unpaidCharges': !exists(json, 'unpaid_charges') ? undefined : (json['unpaid_charges'] as Array<any>).map(UnpaidChargeFromJSON),
        'covidRiskScore': !exists(json, 'covid_risk_score') ? undefined : DailyScheduleResponseCovidRiskScoreFromJSON(json['covid_risk_score']),
        'xop': !exists(json, 'xop') ? undefined : json['xop'],
        'videoConference': !exists(json, 'video_conference') ? undefined : VideoConferenceOutputFromJSON(json['video_conference']),
        'hraBalance': !exists(json, 'hra_balance') ? undefined : json['hra_balance'],
        'checkinHl7Generated': !exists(json, 'checkin_hl7_generated') ? undefined : json['checkin_hl7_generated'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'xoid': !exists(json, 'xoid') ? undefined : json['xoid'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'canSeeSogi': !exists(json, 'can_see_sogi') ? undefined : json['can_see_sogi'],
    };
}

export function DailyScheduleResponseAppointmentsToJSON(value?: DailyScheduleResponseAppointments): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'provider_id': value.providerId,
        'id': value.id,
        'client': value.client,
        'appointment_at': value.appointmentAt === undefined ? undefined : value.appointmentAt.toISOString(),
        'appointment_at_utc': value.appointmentAtUtc === undefined ? undefined : value.appointmentAtUtc.toISOString(),
        'user_id': value.userId,
        'insurance_id': value.insuranceId,
        'patient_id': value.patientId,
        'employee_id': value.employeeId,
        'insurance_plan': value.insurancePlan,
        'amount': value.amount,
        'owed': value.owed,
        'paid': value.paid,
        'copay_charge': DailyScheduleResponseCopayChargeToJSON(value.copayCharge),
        'mode': value.mode,
        'summary': value.summary,
        'summary_sub': value.summarySub,
        'name': value.name,
        'dob': value.dob,
        'sexage': value.sexage,
        'rookie': value.rookie,
        'starts_at': value.startsAt,
        'duration': value.duration,
        'notes': value.notes,
        'appointment_notes': value.appointmentNotes === undefined ? undefined : (value.appointmentNotes as Array<any>).map(AppointmentNoteOutputToJSON),
        'display': value.display,
        'locked': value.locked,
        'reason': value.reason,
        'can_checkin': value.canCheckin,
        'can_revoke_checkin': value.canRevokeCheckin,
        'restricted_dependent': value.restrictedDependent,
        'email': value.email,
        'action_status': value.actionStatus,
        'status': value.status,
        'ehr_sync_status': value.ehrSyncStatus,
        'accommodations': value.accommodations,
        'preferred_language': value.preferredLanguage,
        'unpaid_charges': value.unpaidCharges === undefined ? undefined : (value.unpaidCharges as Array<any>).map(UnpaidChargeToJSON),
        'covid_risk_score': DailyScheduleResponseCovidRiskScoreToJSON(value.covidRiskScore),
        'xop': value.xop,
        'video_conference': VideoConferenceOutputToJSON(value.videoConference),
        'hra_balance': value.hraBalance,
        'checkin_hl7_generated': value.checkinHl7Generated,
        'pronouns': value.pronouns,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'cell_phone': value.cellPhone,
        'xoid': value.xoid,
        'employer': value.employer,
        'can_see_sogi': value.canSeeSogi,
    };
}


