import React, { useEffect } from "react";
import useDraftSafeNavigate from "../../hooks/useDraftSafeNavigate";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";
import { useDispatch } from "react-redux";
import { updateNativePath, updateNativeAppState } from "shared/state/nativeApp";
import isEqual from "lodash/isEqual";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonBase from "components/Button/ButtonBase";
import { firstValueFrom } from "rxjs";
import {
  lastAccountPath$,
  lastNotificationsPath$,
} from "../../containers/Routing/RouteChangeHandler";
import Typography from "components/Typography";
import styles from "./index.module.css";
import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { Link } from "components/Button";
import useSmallScreenCheck from "hooks/useMobileCheck";

export const pathTree: any = {
  dashboard: {
    goTo: () => "/",
  },
  timeline: {
    goTo: () => "last-known-trunk",
  },
  careHistory: {
    goTo: () => "/care-history",
  },
  careHistoryVisits: {
    goTo: () => "/care-history?tab=visits",
  },
  memberProfile: {
    goTo: ({ memberId }: any) => `/members/${memberId}/visits`,
  },
  componentDetail: {
    permission: PermissionsEnum.CareTimelineRead,
    goTo: ({ memberId, eocId }: any) =>
      `/members/${memberId}/conversations/${eocId}`,
  },
  visitDetail: {
    goTo: () => "last-known-trunk",
  },
  selfSchedule: {
    goTo: () => "last-known-trunk",
  },
  proactive: {
    goTo: () => "last-known-trunk",
  },
  directMessaging: {
    goTo: () => "last-known-trunk",
  },
  getCare: {
    goTo: () => "last-known-trunk",
  },
  messageAndMore: {
    permission: PermissionsEnum.CareTimelineRead,
    goTo: ({ memberId, eocId }: any) =>
      `/members/${memberId}/conversations/${eocId}`,
  },
  newMessage: {
    permission: PermissionsEnum.CareTimelineRead,
    goTo: ({ memberId, eocId }: any) =>
      `/members/${memberId}/conversations/${eocId}`,
  },
  newMessageComponent: {
    goTo: ({ memberId, eocId, componentId }: any) =>
      `/members/${memberId}/conversations/${eocId}/component/${componentId}`,
  },
  providers: {
    permission: PermissionsEnum.MeetYourTeamRead,
    goTo: () => "/providers",
  },
  account: {
    goTo: () => "/account",
  },
  accountNavigation: {
    goTo: () => "pre-account",
  },
  healthProfile: {
    permission: PermissionsEnum.HealthRecordsRead,
    goTo: () => "/health-records",
  },
  checkIn: {
    goTo: ({ memberId, eocId, componentId }: any) =>
      `/members/${memberId}/conversations/${eocId}/component/${componentId}`,
  },
  notificationCenter: {
    goTo: () => "pre-notifications",
  },
};

const TRUNKS = [
  "dashboard",
  "careHistoryVisits",
  "careHistory",
  "healthProfile",
  "providers",
  "memberProfile",
];
const CTM_TRUNKS = ["memberProfile"];

interface NativeRouteUpdateInput {
  pathName: string;
  pathProps?: Record<string, any>;
  currentPageTitle?: string;
}

export const useNativeRouteUpdate = ({
  pathName,
  pathProps,
  currentPageTitle,
}: NativeRouteUpdateInput) => {
  const { lastPath, pageTitle }: any = selectNativeAppState();
  const user = selectLoggedInUser();

  const dispatch = useDispatch();
  const defaultTrunk = user?.ctm ? "memberProfile" : "dashboard";
  const trunkPath = (user?.ctm ? CTM_TRUNKS : TRUNKS).includes(pathName)
    ? pathName
    : lastPath?.trunk || defaultTrunk;

  const path = pathProps
    ? {
        name: pathName,
        details: pathProps,
        trunk: trunkPath,
      }
    : { name: pathName, trunk: trunkPath };

  useEffect(() => {
    if (!isEqual(lastPath, path)) {
      dispatch(updateNativePath(path));
    }
  }, [path?.name]);

  if (currentPageTitle && !isEqual(pageTitle, currentPageTitle)) {
    dispatch(updateNativeAppState({ pageTitle: currentPageTitle }));
  }

  useEffect(() => {
    return () => {
      dispatch(updateNativeAppState({ pageTitle: null }));
    };
  }, []);

  return lastPath;
};

export const NativeRouteNavigate = (): JSX.Element | null => {
  const { isApp, lastPath, pageTitle }: any = selectNativeAppState();

  const navigate = useDraftSafeNavigate();

  const nextPath = pathTree?.[lastPath?.name]?.goTo(lastPath?.details);
  const hasAccessToNextPath = useAccess({
    to: pathTree?.[lastPath?.name]?.permission
      ? pathTree?.[lastPath?.name]?.permission
      : PermissionsEnum.MemberHomepageRead,
  });

  const goBack = () => {
    if (!hasAccessToNextPath) {
      return navigate(pathTree?.[lastPath?.trunk]?.goTo());
    }

    if (nextPath === "last-known-trunk") {
      return navigate(pathTree?.[lastPath?.trunk]?.goTo());
    }

    if (nextPath === "pre-account") {
      return firstValueFrom(lastAccountPath$).then(navigate);
    }

    if (nextPath === "pre-notifications") {
      return firstValueFrom(lastNotificationsPath$).then(navigate);
    }

    return navigate(nextPath);
  };

  const isSmallScreen = useSmallScreenCheck();

  useEffect(() => {
    window.addEventListener("goBack", goBack);
    return () => {
      window.removeEventListener("goBack", goBack);
    };
  }, [lastPath]);

  if (TRUNKS.includes(lastPath?.name) || (!isApp && !isSmallScreen)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <ButtonBase onClick={goBack} aria-label="Back">
        <ArrowBackIcon className={styles.arrow} />
      </ButtonBase>
      <Typography appearance="bodyEmphasized" className={styles.pageTitle}>
        {pageTitle}
      </Typography>
    </div>
  );
};

export const BackToPreviousRoute = (): JSX.Element | null => {
  const { lastPath }: any = selectNativeAppState();
  const navigate = useDraftSafeNavigate();

  const goBack = () => {
    return navigate(pathTree?.[lastPath?.trunk]?.goTo(lastPath?.details));
  };

  const pageTitles = {
    "/health-records": "Health Records",
    "/care-history": "Care History",
    "/care-history?tab=visits": "Visits",
    "/providers": "Meet Your Team",
    "/account": "Accounts and Settings",
    "/": "Dashboard",
    "/get-care": "Preventive Care",
    "/direct-messaging": "Direct Message",
    [`/members/${lastPath?.details?.memberId}/visits`]: "Member Profile",
  };

  const lastPageTitle =
    pageTitles[pathTree?.[lastPath?.trunk]?.goTo(lastPath?.details)];

  return (
    <Link
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        goBack();
      }}
      to={""}
      className={styles.link}
    >
      Back to {lastPageTitle}
    </Link>
  );
};
