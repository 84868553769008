import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import useGetCalendarStaticData from "./useGetCalendarStaticData";
import { ALL_TYPES } from "components/XOCal/utils";

export function useGetProviders(
  clinicId: string,
  serviceType?: string
): XOCalProvider[] {
  const { data: calendarStaticData } = useGetCalendarStaticData({ clinicId });

  return (
    calendarStaticData?.clinicDetails?.providersByServiceType || []
  ).flatMap((providerGroup) => {
    const shouldReturnProviderGroup =
      !serviceType ||
      serviceType === ALL_TYPES ||
      providerGroup.serviceType === serviceType;
    if (shouldReturnProviderGroup) {
      return providerGroup.providers || [];
    }
    return [];
  });
}
