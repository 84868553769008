import React from "react";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { format } from "date-fns";
import { composeTooltipSlotProps } from "components/XOCal/Slot/utils";
import { Tooltip } from "@mui/material";
import { ClinicAppointmentsKeyedByAppointmentId } from "components/XOCal/utils";
import PatientInformation from "./PatientInformation";
import uniqueId from "lodash/uniqueId";

interface IProps {
  slot: SlotOutput;
  timezone: string;
  appointmentInfoByAppointmentId: ClinicAppointmentsKeyedByAppointmentId;
  slotId: string;
}

function FullInformationContent({
  slot,
  timezone,
  appointmentInfoByAppointmentId,
}: IProps) {
  const { appointments, appointmentTypes } = slot;

  const startAt = slot.startAt && new Date(slot.startAt);
  const startAtTime = startAt?.toLocaleString("en-US", {
    timeZone: timezone,
  });
  const endAt = slot.endAt && new Date(slot.endAt);
  const endAtTime = endAt?.toLocaleString("en-US", { timeZone: timezone });

  const hasAScheduledPatient = appointments?.length !== 0;
  const appointmentType = appointments?.[0]?.appointmentType;

  return appointmentTypes ? (
    <div
      style={{ width: "max-content" }}
      data-testid="full-info-tooltip-content-wrapper"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!hasAScheduledPatient ? (
          appointmentTypes.map((type) => {
            return (
              <div
                key={uniqueId()}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>{appointmentInfoByAppointmentId[type]?.name}</div>{" "}
                {/* spaces and bullet point */}
                <span style={{ padding: "0px 3px" }}> &#8226; </span>
                {`${startAtTime && format(new Date(startAtTime), "p")}-${
                  endAtTime && format(new Date(endAtTime), "p")
                }`}
              </div>
            );
          })
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              {appointmentType &&
                appointmentInfoByAppointmentId[appointmentType]?.name}
            </div>{" "}
            {/* spaces and bullet point */}
            <span style={{ padding: "0px 3px" }}> &#8226; </span>
            {`${startAtTime && format(new Date(startAtTime), "p")}-${
              endAtTime && format(new Date(endAtTime), "p")
            }`}
          </div>
        )}
      </div>

      {hasAScheduledPatient &&
        appointments?.map((appointment) => {
          return appointments?.length === 1 ? (
            <>
              <div
                key={uniqueId()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {" "}
                <div>{appointment.patientInfo?.name}</div>{" "}
                {/* spaces and bullet point */}
                <span style={{ padding: "0px 3px" }}> &#8226; </span>{" "}
                {/* TODOOOO */}
                <PatientInformation patientInfo={appointment.patientInfo} />
              </div>
              <div>
                Reason for visit:{" "}
                {appointments[0].reason
                  ? appointments[0].reason
                  : "Not provided"}
              </div>
            </>
          ) : (
            <>
              <div style={{ fontWeight: "bold" }}>
                {appointment.patientInfo?.name}
              </div>{" "}
              <PatientInformation patientInfo={appointment.patientInfo} />
            </>
          );
        })}
    </div>
  ) : null;
}

// Has no visible content unless hovered over. covers the "blank"
// part of the slot
export default function FullInformationTooltip(props: IProps) {
  return (
    <Tooltip
      title={<FullInformationContent {...props} />}
      slotProps={composeTooltipSlotProps({ offset: [50, -10] })}
    >
      <div
        style={{ height: "100%", width: "100%" }}
        data-testid={`full-slot-info-${props.slotId}`}
      ></div>
    </Tooltip>
  );
}
