import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { State } from "@types";
import makeStyles from "@mui/styles/makeStyles";
import useFormatDate from "shared/utils/useFormatDate";
import Typography from "components/Typography";
import Divider from "components/Divider";
import Avatar from "components/Avatar";

interface IVisitDetailsCard {
  isSideBar?: boolean;
}

const useStyles = makeStyles(({ palette }) => ({
  visitProviderData: {
    marginLeft: "12px",
  },
  visitDetails: {
    display: "flex",
    flexDirection: (props: any) =>
      props.isSideBar ? "column" : "column-reverse",
    backgroundColor: (props: any) =>
      props.isSideBar ? palette.white : palette?.appBackground?.main,
    marginBottom: 32,
    padding: "12px",
    borderRadius: "6px",
  },
  visitDate: {
    display: "flex",
  },
  visitDateIcon: {
    minWidth: "40px",
    height: "40px",
    padding: "4px 0px",
    borderRadius: "4px",
    backgroundColor: palette?.appBackground?.navy,
    "& p": {
      color: palette.white,
      textAlign: "center",
    },
  },
  visitIconMonth: {
    fontSize: "9px",
  },
  visitIconDay: {
    fontSize: "16px",
  },
  visitDateDay: {
    alignSelf: "center",
    marginLeft: "12px",
  },
  visitProvider: {
    display: "flex",
  },
  divider: {
    margin: "14px 0px",
  },
}));

const VisitDetailsCard: FunctionComponent<IVisitDetailsCard> = ({
  isSideBar = false,
}) => {
  const classes = useStyles({ isSideBar });
  const formatDate = useFormatDate();

  const {
    datePicker: { slotProvider, slot },
    visits,
    centers,
  } = useSelector((state: State) => state.ui.careAccess);

  return (
    <div className={classes.visitDetails}>
      <div className={classes.visitDate}>
        <div className={classes.visitDateIcon}>
          <p className={classes.visitIconMonth}>
            {formatDate(slot?.startAt, "MMM").toString().toUpperCase()}
          </p>
          <p className={classes.visitIconDay}>
            {formatDate(slot?.startAt, "d")}
          </p>
        </div>

        <div className={classes.visitDateDay}>
          <Typography fontWeightBold appearance="smallBody" color="textPrimary">
            {" "}
            {formatDate(slot?.startAt, "EEEE, MMM d, @ h:mmaaa zzz")}{" "}
          </Typography>
        </div>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.visitProvider}>
        <Avatar
          containerStyle={{ margin: 0 }}
          displayText={slotProvider?.name}
          src={slotProvider?.avatarUrl}
          size="forty"
          isPresentational
        />

        <div className={classes.visitProviderData}>
          <Typography fontWeightBold appearance="smallBody" color="textPrimary">
            {slotProvider?.name}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {slotProvider?.guilds?.[0]}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {visits.appointmentLabel},
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {centers.centerName}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default VisitDetailsCard;
