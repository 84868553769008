// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSlotSeriesRequestBody
 */
export interface CreateSlotSeriesRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeriesRequestBody
     */
    clinicId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeriesRequestBody
     */
    providerId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSlotSeriesRequestBody
     */
    appointmentTypes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSlotSeriesRequestBody
     */
    daysActive: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeriesRequestBody
     */
    visibility: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSlotSeriesRequestBody
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSlotSeriesRequestBody
     */
    maxOverbook?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeriesRequestBody
     */
    restrictedTo?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSlotSeriesRequestBody
     */
    seriesStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateSlotSeriesRequestBody
     */
    seriesEnd: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeriesRequestBody
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeriesRequestBody
     */
    endTime: string;
}

export function CreateSlotSeriesRequestBodyFromJSON(json: any): CreateSlotSeriesRequestBody {
    return {
        'clinicId': json['clinic_id'],
        'providerId': json['provider_id'],
        'appointmentTypes': json['appointment_types'],
        'daysActive': json['days_active'],
        'visibility': json['visibility'],
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'restrictedTo': !exists(json, 'restricted_to') ? undefined : json['restricted_to'],
        'seriesStart': new Date(json['series_start']),
        'seriesEnd': new Date(json['series_end']),
        'startTime': json['start_time'],
        'endTime': json['end_time'],
    };
}

export function CreateSlotSeriesRequestBodyToJSON(value?: CreateSlotSeriesRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinic_id': value.clinicId,
        'provider_id': value.providerId,
        'appointment_types': value.appointmentTypes,
        'days_active': value.daysActive,
        'visibility': value.visibility,
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
        'restricted_to': value.restrictedTo,
        'series_start': value.seriesStart.toISOString().substr(0,10),
        'series_end': value.seriesEnd.toISOString().substr(0,10),
        'start_time': value.startTime,
        'end_time': value.endTime,
    };
}


