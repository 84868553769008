import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { SlotOutputWithOneAppointment } from "./ConflictsQueueContainer";
import {
  ProvidersKeyedByProviderId,
  ClinicAppointmentsKeyedByAppointmentId,
} from "components/XOCal/utils";
import { RoleOutputCoreSchema } from "shared/fetch/src/models/RoleOutputCoreSchema";
import ConflictsTableRow from "./ConflictsTableRow";
import ConflictsTableTitle from "./ConflictsTableTitle";

function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  appointmentObjectKeyedById: ClinicAppointmentsKeyedByAppointmentId,
  providersObject: ProvidersKeyedByProviderId
): number {
  let itemA: any = a[orderBy];
  let itemB: any = b[orderBy];

  switch (orderBy) {
    case "member":
      itemA = (a as any).appointments[0].patientInfo.name;
      itemB = (b as any).appointments[0].patientInfo.name;
      break;
    case "visit-type":
      itemA = appointmentObjectKeyedById[(a as any).appointmentType].name;
      itemB = appointmentObjectKeyedById[(b as any).appointmentType].name;
      break;
    case "provider":
      itemA = providersObject[(a as any).appointments[0].providerId].name;
      itemB = providersObject[(b as any).appointments[0].providerId].name;
      break;
    default:
      break;
  }

  if (itemB < itemA) {
    return -1;
  }
  if (itemB > itemA) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

// Use generic type and better handling for key ordering
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  appointmentObjectKeyedById: any,
  providersObject: any
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) =>
        descendingComparator(
          a,
          b,
          orderBy,
          appointmentObjectKeyedById,
          providersObject
        )
    : (a, b) =>
        -descendingComparator(
          a,
          b,
          orderBy,
          appointmentObjectKeyedById,
          providersObject
        );
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: SlotOutputWithOneAppointment[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map(
    (el: SlotOutputWithOneAppointment, index: number) =>
      [el, index] as [SlotOutputWithOneAppointment, number]
  );
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  { id: "startAt", label: "VISIT DATE" },
  { id: "member", label: "MEMBER" },
  { id: "visit-type", label: "VISIT TYPE" },
  { id: "provider", label: "PROVIDER" },
  { id: "actions", label: "" },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function ConflictsTableHeaderRow(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "actions" && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  hasConflicts: boolean;
  conflicts: SlotOutputWithOneAppointment[];
  providersObject: ProvidersKeyedByProviderId;
  appointmentObjectKeyedById: ClinicAppointmentsKeyedByAppointmentId;
  roles?: RoleOutputCoreSchema[];
  clinicId?: string | null;
}

export default function ConflictsTable({
  hasConflicts,
  conflicts,
  providersObject,
  appointmentObjectKeyedById,
  roles,
  clinicId,
}: IProps) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("startAt");

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedConflicts = React.useMemo(
    () =>
      stableSort(
        conflicts,
        getComparator(
          order,
          orderBy,
          appointmentObjectKeyedById,
          providersObject
        )
      ),
    [order, orderBy, conflicts]
  );

  return (
    <Box sx={{ width: "100%" }}>
      {hasConflicts ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <ConflictsTableTitle clinicId={clinicId} />
            <Table sx={{ minWidth: 750 }} aria-labelledby="table-title">
              <ConflictsTableHeaderRow
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {sortedConflicts.map(
                  (row: SlotOutputWithOneAppointment, index: number) => (
                    <ConflictsTableRow
                      row={row}
                      key={`${row.id}-${index}`}
                      roles={roles}
                      index={index}
                      providersObject={providersObject}
                      appointmentObjectKeyedById={appointmentObjectKeyedById}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper sx={{ width: "100%" }}>
          <ConflictsTableTitle clinicId={clinicId} />
          <Box paddingLeft={5} paddingTop={3} paddingBottom={14}>
            There are no unresolved conflicts.
          </Box>
        </Paper>
      )}
    </Box>
  );
}
