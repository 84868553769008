/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateAppointmentById,
    UpdateAppointmentByIdFromJSON,
    UpdateAppointmentByIdToJSON,
} from '../models/UpdateAppointmentById'
import {
    VirtualVisitReferralComponentDetailsOutput,
    VirtualVisitReferralComponentDetailsOutputFromJSON,
    VirtualVisitReferralComponentDetailsOutputToJSON,
} from '../models/VirtualVisitReferralComponentDetailsOutput'


export interface UpdateAppointmentByIdRequest {
    id: string;
    updateAppointmentById: UpdateAppointmentById;
}


export interface IUpdateAppointmentApi {
      updateAppointmentById: (requestParameters: UpdateAppointmentByIdRequest) => Promise<VirtualVisitReferralComponentDetailsOutput>
      updateAppointmentByIdWithResponse: (requestParameters: UpdateAppointmentByIdRequest) => Promise<{ value: VirtualVisitReferralComponentDetailsOutput, response: any}>
}

/**
 * no description
 */
export class UpdateAppointmentApi extends runtime.BaseAPI {

    /**
     * Update an appointment by ID
     * Update an appointment by ID
     */
    updateAppointmentByIdRaw = async (requestParameters: UpdateAppointmentByIdRequest): Promise<{ runtime: runtime.ApiResponse<VirtualVisitReferralComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAppointmentById.');
        }

                        if (requestParameters.updateAppointmentById === null || requestParameters.updateAppointmentById === undefined) {
            throw new runtime.RequiredError('updateAppointmentById','Required parameter requestParameters.updateAppointmentById was null or undefined when calling updateAppointmentById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAppointmentByIdToJSON(requestParameters.updateAppointmentById),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => VirtualVisitReferralComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Update an appointment by ID
     * Update an appointment by ID
     */
    updateAppointmentById = async (requestParameters: UpdateAppointmentByIdRequest): Promise<VirtualVisitReferralComponentDetailsOutput> => {
        const { runtime } = await this.updateAppointmentByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update an appointment by ID
     * Update an appointment by ID
     */
    updateAppointmentByIdWithResponse = async (requestParameters: UpdateAppointmentByIdRequest): Promise<{ value: VirtualVisitReferralComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.updateAppointmentByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchUpdateAppointmentApi: IUpdateAppointmentApi  = new UpdateAppointmentApi();
