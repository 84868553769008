import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Asset from "components/Asset";
import Heading from "components/DynamicHeadings";
import Typography from "components/Typography";
import Button from "components/Button";
import clsx from "clsx";

interface IProps {
  assetName: string;
  title: string;
  description?: string;
  isService?: boolean;
  handleClick?: () => void;
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  careTile: {
    padding: 12,
    border: `1px solid ${palette.text.mediumDarkGray}`,
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: palette?.appBackground?.main,
      border: `2px solid ${palette?.appBackground?.blue}`,
      padding: 11,
    },
    "@media (hover: none)": {
      "&:hover": {
        backgroundColor: "unset",
        border: `1px solid ${palette.text.mediumDarkGray}`, // Reset to default
        padding: 12, // Reset padding
      },
    },
  },
  careTileDimensions: {
    width: 381,
    height: 144,
    [breakpoints.down("md")]: {
      width: "100%",
    },
  },
  careHeading: {
    width: 320,
  },
  careDescription: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  careHeadingRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
    marginBottom: 5,
    width: "100%",
  },
  serviceTile: {
    maxWidth: 248,
    [breakpoints.down("md")]: {
      width: "49%",
      maxWidth: "unset",
    },
  },
  arrow: {
    width: "18px",
    height: "18px",
  },
}));

const CareAccessCareTile: FunctionComponent<IProps> = ({
  assetName,
  title,
  description = "",
  isService,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(
        classes.careTile,
        isService && classes.serviceTile,
        !isService && classes.careTileDimensions
      )}
      onClick={handleClick}
      data-testid="care-access-tile"
    >
      <Asset
        name={assetName}
        style={isService ? { width: 150, height: 100 } : {}}
        hiddenFromScreenReader
      />
      <div className={classes.careHeadingRow}>
        <Heading.LevelBoundary levelOverride={3}>
          <Heading.H
            className={classes.careHeading}
            appearance="h6"
            color="textPrimary"
            fontWeightBold
          >
            {title}
          </Heading.H>
        </Heading.LevelBoundary>
        <Asset
          name="care-arrow-forward"
          className={classes.arrow}
          hiddenFromScreenReader
        />
      </div>
      <Typography
        color="textSecondary"
        appearance="smallBody"
        className={isService ? "" : classes.careDescription}
      >
        {description}
      </Typography>
    </Button>
  );
};

export default CareAccessCareTile;
