import React, { FunctionComponent, forwardRef } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "components/Progress/CircularProgress";
import ButtonComponent, { ButtonProps } from "@mui/material/Button";
import {
  pluckFirst,
  useObservable,
  useObservableCallback,
  useSubscription,
} from "observable-hooks";
import { defer, exhaustAll, map, throttleTime, withLatestFrom } from "rxjs";

export type ButtonColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "link"
  | "link-primary"
  | "link-secondary"
  | "link-light"
  | "fab";

export type LinkColor = "primary" | "secondary" | "light";

interface IExtendedProps extends Omit<ButtonProps, "size"> {
  /** The string URI to link to. Supports relative and absolute URIs. */
  to?: string;
  stateParam?: any;
  component?: React.ElementType;
  size?: "" | "small" | "medium" | "large";
  fontWeightBold?: boolean;
  fontWeightNormal?: boolean;
  fontSizeInherit?: boolean;
  isLoading?: boolean;
  throttleButtonTime?: number;
}

interface IExtendedButtonProps extends Omit<IExtendedProps, "color"> {
  endIcon?: React.ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "link"
    | "link-primary"
    | "link-secondary"
    | "link-light"
    | "fab"
    | "links.primary"
    | "inherit";
}

interface IExtendedLinkProps extends Omit<IExtendedProps, "color"> {
  /** Boolean to make link appear as a button. */
  button?: boolean;
  color?: "primary" | "secondary" | "light";
  /** Boolean to format link when it displays inline with other text. */
  inline?: boolean;
  onLinkClick?: (
    event?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
}

const useStyles = makeStyles(({ palette }) => ({
  root: {},
  circularStyles: {
    width: "25px !important",
    height: "25px !important",
    margin: "2px 15px",
    color: palette?.text?.disabled,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  fontWeightNormal: {
    fontWeight: "normal",
  },
  fontSizeInherit: {
    fontSize: "inherit",
  },
  tertiary: {
    color: palette?.tab?.pill?.primary,
    "&:hover": {
      color: palette?.tab?.pill?.primary,
    },
  },
  "link-primary": {
    padding: 0,
    color: palette?.text?.link,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      color: palette?.linkColorHover,
    },
  },
  "link-secondary": {
    padding: 0,
    color: palette?.text?.secondary,
    textDecoration: "underline",
    "&:hover": {
      color: palette?.text?.primary,
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
  "link-light": {
    padding: 0,
    color: palette?.common.white,
    textDecoration: "underline",
    "&:hover": {
      color: palette?.text?.strokes,
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
}));

const Button = forwardRef<HTMLButtonElement, IExtendedButtonProps>(
  function Button(props, ref) {
    const classes = useStyles(props);
    const navigate = useNavigate();
    const {
      color,
      className,
      href,
      to,
      stateParam,
      onClick,
      variant,
      fontWeightBold,
      fontWeightNormal,
      fontSizeInherit,
      isLoading,
      throttleButtonTime,
      ...rest
    } = props as any;
    let updatedColor = color;
    let updatedVariant = variant;
    let updatedOnClick = onClick;
    let updatedHref = null;
    let fontWeightClass = null;
    let fontSizeClass = null;

    if (rest.component && rest.component === "a") {
      if (href?.includes(":")) {
        updatedOnClick = (e: any) => {
          if (onClick) {
            onClick(e);
          }
          window.open(href, "_blank", "noopener, noreferrer");
        };
      }
      updatedHref = href || "#";
    }

    if (fontWeightBold) {
      fontWeightClass = classes.fontWeightBold;
    } else if (fontWeightNormal) {
      fontWeightClass = classes.fontWeightNormal;
    }

    if (fontSizeInherit) {
      fontSizeClass = classes.fontSizeInherit;
    }

    if (to) {
      updatedOnClick = (e: any) => {
        if (onClick) {
          onClick(e);
        }
        if (to.includes(":")) {
          window.open(to, "_blank", "noopener, noreferrer");
        } else if (stateParam) {
          navigate(to, { state: stateParam });
        } else {
          navigate(to);
        }
      };
    }

    if (!variant) {
      switch (color as ButtonColor) {
        case "primary":
          updatedVariant = "contained";
          break;

        case "secondary":
          updatedColor = "primary";
          updatedVariant = "outlined";
          break;

        case "tertiary":
          updatedColor = "primary";
          break;

        case "link-primary":
          updatedColor = "primary";
          break;

        case "link-secondary":
          updatedColor = "inherit";
          break;

        case "link-light":
          break;

        case "link":
          updatedColor = "inherit";
          break;
      }
    }

    const updatedOnClick$ = useObservable(pluckFirst, [updatedOnClick]);

    const [onButtonClick, onButtonClick$] = useObservableCallback((event$) =>
      event$.pipe(
        throttleTime(throttleButtonTime || 200),
        withLatestFrom(updatedOnClick$),
        map(([event, updatedOnButtonClick]: any) =>
          defer(() => {
            if (updatedOnButtonClick) {
              event.preventDefault();
              return Promise.resolve(updatedOnButtonClick(event));
            }

            return Promise.resolve();
          })
        ),
        exhaustAll()
      )
    );

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useSubscription(onButtonClick$, () => {});

    return isLoading ? (
      <ButtonComponent
        className={clsx(
          classes[color],
          className,
          fontWeightClass,
          fontSizeClass
        )}
        variant={updatedVariant as any}
        disabled
      >
        <CircularProgress
          data-testid="loading-spinner"
          classes={{ root: classes.circularStyles }}
        ></CircularProgress>
      </ButtonComponent>
    ) : (
      <ButtonComponent
        className={clsx(
          classes[color],
          className,
          fontWeightClass,
          fontSizeClass
        )}
        href={updatedHref}
        ref={ref}
        color={updatedColor as any}
        variant={updatedVariant as any}
        onClick={onButtonClick}
        {...rest}
      />
    );
  }
);

export const Link: FunctionComponent<
  IExtendedLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
  const { button, color, inline, style, onLinkClick, ...passThroughProps } =
    props;

  let updatedColor: ButtonColor;
  let updatedStyle = style;

  if (inline) {
    updatedStyle = {
      minWidth: 0,
      verticalAlign: "baseline",
      display: "inline",
      ...updatedStyle,
    };
  }

  switch (color as ButtonColor | "light") {
    case "primary":
      updatedColor = button ? "primary" : "link-primary";
      break;

    case "secondary":
      updatedColor = button ? "secondary" : "link-secondary";
      break;

    case "light":
      updatedColor = "link-light";
      break;

    default:
      updatedColor = "link";
      break;
  }

  return (
    <Button
      component="a"
      onClick={typeof onLinkClick === "function" ? onLinkClick : undefined}
      color={updatedColor as any}
      style={updatedStyle}
      {...passThroughProps}
    />
  );
};

export default Button;
