import { EventContentArg } from "@fullcalendar/core";
import React from "react";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { getAppointmentObjectKeyedById } from "./utils";
import { CalendarStaticDataResponse } from "shared/fetch/src/models/CalendarStaticDataResponse";
import MirrorSlot from "components/XOCal/Slot/MirrorSlot";
import Slot from "components/XOCal/Slot/Slot";

interface EventContentProps {
  eventInfo: EventContentArg;
  timezone: string;
  calendarStaticData: CalendarStaticDataResponse | undefined;
}

// The function is pretty much the controller/model for the the Slot component
const EventContent: React.FC<EventContentProps> = ({
  eventInfo,
  timezone,
  calendarStaticData,
}) => {
  const { isMirror, timeText } = eventInfo; // isMirror means user is dragging, resizing, or selecting slot
  const title = eventInfo?.event?.title;

  // TODO: make id a required prop of SlotOutput
  const event = eventInfo?.event?.extendedProps as SlotOutput;
  // the slot-as-event- delivered from FullCalendar does not have an id,
  // but we can find it here ...
  const slotId = eventInfo?.event?.id;
  const eventMinutes = Math.floor(
    // @ts-ignore
    (eventInfo.event.end! - eventInfo.event.start!) / 60000
  );

  const appointmentInfoByAppointmentId =
    getAppointmentObjectKeyedById(calendarStaticData);

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat(undefined, {
      // @ts-ignore
      dateStyle: "full",
      timeStyle: "short",
      timeZone: timezone,
    }).format(new Date(date));

  const eventStart = formatDate(eventInfo.event.startStr);
  const eventEnd = formatDate(eventInfo.event.endStr);

  return (
    <>
      {isMirror ? (
        <MirrorSlot title={title} timeText={timeText} />
      ) : (
        <Slot
          slot={event}
          slotId={slotId}
          eventStart={eventStart}
          eventEnd={eventEnd}
          eventMinutes={eventMinutes}
          title={title}
          appointmentInfoByAppointmentId={appointmentInfoByAppointmentId}
          timezone={timezone}
        />
      )}
    </>
  );
};

export default EventContent;
