import React, { FunctionComponent } from "react";
import { IMemberSummaryDSPayment } from ".";
import Box from "components/Box";
import Typography from "components/Typography";
import classes from "./index.module.css";
interface IProps {
  memberSummary: IMemberSummaryDSPayment;
  providerName?: string;
  className?: string;
}

const MemberPaymentDetails: FunctionComponent<IProps> = ({
  memberSummary,
  providerName,
  className,
}) => {
  return (
    <Box className={className ? className : classes.memberCard}>
      <Typography appearance="bodyEmphasized">
        {memberSummary.memberName}
      </Typography>
      <Box className={classes.memberSecondaryInfo}>
        <Typography className={classes.greyText}>
          {memberSummary.memberPronouns} {memberSummary.memberDob}{" "}
          {memberSummary.memberAge} {memberSummary.memberSex}
        </Typography>
        <Typography className={classes.greyText}>
          {memberSummary.memberPhone}
        </Typography>
      </Box>
      <Typography>
        {memberSummary.summary} {providerName ? `with ${providerName}` : ""}
      </Typography>
      <Typography>{memberSummary.dateTime}</Typography>
    </Box>
  );
};

export default MemberPaymentDetails;
