export const isValidPhoneNumber = (phoneNumber: string) => {
  /**
   * According to NANP, valid phone numbers follow NXX NXX XXXX format, where N is [2-9] and X is [0-9].
   * This regex also invalidates the exceptions like N11 (e.g. 911) which can't be used as area codes or central office codes (the 2nd 3-digits).
   * Another thing not accounted for is that the 2nd digit of an area code currently only uses 0-8, as 9 is reserved for decades in the future when the area codes will need to be expanded.
   * This wasn't excluded because it's technically valid but just not currently.
   * There are also Easily Recognizable Codes like 800 or 988 where the 2nd and 3rd digits are the same. This regex does not exclude those either,
   * but that can be done by changing [\d]{2} in the area code section to (\d)(?!\2)[\d] which will ensure the 2nd and 3rd digit are different.
   */
  const validUSPhoneNum =
    /^\(?([2-9](?!11)[\d]{2})\)?[-. ]?([2-9](?!11)[\d]{2})[-. ]?([0-9]{4})$/;
  // TODO: BE doesn't seem to accept international phone numbers as valid phone numbers.
  // Update validation after discussion
  let value = phoneNumber;
  if (!value) {
    return false;
  }

  if (value && !value.match(validUSPhoneNum)) {
    value = value.replace(/-|\s|\.|\(|\)/g, "");
  }

  const isValid = validUSPhoneNum.test(value);
  return isValid;
};

export const isValidZipCode = (code: string): boolean => {
  // TODO: Validation checks for 5 digits or 32010-0040 might want to change this in the future.
  const validUSZipCode = /^\d{5}$|^\d{5}-\d{4}$/;
  return validUSZipCode.test(code);
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const isAlphabets = (input: string): boolean => {
  const validAlphabets = /^[a-zA-Z ]+$/;
  return validAlphabets.test(input);
};

export const isNumbersWithDashes = (input: string): boolean => {
  const numbersWithDashes = /^[0-9-]+$/;
  return numbersWithDashes.test(input);
};

export const isAlphaNumericWithDashes = (input: string): boolean => {
  const alphaNumbersWithDashes = /^[a-zA-Z0-9-]+$/;
  return alphaNumbersWithDashes.test(input);
};
