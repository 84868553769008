import React, { FunctionComponent } from "react";
import clsx from "clsx";
import TypographyComponent, { TypographyProps } from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme, Theme } from "@mui/material";
import spacing from "@mui/system";

export type TypographyAppearance =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "lead"
  | "body"
  | "smallBody"
  | "caption"
  | "miniCaption"
  | "bodyEmphasized";

type IProps = TypographyProps &
  spacing.SpacingProps & {
    appearance?: TypographyAppearance;
    fontWeightBold?: boolean;
    fontWeightNormal?: boolean;
    textDecorationUnderline?: boolean;
    domRef?: React.RefObject<HTMLElement>;
  };

const spacingShorthand = {
  m: "margin",
  mt: "marginTop",
  mb: "marginBottom",
  ml: "marginLeft",
  mr: "marginRight",
  mx: "marginX",
  my: "marginY",
  p: "padding",
  pt: "paddingTop",
  pb: "paddingBottom",
  pl: "paddingLeft",
  pr: "paddingRight",
  px: "paddingX",
  py: "paddingY",
};

const applySpacingAttributes = (props: any) => {
  const theme = useTheme<Theme>();
  let spacingAttrs = {};

  Object.keys(spacingShorthand).map((attr) => {
    const spacingValue: number = props[attr];
    if (props[attr]) {
      spacingAttrs = {
        ...spacingAttrs,
        [spacingShorthand[attr]]: theme.spacing(spacingValue),
      };
    }
  });
  return spacingAttrs;
};

const applyOtherAttributes = (props: any) => {
  let otherAttrs = {};

  for (const key of Object.keys(props)) {
    if (!spacingShorthand[key]) {
      otherAttrs = { ...otherAttrs, [key]: props[key] };
    }
  }
  return otherAttrs;
};

const useStyles = makeStyles(({ typography, palette }) =>
  createStyles({
    h1: typography?.h1,
    h2: typography?.h2,
    h3: typography?.h3,
    h4: typography?.h4,
    h5: typography?.h5,
    lead: {
      ...typography?.h6,
      ...typography?.lead,
    },
    body: typography?.body1,
    // @ts-ignore
    bodyEmphasized: typography?.bodyEmphasized,
    smallBody: typography?.body2,
    caption: typography?.caption,
    miniCaption: {
      ...typography?.caption,
      ...typography?.miniCaption,
    },
    fontWeightBold: {
      // important added to fontWeight as it will be overrided if you use
      // the fontWeightBold prop on soemthing that has appearance="body".
      fontWeight: "bold !important",
    },
    fontWeightNormal: {
      fontWeight: "normal",
    },
    textDecorationUnderline: {
      textDecoration: "underline",
    },
    focusStyle: {
      "&:focus": {
        "&[data-whatintent=keyboard]": {
          border: `2px solid ${palette?.blueHover}`,
          borderRadius: "5px",
        },
      },
    },
  })
);

const Typography: FunctionComponent<IProps> = (props) => {
  const classes = useStyles(props);
  const {
    className,
    appearance,
    fontWeightBold,
    fontWeightNormal,
    textDecorationUnderline,
    domRef,
    ...rest
  } = props;
  let fontWeight = null;
  let textDecoration = null;
  if (fontWeightBold === true) {
    fontWeight = classes.fontWeightBold;
  } else {
    if (fontWeightNormal) {
      fontWeight = classes.fontWeightNormal;
    }
  }
  if (textDecorationUnderline === true) {
    textDecoration = classes.textDecorationUnderline;
  }
  const spacingProps = applySpacingAttributes(rest);
  const typographyProps = applyOtherAttributes(rest);

  return (
    <TypographyComponent
      className={clsx(
        classes[appearance!],
        classes.focusStyle,
        fontWeight,
        textDecoration,
        className
      )}
      style={{ ...spacingProps }}
      {...typographyProps}
      ref={domRef}
    />
  );
};

export default Typography;
