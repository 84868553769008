// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteSlotSeries
 */
export interface DeleteSlotSeries  {
    /**
     * 
     * @type {string}
     * @memberof DeleteSlotSeries
     */
    conflictResolution?: DeleteSlotSeriesConflictResolutionEnum;
}

export function DeleteSlotSeriesFromJSON(json: any): DeleteSlotSeries {
    return {
        'conflictResolution': !exists(json, 'conflict_resolution') ? undefined : json['conflict_resolution'],
    };
}

export function DeleteSlotSeriesToJSON(value?: DeleteSlotSeries): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'conflict_resolution': value.conflictResolution,
    };
}

/**
* @export
* @enum {string}
*/
export enum DeleteSlotSeriesConflictResolutionEnum {
    Cancel = 'cancel',
    Keep = 'keep',
    Ignore = 'ignore'
}


