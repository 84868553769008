import React, { useState } from "react";
import Popover from "components/Popover";
import MenuList from "components/Menu/MenuList";
import { ListItemButton } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "components/Button";
import { DailyScheduleResponseAppointments } from "shared/fetch/src/models/DailyScheduleResponseAppointments";
import { SxProps, Theme } from "@mui/material";
import palette from "styles/mui/palette";
import typography from "styles/mui/typography";
import { MoreOptions } from "./";

interface IProps {
  appointment: DailyScheduleResponseAppointments;
  options: MoreOptions[];
  sxProps: SxProps<Theme>;
  appointmentDetailsLabel: string;
  isActionsExpanded: boolean;
  setIsActionsExpanded: any;
}

export default function OptionsDropdownButton({
  appointment,
  options,
  sxProps,
  appointmentDetailsLabel,
  isActionsExpanded,
  setIsActionsExpanded,
}: IProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const appointmentId = appointment.id;

  const openActionsOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsActionsExpanded(true);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      const popoverList = document.getElementById(
        `appointment-actions-popover-${appointmentId}`
      );
      popoverList?.focus();
    }, 100);
  };

  const closeActionsOptions = () => {
    setIsActionsExpanded(false);
    setAnchorEl(null);
  };

  const handleActionsArrowClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!isActionsExpanded) {
      openActionsOptions(event);
    } else {
      closeActionsOptions();
    }
  };

  return (
    <>
      <Button
        color="inherit"
        sx={{
          ...sxProps,
          padding: 0,
          flex: 1,
          "&:hover": {
            backgroundColor: palette.blueHover,
            color: palette.white,
          },
        }}
        onClick={handleActionsArrowClick}
        aria-label={`More options for visit scheduled for ${appointmentDetailsLabel}`}
      >
        {isActionsExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>

      <Popover
        open={isActionsExpanded && Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setIsActionsExpanded(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList
          tabIndex={-1}
          data-testid={`daily-schedule-dropdown-options-${appointmentId}`}
          id={`appointment-actions-popover-${appointmentId}`}
          aria-label="Options"
        >
          {options.map((option) => (
            <ListItemButton
              sx={{
                ...sxProps,
                "& .MuiButtonBase-root-MuiListItemButton-root": {
                  paddingRight: "16px",
                  paddingLeft: "16px",
                },
                borderRadius: 0,
                width: "146px",
                backgroundColor: palette.white,
                fontSize: typography.body2.fontSize,
                fontWeight: "normal",
                color: palette.darkGrey,
                "&:hover": {
                  backgroundColor: palette.blue,
                  color: palette.white,
                },
              }}
              key={`${option.label}${appointmentId}`}
              onClick={option.clickHandler}
              disabled={option.isDisabled}
            >
              {option.label}
            </ListItemButton>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}
