import React from "react";
import { useSelector } from "react-redux";

import { getCurrentUser } from "shared/features/user/selectors";

import Grid from "components/Grid";
import Hidden from "components/Hidden";
import Box from "components/Box";

import { useStyles } from "pages/LandingPage/styles";
import {
  LandingPageHealthRecordsHotLinks,
  LandingPagePendingPaymentsBalance,
  LandingPageTertiaryCTA,
} from "pages/LandingPage/Common";
import InsuranceCTALandingPage from "./InsuranceCTALandingPage";
import AfterHoursWidget from "components/AfterHoursWidget";
import DependentAccessDisclaimer from "components/Access/DependentAccessDisclaimer";

interface ConversationsLayoutProps {
  children: React.ReactNode;
  isMinorLayout?: boolean;
}

const ConversationsLayout: React.FC<ConversationsLayoutProps> = ({
  children,
  isMinorLayout = false,
}) => {
  const classes = useStyles();
  const member = useSelector(getCurrentUser);
  const message = `For minor account, please call your clinic at ${member?.clinicContact} to gain access to medical record(s).`;

  const SideInfoComponents = () => (
    <React.Fragment>
      {isMinorLayout ? (
        <div className={classes.disclaimerText}>
          <DependentAccessDisclaimer message={message} />
        </div>
      ) : (
        <>
          <LandingPageTertiaryCTA />
          <LandingPagePendingPaymentsBalance />
          <InsuranceCTALandingPage />
        </>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.episodeContainer}>
        <Hidden lgUp>
          <Box style={{ paddingLeft: "16px" }}>
            <LandingPageHealthRecordsHotLinks />
            <div style={{ paddingTop: "16px" }}>
              <AfterHoursWidget />
            </div>
          </Box>
        </Hidden>
        <Grid item lg={8} md={12}>
          {children}
        </Grid>
        <Hidden lgDown>
          <Grid item lg={4} md={12}>
            <AfterHoursWidget />
            <LandingPageHealthRecordsHotLinks />
            <SideInfoComponents />
          </Grid>
        </Hidden>
      </Grid>

      <Hidden lgUp>
        <Box mt={6} mb={3}>
          <SideInfoComponents />
        </Box>
      </Hidden>
    </React.Fragment>
  );
};

export default ConversationsLayout;
