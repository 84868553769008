import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Button";
import FormControlLabel from "components/Form/FormControlLabel";
import Radio from "components/Radio";
import RadioGroup from "components/Radio/RadioGroup";
import Typography from "components/Typography";
import React from "react";
import classes from "./index.module.css";

interface IProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAction: (option: SlotSeriesActionOptions) => void;
  actionType: "update" | "delete";
  slotSeriesOptions: SlotSeriesActionOptions;
  setSlotSeriesOptions: React.Dispatch<
    React.SetStateAction<SlotSeriesActionOptions>
  >;
}

export enum SlotSeriesActionOptions {
  THIS_SLOT = "this_slot",
  THIS_AND_FUTURE = "this_and_future",
  ALL_SLOTS = "all_slots",
}

const SlotSeriesActionConfirmModal = ({
  modalOpen,
  setModalOpen,
  handleAction,
  actionType,
  slotSeriesOptions,
  setSlotSeriesOptions,
}: IProps) => {
  const actionButtonRef = React.useRef<HTMLButtonElement>(null);
  const actionVerb = actionType === "delete" ? "delete" : "update";
  const actionNoun = actionType === "delete" ? "Delete" : "Update";

  return (
    <Dialog open={modalOpen} maxWidth={"xs"}>
      <div className={classes.actionModal} data-testid="action-confirm-modal">
        <DialogTitle className={classes.actionModalTitle}>
          <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
            {" "}
            This slot is a series{" "}
          </Typography>
          <ButtonBase
            className={classes.actionModalCancel}
            role="button"
            aria-label={`Close ${actionVerb} slot modal`}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant={"body1"}>
            <Typography className={classes.text}>
              Select which slots you want to {actionVerb}.
            </Typography>
            <RadioGroup
              name={"slotsToAction"}
              value={slotSeriesOptions}
              onChange={(event) =>
                setSlotSeriesOptions(
                  event.target.value as SlotSeriesActionOptions
                )
              }
              aria-labelledby="action-radio-group-label"
            >
              <FormControlLabel
                control={<Radio />}
                checked={
                  slotSeriesOptions === SlotSeriesActionOptions.THIS_SLOT
                }
                label={
                  <Typography className={classes.text}>
                    Only this slot
                  </Typography>
                }
                value={SlotSeriesActionOptions.THIS_SLOT}
              />
              <FormControlLabel
                checked={
                  slotSeriesOptions === SlotSeriesActionOptions.THIS_AND_FUTURE
                }
                value={SlotSeriesActionOptions.THIS_AND_FUTURE}
                control={<Radio />}
                label={
                  <Typography className={classes.text}>
                    This and all future slots
                  </Typography>
                }
              />
              <FormControlLabel
                checked={
                  slotSeriesOptions === SlotSeriesActionOptions.ALL_SLOTS
                }
                value={SlotSeriesActionOptions.ALL_SLOTS}
                control={<Radio />}
                label={
                  <Typography className={classes.text}>
                    All slots in the series
                  </Typography>
                }
              />
            </RadioGroup>
          </DialogContentText>
          <DialogActions className={classes.actionModalActions}>
            <Button
              ref={actionButtonRef}
              color={"primary"}
              onClick={() => {
                handleAction(slotSeriesOptions);
                setModalOpen(false);
              }}
              aria-label={`Yes, ${actionVerb}. Once you ${actionVerb} it cannot be undone.`}
            >
              {actionNoun}
            </Button>
            <Button
              color={"link-primary"}
              onClick={() => setModalOpen(false)}
              aria-label={`Cancel, do not ${actionVerb} slot`}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default SlotSeriesActionConfirmModal;
