import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import XOCalDatePicker from "./DatePicker/XOCalDatePicker";
import SelectProvider from "components/XOCal/Sidebar/SelectProvider";
import SelectServiceType from "components/XOCal/Sidebar/SelectServiceType";
import SelectCenter from "./Sidebar/SelectCenter";
import { useSearchParams } from "react-router-dom";
import ConflictsLink from "./ConflictsLink";
import { useGetProviders } from "shared/features/xocal/useGetProviders";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";

const useStyles = makeStyles(({ palette }) => ({
  sidebarWrapper: {
    width: "253px",
    padding: "20px",
    backgroundColor: palette?.appBackground?.main,
  },
  sidebarSpacing: {
    paddingBottom: 20,
  },
  sidebarSpacingLarger: {
    paddingBottom: 35,
  },
}));

const CalendarSidebar: FunctionComponent = () => {
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get("clinicId");
  const serviceType = searchParams.get("serviceType");

  const providers = useGetProviders(clinicId as string, serviceType!);

  const alphabatizedProviders = providers.sort((a, b) => {
    if (a.flat && b.flat) {
      if (a.flat < b.flat) {
        return -1;
      }
      if (a.flat > b.flat) {
        return 1;
      }
    }
    return 0;
  });

  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: clinicId as string,
  });

  return (
    <div
      className={`${classes.sidebarWrapper} xo-cal-sidebar`}
      data-testid="xo-cal-sidebar"
    >
      <div className={classes.sidebarSpacing}>XOCal</div>
      <div className={classes.sidebarSpacing}>
        <SelectCenter centers={calendarStaticData?.clinics || []} />
      </div>
      <div className={classes.sidebarSpacingLarger}>
        <XOCalDatePicker />
      </div>
      <div className={classes.sidebarSpacingLarger}>
        {calendarStaticData?.serviceTypes && (
          <SelectServiceType serviceTypes={calendarStaticData.serviceTypes} />
        )}
      </div>
      <div className={classes.sidebarSpacing}>
        <SelectProvider providers={alphabatizedProviders || []} />
      </div>
      <ConflictsLink />
    </div>
  );
};

export default CalendarSidebar;
