// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarStaticDataResponseClinicDetailsInternalHoldTypes
 */
export interface CalendarStaticDataResponseClinicDetailsInternalHoldTypes  {
    /**
     * 
     * @type {string}
     * @memberof CalendarStaticDataResponseClinicDetailsInternalHoldTypes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarStaticDataResponseClinicDetailsInternalHoldTypes
     */
    modality?: string;
}

export function CalendarStaticDataResponseClinicDetailsInternalHoldTypesFromJSON(json: any): CalendarStaticDataResponseClinicDetailsInternalHoldTypes {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'modality': !exists(json, 'modality') ? undefined : json['modality'],
    };
}

export function CalendarStaticDataResponseClinicDetailsInternalHoldTypesToJSON(value?: CalendarStaticDataResponseClinicDetailsInternalHoldTypes): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'modality': value.modality,
    };
}


