// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentOutput,
    AppointmentOutputFromJSON,
    AppointmentOutputToJSON,
} from './AppointmentOutput';
import {
    RestrictedToEnum,
    RestrictedToEnumFromJSON,
    RestrictedToEnumToJSON,
} from './RestrictedToEnum';


/**
 * 
 * @export
 * @interface DeleteDateRangeSlotsSlots
 */
export interface DeleteDateRangeSlotsSlots  {
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    appointmentType?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeleteDateRangeSlotsSlots
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeleteDateRangeSlotsSlots
     */
    endAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    visibility?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    clinicId?: string;
    /**
     * 
     * @type {number}
     * @memberof DeleteDateRangeSlotsSlots
     */
    slotSeriesId?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteDateRangeSlotsSlots
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteDateRangeSlotsSlots
     */
    maxOverbook?: number;
    /**
     * 
     * @type {Date}
     * @memberof DeleteDateRangeSlotsSlots
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeleteDateRangeSlotsSlots
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteDateRangeSlotsSlots
     */
    restore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteDateRangeSlotsSlots
     */
    conflictStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteDateRangeSlotsSlots
     */
    hasReachedMaxCapacity?: boolean;
    /**
     * 
     * @type {RestrictedToEnum}
     * @memberof DeleteDateRangeSlotsSlots
     */
    restrictedTo?: RestrictedToEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteDateRangeSlotsSlots
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {AppointmentOutput}
     * @memberof DeleteDateRangeSlotsSlots
     */
    appointments?: AppointmentOutput;
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteDateRangeSlotsSlots
     */
    key?: string;
}

export function DeleteDateRangeSlotsSlotsFromJSON(json: any): DeleteDateRangeSlotsSlots {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'appointmentType': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'startAt': !exists(json, 'start_at') ? undefined : new Date(json['start_at']),
        'endAt': !exists(json, 'end_at') ? undefined : new Date(json['end_at']),
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'slotSeriesId': !exists(json, 'slot_series_id') ? undefined : json['slot_series_id'],
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'restore': !exists(json, 'restore') ? undefined : json['restore'],
        'conflictStatus': !exists(json, 'conflict_status') ? undefined : json['conflict_status'],
        'hasReachedMaxCapacity': !exists(json, 'has_reached_max_capacity') ? undefined : json['has_reached_max_capacity'],
        'restrictedTo': !exists(json, 'restricted_to') ? undefined : RestrictedToEnumFromJSON(json['restricted_to']),
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'appointments': !exists(json, 'appointments') ? undefined : AppointmentOutputFromJSON(json['appointments']),
        'label': !exists(json, 'label') ? undefined : json['label'],
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function DeleteDateRangeSlotsSlotsToJSON(value?: DeleteDateRangeSlotsSlots): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'appointment_type': value.appointmentType,
        'start_at': value.startAt === undefined ? undefined : value.startAt.toISOString(),
        'end_at': value.endAt === undefined ? undefined : value.endAt.toISOString(),
        'visibility': value.visibility,
        'provider_id': value.providerId,
        'clinic_id': value.clinicId,
        'slot_series_id': value.slotSeriesId,
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'restore': value.restore,
        'conflict_status': value.conflictStatus,
        'has_reached_max_capacity': value.hasReachedMaxCapacity,
        'restricted_to': RestrictedToEnumToJSON(value.restrictedTo),
        'appointment_types': value.appointmentTypes,
        'appointments': AppointmentOutputToJSON(value.appointments),
        'label': value.label,
        'key': value.key,
    };
}


