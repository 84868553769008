import React, { FunctionComponent } from "react";
import classes from "./index.module.css";
import RadioGroup from "components/Radio/RadioGroup";
import FormControlLabel from "components/Form/FormControlLabel";
import Typography from "components/Typography";
import Radio from "components/Radio";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";

interface IProps {
  visibility: SlotVisibilityEnum;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  disabled?: boolean;
}

const SelectVisibility: FunctionComponent<IProps> = ({
  visibility,
  handleChange,
  disabled,
}) => {
  return (
    <RadioGroup
      name="visibility"
      value={visibility || SlotVisibilityEnum.Hold}
      onChange={handleChange}
      className={classes.radioGroup}
      aria-labelledby="visibility-radio-group-label"
    >
      <FormControlLabel
        checked={visibility === SlotVisibilityEnum.Hold}
        value={SlotVisibilityEnum.Hold}
        control={<Radio />}
        disabled={disabled}
        label={
          <span className={classes.radioLabel}>
            <Typography className={classes.text}>Hold</Typography>
            <Typography className={classes.smallText}>
              (Not visible to members for scheduling or in the proxy)
            </Typography>
          </span>
        }
      />
      <FormControlLabel
        checked={visibility === SlotVisibilityEnum.Internal}
        value={SlotVisibilityEnum.Internal}
        control={<Radio />}
        disabled={disabled}
        label={
          <span className={classes.radioLabel}>
            <Typography className={classes.text}>Private</Typography>
            <Typography className={classes.smallText}>
              {" "}
              (Not visible to members for scheduling)
            </Typography>
          </span>
        }
      />
      <FormControlLabel
        checked={visibility === SlotVisibilityEnum.Accessible}
        value={SlotVisibilityEnum.Accessible}
        control={<Radio />}
        disabled={disabled}
        label={
          <span className={classes.radioLabel}>
            <Typography className={classes.text}>Public</Typography>
            <Typography className={classes.smallText}>
              {" "}
              (Visible to members for scheduling)
            </Typography>
          </span>
        }
      />
    </RadioGroup>
  );
};

export default SelectVisibility;
