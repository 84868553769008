/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CalendarStaticDataResponse,
    CalendarStaticDataResponseFromJSON,
    CalendarStaticDataResponseToJSON,
} from '../models/CalendarStaticDataResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetCalendarStaticDataRequest {
    clinicId: string;
}


export interface ICalendarApi {
      getCalendarStaticData: (requestParameters: GetCalendarStaticDataRequest) => Promise<CalendarStaticDataResponse>
      getCalendarStaticDataWithResponse: (requestParameters: GetCalendarStaticDataRequest) => Promise<{ value: CalendarStaticDataResponse, response: any}>
}

/**
 * no description
 */
export class CalendarApi extends runtime.BaseAPI {

    /**
     * Return all the provider, appointment types, and clinic data needed for the calendar to display.
     * Return all the provider, appointment types, and clinic data needed for the calendar to display.
     */
    getCalendarStaticDataRaw = async (requestParameters: GetCalendarStaticDataRequest): Promise<{ runtime: runtime.ApiResponse<CalendarStaticDataResponse>, response: any}> => {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getCalendarStaticData.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/calendar-static-data/{clinic_id}`.replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CalendarStaticDataResponseFromJSON(jsonValue)), response };
    }

    /**
     * Return all the provider, appointment types, and clinic data needed for the calendar to display.
     * Return all the provider, appointment types, and clinic data needed for the calendar to display.
     */
    getCalendarStaticData = async (requestParameters: GetCalendarStaticDataRequest): Promise<CalendarStaticDataResponse> => {
        const { runtime } = await this.getCalendarStaticDataRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Return all the provider, appointment types, and clinic data needed for the calendar to display.
     * Return all the provider, appointment types, and clinic data needed for the calendar to display.
     */
    getCalendarStaticDataWithResponse = async (requestParameters: GetCalendarStaticDataRequest): Promise<{ value: CalendarStaticDataResponse, response: any}> => {
        const { runtime, response } = await this.getCalendarStaticDataRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCalendarApi: ICalendarApi  = new CalendarApi();
