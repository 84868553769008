// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPayLaterSchema
 */
export interface PostPayLaterSchema  {
    /**
     * 
     * @type {string}
     * @memberof PostPayLaterSchema
     */
    appointmentId: string;
    /**
     * 
     * @type {string}
     * @memberof PostPayLaterSchema
     */
    patientId: string;
    /**
     * 
     * @type {string}
     * @memberof PostPayLaterSchema
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof PostPayLaterSchema
     */
    status: string;
}

export function PostPayLaterSchemaFromJSON(json: any): PostPayLaterSchema {
    return {
        'appointmentId': json['appointment_id'],
        'patientId': json['patient_id'],
        'amount': json['amount'],
        'status': json['status'],
    };
}

export function PostPayLaterSchemaToJSON(value?: PostPayLaterSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointment_id': value.appointmentId,
        'patient_id': value.patientId,
        'amount': value.amount,
        'status': value.status,
    };
}


