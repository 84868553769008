import React from "react";
import { Drawer, ButtonBase } from "@mui/material";
import classes from "./index.module.css";
import Heading from "components/DynamicHeadings";
import Divider from "components/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { ValuesType } from "./utils";
import SearchAndScheduleMember from "./SearchAndScheduleMember";
import { useSearchParams } from "react-router-dom";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import useUpdateAppointment from "shared/features/xocal/useUpdateAppointment";
import CancelVisitModal from "./CancelVisitModal";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { useQueryClient } from "react-query";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { getFetchSlotQueryKey } from "shared/features/xocal/useGetSlot";
import Button from "components/Button";

const EditMemberDrawer = ({
  open,
  setOpen,
  values,
  setField,
  handleChange,
  visitReason,
  setVisitReason,
  cancelModalOpen,
  setCancelModalOpen,
  appointmentType,
  slot,
  timezone,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  values: ValuesType;
  setField: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<any>) => void;
  visitReason: string | undefined;
  setVisitReason: React.Dispatch<React.SetStateAction<string | undefined>>;
  cancelModalOpen: boolean;
  setCancelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentType: string | undefined;
  slot: SlotOutput | undefined;
  timezone: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: updateAppointment, isLoading } = useUpdateAppointment();
  const queryClient = useQueryClient();

  const appointmentId = searchParams.get("appointmentId");
  const clinicId = searchParams.get("clinicId");

  const handleCloseSlot = () => {
    setOpen(false);
    searchParams.delete("member");
    searchParams.delete("appointmentId");
    setSearchParams(searchParams);
  };

  const handleDeleteMember = () => {
    setCancelModalOpen(true);
  };

  const handleEditVisit = async () => {
    await updateAppointment(
      {
        id: appointmentId!,
        updateAppointmentById: {
          reason: visitReason,
          appointmentType: values?.appointmentType,
          conflicted: false,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getFetchSlotsQueryKey({
              clinicId: clinicId as string,
            }),
          });
          queryClient.invalidateQueries({
            queryKey: getFetchSlotQueryKey({ id: values.id?.toString() }),
          });
        },
      }
    );
    searchParams.delete("member");
    searchParams.delete("appointmentId");
    setSearchParams(searchParams);
    setOpen(false);
  };

  return (
    <>
      <CancelVisitModal
        title="Cancel visit"
        confirmButtonAriaLabel="Cancel visit"
        confirmButtonDisplayLabel="Cancel visit"
        isOpen={cancelModalOpen}
        setIsOpen={setCancelModalOpen}
        values={values}
        appointmentType={appointmentType}
        setEditMemberDrawerOpen={setOpen}
        appointmentTypeDisplayLabel={appointmentType}
      />
      <Drawer
        open={open}
        transitionDuration={400}
        anchor="right"
        classes={{
          paper: classes.drawer,
        }}
        role="dialog"
        aria-labelledby="drawer-title"
        onClose={handleCloseSlot} // TODO: require this prop on our imported Drawer component
        data-testid="slot-action-drawer"
      >
        <div className={classes.topContainer}>
          <div className={classes.titleContainer}>
            <Heading.H
              appearance="h5"
              className={classes.text}
              id="drawer-title"
            >
              Edit visit
            </Heading.H>
            <div>
              <ButtonBase
                role="button"
                aria-label="Delete member from visit"
                onClick={handleDeleteMember}
              >
                <EventBusyOutlinedIcon className={classes.deleteMemberIcon} />
              </ButtonBase>
              <ButtonBase
                role="button"
                aria-label={`Close edit visit dialog`}
                onClick={handleCloseSlot}
              >
                <CloseIcon className={classes.closeIcon} />
              </ButtonBase>
            </div>
          </div>
          <Divider />
          <div className={classes.sectionEdit}>
            <SearchAndScheduleMember
              values={values}
              setField={setField}
              handleChange={handleChange}
              additionalMember={false}
              visitReason={visitReason}
              setVisitReason={setVisitReason}
              editMember
              slot={slot}
              timezone={timezone}
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.createUpdateButton}
              onClick={handleEditVisit}
              variant="contained"
              isLoading={isLoading}
              disabled={values?.appointmentTypes?.length === 0}
            >
              Save
            </Button>
            <Button
              variant="text"
              className={classes.cancelButton}
              onClick={handleCloseSlot}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default EditMemberDrawer;
