// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClinicConfigMisc,
    ClinicConfigMiscFromJSON,
    ClinicConfigMiscToJSON,
} from './ClinicConfigMisc';
import {
    ClinicConfigSchemaAnys,
    ClinicConfigSchemaAnysFromJSON,
    ClinicConfigSchemaAnysToJSON,
} from './ClinicConfigSchemaAnys';
import {
    ClinicConfigSchemaCosts,
    ClinicConfigSchemaCostsFromJSON,
    ClinicConfigSchemaCostsToJSON,
} from './ClinicConfigSchemaCosts';
import {
    ClinicConfigSchemaModalities,
    ClinicConfigSchemaModalitiesFromJSON,
    ClinicConfigSchemaModalitiesToJSON,
} from './ClinicConfigSchemaModalities';
import {
    ClinicConfigSchemaReexaminationWindows,
    ClinicConfigSchemaReexaminationWindowsFromJSON,
    ClinicConfigSchemaReexaminationWindowsToJSON,
} from './ClinicConfigSchemaReexaminationWindows';


/**
 * 
 * @export
 * @interface ClinicConfigSchema
 */
export interface ClinicConfigSchema  {
    /**
     * 
     * @type {number}
     * @memberof ClinicConfigSchema
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    clinicId: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    zone: string;
    /**
     * 
     * @type {Date}
     * @memberof ClinicConfigSchema
     */
    open?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    dial: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    scap: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    elationDefaultProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    elationPractice?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    clinicType?: ClinicConfigSchemaClinicTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    enablePcpEnhancement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    enablePcpCareTeamEnhancement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    hideLandingPageOfficeBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    enablePros?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof ClinicConfigSchema
     */
    hoursOfOperation: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ClinicConfigSchema
     */
    memberHoursOfOperation: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    wait?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicConfigSchema
     */
    zip?: Array<string>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaAnys>}
     * @memberof ClinicConfigSchema
     */
    anys?: Array<ClinicConfigSchemaAnys>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaModalities>}
     * @memberof ClinicConfigSchema
     */
    modalities?: Array<ClinicConfigSchemaModalities>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaReexaminationWindows>}
     * @memberof ClinicConfigSchema
     */
    reexaminationWindows?: Array<ClinicConfigSchemaReexaminationWindows>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaReexaminationWindows>}
     * @memberof ClinicConfigSchema
     */
    bookingThrottles?: Array<ClinicConfigSchemaReexaminationWindows>;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    checkinPin?: string;
    /**
     * The name of the mailer.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    mailerName?: string;
    /**
     * The dial information for the mailer.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    mailerDial?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    mailerZone?: string;
    /**
     * The state associated with the mailer.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    mailerState?: string;
    /**
     * The sender address for communications.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    from?: string;
    /**
     * The NPID (unique identifier) for the clinic.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    npid?: string;
    /**
     * The host address for the clinic configuration.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    host?: string;
    /**
     * The base configuration or URL.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    base?: string;
    /**
     * The port number used in configurations.
     * @type {number}
     * @memberof ClinicConfigSchema
     */
    port?: number;
    /**
     * An array of strings containing additional copy of emails
     * @type {Array<string>}
     * @memberof ClinicConfigSchema
     */
    copy?: Array<string>;
    /**
     * An array of strings containing patient search scopes ef_ids.
     * @type {Array<string>}
     * @memberof ClinicConfigSchema
     */
    patientSearchScopes?: Array<string>;
    /**
     * 
     * @type {ClinicConfigMisc}
     * @memberof ClinicConfigSchema
     */
    misc?: ClinicConfigMisc;
    /**
     * 
     * @type {Array<ClinicConfigSchemaCosts>}
     * @memberof ClinicConfigSchema
     */
    costs?: Array<ClinicConfigSchemaCosts>;
    /**
     * SMS configuration or details.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    smss?: string;
    /**
     * Spot location or identifier.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    spot?: string;
    /**
     * Building identifier.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    bldg?: string;
    /**
     * Address details.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    addr?: string;
    /**
     * Map URL or identifier.
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    mapu?: string;
    /**
     * Indicates whether no-show/late cancel emails are disabled.
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    noshowLatecancelEmailsDisabled?: boolean;
}

export function ClinicConfigSchemaFromJSON(json: any): ClinicConfigSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clinicId': json['clinic_id'],
        'name': json['name'],
        'code': json['code'],
        'zone': json['zone'],
        'open': !exists(json, 'open') ? undefined : new Date(json['open']),
        'dial': json['dial'],
        'scap': json['scap'],
        'state': json['state'],
        'elationDefaultProvider': !exists(json, 'elation_default_provider') ? undefined : json['elation_default_provider'],
        'elationPractice': !exists(json, 'elation_practice') ? undefined : json['elation_practice'],
        'clinicType': !exists(json, 'clinic_type') ? undefined : json['clinic_type'],
        'enablePcpEnhancement': !exists(json, 'enable_pcp_enhancement') ? undefined : json['enable_pcp_enhancement'],
        'enablePcpCareTeamEnhancement': !exists(json, 'enable_pcp_care_team_enhancement') ? undefined : json['enable_pcp_care_team_enhancement'],
        'hideLandingPageOfficeBooking': !exists(json, 'hide_landing_page_office_booking') ? undefined : json['hide_landing_page_office_booking'],
        'enablePros': !exists(json, 'enable_pros') ? undefined : json['enable_pros'],
        'hoursOfOperation': json['hours_of_operation'],
        'memberHoursOfOperation': json['member_hours_of_operation'],
        'wait': !exists(json, 'wait') ? undefined : json['wait'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'anys': !exists(json, 'anys') ? undefined : (json['anys'] as Array<any>).map(ClinicConfigSchemaAnysFromJSON),
        'modalities': !exists(json, 'modalities') ? undefined : (json['modalities'] as Array<any>).map(ClinicConfigSchemaModalitiesFromJSON),
        'reexaminationWindows': !exists(json, 'reexamination_windows') ? undefined : (json['reexamination_windows'] as Array<any>).map(ClinicConfigSchemaReexaminationWindowsFromJSON),
        'bookingThrottles': !exists(json, 'booking_throttles') ? undefined : (json['booking_throttles'] as Array<any>).map(ClinicConfigSchemaReexaminationWindowsFromJSON),
        'checkinPin': !exists(json, 'checkin_pin') ? undefined : json['checkin_pin'],
        'mailerName': !exists(json, 'mailer_name') ? undefined : json['mailer_name'],
        'mailerDial': !exists(json, 'mailer_dial') ? undefined : json['mailer_dial'],
        'mailerZone': !exists(json, 'mailer_zone') ? undefined : json['mailer_zone'],
        'mailerState': !exists(json, 'mailer_state') ? undefined : json['mailer_state'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'npid': !exists(json, 'npid') ? undefined : json['npid'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'base': !exists(json, 'base') ? undefined : json['base'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'copy': !exists(json, 'copy') ? undefined : json['copy'],
        'patientSearchScopes': !exists(json, 'patient_search_scopes') ? undefined : json['patient_search_scopes'],
        'misc': !exists(json, 'misc') ? undefined : ClinicConfigMiscFromJSON(json['misc']),
        'costs': !exists(json, 'costs') ? undefined : (json['costs'] as Array<any>).map(ClinicConfigSchemaCostsFromJSON),
        'smss': !exists(json, 'smss') ? undefined : json['smss'],
        'spot': !exists(json, 'spot') ? undefined : json['spot'],
        'bldg': !exists(json, 'bldg') ? undefined : json['bldg'],
        'addr': !exists(json, 'addr') ? undefined : json['addr'],
        'mapu': !exists(json, 'mapu') ? undefined : json['mapu'],
        'noshowLatecancelEmailsDisabled': !exists(json, 'noshow_latecancel_emails_disabled') ? undefined : json['noshow_latecancel_emails_disabled'],
    };
}

export function ClinicConfigSchemaToJSON(value?: ClinicConfigSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'clinic_id': value.clinicId,
        'name': value.name,
        'code': value.code,
        'zone': value.zone,
        'open': value.open === undefined ? undefined : value.open.toISOString().substr(0,10),
        'dial': value.dial,
        'scap': value.scap,
        'state': value.state,
        'elation_default_provider': value.elationDefaultProvider,
        'elation_practice': value.elationPractice,
        'clinic_type': value.clinicType,
        'enable_pcp_enhancement': value.enablePcpEnhancement,
        'enable_pcp_care_team_enhancement': value.enablePcpCareTeamEnhancement,
        'hide_landing_page_office_booking': value.hideLandingPageOfficeBooking,
        'enable_pros': value.enablePros,
        'hours_of_operation': value.hoursOfOperation,
        'member_hours_of_operation': value.memberHoursOfOperation,
        'wait': value.wait,
        'zip': value.zip,
        'anys': value.anys === undefined ? undefined : (value.anys as Array<any>).map(ClinicConfigSchemaAnysToJSON),
        'modalities': value.modalities === undefined ? undefined : (value.modalities as Array<any>).map(ClinicConfigSchemaModalitiesToJSON),
        'reexamination_windows': value.reexaminationWindows === undefined ? undefined : (value.reexaminationWindows as Array<any>).map(ClinicConfigSchemaReexaminationWindowsToJSON),
        'booking_throttles': value.bookingThrottles === undefined ? undefined : (value.bookingThrottles as Array<any>).map(ClinicConfigSchemaReexaminationWindowsToJSON),
        'checkin_pin': value.checkinPin,
        'mailer_name': value.mailerName,
        'mailer_dial': value.mailerDial,
        'mailer_zone': value.mailerZone,
        'mailer_state': value.mailerState,
        'from': value.from,
        'npid': value.npid,
        'host': value.host,
        'base': value.base,
        'port': value.port,
        'copy': value.copy,
        'patient_search_scopes': value.patientSearchScopes,
        'misc': ClinicConfigMiscToJSON(value.misc),
        'costs': value.costs === undefined ? undefined : (value.costs as Array<any>).map(ClinicConfigSchemaCostsToJSON),
        'smss': value.smss,
        'spot': value.spot,
        'bldg': value.bldg,
        'addr': value.addr,
        'mapu': value.mapu,
        'noshow_latecancel_emails_disabled': value.noshowLatecancelEmailsDisabled,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClinicConfigSchemaClinicTypeEnum {
    Virtual = 'virtual',
    OnSite = 'on_site'
}


