import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import classes from "./index.module.css";
import { ButtonBase, TextField, Typography, Box } from "@mui/material";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { ValuesType } from "./utils";
import MemberInformation from "./MemberInformation";
import useCancelAppointment from "shared/features/xocal/useCancelAppointment";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { AppointmentPatientInfoObject } from "shared/fetch/src/models/AppointmentPatientInfoObject";
import { GetSlotsForDateRangeConflictedEnum } from "shared/fetch/src/apis/SlotsApi";
import { useQueryClient } from "react-query";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { getFetchSlotQueryKey } from "shared/features/xocal/useGetSlot";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import {
  getProvidersObjectKeyedById,
  getAppointmentObjectKeyedById,
} from "components/XOCal/utils";
import theme from "styles/mui";

// There are different sets of required props depending on where this
// modal is used
// TODO: I'm unsure why the typing below doens't work. Come back to it.
// interface RenderedFromXoCalDrawerProps {
//   title: string;
//   confirmButtonDisplayLabel: string;
//   confirmButtonAriaLabel?: string;
//   isOpen: boolean;
//   setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
//   values: ValuesType | undefined;
//   appointmentType: string | undefined;
//   setEditMemberDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
// }

// interface RenderedFromConflictsQueueProps extends RenderedFromXoCalDrawerProps {
//   isConflictsQueueModal: true;
//   slotFromConflictsQueue: SlotOutput;
//   patientInfoFromConflictsQueue: AppointmentPatientInfoObject;
//   hasRescheduleRedirect?: boolean;
// }

// type IProps =
//   | RenderedFromXoCalDrawerProps
//   | RenderedFromConflictsQueueProps;

interface IProps {
  title: string;
  confirmButtonDisplayLabel: string;
  confirmButtonAriaLabel?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  values: ValuesType | undefined;
  appointmentType: string | undefined;
  setEditMemberDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isConflictsQueueModal?: boolean;
  slotFromConflictsQueue?: SlotOutput;
  patientInfoFromConflictsQueue?: AppointmentPatientInfoObject;
  hasRescheduleRedirect?: boolean;
  startTimeStringForRescheduleFlow?: string;
  appointmentTypeDisplayLabel?: string;
}

export interface IRedirectFromConflictsQueueState {
  isRescheduleFromConflictsQueue: boolean;
  cancelledAppointmentInfo: string;
}

// modal is rendered from slot action drawer and conflicts queue, with slightly
const CancelVisitModal = ({
  title,
  confirmButtonDisplayLabel,
  confirmButtonAriaLabel,
  isOpen,
  setIsOpen,
  values,
  appointmentType,
  setEditMemberDrawerOpen,
  isConflictsQueueModal,
  slotFromConflictsQueue,
  patientInfoFromConflictsQueue,
  hasRescheduleRedirect,
  startTimeStringForRescheduleFlow,
  appointmentTypeDisplayLabel,
}: IProps) => {
  const deleteButtonRef = React.useRef<HTMLButtonElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: cancelAppointment } = useCancelAppointment();
  const [cancelReason, setCancelReason] = React.useState<string>("");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // the apptId from params is used when the modal is rendered from the SlotActionDrawer
  const apptIdFromParams = searchParams.get("appointmentId");
  // but we don't have that params in the conflicts queue, so we get the apptId from props
  const apptIdFromConflictsQueue =
    slotFromConflictsQueue?.appointments &&
    slotFromConflictsQueue?.appointments[0].id;

  const clinicId = searchParams.get("clinicId");
  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: clinicId?.toString() as string,
  });

  const handleCloseModal = () => {
    updateParamsAfterVisitCancelledFromDrawer();
    setIsOpen(false);
  };

  // click handlers and helpers for modal when triggered from slot action drawer
  const updateParamsAfterVisitCancelledFromDrawer = () => {
    searchParams.delete("member");
    searchParams.delete("appointmentId");
    setSearchParams(searchParams);
  };

  const handleCancelVisitFromDrawer = () => {
    apptIdFromParams &&
      cancelAppointment(
        {
          id: apptIdFromParams?.toString(),
          updateAppointment: { reason: cancelReason },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: getFetchSlotsQueryKey({
                clinicId: clinicId as string,
                conflicted: GetSlotsForDateRangeConflictedEnum.True,
              }),
            });
            queryClient.invalidateQueries(
              getFetchSlotQueryKey({ id: values?.id?.toString() })
            );
          },
        }
      );

    updateParamsAfterVisitCancelledFromDrawer();
    setIsOpen(false);
    setEditMemberDrawerOpen && setEditMemberDrawerOpen(false);
  };

  // click handlers and helpers for modal when triggered from slot action drawer
  const cancelVisitAndRefetchSlots = () => {
    apptIdFromConflictsQueue &&
      cancelAppointment(
        {
          // when we use the modal from the conflicts queue the appt id will not be in the url params
          id: apptIdFromConflictsQueue?.toString(),
          updateAppointment: { reason: cancelReason },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: getFetchSlotsQueryKey({
                clinicId: clinicId as string,
                conflicted: GetSlotsForDateRangeConflictedEnum.True,
              }),
            });
            queryClient.invalidateQueries(
              getFetchSlotQueryKey({
                id: slotFromConflictsQueue?.id?.toString(),
              })
            );
          },
        }
      );
  };

  const getCancelledAppointmentInfo = () => {
    const allClinicProvidersObject =
      getProvidersObjectKeyedById(calendarStaticData);
    const { providerId } = slotFromConflictsQueue!;
    const providerObj = allClinicProvidersObject[providerId!];
    const providerName = providerObj.name;
    const allClinicApptsObj = getAppointmentObjectKeyedById(calendarStaticData);
    const apptObj = allClinicApptsObj[slotFromConflictsQueue?.appointmentType!];
    const appointmentLabel = apptObj.name;

    const patientName = patientInfoFromConflictsQueue?.name;
    return `You are rescheduling the ${appointmentLabel} visit for ${patientName} with ${providerName}. The visit was orginally scheduled for ${startTimeStringForRescheduleFlow}`;
  };

  const navigateToSelfSchedule = () => {
    const patientId = patientInfoFromConflictsQueue?.id;
    const cancelledAppointmentInfo = getCancelledAppointmentInfo();

    const state: IRedirectFromConflictsQueueState = {
      isRescheduleFromConflictsQueue: true,
      cancelledAppointmentInfo,
    };

    navigate(`/members/${patientId}/self-schedule?step=visitType`, { state });
  };

  const handleCancelVisitFromConflictsQueue = () => {
    cancelVisitAndRefetchSlots();
    setIsOpen(false);
    if (hasRescheduleRedirect) {
      navigateToSelfSchedule();
    }
  };

  const getPatientInfoFromValues = () => {
    // when this modal is rendered from the slot action drawer
    // there are possibly multiple members scheduled on the slot
    // so we have to find the correct appointment
    let result;
    if (values?.appointments?.length) {
      const memberId = searchParams.get("member");
      const appointment = values?.appointments.find(
        (a) => a.patientInfo?.id?.toString() === memberId
      );
      result = appointment?.patientInfo;
    }
    return result;
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      {" "}
      <div
        className={classes.cancelMemberModal}
        data-testid="cancel-confirm-modal"
      >
        <div className={classes.cancelMemberModalTitleContainer}>
          <ButtonBase
            className={classes.deleteModalCancel}
            role="button"
            aria-label={`Close modal`} // is this correct, or are we cancelling a visit?
            onClick={handleCloseModal}
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
          <DialogTitle component="h1" className={classes.cancelModalTitle}>
            {" "}
            {title}
          </DialogTitle>{" "}
        </div>
        <DialogContent>
          <DialogContentText variant={"body2"}>
            <Typography sx={{ paddingBottom: "16px", width: "90%" }}>
              Once the visit is cancelled, the Member will be notified.
            </Typography>
            {isConflictsQueueModal ? (
              <MemberInformation
                editMember={false}
                cancelVisit
                appointmentType={appointmentType}
                patientInfoFromConflictsQueue={patientInfoFromConflictsQueue}
                appointmentTypeDisplayLabel={appointmentTypeDisplayLabel}
                isRenderedInCancelModal
              />
            ) : (
              <>
                <MemberInformation
                  values={values as ValuesType}
                  editMember={false}
                  cancelVisit
                  patientInfoFromConflictsQueue={getPatientInfoFromValues()}
                  appointmentType={appointmentType}
                  appointmentTypeDisplayLabel={appointmentTypeDisplayLabel}
                  isRenderedInCancelModal
                />
              </>
            )}
          </DialogContentText>
          <Typography
            sx={{
              paddingTop: theme.spacing(1.25),
              paddingBottom: theme.spacing(0.1),
            }}
            id="cancel-modal-provide-optional-reason-label"
          >
            Provide a reason
          </Typography>
          <TextField
            fullWidth
            multiline
            className={classes.cancelReasonTextBox}
            value={cancelReason}
            rows={5}
            onChange={(e) => {
              setCancelReason(e.target.value);
            }}
            placeholder={"Cancellation reason - optional"}
            inputProps={{
              "aria-labelledby": "cancel-modal-provide-optional-reason-label",
            }}
          />
          <DialogActions className={classes.cancelMemberModalActions}>
            <Box>
              <Button
                ref={deleteButtonRef}
                color={"primary"}
                onClick={
                  isConflictsQueueModal
                    ? handleCancelVisitFromConflictsQueue
                    : handleCancelVisitFromDrawer
                }
                aria-label={confirmButtonAriaLabel || confirmButtonDisplayLabel}
                className={classes.cancelMemberButton}
              >
                {confirmButtonDisplayLabel}
              </Button>
              <Button
                color="link-primary"
                onClick={() => setIsOpen(false)}
                className={classes.nevermindButton}
              >
                No, nevermind
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default CancelVisitModal;
