import React from "react";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import styles from "components/XOCal/EventContent.module.css";
import { CheckinStatusEnum } from "shared/fetch/src/models/CheckinStatusEnum";
import clsx from "clsx";
import {
  EventSummary,
  FullInformation,
  VisibilityIcon,
  SlotSeriesIcon,
  RestrictedIcon,
  ScheduledPatient,
  GroupPatientInfo,
  VisitNotesIcon,
  ConflictsIcon,
} from "./Sections";
import { ClinicAppointmentsKeyedByAppointmentId } from "../utils";

interface IProps {
  slot: SlotOutput;
  slotId: string;
  eventStart: string;
  eventEnd: string;
  eventMinutes: number;
  title: string;
  appointmentInfoByAppointmentId: ClinicAppointmentsKeyedByAppointmentId;
  timezone: string;
}

export default function Slot({
  slot,
  slotId,
  eventStart,
  eventEnd,
  eventMinutes,
  title,
  appointmentInfoByAppointmentId,
  timezone,
}: IProps) {
  const {
    appointments,
    restrictedTo,
    slotSeries: isSlotSeries,
    conflictStatus: isConflicted,
    visibility,
  } = slot;
  // TODO: make sure we should use [0] here, what if there are muliple patients?
  const patientInfo = appointments?.[0]?.patientInfo;
  const totalVisitCapacity = slot.maxPatients! + slot.maxOverbook!;

  // A slot is booked if we have any appointments.
  const isBooked = appointments && appointments.length > 0;
  // A slot may be able to be booked more than once.
  const isMaxBooked = appointments && appointments.length >= totalVisitCapacity;
  // A slot is open for group bookings if it can accomodate more than one member at a time
  const isGroup = totalVisitCapacity && totalVisitCapacity >= 2;

  const isHold = visibility === SlotVisibilityEnum.Hold;
  const isInternal = visibility === SlotVisibilityEnum.Internal; // 'private' in the SlotActionDrawer UI
  const isAccessible = visibility === SlotVisibilityEnum.Accessible;
  const isRestricted = restrictedTo && restrictedTo !== "not_restricted";

  const isCheckInPending =
    isBooked &&
    appointments?.every((a) => a.checkinStatus === CheckinStatusEnum.Pending);
  const isCheckInCompleted =
    isBooked &&
    appointments?.every((a) => a.checkinStatus === CheckinStatusEnum.Done);

  const ariaLabel = `Event, ${eventStart} to ${eventEnd}, ${eventMinutes} minutes, ${title} ${
    isGroup ? `, Group with ${totalVisitCapacity} maximum patients` : ""
  }`;

  const slotClassNames = clsx({
    [styles.eventContainer]: true,
    group: isGroup,
    booked: !isGroup && !isHold && isBooked,
    maxBooked: isGroup && isMaxBooked,
    internalSlot: isInternal,
    openSlot:
      (!isGroup && !isHold && !isBooked) ||
      (!isHold && isGroup && !isMaxBooked),
    bookedHoldSlot: isHold && isBooked,
    openHoldGroupSlot: isGroup && isHold && !isBooked,
    partiallyBooked: isGroup && isBooked && !isMaxBooked,
    checkInPending: !isHold && isCheckInPending,
    checkInCompleted: !isHold && isCheckInCompleted,
    conflicted: isConflicted,
  });

  return (
    <div
      className={slotClassNames}
      role="button"
      data-testid={`slot-${slotId}`}
      aria-label={ariaLabel}
    >
      <div>
        <div className={styles.titleDisplayContainer}>
          <div className={styles.titleAndDate}>
            <div className={styles.iconsContainer}>
              <VisibilityIcon
                isHold={isHold}
                isInternal={isInternal}
                isAccessible={isAccessible}
              />
              <EventSummary
                slot={slot}
                appointmentInfoByAppointmentId={appointmentInfoByAppointmentId}
              />
              <RestrictedIcon isRestricted={Boolean(isRestricted)} />
              <SlotSeriesIcon isSlotSeries={Boolean(isSlotSeries)} />
              <VisitNotesIcon appointments={appointments!} />
              <ConflictsIcon isConflicted={Boolean(isConflicted)} />
            </div>
            <div className={styles.titleTrunaction}>{title}</div>
            <span className={styles.minuteText}>{eventMinutes} min</span>
          </div>
          {isGroup ? (
            <GroupPatientInfo
              patientInfo={patientInfo}
              appointments={appointments!}
              totalNumberOfPatients={totalVisitCapacity}
            />
          ) : (
            <ScheduledPatient patientInfo={patientInfo} />
          )}
        </div>
      </div>
      <FullInformation
        slot={slot}
        slotId={slotId}
        timezone={timezone}
        appointmentInfoByAppointmentId={appointmentInfoByAppointmentId}
      />
    </div>
  );
}
