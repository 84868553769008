import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import ProviderButton from "./ProviderButton";
import { Theme } from "@mui/material/styles";
import ButtonBase from "components/Button/ButtonBase";
import { Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "components/Button";
import AddIcon from "@mui/icons-material/Add";
import { DAY_VIEW } from "./utils";
import Popover from "@mui/material/Popover";
import { useSearchParams } from "react-router-dom";
import ProviderSearch from "./ProviderSearch";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";

interface IProps {
  // TODO: type
  currentProvider: XOCalProvider | undefined;
  onClickToday(): void;
  onClickPrev(): void;
  onClickNext(): void;
  viewTitle?: string;
  onClickCreateSlotButton: () => void;
  setIsSlotCreation: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: (arg0: boolean) => void;
  hasFullXoCalPermissions: boolean;
  isXoCalHeader?: boolean;
  oooDays: Set<{
    start: "";
    end: "";
    provider: "";
  }>;
  isClinicOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: "8px",
      border: `1px solid ${theme?.palette?.appBackground?.darkestGray}`,
      padding: "5px 10px",
      color: theme?.palette?.appBackground?.darkestGray,
      backgroundColor: theme?.palette?.appBackground?.lightestGray,
      marginRight: "5px",
      whiteSpace: "nowrap",
    },
    arrowButton: {
      color: theme?.palette?.appBackground?.darkestGray,
    },
    createSlotButton: {
      padding: "4px 16px 4px 16px",
      minWidth: "150px",
    },
    createSlotButtonWeekView: {
      borderRadius: "50%",
      padding: "0px !important",
      margin: "0px !important",
      height: "30px",
      minWidth: "32px",
      borderWidth: "2px",
    },
    icon: {
      width: "24px",
      height: "24px",
      fill: theme?.palette?.text?.primary,
      marginLeft: "8px",
    },
    iconWeekView: {
      width: "24px",
      height: "24px",
      fill: theme?.palette?.text?.primary,
    },
    disabledIcon: {
      width: "24px",
      height: "24px",
      fill: theme?.palette?.text?.disabled,
    },
    providerDropdownModal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      border: "2px solid #000",
    },
  })
);

const getArrowButtonLabel = (direction: "back" | "forward", view: string) => {
  const dayView = view === DAY_VIEW;
  const timeInterval = view !== "" ? `${dayView ? "day" : "week"}` : "";
  if (direction === "back") {
    return `View previous ${timeInterval}`;
  } else if (direction === "forward") {
    return `View next ${timeInterval}`;
  } else {
    return;
  }
};

const XOCalHeader = (props: IProps) => {
  const isCreateSlotButtonDisabled = !props.hasFullXoCalPermissions;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const menuRef = React.useRef<HTMLUListElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.setIsSlotCreation(true);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      // need to investigate why (menuRef?.current?.children[0] as HTMLElement is undefined in cypress
      (menuRef?.current?.children[0] as HTMLElement)?.focus();
    }, 100);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [searchParams] = useSearchParams();

  const view = searchParams.get("view");

  const isDayView = view === DAY_VIEW;

  const getIconClass = (isButtonDisabled: boolean, isDay: boolean) => {
    if (isButtonDisabled) {
      return classes.disabledIcon;
    }
    return isDay ? classes.icon : classes.iconWeekView;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* make its own component vv */}
      <ButtonBase className={classes.button} onClick={props.onClickToday}>
        {isDayView ? "Today" : "Current week"}
      </ButtonBase>
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <ButtonBase
          className={classes.arrowButton}
          onClick={props.onClickPrev}
          aria-label={getArrowButtonLabel("back", view || "")}
        >
          <KeyboardArrowLeftIcon />
        </ButtonBase>
        <Typography
          className={classes.arrowButton}
          data-testid={"xo-cal-date-title"}
        >
          {props.viewTitle}
        </Typography>
        <ButtonBase
          className={classes.arrowButton}
          onClick={props.onClickNext}
          aria-label={getArrowButtonLabel("forward", view || "")}
        >
          <KeyboardArrowRightIcon />
        </ButtonBase>
      </div>
      {/* make its own component ^^ */}

      {/* TODO: once this is hooked up to the BE, there probably won't be a situation where currentProvider isn't provided, so we wouldn't need this check. this check is needed now so we don't break if there is no currentProvider */}
      {props.currentProvider && (
        <ProviderButton currentProvider={props.currentProvider} />
      )}
      {/* make its own component vv */}
      <Button
        variant="outlined"
        classes={{
          root: !isDayView
            ? classes.createSlotButtonWeekView
            : classes.createSlotButton,
        }}
        onClick={isDayView ? handleClick : props.onClickCreateSlotButton}
        aria-label="Create slot"
        disabled={isCreateSlotButtonDisabled}
      >
        {isDayView && "Create slot"}
        <AddIcon
          className={getIconClass(isCreateSlotButtonDisabled, isDayView)}
        />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ProviderSearch
          menuRef={menuRef}
          openDrawer={props.openDrawer}
          handleClose={handleClose}
          oooDays={props.oooDays}
          isClinicOpen={props.isClinicOpen}
        />
      </Popover>
      {/* make its own component ^^ */}
    </div>
  );
};

export default XOCalHeader;
