// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotSeriesOutputUpdateDryRunSummary,
    SlotSeriesOutputUpdateDryRunSummaryFromJSON,
    SlotSeriesOutputUpdateDryRunSummaryToJSON,
} from './SlotSeriesOutputUpdateDryRunSummary';


/**
 * 
 * @export
 * @interface SlotSeriesOutputUpdateDryRun
 */
export interface SlotSeriesOutputUpdateDryRun  {
    /**
     * 
     * @type {string}
     * @memberof SlotSeriesOutputUpdateDryRun
     */
    type?: string;
    /**
     * 
     * @type {SlotSeriesOutputUpdateDryRunSummary}
     * @memberof SlotSeriesOutputUpdateDryRun
     */
    summary?: SlotSeriesOutputUpdateDryRunSummary;
}

export function SlotSeriesOutputUpdateDryRunFromJSON(json: any): SlotSeriesOutputUpdateDryRun {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'summary': !exists(json, 'summary') ? undefined : SlotSeriesOutputUpdateDryRunSummaryFromJSON(json['summary']),
    };
}

export function SlotSeriesOutputUpdateDryRunToJSON(value?: SlotSeriesOutputUpdateDryRun): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'summary': SlotSeriesOutputUpdateDryRunSummaryToJSON(value.summary),
    };
}


