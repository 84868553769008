import { reduce } from "lodash";
import { useInfiniteQuery } from "react-query";
import {
  GetPaymentHistoryRequest,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";
import { useEffect } from "react";

export const unpaidPaymentHistoryInfoQueryKey = (
  request: GetPaymentHistoryRequest
) => ["unpaidPaymentHistory", request];

let fetching = false;

export const useGetAllUnpaidHistory = (request: GetPaymentHistoryRequest) => {
  const {
    data,
    fetchNextPage: fetchNextHistoryPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery(
    unpaidPaymentHistoryInfoQueryKey(request),
    ({ pageParam }) => {
      if (!pageParam) {
        return fetchMemberPaymentsApi.getPaymentHistory({
          ...request,
          page: "1",
        });
      }
      return fetchMemberPaymentsApi.getPaymentHistory({
        ...request,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page === lastPage.last) {
          return false;
        }

        return (lastPage?.page || 0) + 1;
      },
      keepPreviousData: true,
    }
  );

  const history = reduce(
    data?.pages,
    (acc: any, { results: fetchedHistory = [] }) => {
      return [...acc, ...fetchedHistory];
    },
    []
  );

  const fetchNextPage = () => {
    if (!fetching && hasNextPage) {
      fetching = true;
      fetchNextHistoryPage();
    }
  };

  useEffect(() => {
    fetching = false;
    //fetch all until loaded
    fetchNextPage();
  }, [history.length]);

  return {
    results: history,
    unpaidItemsCount: data?.pages[0].unpaidItemsCount,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  };
};

export default useGetAllUnpaidHistory;
