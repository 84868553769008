// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentOutput,
    AppointmentOutputFromJSON,
    AppointmentOutputToJSON,
} from './AppointmentOutput';


/**
 * 
 * @export
 * @interface SlotSeriesDeleteResponseSummary
 */
export interface SlotSeriesDeleteResponseSummary  {
    /**
     * 
     * @type {number}
     * @memberof SlotSeriesDeleteResponseSummary
     */
    slotSeriesId?: number;
    /**
     * 
     * @type {number}
     * @memberof SlotSeriesDeleteResponseSummary
     */
    totalNewSlots?: number;
    /**
     * 
     * @type {number}
     * @memberof SlotSeriesDeleteResponseSummary
     */
    totalOverlappingSlots?: number;
    /**
     * 
     * @type {number}
     * @memberof SlotSeriesDeleteResponseSummary
     */
    totalRemovedSlots?: number;
    /**
     * 
     * @type {Array<AppointmentOutput>}
     * @memberof SlotSeriesDeleteResponseSummary
     */
    conflictingAppointments?: Array<AppointmentOutput>;
}

export function SlotSeriesDeleteResponseSummaryFromJSON(json: any): SlotSeriesDeleteResponseSummary {
    return {
        'slotSeriesId': !exists(json, 'slot_series_id') ? undefined : json['slot_series_id'],
        'totalNewSlots': !exists(json, 'total_new_slots') ? undefined : json['total_new_slots'],
        'totalOverlappingSlots': !exists(json, 'total_overlapping_slots') ? undefined : json['total_overlapping_slots'],
        'totalRemovedSlots': !exists(json, 'total_removed_slots') ? undefined : json['total_removed_slots'],
        'conflictingAppointments': !exists(json, 'conflicting_appointments') ? undefined : (json['conflicting_appointments'] as Array<any>).map(AppointmentOutputFromJSON),
    };
}

export function SlotSeriesDeleteResponseSummaryToJSON(value?: SlotSeriesDeleteResponseSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slot_series_id': value.slotSeriesId,
        'total_new_slots': value.totalNewSlots,
        'total_overlapping_slots': value.totalOverlappingSlots,
        'total_removed_slots': value.totalRemovedSlots,
        'conflicting_appointments': value.conflictingAppointments === undefined ? undefined : (value.conflictingAppointments as Array<any>).map(AppointmentOutputToJSON),
    };
}


