import React from "react";
import Grid from "components/Grid";
import Asset from "components/Asset";
import Button from "components/Button";
import Typography from "components/Typography";
import { SvgIcon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    button: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    container: {
      paddingLeft: 0,
      listStyleType: "none",
    },
  })
);

interface IHealthRecordsHotLinksProps {
  hasTestResults: boolean;
  hasDocs: boolean;
  hasConsents: boolean;
}

const HealthRecordsHotLinks: React.FC<IHealthRecordsHotLinksProps> = ({
  hasDocs,
  hasTestResults,
  hasConsents,
}) => {
  const classes = useStyles();
  const hasHealthRecordsAccess = useAccess({
    to: PermissionsEnum.HealthRecordsRead,
  });

  if (!hasHealthRecordsAccess) {
    return null;
  }

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography variant="h2" color="textSecondary" appearance="smallBody">
          {/* // TODO: update after prescriptions and/or documents are live, leave only QUICK LINKS */}
          {hasDocs ? "QUICK LINKS" : "QUICK LINK"}
        </Typography>
      </Grid>

      <Grid item>
        <Grid
          container
          className={classes.container}
          component="ul"
          wrap="wrap"
          spacing={1}
        >
          <Grid item component="li">
            <Button
              size="small"
              component="a"
              color="tertiary"
              disabled={!hasTestResults}
              variant="contained"
              to={`/health-records?tab=labs`}
              className={classes.button}
              startIcon={
                <SvgIcon fontSize="small">
                  <Asset name="hp-lab-results" />
                </SvgIcon>
              }
            >
              Test Results
            </Button>
          </Grid>

          <Grid item component="li">
            <Button
              size="small"
              component="a"
              color="tertiary"
              variant="contained"
              className={classes.button}
              to={`/health-records?tab=prescriptions`}
              aria-disabled="false"
              startIcon={
                <SvgIcon fontSize="small">
                  <Asset name="hp-prescriptions" />
                </SvgIcon>
              }
            >
              Prescriptions
            </Button>
          </Grid>

          <Grid item component="li">
            <Button
              size="small"
              component="a"
              color="tertiary"
              disabled={!hasDocs}
              variant="contained"
              className={classes.button}
              to={`/health-records?tab=documents`}
              startIcon={
                <SvgIcon fontSize="small">
                  <Asset name="hp-documents" />
                </SvgIcon>
              }
            >
              Documents
            </Button>
          </Grid>

          <Grid item component="li">
            <Button
              size="small"
              component="a"
              color="tertiary"
              variant="contained"
              disabled={!hasConsents}
              className={classes.button}
              to={`/health-records?tab=consents`}
              startIcon={
                <SvgIcon fontSize="small">
                  <Asset name="hp-consents" />
                </SvgIcon>
              }
            >
              Consents
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HealthRecordsHotLinks;
