import React, { useState, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Box from "components/Box";
import Typography from "components/Typography";
import RadioGroup from "components/Radio/RadioGroup";
import FormControlLabel from "components/Form/FormControlLabel";
import Radio from "components/Radio";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { setDefaultTimeZone } from "shared/utils/timeZone";
import { setTimeZone } from "shared/state/ui/careAccess/actions";
import TimezoneDropdown from "./TimezoneDropdown";

interface IProps {
  memberId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promptText: {
      width: "529px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    radioBtns: {
      paddingTop: "16px",
    },
    timeZoneLabel: {
      paddingTop: "16px",
      paddingBottom: "4px",
    },
  })
);

const Timezones: FunctionComponent<IProps> = ({ memberId }): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [preferredTimeZoneButton, setPreferredTimeZoneButton] = useState("Yes");

  const { accountInfo } = useGetAccountInfo({ id: memberId ?? "" });

  const usersIANATimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultTimeZone = setDefaultTimeZone(
    usersIANATimeZone,
    accountInfo?.timezone
  );

  const [timezone, setTimezone] = useState({
    timezone: defaultTimeZone?.timezone,
    name: defaultTimeZone?.name,
  });

  const handlePreferredTimeZoneButton = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreferredTimeZoneButton(event.target.value);
  };

  const handleSetTimeZone = (value: any) => {
    setTimezone(value);
    dispatch(setTimeZone(value));
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mr={10} className={classes.promptText}>
        <Typography appearance="body" color="textSecondary">
          Do you want to use{" "}
          <span style={{ fontWeight: "bold" }}>
            ‘{`${defaultTimeZone?.name}`}’
          </span>{" "}
          as your preferred time zone?
        </Typography>
      </Box>
      <RadioGroup
        className={classes.radioBtns}
        row
        name="preferredTimeZone"
        value={preferredTimeZoneButton}
        aria-label={`Do you want to use ${defaultTimeZone?.name} as your preferred time zone?`}
        onChange={handlePreferredTimeZoneButton}
      >
        <FormControlLabel
          checked={preferredTimeZoneButton === "Yes"}
          value="Yes"
          control={
            <Radio
              color="secondary"
              inputProps={{
                "aria-label": `I want to use ${defaultTimeZone?.name} as my preferred time zone`,
              }}
            />
          }
          label="Yes"
          id="radio-button-yes"
        />
        <FormControlLabel
          checked={preferredTimeZoneButton === "No"}
          value="No"
          control={
            <Radio
              color="secondary"
              inputProps={{
                "aria-label": "I want to change my preferred time zone",
              }}
            />
          }
          label="No"
          id="radio-button-no"
        />
      </RadioGroup>
      {preferredTimeZoneButton === "No" && (
        <>
          <Typography
            className={classes.timeZoneLabel}
            appearance="body"
            color="textSecondary"
          >
            Time zone
          </Typography>
          <TimezoneDropdown
            timezone={timezone}
            handleSetTimeZone={handleSetTimeZone}
          />
        </>
      )}
    </Box>
  );
};

export default Timezones;
