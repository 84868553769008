import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useSearchParams } from "react-router-dom";
import {
  ALL_PROVIDERS,
  ALL_TYPES,
  WEEK_VIEW,
  DAY_VIEW,
  ACTIVE_PROVIDERS,
} from "../utils";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";

interface IProps {
  providers: XOCalProvider[];
}

export default function SidebarSelectProvider({ providers }: IProps) {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>(ACTIVE_PROVIDERS);

  const selectProviderLabel = "View by provider";

  const providerId = searchParams.get("providerId");

  const selectedProvider = providers.find((p) => p.providerId === providerId);

  useEffect(() => {
    providerId !== null && setValue(providerId);
  }, [providerId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const globalId = event.target.value;
    setSearchParams((params) => {
      if (globalId === ALL_PROVIDERS) {
        params.delete("providerId");
        params.set("providerId", ALL_PROVIDERS);
        setValue(ALL_PROVIDERS);
      } else if (globalId === ACTIVE_PROVIDERS) {
        params.delete("providerId");
        params.set("providerId", ACTIVE_PROVIDERS);
        setValue(ACTIVE_PROVIDERS);
      } else {
        params.delete("activeProviders");
        params.set("providerId", globalId);
        params.set("slotActionDrawerProvider", globalId);
        setValue(globalId);
      }
      if (globalId !== (ACTIVE_PROVIDERS || ALL_PROVIDERS)) {
        params.set("view", WEEK_VIEW);
      } else {
        params.set("view", DAY_VIEW);
      }
      return params;
    });
  };

  useEffect(() => {
    const serviceType = searchParams.get("serviceType");
    const shouldDisable = Boolean(serviceType) && serviceType !== ALL_TYPES;
    setIsDisabled(shouldDisable);
  }, [searchParams.get("serviceType"), providerId]);

  const renderOption = (option: XOCalProvider, i: number) => {
    const shouldDisplay = true; // or any condition based on the provider data

    if (!shouldDisplay) {
      return;
    }

    return (
      <MenuItem key={i + 100} value={option.providerId}>
        {option.name}
      </MenuItem>
    );
  };

  return (
    <FormControl fullWidth>
      <TextField
        disabled={isDisabled}
        select
        label={selectProviderLabel}
        defaultValue={searchParams.get("providerId") || ACTIVE_PROVIDERS}
        onChange={handleChange}
        value={selectedProvider ? selectedProvider.providerId : value}
      >
        <MenuItem key={0} value={ACTIVE_PROVIDERS}>
          All Active Providers
        </MenuItem>
        <MenuItem key={1} value={ALL_PROVIDERS}>
          All Providers
        </MenuItem>
        {providers.map((option, i) => renderOption(option, i))}
      </TextField>
    </FormControl>
  );
}
