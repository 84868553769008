import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { SlotSeriesOutputDaysActiveEnum } from "shared/fetch/src/models/SlotSeriesOutput";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import { getHours, getMinutes, setHours, setMinutes, isPast } from "date-fns";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import palette from "styles/mui/palette";

export type ValuesType = SlotOutput & {
  selectedProviderId: string | null;
  duration: number | null;
  selectedDate: Date | null;
  reason: string | null;
  repeats: boolean;
  daysActive: SlotSeriesOutputDaysActiveEnum[];
  seriesEnd: string | null;
  seriesStart: string | null;
  startTime: string | null;
  endTime: string | null;
  selectedProvider?: string | null;
  selectedMember?: string | null;
  timezone?: string | null;
  visibility: SlotVisibilityEnum;
  appointmentRequestAppointmentTypes?: [string] | undefined;
  newSlotAppointmentType?: string | undefined;
  episodeId?: string | undefined;
};

// since the sidebar sets the date in the serach params we can use the params as a stable
// reference for the slot drawer when the create slot button is clicked
export const getCalendarUiDateObjectFromParams = (params: URLSearchParams) => {
  const dateStringFromParams = params.get("date") as string;
  return new Date(dateStringFromParams.replace(/-/g, "/"));
};

// Takes in a Date and timezone, returns time of day as formatted string relative to
// timezone passed. Example output "3:30 PM"
export const formatTime = (date: Date, timezone: string): string => {
  return formatTZ(utcToZonedTime(date, timezone), "h:mm a", {
    timeZone: timezone,
  });
};

// take two Date objects as args
// one has the desired date value
// one has the desired hours and minutes value
// merges them together and return them as an ISOString
export const mergeDateObjectsIntoIsoString = (
  dateWithDesiredDay: Date,
  dateWithDesiredTime: Date
) => {
  const hours = getHours(dateWithDesiredTime);
  const minutes = getMinutes(dateWithDesiredTime);
  let result = setHours(dateWithDesiredDay, hours);
  result = setMinutes(result, minutes);

  return result.toISOString() as string;
};

export const formatISOStringForDisplay = (
  time: string,
  timezone: string
): string => {
  if (!time) {
    return "";
  }
  const parsedTime = utcToZonedTime(new Date(time), timezone);
  return formatTZ(parsedTime, "h:mm a", { timeZone: timezone });
};

// returns date string from params as Date object
// example: params "?date=2024-11-21"
// will return "Thu Nov 21 2024 00:00:00 GMT-0500 (Eastern Standard Time)"
export const getDateFromParams = (searchParamz: URLSearchParams) => {
  const date = searchParamz.get("date") as string;
  return date ? new Date(date.replace(/-/g, "/")) : null;
};

export const inputSxProps = {
  "& .MuiInputBase-root": {
    padding: "0",
    border: "1px solid",
    borderRadius: "10px",
  },
  "& .Mui-focused": {
    border: "none !important",
    outline: `2px solid ${palette?.text?.link} !important`,
    overflow: "visible",
  },
};

export const isParamsDateInPast = (searchParams: URLSearchParams) => {
  const calendarDate = searchParams.get("date");
  let calendarDateObj;

  if (calendarDate) {
    const calendarDateSplit = calendarDate.split("-"); // separate the date from '2024-10-14' to year, month, day
    const calendarDateYear = Number(calendarDateSplit[0]);
    const calendarDateDay = Number(calendarDateSplit[2]);
    const calendarDateMonth = Number(calendarDateSplit[1]) - 1;

    calendarDateObj = new Date(
      calendarDateYear,
      calendarDateMonth,
      calendarDateDay
    );
  }
  if (calendarDateObj) {
    // Add one more day to date object
    calendarDateObj.setDate(calendarDateObj.getDate() + 1); // Without the '+ 1' here providers would not be able to edit slots created on today's date
  }

  return calendarDateObj && isPast(calendarDateObj); // check if date is in past
};

export const hasDatePassed = (slotStartTime?: string) => {
  // returning true when param is undefined prevents UI elements from flashing, then disappearing
  if (!Boolean(slotStartTime)) {
    return true;
  }
  const slotDate = new Date(slotStartTime!);
  slotDate.setHours(0, 0, 0);
  slotDate.setDate(slotDate.getDate() + 1);

  return isPast(slotDate);
};

export const replaceDashesInDateStringWithSlashes = (
  dateString: string | undefined | null
) => {
  return dateString?.replace(/-/g, "/") || "";
};
