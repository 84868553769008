import React from "react";
import { Tooltip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import styles from "components/XOCal/EventContent.module.css";
import { composeTooltipSlotProps } from "../utils";

export default function RestrictedIcon({
  isRestricted,
}: {
  isRestricted: boolean;
}) {
  if (!isRestricted) {
    return null;
  }
  return (
    <Tooltip
      title={"XOP Referrals only"}
      slotProps={composeTooltipSlotProps({ offset: [50, -10] })}
    >
      <div>
        <SendIcon
          style={{ paddingTop: "2px" }}
          className={styles.visibilityIcon}
        />
      </div>
    </Tooltip>
  );
}
