// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAppointmentById
 */
export interface UpdateAppointmentById  {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    appointmentType?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    slotId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    startAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    endAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    patientStatus?: UpdateAppointmentByIdPatientStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    renderingProvId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    cancelReason?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentById
     */
    disposition?: UpdateAppointmentByIdDispositionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentById
     */
    conflicted?: boolean;
}

export function UpdateAppointmentByIdFromJSON(json: any): UpdateAppointmentById {
    return {
        'appointmentType': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'slotId': !exists(json, 'slot_id') ? undefined : json['slot_id'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'startAt': !exists(json, 'start_at') ? undefined : json['start_at'],
        'endAt': !exists(json, 'end_at') ? undefined : json['end_at'],
        'patientStatus': !exists(json, 'patient_status') ? undefined : json['patient_status'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'renderingProvId': !exists(json, 'rendering_prov_id') ? undefined : json['rendering_prov_id'],
        'cancelReason': !exists(json, 'cancel_reason') ? undefined : json['cancel_reason'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'conflicted': !exists(json, 'conflicted') ? undefined : json['conflicted'],
    };
}

export function UpdateAppointmentByIdToJSON(value?: UpdateAppointmentById): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointment_type': value.appointmentType,
        'slot_id': value.slotId,
        'provider_id': value.providerId,
        'start_at': value.startAt,
        'end_at': value.endAt,
        'patient_status': value.patientStatus,
        'reason': value.reason,
        'rendering_prov_id': value.renderingProvId,
        'cancel_reason': value.cancelReason,
        'disposition': value.disposition,
        'conflicted': value.conflicted,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateAppointmentByIdPatientStatusEnum {
    CheckedIn = 'checked_in',
    InRoom = 'in_room'
}
/**
* @export
* @enum {string}
*/
export enum UpdateAppointmentByIdDispositionEnum {
    Complete = 'complete',
    Cancel = 'cancel',
    NoShow = 'no_show',
    LateCancel = 'late_cancel'
}


