import { useQuery } from "react-query";
import {
  GetPaymentMethodRequest,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";

export const paymentMethodQueryKey = (
  requestParameters: GetPaymentMethodRequest
) => ["paymentMethod", requestParameters];

export default (requestParameters: GetPaymentMethodRequest) => {
  const { data, isLoading } = useQuery(
    paymentMethodQueryKey(requestParameters),
    () => fetchMemberPaymentsApi.getPaymentMethod(requestParameters)
  );

  return {
    paymentMethod: data,
    isPending: isLoading,
  };
};
