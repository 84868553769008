/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessPlanPodAssignmentFiltersResponse,
    AccessPlanPodAssignmentFiltersResponseFromJSON,
    AccessPlanPodAssignmentFiltersResponseToJSON,
} from '../models/AccessPlanPodAssignmentFiltersResponse'
import {
    AccessPlanPodAssignmentResponse,
    AccessPlanPodAssignmentResponseFromJSON,
    AccessPlanPodAssignmentResponseToJSON,
} from '../models/AccessPlanPodAssignmentResponse'
import {
    CtmPodAssignmentFiltersResponse,
    CtmPodAssignmentFiltersResponseFromJSON,
    CtmPodAssignmentFiltersResponseToJSON,
} from '../models/CtmPodAssignmentFiltersResponse'
import {
    CtmPodAssignmentResponse,
    CtmPodAssignmentResponseFromJSON,
    CtmPodAssignmentResponseToJSON,
} from '../models/CtmPodAssignmentResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchAccessPlanPodAssignmentsRequest {
    page?: string;
    podIds?: Array<number>;
    efIds?: Array<number>;
    accessPlanIds?: Array<number>;
}
export interface FetchCTMPodAssignmentsRequest {
    page?: string;
    podIds?: Array<number>;
    ctmIds?: Array<number>;
    roles?: Array<string>;
    isPublic?: Array<string>;
}


export interface INetworkToolsApi {
      fetchAccessPlanPodAssignments: (requestParameters: FetchAccessPlanPodAssignmentsRequest) => Promise<AccessPlanPodAssignmentResponse>
      fetchAccessPlanPodAssignmentsFilters: () => Promise<AccessPlanPodAssignmentFiltersResponse>
      fetchCTMPodAssignments: (requestParameters: FetchCTMPodAssignmentsRequest) => Promise<CtmPodAssignmentResponse>
      fetchCTMPodAssignmentsFilters: () => Promise<CtmPodAssignmentFiltersResponse>
      fetchAccessPlanPodAssignmentsWithResponse: (requestParameters: FetchAccessPlanPodAssignmentsRequest) => Promise<{ value: AccessPlanPodAssignmentResponse, response: any}>
      fetchAccessPlanPodAssignmentsFiltersWithResponse: () => Promise<{ value: AccessPlanPodAssignmentFiltersResponse, response: any}>
      fetchCTMPodAssignmentsWithResponse: (requestParameters: FetchCTMPodAssignmentsRequest) => Promise<{ value: CtmPodAssignmentResponse, response: any}>
      fetchCTMPodAssignmentsFiltersWithResponse: () => Promise<{ value: CtmPodAssignmentFiltersResponse, response: any}>
}

/**
 * no description
 */
export class NetworkToolsApi extends runtime.BaseAPI {

    /**
     * Fetch Access Pod assignments
     * Fetch Access Pod assignments
     */
    fetchAccessPlanPodAssignmentsRaw = async (requestParameters: FetchAccessPlanPodAssignmentsRequest): Promise<{ runtime: runtime.ApiResponse<AccessPlanPodAssignmentResponse>, response: any}> => {
                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.podIds) {
            queryParameters['pod_ids[]'] = requestParameters.podIds;
        }

        if (requestParameters.efIds) {
            queryParameters['ef_ids[]'] = requestParameters.efIds;
        }

        if (requestParameters.accessPlanIds) {
            queryParameters['access_plan_ids[]'] = requestParameters.accessPlanIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/access_plan_pod_assignments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AccessPlanPodAssignmentResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Access Pod assignments
     * Fetch Access Pod assignments
     */
    fetchAccessPlanPodAssignments = async (requestParameters: FetchAccessPlanPodAssignmentsRequest): Promise<AccessPlanPodAssignmentResponse> => {
        const { runtime } = await this.fetchAccessPlanPodAssignmentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Access Pod assignments
     * Fetch Access Pod assignments
     */
    fetchAccessPlanPodAssignmentsWithResponse = async (requestParameters: FetchAccessPlanPodAssignmentsRequest): Promise<{ value: AccessPlanPodAssignmentResponse, response: any}> => {
        const { runtime, response } = await this.fetchAccessPlanPodAssignmentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Access Plan Pod assignments Filters
     * Fetch Access Plan Pod assignments Filters
     */
    fetchAccessPlanPodAssignmentsFiltersRaw = async (): Promise<{ runtime: runtime.ApiResponse<AccessPlanPodAssignmentFiltersResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/access_plan_pod_assignments/filter_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AccessPlanPodAssignmentFiltersResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Access Plan Pod assignments Filters
     * Fetch Access Plan Pod assignments Filters
     */
    fetchAccessPlanPodAssignmentsFilters = async (): Promise<AccessPlanPodAssignmentFiltersResponse> => {
        const { runtime } = await this.fetchAccessPlanPodAssignmentsFiltersRaw();
        return await runtime.value();
    }

    /**
     * Fetch Access Plan Pod assignments Filters
     * Fetch Access Plan Pod assignments Filters
     */
    fetchAccessPlanPodAssignmentsFiltersWithResponse = async (): Promise<{ value: AccessPlanPodAssignmentFiltersResponse, response: any}> => {
        const { runtime, response } = await this.fetchAccessPlanPodAssignmentsFiltersRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch CTM Pod assignments
     * Fetch CTM Pod assignments
     */
    fetchCTMPodAssignmentsRaw = async (requestParameters: FetchCTMPodAssignmentsRequest): Promise<{ runtime: runtime.ApiResponse<CtmPodAssignmentResponse>, response: any}> => {
                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.podIds) {
            queryParameters['pod_ids[]'] = requestParameters.podIds;
        }

        if (requestParameters.ctmIds) {
            queryParameters['ctm_ids[]'] = requestParameters.ctmIds;
        }

        if (requestParameters.roles) {
            queryParameters['roles[]'] = requestParameters.roles;
        }

        if (requestParameters.isPublic) {
            queryParameters['is_public'] = requestParameters.isPublic;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/ctm_pod_assignments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CtmPodAssignmentResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch CTM Pod assignments
     * Fetch CTM Pod assignments
     */
    fetchCTMPodAssignments = async (requestParameters: FetchCTMPodAssignmentsRequest): Promise<CtmPodAssignmentResponse> => {
        const { runtime } = await this.fetchCTMPodAssignmentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch CTM Pod assignments
     * Fetch CTM Pod assignments
     */
    fetchCTMPodAssignmentsWithResponse = async (requestParameters: FetchCTMPodAssignmentsRequest): Promise<{ value: CtmPodAssignmentResponse, response: any}> => {
        const { runtime, response } = await this.fetchCTMPodAssignmentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch CTM Pod assignments Filters
     * Fetch CTM Pod assignments Filters
     */
    fetchCTMPodAssignmentsFiltersRaw = async (): Promise<{ runtime: runtime.ApiResponse<CtmPodAssignmentFiltersResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/ctm_pod_assignments/filter_options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CtmPodAssignmentFiltersResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch CTM Pod assignments Filters
     * Fetch CTM Pod assignments Filters
     */
    fetchCTMPodAssignmentsFilters = async (): Promise<CtmPodAssignmentFiltersResponse> => {
        const { runtime } = await this.fetchCTMPodAssignmentsFiltersRaw();
        return await runtime.value();
    }

    /**
     * Fetch CTM Pod assignments Filters
     * Fetch CTM Pod assignments Filters
     */
    fetchCTMPodAssignmentsFiltersWithResponse = async (): Promise<{ value: CtmPodAssignmentFiltersResponse, response: any}> => {
        const { runtime, response } = await this.fetchCTMPodAssignmentsFiltersRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchNetworkToolsApi: INetworkToolsApi  = new NetworkToolsApi();
