import React, { FunctionComponent } from "react";
import DialogComponent, { DialogProps } from "@mui/material/Dialog";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FocusTrap from "focus-trap-react";

const useStyles = makeStyles((_theme: Theme) => ({
  paper: {
    borderRadius: 10,
  },
}));

interface IProps {
  ariaLabelledBy: string;
  // TODO: without an onClose function the Dialog will not close when the escape key is pressed,
  // so we should require this prop. However, doing so would expland the scope of work
  // being implemented now, so we will make this prop optional for now
  onClose?: Function;
}

// Ensure that the "aria-labelledby" attribute is set to the ID of the dialog title
// to associate the dialog with its title, improving accessibility for screen readers.
const Dialog: FunctionComponent<DialogProps & IProps> = (props) => {
  const classes = useStyles(props);

  return (
    <DialogComponent
      classes={classes}
      {...props}
      aria-labelledby={props.ariaLabelledBy}
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          fallbackFocus: "#fallbackElement",
        }}
      >
        <div id="fallbackElement" tabIndex={-1}>
          {props.children}
        </div>
      </FocusTrap>
    </DialogComponent>
  );
};

export default Dialog;
