import React from "react";
import Heading from "components/DynamicHeadings";
import { SvgIcon } from "@mui/material";
import Button from "components/Button";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import PhotoId from "styles/images/insurance-tool/photo-id.png";

interface IProps {
  isExpanded: boolean;
  setIsExpanded: (state: boolean) => void;
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  insuranceHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#E3F0FB",
    padding: `${spacing(2)} ${spacing(3)}`,
  },
  photoIdContainer: {
    marginLeft: spacing(3),
    display: "flex",
  },

  insuranceIdImg: {
    marginRight: spacing(3),
    [breakpoints?.only("xs")]: {
      display: "none",
    },
    [breakpoints?.only("sm")]: {
      display: "none",
    },
  },
  iconButton: {
    cursor: "pointer",
    color: palette.links?.tertiary,
  },
}));

export default function InsuranceHeader({ isExpanded, setIsExpanded }: IProps) {
  const classes = useStyles();
  return (
    <Heading.LevelBoundary>
      <div className={classes.insuranceHeader}>
        <Heading.H
          appearance="h5"
          color="inherit"
          data-e2e="onboarding-heading"
        >
          Please confirm or provide your insurance information
        </Heading.H>
        <div className={classes.photoIdContainer}>
          <img
            alt="insurance id"
            className={classes.insuranceIdImg}
            src={PhotoId}
          />
          <Button
            aria-label="Please enter insurance information"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <SvgIcon
              className={classes.iconButton}
              data-e2e="insurance-expander"
            >
              {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </SvgIcon>
          </Button>
        </div>
      </div>
    </Heading.LevelBoundary>
  );
}
