import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Paper,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "styles/mui";
import Typography from "components/Typography";
import classes from "./index.module.css";
import { GetPaymentHistoryStatusEnum } from "shared/fetch/src/apis/MemberPaymentsApi";
import { format } from "date-fns";
import { getPronouns } from "components/Sogi/utils";
import { Link } from "react-router-dom";
import useGetAllUnpaidHistory from "shared/features/payments/paymentsHistory/useGetAllUnpaidHistory";

interface Props {
  open: boolean;
  onClose: () => void;
  memberId: string;
  appointment: any;
}

const PaymentsOwedModal = ({ open, onClose, memberId, appointment }: Props) => {
  const handleClose = () => {
    onClose();
  };

  const { results: paymentsHistory } = useGetAllUnpaidHistory({
    id: memberId?.toString(),
    status: GetPaymentHistoryStatusEnum.Unpaid,
  });

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const truncatePronouns = (pronouns: string) => {
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    const [subjectPronoun, objectPronoun, _possessivePronoun] =
      pronouns.split(" / ");
    return `${subjectPronoun} / ${objectPronoun}`;
  };

  const memberPronouns = appointment?.pronouns
    ? getPronouns(appointment?.pronouns, true)
    : undefined;
  const truncatedPronouns = memberPronouns
    ? truncatePronouns(memberPronouns)
    : null;

  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: "10px", overflowY: "hidden" } }}
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      fullWidth
    >
      <div data-testid="payments-owed-modal" style={{ height: 377 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={() => ({
            position: "absolute",
            right: 12,
            top: 8,
          })}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle className={classes.paymentsOwedModalTitle}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            All payments owed
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            height: "calc(100% - 64px)",
            overflowY: "auto",
          }}
          className={classes.paymentsOwedModalContent}
        >
          <div className={classes.memberInformation}>
            <Link
              className={classes.link}
              to={`/members/${memberId}/payments`}
              data-e2e="payments-link"
            >
              {appointment?.legalAndPreferredName}
            </Link>
            <Typography>
              {memberPronouns}
              {" | "}
              {appointment?.dob}
              {" | "}
              {appointment?.sexage}
            </Typography>
            <Typography>{appointment?.cellPhone}</Typography>
          </div>
          <TableContainer
            sx={{
              maxHeight: 300,
              overflowY: "auto",
            }}
            component={Paper}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ width: "86px", paddingRight: 10 }}
                  >
                    SERVICE DATE
                  </TableCell>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ width: "105px" }}
                  >
                    ITEM
                  </TableCell>
                  <TableCell
                    className={classes.tableHeader}
                    style={{
                      width: "250px",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    DESCRIPTION
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderRight}
                    style={{ textAlign: "right", paddingRight: 0 }}
                  >
                    AMOUNT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentsHistory?.map((charge: any) => (
                  <TableRow
                    className={classes.tableBodyRow}
                    key={charge.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      "& .MuiTableCell-root": {
                        height: isSmallerScreen ? "50px" : "77px",
                      },
                    }}
                  >
                    <TableCell
                      className={classes.tableBody}
                      style={{ paddingRight: "10px" }}
                    >
                      {format(new Date(charge.dateOfService!), "MMM dd, yyyy")}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      <Typography
                        data-testid="payments-owed-item"
                        className={classes.paymentsOwedItem}
                      >
                        {charge.item}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableBodyOffset}
                      sx={{
                        height: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                        display: "table-cell",
                      }}
                    >
                      <Typography
                        className={classes.tableRow}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          wordBreak: "break-word",
                          maxHeight: isSmallerScreen ? "2.4em" : "3.6em",
                        }}
                      >
                        {charge.description}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableBody}
                      align="right"
                      style={{ fontWeight: "bold" }}
                    >
                      {"$"}
                      {charge.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default PaymentsOwedModal;
