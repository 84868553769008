/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    PatientSearchResultDetails,
    PatientSearchResultDetailsFromJSON,
    PatientSearchResultDetailsToJSON,
} from '../models/PatientSearchResultDetails'
import {
    PatientsSearchRequest,
    PatientsSearchRequestFromJSON,
    PatientsSearchRequestToJSON,
} from '../models/PatientsSearchRequest'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface PatientsSearchPortalRequest {
    patientsSearchRequest?: PatientsSearchRequest;
}


export interface IDefaultApi {
      patientsSearchPortal: (requestParameters: PatientsSearchPortalRequest) => Promise<Array<PatientSearchResultDetails>>
      patientsSearchPortalWithResponse: (requestParameters: PatientsSearchPortalRequest) => Promise<{ value: Array<PatientSearchResultDetails>, response: any}>
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Search patients matching critera
     */
    patientsSearchPortalRaw = async (requestParameters: PatientsSearchPortalRequest): Promise<{ runtime: runtime.ApiResponse<Array<PatientSearchResultDetails>>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: PatientsSearchRequestToJSON(requestParameters.patientsSearchRequest),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientSearchResultDetailsFromJSON)), response };
    }

    /**
     * Search patients matching critera
     */
    patientsSearchPortal = async (requestParameters: PatientsSearchPortalRequest): Promise<Array<PatientSearchResultDetails>> => {
        const { runtime } = await this.patientsSearchPortalRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Search patients matching critera
     */
    patientsSearchPortalWithResponse = async (requestParameters: PatientsSearchPortalRequest): Promise<{ value: Array<PatientSearchResultDetails>, response: any}> => {
        const { runtime, response } = await this.patientsSearchPortalRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchDefaultApi: IDefaultApi  = new DefaultApi();
