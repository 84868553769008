import { useState, useEffect } from "react";
import { isValidZipCode } from "utils/validate";
import trim from "lodash/trim";
import isEqual from "lodash/isEqual";

const formatBoolean = (e: any) => e.target.checked;
const validateZip = (value: string) =>
  value && !isValidZipCode(trim(value)) ? "Invalid zip code." : "";

const useValidState = (
  initState: any,
  options: {
    format?: (e: any) => any;
    validate?: (value: any) => string;
  } = {}
) => {
  const {
    format = (e: any) => e.target.value,
    validate = (v: any) => (!v ? "field is empty" : ""),
  } = options;
  const [state, setState] = useState(initState);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isEqual(initState, state)) {
      setState(initState);
    }
  }, [initState]);

  const handleStateChange = (event: any) => {
    const data = format(event);
    setError(validate(data));
    setState(data);
  };

  const checkError = () => {
    const stateError = validate(state);
    setError(stateError);
    return stateError;
  };

  return {
    error,
    state,
    checkError,
    setState: handleStateChange,
  };
};

const useFormAPI = (initialValues?: any) => {
  const {
    primary = false,
    cardAccountType: isHsaOrFsa = false,
    cardLabel = "",
    isEdit = false,
    handleElementErrorCallback = null,
  } = initialValues || {};
  const nickName = useValidState(cardLabel);
  const zip = useValidState("", { validate: validateZip });
  const country = useValidState({ code: "US", label: "United States" });
  const isPrimary = useValidState(!!primary, { format: formatBoolean });
  const isFsaHsa = useValidState(!!isHsaOrFsa, { format: formatBoolean });

  const [cardNumberError, setCardNumberError] = useState("");
  const [cardNumberErrorObj, setCardNumberErrorObj] = useState({
    complete: false,
    empty: true,
  });
  const [cardExpiryError, setCardExpiryError] = useState("");
  const [cardExpiryErrorObj, setCardExpiryErrorObj] = useState({
    complete: false,
    empty: true,
  });
  const [cardCvcError, setCardCvcError] = useState("");
  const [cardCvcErrorObj, setCardCvcErrorObj] = useState({
    complete: false,
    empty: true,
  });

  const isCardNumberValid =
    cardNumberErrorObj.complete &&
    !cardNumberErrorObj.empty &&
    !cardNumberError;
  const isCardExpiryValid =
    cardExpiryErrorObj.complete &&
    !cardExpiryErrorObj.empty &&
    !cardNumberError;
  const isCardCvcValid =
    cardCvcErrorObj.complete && !cardCvcErrorObj.empty && !cardCvcError;

  const isStripeMethodDetailsInvalid =
    !isCardCvcValid || !isCardExpiryValid || !isCardNumberValid;

  const isBtnDisabled = isEdit ? false : isStripeMethodDetailsInvalid;

  const isStripeMethodDetailsEdited =
    !cardCvcErrorObj.empty ||
    !cardExpiryErrorObj.empty ||
    !cardNumberErrorObj.empty;
  useEffect(() => {
    if (!isStripeMethodDetailsEdited) {
      setCardNumberError("");
      setCardExpiryError("");
      setCardCvcError("");
    }
  }, [cardCvcErrorObj, cardExpiryErrorObj, cardNumberErrorObj]);
  const getErrors = () => country.checkError();
  const getValues = () => ({
    zip: zip.state,
    country: country.state?.code,
    nickName: nickName.state,
    primaryCheck: isPrimary.state,
    fsaHsaCheck: isFsaHsa.state,
  });
  const handleElementErrors = (event: any) => {
    switch (event.elementType) {
      case "cardNumber":
        setCardNumberError(event.error?.message || "");
        setCardNumberErrorObj({ complete: event.complete, empty: event.empty });
        break;
      case "cardExpiry":
        setCardExpiryError(event.error?.message || "");
        setCardExpiryErrorObj({ complete: event.complete, empty: event.empty });
        break;
      case "cardCvc":
        setCardCvcError(event.error?.message || "");
        setCardCvcErrorObj({ complete: event.complete, empty: event.empty });
        break;
    }
    //if present, clears errors generated by Stripe (from payNow API) after editing card information.
    if (handleElementErrorCallback) {
      if (!cardNumberError && !cardExpiryError && !cardCvcError) {
        handleElementErrorCallback();
      }
    }
  };

  return {
    zip,
    country,
    nickName,
    isFsaHsa,
    isPrimary,
    getErrors,
    getValues,
    isBtnDisabled,
    elementErrors: {
      cardNumber: cardNumberError,
      cardExpiry: cardExpiryError,
      cardCvc: cardCvcError,
    },
    isStripeMethodDetailsEdited,
    handleElementErrors,
  };
};

export default useFormAPI;
