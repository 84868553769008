import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSearchParams } from "react-router-dom";
import { Link } from "components/Button";
import Box from "components/Box";
import WarningIcon from "@mui/icons-material/Warning";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  wrapper: {
    borderRadius: spacing(1),
    marginTop: spacing(15),
    backgroundColor: palette.white,
    flexDirection: "row",
    width: "213px",
    minHeight: "28px",
    color: palette.calendar?.textHover,
    position: "fixed",
    bottom: "16px",
    [breakpoints.down("lg")]: {
      bottom: "92px",
    },
  },
  noConflicts: {
    border: `1px solid ${palette.calendar?.textHover}`,
    color: palette.appBackground?.darkestGray,
    padding: spacing(0.75),
    paddingLeft: spacing(1.2),
  },
  conflictsBarLeftGroup: {
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: spacing(1),
  },
  conflictsLink: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "100%",
  },
  conflictsBadge: {
    minWidth: "28px",
    minHeigth: "28px",
    borderRadius: "50%",
    backgroundColor: palette.danger?.dark,
    color: palette.white,
    textAlign: "center",
  },
}));

export default function ConflictsLink() {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get("clinicId");
  const conflicts = searchParams.get("conflicts");
  const hasConflicts = conflicts !== null && conflicts !== "0";

  return (
    // would be good ux to put in a spinner or skeleton when request is fetching
    <Box
      className={clsx(classes.wrapper, hasConflicts ? "" : classes.noConflicts)}
    >
      {hasConflicts ? (
        <Link
          className={classes.conflictsLink}
          to={`/calendar/conflicts?clinicId=${clinicId}&conflicts=${conflicts}`}
        >
          <span className={classes.conflictsBarLeftGroup}>
            <WarningIcon />
            <Box component="span" sx={{ paddingLeft: "8px" }}>
              Conflicts
            </Box>
          </span>
          <span
            className={classes.conflictsBadge}
            data-testid="conflicts-link-conflicts-count"
          >
            {conflicts}
          </span>
        </Link>
      ) : (
        <Box>There are no conflicts</Box>
      )}
    </Box>
  );
}
