import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import {
  DeleteSlotDateRangeRequest,
  fetchSlotsApi,
} from "shared/fetch/src/apis/SlotsApi";
import { DeleteDateRangeSlots } from "shared/fetch/src/models/DeleteDateRangeSlots";
// import { getFetchSlotQueryKey } from "./useGetSlot";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";

type DeleteSlotDateRangeMutationFn = (
  requestParameters: DeleteSlotDateRangeRequest
) => Promise<DeleteDateRangeSlots>;

const useDeleteSlotDateRange = (
  config?: UseMutationOptions<
    DeleteDateRangeSlots,
    Error,
    DeleteSlotDateRangeRequest
  >
): UseMutationResult<
  DeleteDateRangeSlots,
  Error,
  DeleteSlotDateRangeRequest
> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();
  const mutationFn: DeleteSlotDateRangeMutationFn = (
    requestParameters: DeleteSlotDateRangeRequest
  ) => {
    return fetchSlotsApi.deleteSlotDateRange(
      requestParameters
    ) as Promise<DeleteDateRangeSlots>;
  };

  const defaultConfig: UseMutationOptions<
    DeleteDateRangeSlots,
    Error,
    DeleteSlotDateRangeRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Slot range has been successfully deleted.");
      // queryClient.invalidateQueries(getFetchSlotQueryKey({})); not sure this is needed
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to delete the slot range.");
    },
    ...config,
  };

  return useMutation<DeleteDateRangeSlots, Error, DeleteSlotDateRangeRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useDeleteSlotDateRange;
