import React from "react";
import styles from "components/XOCal/EventContent.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Asset from "components/Asset";
import { Tooltip } from "@mui/material";
import { composeTooltipSlotProps } from "../utils";

interface IProps {
  isHold: boolean;
  isInternal: boolean;
  isAccessible: boolean;
}

export default function VisibilityIcon({
  isHold,
  isInternal,
  isAccessible,
}: IProps) {
  const getTooltipContent = () => {
    if (isHold) {
      return "Hold";
    } else if (isInternal) {
      return "Private";
    } else if (isAccessible) {
      return "Public";
    }
    {
      return null;
    }
  };
  return (
    <Tooltip
      title={getTooltipContent()}
      slotProps={composeTooltipSlotProps({ offset: [20, -10] })}
    >
      <div>
        {isHold && (
          <Asset
            alt="Hold slot"
            name="Hold"
            className={styles.visibilityIcon}
          />
        )}
        {isInternal && <VisibilityOff className={styles.visibilityIcon} />}
        {isAccessible && <Visibility className={styles.visibilityIcon} />}
      </div>
    </Tooltip>
  );
}
