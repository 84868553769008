import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { useGetProviders } from "shared/features/xocal/useGetProviders";
import CalendarSidebar from "./CalendarSidebar";
import XOCal from "./XOCal";
import { State } from "shared/types";
import { inputEvents } from "./data";
import { ALL_TYPES, DAY_VIEW, ACTIVE_PROVIDERS } from "./utils";
import SmallScreenSizes from "./SmallScreenSizes";
import { useMediaQuery } from "@mui/material";
import { selectFeatures } from "shared/features/featureFlags/selectors";

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    display: "flex",
    height: "100%",
  },
  page: {
    backgroundColor: palette?.white,
    padding: "20px",
    width: "100%",
    height: "100%",
  },
}));

const XOCalContainer = () => {
  const classes = useStyles();
  const user = useSelector(getUser) as JsonUser;
  const userDefaultClinic = user.clinicId;
  const lastSelectedClinic = useSelector(
    (state: State) => state.ui.xoCal.clinic
  );
  const pageLoadClinic = lastSelectedClinic || userDefaultClinic;
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: pageLoadClinic!,
  });

  // set up default values
  React.useEffect(() => {
    if (!searchParams.has("view")) {
      searchParams.append("view", DAY_VIEW);
      setSearchParams(searchParams);
    }

    if (!searchParams.has("clinicId") && Boolean(pageLoadClinic)) {
      searchParams.append("clinicId", pageLoadClinic!);
      setSearchParams(searchParams);
    }

    if (!searchParams.has("providerId")) {
      searchParams.append("providerId", ACTIVE_PROVIDERS);
      setSearchParams(searchParams);
    }
  }, [pageLoadClinic]); // the default clinic will only change when the logged in user changes

  // set up default service type value if none exists
  React.useEffect(() => {
    if (!searchParams.has("serviceType")) {
      searchParams.append("serviceType", ALL_TYPES);
      setSearchParams(searchParams);
    }
  }, []);

  const clinicId = searchParams.get("clinicId");
  const serviceType = searchParams.get("serviceType");
  const providers = useGetProviders(clinicId as string, serviceType!);
  const shouldShowXOCal = useMediaQuery("(min-width:750px)");
  const features = selectFeatures();

  if (!features.hasXOCal()) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {shouldShowXOCal ? (
        <>
          <CalendarSidebar />
          <div className={classes.page} data-testid="xo-cal-main-container">
            {calendarStaticData && (
              <XOCal
                inputEvents={inputEvents}
                schedulerProviders={providers || []}
              />
            )}
          </div>
        </>
      ) : (
        <SmallScreenSizes />
      )}
    </div>
  );
};

export default XOCalContainer;
