import React from "react";
import styles from "components/XOCal/EventContent.module.css";

interface IProps {
  title: string;
  timeText: string;
}
export default function MirrorSlot({ title, timeText }: IProps) {
  return (
    <div className={styles.eventContainerMirror} role="button">
      <div>
        <div className={styles.titleDisplayContainer}>
          <div className={styles.titleAndDate}>
            <div>{timeText}</div>
            <div className={styles.titleTrunaction}>{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
