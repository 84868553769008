// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetSlotsForDateOutputWithArray,
    GetSlotsForDateOutputWithArrayFromJSON,
    GetSlotsForDateOutputWithArrayToJSON,
} from './GetSlotsForDateOutputWithArray';
import {
    GetSlotsForDateOutputWithObject,
    GetSlotsForDateOutputWithObjectFromJSON,
    GetSlotsForDateOutputWithObjectToJSON,
} from './GetSlotsForDateOutputWithObject';

/**
 * @type GetSlotsForDateOutput
 * @export
 */
export type GetSlotsForDateOutput = GetSlotsForDateOutputWithArray | GetSlotsForDateOutputWithObject;


export function GetSlotsForDateOutputFromJSON(json: any): GetSlotsForDateOutput {
  switch (json.shape) {
  case 'list': return GetSlotsForDateOutputWithArrayFromJSON(json);
  case 'keyed': return GetSlotsForDateOutputWithObjectFromJSON(json);
  default: throw new Error("Unexpected shape value.");
  }
}

export function GetSlotsForDateOutputToJSON(value?: GetSlotsForDateOutput): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.shape) {
    case 'list': return GetSlotsForDateOutputWithArrayToJSON(<GetSlotsForDateOutputWithArray>value);
    case 'keyed': return GetSlotsForDateOutputWithObjectToJSON(<GetSlotsForDateOutputWithObject>value);
    default: throw new Error("Unexpected shape value.");
    }
}

