// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCopaySchema
 */
export interface UpdateCopaySchema  {
    /**
     * 
     * @type {number}
     * @memberof UpdateCopaySchema
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCopaySchema
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCopaySchema
     */
    patientId?: string;
}

export function UpdateCopaySchemaFromJSON(json: any): UpdateCopaySchema {
    return {
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
    };
}

export function UpdateCopaySchemaToJSON(value?: UpdateCopaySchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'appointment_id': value.appointmentId,
        'patient_id': value.patientId,
    };
}


