import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotQueryKey } from "./useGetSlot";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import {
  DeleteSlotSeriesRequest,
  fetchSlotSeriesApi,
} from "shared/fetch/src/apis/SlotSeriesApi";
import { SlotSeriesDeleteResponse } from "shared/fetch/src/models/SlotSeriesDeleteResponse";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

const useDeleteSlotSeries = (
  config?: UseMutationOptions<
    SlotSeriesDeleteResponse,
    Error,
    DeleteSlotSeriesRequest
  >
): UseMutationResult<
  SlotSeriesDeleteResponse,
  Error,
  DeleteSlotSeriesRequest
> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const user = useSelector(getUser) as JsonUser;
  if (!user?.hasFullXoCalPermissions) {
    return useMutation<
      SlotSeriesDeleteResponse,
      Error,
      DeleteSlotSeriesRequest
    >(
      () => {
        throw new Error("You do not have permission to delete slot series");
      },
      {
        onError: () => {
          showErrorStatusSnackbar(
            "You do not have permission to delete slot series"
          );
        },
      }
    );
  }

  const mutationFn = (requestParameters: DeleteSlotSeriesRequest) => {
    return fetchSlotSeriesApi.deleteSlotSeries(
      requestParameters
    ) as Promise<SlotSeriesDeleteResponse>;
  };

  const defaultConfig: UseMutationOptions<
    SlotSeriesDeleteResponse,
    Error,
    DeleteSlotSeriesRequest
  > = {
    onSuccess: (_data) => {
      showSuccessStatusSnackbar("Slot has been successfully deleted.");
      queryClient.invalidateQueries(getFetchSlotQueryKey({}));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to delete the slot.");
    },
    ...config,
  };

  return useMutation<SlotSeriesDeleteResponse, Error, DeleteSlotSeriesRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useDeleteSlotSeries;
