import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  ButtonBase,
  Popper,
} from "@mui/material";
import clsx from "clsx";
import Autocomplete from "components/Autocomplete";
import Checkbox from "components/Checkbox";
import Chip from "components/Chip";
import TextField from "components/TextField";
import Tooltip from "components/Tooltip";
import Typography from "components/Typography";
import React from "react";
import classes from "../index.module.css";

import InfoIcon from "@mui/icons-material/Info";
import { OptionType, useSelectVisitType } from "./useSelectVisitType";

import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import { capitalizeFirstLetterOfEveryWord } from "../../utils";
import { ValuesType, inputSxProps } from "../utils";
import uniqueId from "lodash/uniqueId";

interface IAppointmentTypeComboBoxProps {
  selectedVisitTypes: string[];
  handleChange: (event: any) => void;
  setField: (field: string, value: any) => void;
  visibility?: SlotVisibilityEnum;
  maxPatients?: number;
  maxOverbook?: number;
  providerId?: string | null;
  isScheduleVisitsTab: boolean;
  values: ValuesType;
  slot: SlotOutput | undefined;
  appointmentType: string;
  hasMultipleMembersScheduled?: boolean;
  disabled?: boolean;
  isKeepOrCancelFlow?: boolean;
  setIsKeepOrCancelFlow?: React.Dispatch<React.SetStateAction<boolean>>;
  isChangingVisitType?: boolean;
  setIsChangingVisitType?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AnchorVisitBadge: React.FC = () => (
  <span className={classes.badge}>Anchor visit</span>
);

const PopperComponent: React.FC<any> = (props) => (
  <Popper
    {...props}
    placement="bottom-end"
    style={{ width: "400px", height: 0 }}
  />
);

const SelectVisitType: React.FC<IAppointmentTypeComboBoxProps> = (props) => {
  const {
    anchorVisit,
    selectedType,
    hasScheduledVisits,
    appointmentTypesByVisibility,
    getAppointmentTypeName,
    isOptionAnchorVisit,
    handleAutoCompleteChange,
    isOptionDisabled,
    handleInputChange,
    isInternalHold,
  } = useSelectVisitType(props);

  const {
    selectedVisitTypes,
    isScheduleVisitsTab,
    slot,
    hasMultipleMembersScheduled,
    disabled,
    setIsKeepOrCancelFlow,
    setIsChangingVisitType,
    handleChange,
  } = props;

  const appointmentNameSelectedType = getAppointmentTypeName(
    Array.isArray(selectedType) ? selectedType[0] : selectedType
  );

  // when switching tabs, we need to reset anything
  // that was set in Settings Tab
  React.useEffect(() => {
    handleChange({
      target: {
        name: "appointmentTypes",
        value: slot?.appointmentTypes?.length ? slot?.appointmentTypes : [],
      },
    });
    handleChange({
      target: {
        name: "maxPatients",
        value: slot?.maxPatients || 1,
      },
    });
    handleChange({
      target: {
        name: "maxOverbook",
        value: slot?.maxOverbook || 0,
      },
    });
    handleChange({
      target: {
        name: "repeats",
        value: slot?.slotSeries !== undefined,
      },
    });
  }, []);

  const renderTags = (tagValue: OptionType[]): React.ReactNode => {
    // if we are in the slot settings tab, but we also have appointments scheduled,
    // we need to use the selectedType to get the appointment type name, even if the tagValue
    // is different because selectedType is the value that will be accurate with the scheduled slot
    if (!isScheduleVisitsTab && slot?.appointments?.length) {
      return appointmentNameSelectedType;
    } else {
      if (tagValue.length === 1) {
        return appointmentNameSelectedType;
      } else if (tagValue.length > 1 && isScheduleVisitsTab) {
        return anchorVisit && getAppointmentTypeName(anchorVisit);
      } else if (!isScheduleVisitsTab && tagValue.length > 1) {
        return `Multiple visits (${tagValue.length})`;
      }
      return null;
    }
  };

  // since this component is used as a multiselect in the Slot Settings tab and a
  // single select in the Schedule visits tab, the option value passed can be a
  // string (from sinlge select) OR an array (multi-select). This function is messy but it works.
  // Not going to refactor at the moment, there's plenty of test coverage in the cypress tests
  // to refactor against if we decie to do that
  const renderAutoCompleteInputLabel = (option: any) => {
    // on Slot settings tab
    const isSlotSettingsTab = !isScheduleVisitsTab;
    // does slot have appointment
    const hasAppointment = Boolean(slot?.appointments?.length);
    // is option value left ofter from previous renders?
    const hasStaleOptionValue = Array.isArray(option);
    // has the user not selected an option?
    const hasNoSelectionValue = !Boolean(option.length);
    // the useer HAS selected an option
    const hasUserSelectionValue = typeof option === "string"; // I hate this
    // get the appointment id in case it is needed
    const appointmentId = slot?.appointments?.[0]?.appointmentType;

    if (isSlotSettingsTab) {
      // the autocomplete component appears to handle this display in another function, the return
      // value here does not show up in the UI
      return "";
    } else if (isScheduleVisitsTab && !hasAppointment && hasNoSelectionValue) {
      // value here does not show up in the UI, we might not even hit this block
      return "";
    } else if (isScheduleVisitsTab && hasAppointment && hasUserSelectionValue) {
      return getAppointmentTypeName(option || option[0]) as string;
    } else if (isScheduleVisitsTab && hasAppointment && !hasStaleOptionValue) {
      return getAppointmentTypeName(appointmentId!) as string;
    } else if (isScheduleVisitsTab && hasAppointment && hasNoSelectionValue) {
      return getAppointmentTypeName(appointmentId!) as string;
    } else if (isScheduleVisitsTab && !hasAppointment && !hasStaleOptionValue) {
      return getAppointmentTypeName(option || option[0]) as string;
    } else if (isScheduleVisitsTab && hasAppointment && hasStaleOptionValue) {
      return getAppointmentTypeName(appointmentId!) as string;
    }
    return getAppointmentTypeName(option[0]) as string;
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OptionType,
    state: AutocompleteRenderOptionState
  ) => {
    const hasAnchorVisitBadge =
      !isInternalHold(option) &&
      isOptionAnchorVisit(option) &&
      !isScheduleVisitsTab;
    const optionName = capitalizeFirstLetterOfEveryWord(option?.name);

    return (
      <li
        className={clsx(classes.listItem, props.className)}
        data-testid="select-visit-type-item"
        role="option"
        aria-selected={state.selected}
        aria-label={`${optionName}${state.selected ? ", selected" : ""}${
          hasAnchorVisitBadge ? ", anchor visit" : ""
        }`}
        {...props}
        key={option.id} // must come after the spread ...props or it will be overwritten
      >
        <Checkbox
          classes={{
            root: classes.visitTypeCheckBox,
          }}
          data-testid="select-visit-type-checkbox"
          checked={state.selected}
          name={optionName}
          tabIndex={-1}
          inputProps={{
            "aria-label": `Select ${optionName}`,
          }}
        />
        <div
          className={classes?.optionLabel}
          data-testid={`select-${optionName}`}
        >
          <span>{optionName}</span>
          {hasAnchorVisitBadge && <AnchorVisitBadge />}
        </div>
      </li>
    );
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    return (
      <ul key={params.key}>
        <Typography
          fontWeightBold
          appearance="body"
          className={classes.visitTypeGroupText}
        >
          {params.group}
        </Typography>
        {params.children}
      </ul>
    );
  };

  const filterOptions = (
    options: OptionType[],
    { inputValue }: { inputValue: string }
  ) => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const isOptionEqualToValue = (option: OptionType, value: any): boolean => {
    if (value === null) {
      return false;
    }
    // if it is the schedule visit tab, or if the slot has scheduled visits
    // then we need to use the selectedType rather than the value
    if (isScheduleVisitsTab || hasScheduledVisits) {
      return (
        (Array.isArray(selectedType) ? selectedType[0] : selectedType) ===
        option?.id?.toString()
      );
    }

    return value?.toString() === option?.id?.toString();
  };

  return (
    <>
      <div className={classes.visitTypeContainer}>
        <div
          className={
            isScheduleVisitsTab
              ? classes.comboBoxScheduleVisitTab
              : classes.comboBox
          }
        >
          <Autocomplete
            classes={{
              input: classes.input,
              paper: classes.autocompleteDropdown,
            }}
            sx={inputSxProps}
            disabled={
              isScheduleVisitsTab
                ? (slot?.appointments && slot?.appointments?.length > 1) ||
                  hasMultipleMembersScheduled
                : disabled
            }
            aria-label="Select visit type"
            role="combobox"
            id="visit-type-select"
            disableCloseOnSelect
            disableClearable={selectedVisitTypes?.length > 1}
            options={appointmentTypesByVisibility ?? []}
            getOptionDisabled={isOptionDisabled}
            groupBy={(option) => option?.modality}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(e, newValue) => {
              handleAutoCompleteChange(e, newValue);

              // the coditional below allows us to track if we are changing the visit type AND
              // if we need ot set the keepOrCancel flow
              // we need BOTH values because we need to know if we are changing visit type
              // for use in isOptionDisabled in useSelectVisitType
              // as sometimes we will have a true keepOrCancel flow due to changing a different
              // variable in the SlotActionDrawer (such as time, frequency, or number of members)
              if (!!slot?.appointments?.length) {
                !isScheduleVisitsTab &&
                  setIsKeepOrCancelFlow &&
                  setIsKeepOrCancelFlow(true);
                if (
                  newValue[0]?.id !== slot?.appointments?.[0]?.appointmentType
                ) {
                  setIsChangingVisitType && setIsChangingVisitType(true);
                }
              } else {
                setIsKeepOrCancelFlow && setIsKeepOrCancelFlow(false);
                setIsChangingVisitType && setIsChangingVisitType(false);
              }
            }}
            PopperComponent={PopperComponent}
            value={selectedVisitTypes || []}
            multiple={!isScheduleVisitsTab}
            renderOption={renderOption}
            renderGroup={renderGroup}
            filterOptions={filterOptions}
            getOptionLabel={renderAutoCompleteInputLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                name={
                  isScheduleVisitsTab
                    ? "appointmentRequestAppointmentTypes"
                    : "visitTypes"
                }
                data-testid="visit-type-select"
                classes={{
                  root: clsx(classes.textField),
                }}
                fullWidth
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "0",
                    paddingLeft: "20px",
                  },
                }}
                InputLabelProps={{ shrink: true }}
                placeholder={
                  selectedVisitTypes?.length === 0 ? "Select visit type" : ""
                }
                color="secondary"
                inputProps={{
                  ...params.inputProps,
                  "aria-label": "Select visit type",
                }}
              />
            )}
            renderTags={renderTags}
          />
        </div>
        {!isScheduleVisitsTab && (
          <Tooltip
            title="If you don't see the visit you're looking for, contact tech support"
            placement="bottom-start"
            classes={{
              tooltip: classes.tooltip,
            }}
            data-testid="tool-tip-title"
          >
            <ButtonBase>
              <InfoIcon
                className={classes.drawerIcons}
                data-testid="tool-tip"
              />
            </ButtonBase>
          </Tooltip>
        )}
      </div>
      {selectedVisitTypes?.length > 1 &&
        !hasScheduledVisits &&
        !isScheduleVisitsTab && (
          <div className={classes.chipContainer}>
            {selectedVisitTypes.map((visitType: string) => {
              // removes visit type from selection
              const unSelectVisitType = () =>
                handleInputChange(
                  "appointmentTypes",
                  selectedVisitTypes.filter(
                    (v) => v.toString() !== visitType.toString()
                  )
                );
              return (
                <Chip
                  key={`${visitType}-${uniqueId()}`}
                  label={getAppointmentTypeName(visitType)}
                  aria-label={`De-select ${getAppointmentTypeName(visitType)}`}
                  // we need both the onClick and onDelete
                  // to activate all the keyboard handlers we want
                  onClick={unSelectVisitType}
                  onDelete={unSelectVisitType}
                />
              );
            })}
          </div>
        )}
    </>
  );
};

export default React.memo(SelectVisitType);
