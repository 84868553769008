import React from "react";
import Dialog from "components/Dialog";
import DialogTitle from "components/Dialog/DialogTitle";
import DialogContent from "components/Dialog/DialogContent";
import Button from "components/Button";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import MemberPaymentDetails from "../ProcessPaymentModal/MemberPaymentDetails";

interface IProps {
  isOpen: boolean;
  onClose: any;
  handlePayLater: any;
  memberSummary: any;
  providerName: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: theme.palette?.links?.primary,
      background: theme.palette?.white,
      textDecoration: "underline",
      paddingTop: "16px",
      fontSize: theme.typography.body1.fontSize,

      "&:hover": {
        color: theme.palette?.links?.primaryHover,
        textDecoration: "underline",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
    },
    confirmButton: {
      width: "106px",
      height: "44px",
      fontWeight: "normal",
      fontSize: theme.typography.body1.fontSize,
    },
    memberDetails: {
      backgroundColor: theme.palette?.appBackground?.main,
      padding: "16px 12px",
      borderRadius: "8px",
    },
  })
);

const PayLaterModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  handlePayLater,
  memberSummary,
  providerName,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={false}
      fullWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "408px",
          width: "100%",
        },
      }}
      open={isOpen}
      onClose={onClose}
      ariaLabelledBy="pay-later-dialog-content"
    >
      <DialogTitle
        onClose={onClose}
        sx={{ height: "72px" }}
        id="pay-later-dialog-title"
      >
        Send to unpaid list
      </DialogTitle>
      <DialogContent sx={{ height: "232px" }}>
        <MemberPaymentDetails
          className={classes.memberDetails}
          memberSummary={memberSummary}
          providerName={providerName}
        />

        <Button className={classes.confirmButton} onClick={handlePayLater}>
          Confirm
        </Button>
        <ButtonStyledAsLink className={classes.cancelButton} onClick={onClose}>
          No, cancel
        </ButtonStyledAsLink>
      </DialogContent>
    </Dialog>
  );
};

export default PayLaterModal;
