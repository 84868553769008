import { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { usePostPaymentMethod } from "shared/features/payments/paymentMethods";

interface INewMethod {
  zip: string;
  country: string;
  nickName: string;
  primaryCheck: boolean;
  fsaHsaCheck: boolean;
}

const useAddAPI = (userId: string) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { addPaymentMethod, isAddPaymentLoading } =
    usePostPaymentMethod(userId);

  useEffect(() => {
    if (stripe && elements) {
      setIsLoading(false);
    }
  }, [stripe, elements]);

  const clearError = () => setErrorMessage("");
  const addMethod = async (newMethod: INewMethod) => {
    const { zip, country } = newMethod;
    const cardElement = elements?.getElement(CardNumberElement) || ({} as any);
    // use data when we implement zip and country fields
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    const data = { address_zip: zip, address_country: country };
    const result = await stripe?.createToken(cardElement);

    if (result?.error) {
      return null;
    }

    return result;
  };

  const addMethodAndSave = async (
    newMethod: INewMethod,
    onComplete: () => void,
    onCompleteSuccess: () => void = () => null,
    onCompleteError: () => void = () => null
  ) => {
    setIsLoading(true);
    const { primaryCheck, fsaHsaCheck, nickName } = newMethod;
    const result = await addMethod(newMethod);

    if (!result || !result?.token) {
      return;
    }

    const { id, card = {} } = result?.token || {};
    // @ts-ignore: Object is possibly 'null'.
    const { brand, id: cardId, last4 } = card;

    await addPaymentMethod(
      {
        last4,
        cardId,
        cardBrand: brand,
        paymentToken: id,
        id: Number(userId),
        cardLabel: nickName,
        primary: primaryCheck,
        isHsaOrFsa: fsaHsaCheck,
      },
      {
        onSettled: () => {
          onComplete();
        },
        onSuccess: () => {
          onCompleteSuccess();
        },
        onError: () => {
          onCompleteError();
        },
      }
    );
  };

  return {
    errorMessage,
    isAddPaymentLoading: isAddPaymentLoading || isLoading,
    clearError,
    addMethod,
    addMethodAndSave,
  };
};

export default useAddAPI;
