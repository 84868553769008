// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Modality
 */
export interface Modality  {
    /**
     * 
     * @type {string}
     * @memberof Modality
     */
    type?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Modality
     */
    list?: Array<number>;
}

export function ModalityFromJSON(json: any): Modality {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'list': !exists(json, 'list') ? undefined : json['list'],
    };
}

export function ModalityToJSON(value?: Modality): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'list': value.list,
    };
}


