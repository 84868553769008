// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalendarStaticDataResponseClinicDetails,
    CalendarStaticDataResponseClinicDetailsFromJSON,
    CalendarStaticDataResponseClinicDetailsToJSON,
} from './CalendarStaticDataResponseClinicDetails';
import {
    CalendarStaticDataResponseClinics,
    CalendarStaticDataResponseClinicsFromJSON,
    CalendarStaticDataResponseClinicsToJSON,
} from './CalendarStaticDataResponseClinics';
import {
    RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';


/**
 * 
 * @export
 * @interface CalendarStaticDataResponse
 */
export interface CalendarStaticDataResponse  {
    /**
     * 
     * @type {Array<CalendarStaticDataResponseClinics>}
     * @memberof CalendarStaticDataResponse
     */
    clinics?: Array<CalendarStaticDataResponseClinics>;
    /**
     * 
     * @type {Array<RoleOutputCoreSchema>}
     * @memberof CalendarStaticDataResponse
     */
    roles?: Array<RoleOutputCoreSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarStaticDataResponse
     */
    providers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarStaticDataResponse
     */
    serviceTypes?: Array<string>;
    /**
     * 
     * @type {CalendarStaticDataResponseClinicDetails}
     * @memberof CalendarStaticDataResponse
     */
    clinicDetails?: CalendarStaticDataResponseClinicDetails;
}

export function CalendarStaticDataResponseFromJSON(json: any): CalendarStaticDataResponse {
    return {
        'clinics': !exists(json, 'clinics') ? undefined : (json['clinics'] as Array<any>).map(CalendarStaticDataResponseClinicsFromJSON),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleOutputCoreSchemaFromJSON),
        'providers': !exists(json, 'providers') ? undefined : json['providers'],
        'serviceTypes': !exists(json, 'service_types') ? undefined : json['service_types'],
        'clinicDetails': !exists(json, 'clinic_details') ? undefined : CalendarStaticDataResponseClinicDetailsFromJSON(json['clinic_details']),
    };
}

export function CalendarStaticDataResponseToJSON(value?: CalendarStaticDataResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinics': value.clinics === undefined ? undefined : (value.clinics as Array<any>).map(CalendarStaticDataResponseClinicsToJSON),
        'roles': value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleOutputCoreSchemaToJSON),
        'providers': value.providers,
        'service_types': value.serviceTypes,
        'clinic_details': CalendarStaticDataResponseClinicDetailsToJSON(value.clinicDetails),
    };
}


