import Box from "components/Box";
import React from "react";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import classes from "./index.module.css";
import { ProviderMenuItem } from "./ProviderMenuItem";
import SlotActionDrawerTabs from "./SlotActionDrawerTabs";

interface IProps {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  activeTab: number;
  slotDrawerProvider: XOCalProvider | undefined;
  isSlotCreation: boolean;
}

const ProviderDisplayAndTabs: React.FC<IProps> = ({
  setActiveTab,
  activeTab,
  slotDrawerProvider,
  isSlotCreation,
}) => {
  return (
    <div className={classes.ctmProfileAndTabContainer}>
      <Box className={classes.providerContainerInTabsRow}>
        <ProviderMenuItem
          title={slotDrawerProvider?.name!}
          avatarUrl={slotDrawerProvider?.avatarUrl!}
          type={slotDrawerProvider?.serviceType!}
          hasNoAvatarLeftMargin
        />
      </Box>
      <SlotActionDrawerTabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        isSlotCreation={isSlotCreation}
      />
    </div>
  );
};

export default ProviderDisplayAndTabs;
