import { matchPath } from "react-router";

const pageTitles = {
  "/": "Dashboard",
  "/conversations": "Conversations",
  "/tools/*": "Tools",
  "/members/:memberId/self-schedule": "Self schedule",
  "/direct-messaging": "Direct Messaging",
  "/get-care/*": "Create a conversation",
  "/notifications/*": "Notification center",
  "/providers": "Meet your team",
  "/members/:memberId/conversations/:id": "Conversation timeline",
  "/members/:memberId/conversations/:id/component/:componentId":
    "Component details",
  "/members/:memberId/conversations/:id/bundle/:timelineEventId":
    "Message and more",
  "/members/:memberId/conversations/:id/new-message":
    "Create a timeline message",
  "/members/:memberId/conversations/:id/component/:componentId/respond":
    "Create a timeline message",
  "/members/:memberId/care-access/messages": "Direct Messaging",
  "/members/:memberId/care-access/*": "Get care now",
  "/members/:id/*": "Member health profile",
  "/members/new": "Create a new member",
  "/engagement-activation": "Welcome to Crossover",
  "/account/*": "Account and settings",
  "/health-records/*": "Health records",
  "/members/:memberId/conversations/:id/component/:componentId/check-in":
    "Check in",
  "/proactive": "Proactive care",
  "/members": "Member search",
  "care-history/*": "Care History",
  "/calendar": "Calendar",
  "/schedule": "Daily Schedule",
};

const getPageTitleFromUrl = (pathname: string) => {
  const currentPageTitle = Object.keys(pageTitles).find((key) => {
    const match = matchPath({ path: key }, pathname);
    return !!match;
  });

  return pageTitles[currentPageTitle || ""];
};

export default getPageTitleFromUrl;
