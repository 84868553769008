import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { selectLoggedInUser } from "shared/features/user/selectors";
import User from "models/User";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Asset from "components/Asset";
import Button from "components/Button";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    marginTop: "10px",
    marginLeft: "1px",
    [breakpoints.down("md")]: {
      flexWrap: "nowrap",
      overflowY: "scroll",
      paddingRight: "16px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    listStyleType: "none",
    paddingLeft: "0px",
    [breakpoints.down(913) && breakpoints.up(767)]: {
      // the quick links were a bit too close to 'Active Conversations' header between these two breakpoints
      marginBottom: "20px",
    },
  },
  button: {
    padding: "4px 12px 4px 8px",
    fontSize: "13.3px",
    lineHeight: "17.3px",
    border: `1px solid ${palette.text.link}`,
    borderRadius: "32px",
    backgroundColor: palette.bluePastelAlt,
    gap: "8px",
    "& span": {
      margin: "0px",
      [breakpoints.down("md")]: {
        textWrap: "nowrap",
      },
    },
    "&:hover": {
      backgroundColor: palette.bluePastelHover,
      color: palette.blueHover,
      borderColor: palette.blueHover,
    },
  },
  extraPadding: {
    padding: "7px 12px 6px 8px",
  },
}));

const QuickLinks = () => {
  const classes = useStyles();
  const hasHealthRecordsPermission = useAccess({
    to: PermissionsEnum.HealthRecordsRead,
  });
  const user = selectLoggedInUser();
  const isIneligible = User.isUserIneligible(user);

  if (!hasHealthRecordsPermission) {
    return null;
  }

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography variant="h2" color="textSecondary" appearance="caption">
          QUICK LINKS
        </Typography>
      </Grid>

      <Grid container component="ul" spacing={1} className={classes.container}>
        {!isIneligible && (
          <Grid item component="li">
            <Button
              component="a"
              color="tertiary"
              variant="outlined"
              className={classes.button}
              to={`/members/${user?.id}/care-access/messages`}
              stateParam={{ page: "direct-message", prevPath: "/" }}
              startIcon={<Asset name="quick-links-direct-message" />}
            >
              <span>Direct Message</span>
            </Button>
          </Grid>
        )}

        <Grid item component="li">
          <Button
            component="a"
            color="tertiary"
            variant="outlined"
            className={classes.button}
            to={`/care-history`}
            startIcon={<Asset name="quick-links-conversations" />}
          >
            <span>All Conversations</span>
          </Button>
        </Grid>

        <Grid item component="li">
          <Button
            component="a"
            color="tertiary"
            variant="outlined"
            className={classes.button}
            to={`care-history?page=1&tab=visits`}
            startIcon={<Asset name="quick-links-visits" />}
          >
            <span>All visits</span>
          </Button>
        </Grid>

        <Grid item component="li">
          <Button
            component="a"
            color="tertiary"
            variant="outlined"
            className={clsx(classes.button, classes.extraPadding)}
            to={`/health-records?tab=prescriptions`}
            startIcon={<Asset name="quick-links-medication" />}
          >
            <span>Refill medication</span>
          </Button>
        </Grid>

        <Grid item component="li">
          <Button
            component="a"
            color="tertiary"
            variant="outlined"
            className={clsx(classes.button, classes.extraPadding)}
            to={`/health-records?tab=labs`}
            startIcon={<Asset name="quick-links-labs" />}
          >
            <span>Labs</span>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuickLinks;
