// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotSeriesDeleteResponseSummary,
    SlotSeriesDeleteResponseSummaryFromJSON,
    SlotSeriesDeleteResponseSummaryToJSON,
} from './SlotSeriesDeleteResponseSummary';


/**
 * 
 * @export
 * @interface SlotSeriesDeleteResponse
 */
export interface SlotSeriesDeleteResponse  {
    /**
     * 
     * @type {SlotSeriesDeleteResponseSummary}
     * @memberof SlotSeriesDeleteResponse
     */
    summary?: SlotSeriesDeleteResponseSummary;
}

export function SlotSeriesDeleteResponseFromJSON(json: any): SlotSeriesDeleteResponse {
    return {
        'summary': !exists(json, 'summary') ? undefined : SlotSeriesDeleteResponseSummaryFromJSON(json['summary']),
    };
}

export function SlotSeriesDeleteResponseToJSON(value?: SlotSeriesDeleteResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'summary': SlotSeriesDeleteResponseSummaryToJSON(value.summary),
    };
}


