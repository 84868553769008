import React from "react";
import EocBadge from "components/EocBadge";
import makeStyles from "@mui/styles/makeStyles";
import { RoleOutputCoreSchema } from "shared/fetch/src/models/RoleOutputCoreSchema";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";

export const getBadgeInfo = (
  roles: RoleOutputCoreSchema[],
  serviceType: string
) => {
  // TODO: if new provider types are added they might not have badges
  // If that happens, the fix is likely to move the provider badge info to the BE,
  // it should live on the
  // XOCalProvider ojbect. But this sub-optimal FE solution is working for now.

  // the roles object contains badge info but the display labels there
  // dont match the provider's serviceType in every case
  // so we have to massage the date for some serviceType's
  let transformedServiceType: string;
  switch (serviceType) {
    case "Physician":
      transformedServiceType = "Primary Care";
      break;
    case "Dermatologist":
      transformedServiceType = "Dermatology";
      break;
    case "Therapist":
      transformedServiceType = "Mental Health";
      break;
    case "Nurse":
      transformedServiceType = "Primary Care Nurse";
      break;
    case "Personal Training":
      transformedServiceType = "Fitness Coach";
      break;
    default:
      transformedServiceType = serviceType!;
  }

  // now find the corresponding role object with the badge info for the given provider type
  let result = roles?.find(
    (role: RoleOutputCoreSchema) =>
      role?.displayLabel === transformedServiceType
  );
  result = Boolean(result?.abbreviation) ? result : undefined;

  return result;
};

interface IProps {
  roles?: RoleOutputCoreSchema[];
  serviceType: string;
  provider?: XOCalProvider;
}

const useStyles = makeStyles(() => ({
  providerBadge: {
    display: "inline",
    height: "0.8rem",
    marginTop: "0px",
    padding: "2px",
    position: "relative",
    top: "-1px",
  },
}));

export default function ProviderBadge({ roles, serviceType }: IProps) {
  const classes = useStyles();
  const badgeInfo = getBadgeInfo(roles!, serviceType!);

  // if badgeInfo is falsey return null
  if (!badgeInfo) {
    return null;
  }

  const { abbreviation, color, backgroundColor } = badgeInfo;
  return (
    <EocBadge
      data-testid="xo-cal-provider-badge"
      className={classes.providerBadge}
      badge={{
        abbreviation: abbreviation ?? "",
        color: color ?? "",
        backgroundColor: backgroundColor ?? "",
      }}
    />
  );
}
