import { useQueryClient, useQuery } from "react-query";
import {
  GetPaymentMethodsRequest,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";

export const paymentMethodsQueryKey = (id: string | number) => [
  "paymentMethods",
  id.toString(),
];

export default (requestParameters: GetPaymentMethodsRequest) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    paymentMethodsQueryKey(requestParameters.id),
    () =>
      fetchMemberPaymentsApi
        .getPaymentMethods(requestParameters)
        .finally(() => {
          queryClient.invalidateQueries(
            paymentMethodsQueryKey(requestParameters.id)
          );
        })
  );

  return {
    paymentMethods: data?.results,
    isPending: isLoading,
  };
};
