// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Serialized object containing additional configurations.
 * @export
 * @interface ClinicConfigMisc
 */
export interface ClinicConfigMisc  {
    /**
     * Title 4 for the clinic.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    title4?: string;
    /**
     * URL or identifier for the logo.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    logo?: string;
    /**
     * Details about missed appointments.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    missAppt?: string;
    /**
     * Title 1 for the clinic.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    title1?: string;
    /**
     * Title 2 for the clinic.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    title2?: string;
    /**
     * Title 3 for the clinic.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    title3?: string;
    /**
     * Address for snack deliveries.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    snackAddr?: string;
    /**
     * Address for chocolate deliveries.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    chocoAddr?: string;
    /**
     * Address for sleep consultations.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    sleepAddr?: string;
    /**
     * Address for pregnancy consultations.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    pregnAddr?: string;
    /**
     * Address for decoding services.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    decodAddr?: string;
    /**
     * Address for stress consultations.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    stresAddr?: string;
    /**
     * Address for posture improvement resources.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    postureHacksAddr?: string;
    /**
     * Address for better movement resources.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    betterMovementHacksAddr?: string;
    /**
     * Link to external resources.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    link?: string;
    /**
     * New building identifier.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    bldgNew?: string;
    /**
     * New address details.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    addrNew?: string;
    /**
     * New map URL or identifier.
     * @type {string}
     * @memberof ClinicConfigMisc
     */
    mapuNew?: string;
}

export function ClinicConfigMiscFromJSON(json: any): ClinicConfigMisc {
    return {
        'title4': !exists(json, 'title4') ? undefined : json['title4'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'missAppt': !exists(json, 'miss_appt') ? undefined : json['miss_appt'],
        'title1': !exists(json, 'title1') ? undefined : json['title1'],
        'title2': !exists(json, 'title2') ? undefined : json['title2'],
        'title3': !exists(json, 'title3') ? undefined : json['title3'],
        'snackAddr': !exists(json, 'snack_addr') ? undefined : json['snack_addr'],
        'chocoAddr': !exists(json, 'choco_addr') ? undefined : json['choco_addr'],
        'sleepAddr': !exists(json, 'sleep_addr') ? undefined : json['sleep_addr'],
        'pregnAddr': !exists(json, 'pregn_addr') ? undefined : json['pregn_addr'],
        'decodAddr': !exists(json, 'decod_addr') ? undefined : json['decod_addr'],
        'stresAddr': !exists(json, 'stres_addr') ? undefined : json['stres_addr'],
        'postureHacksAddr': !exists(json, 'posture_hacks_addr') ? undefined : json['posture_hacks_addr'],
        'betterMovementHacksAddr': !exists(json, 'better_movement_hacks_addr') ? undefined : json['better_movement_hacks_addr'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'bldgNew': !exists(json, 'bldg_new') ? undefined : json['bldg_new'],
        'addrNew': !exists(json, 'addr_new') ? undefined : json['addr_new'],
        'mapuNew': !exists(json, 'mapu_new') ? undefined : json['mapu_new'],
    };
}

export function ClinicConfigMiscToJSON(value?: ClinicConfigMisc): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title4': value.title4,
        'logo': value.logo,
        'miss_appt': value.missAppt,
        'title1': value.title1,
        'title2': value.title2,
        'title3': value.title3,
        'snack_addr': value.snackAddr,
        'choco_addr': value.chocoAddr,
        'sleep_addr': value.sleepAddr,
        'pregn_addr': value.pregnAddr,
        'decod_addr': value.decodAddr,
        'stres_addr': value.stresAddr,
        'posture_hacks_addr': value.postureHacksAddr,
        'better_movement_hacks_addr': value.betterMovementHacksAddr,
        'link': value.link,
        'bldg_new': value.bldgNew,
        'addr_new': value.addrNew,
        'mapu_new': value.mapuNew,
    };
}


