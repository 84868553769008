import React from "react";
import clsx from "clsx";
import useMobileCheck from "hooks/useMobileCheck";
import ButtonWithAccess from "components/Button/ButtonWithAccess";
import Grid from "components/Grid";
import DashboardWelcomeMessage from "components/LandingPage/WelcomeMessage/DashboardWelcomeMessage";
import Heading from "components/DynamicHeadings";
import Container from "components/Container";
import Asset from "components/Asset";
import { useStyles } from "pages/LandingPage/styles";
import LandingPageEmptyComponent from "pages/LandingPage/LandingPageEmptyComponent";

import { HeroHeader } from "templates/Header";
import { eocRootPath } from "containers/Routing";

import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

interface IEmptyEOC {
  totalActiveConversations: number;
  preventiveCare: any;
}

const EmptyEOC = ({ totalActiveConversations, preventiveCare }: IEmptyEOC) => {
  const classes = useStyles();
  const isMobile = useMobileCheck();
  return (
    <>
      <HeroHeader isEmptyEOC color="dark-blue" className={classes.header}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item className={classes.centerText}>
            <DashboardWelcomeMessage
              isEmptyEOC
              title="Welcome to Crossover"
              body="Crossover is the one simple place for all your health needs."
            />
            {!isMobile && (
              <ButtonWithAccess
                access={PermissionsEnum.EpisodesCreate}
                color="primary"
                to={eocRootPath}
                component="a"
                className={classes.getCare}
                aria-describedby="create-eoc-description"
                data-testid="welcome-message-cta-button"
                hideConsentModal
                hidePhoneModal
              >
                Get Care Now
                <Asset
                  className={clsx(
                    "icon icon-plus",
                    classes.dashboardButtonIcon
                  )}
                  name="icon-chevron"
                />
              </ButtonWithAccess>
            )}
          </Grid>
        </Grid>
      </HeroHeader>
      <Heading.LevelBoundary>
        <Container maxWidth="xl" className={classes.container}>
          <LandingPageEmptyComponent
            title={`ACTIVE CONVERSATIONS (${totalActiveConversations})`}
            hasArchivedConversations={false}
            message="There are no active Conversations."
          />
          {preventiveCare}
        </Container>
      </Heading.LevelBoundary>
    </>
  );
};

export default EmptyEOC;
