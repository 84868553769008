// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPlanPodAssignment
 */
export interface AccessPlanPodAssignment  {
    /**
     * 
     * @type {number}
     * @memberof AccessPlanPodAssignment
     */
    efId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessPlanPodAssignment
     */
    accessPlanName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessPlanPodAssignment
     */
    accessPlanId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessPlanPodAssignment
     */
    aboveGroundPods?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessPlanPodAssignment
     */
    underGroundPods?: Array<string>;
}

export function AccessPlanPodAssignmentFromJSON(json: any): AccessPlanPodAssignment {
    return {
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'accessPlanName': !exists(json, 'access_plan_name') ? undefined : json['access_plan_name'],
        'accessPlanId': !exists(json, 'access_plan_id') ? undefined : json['access_plan_id'],
        'aboveGroundPods': !exists(json, 'above_ground_pods') ? undefined : json['above_ground_pods'],
        'underGroundPods': !exists(json, 'under_ground_pods') ? undefined : json['under_ground_pods'],
    };
}

export function AccessPlanPodAssignmentToJSON(value?: AccessPlanPodAssignment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ef_id': value.efId,
        'access_plan_name': value.accessPlanName,
        'access_plan_id': value.accessPlanId,
        'above_ground_pods': value.aboveGroundPods,
        'under_ground_pods': value.underGroundPods,
    };
}


