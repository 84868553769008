import React, { useState } from "react";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import classes from "./index.module.css";
import Paper from "components/Paper";
import { FormHelperText, PaperProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import parseDoB from "shared/utils/parseDoB";
import useFormatDate from "shared/utils/useFormatDate";
import CardSection from "components/Card/CardSection";
import Asset from "components/Asset";
import Typography from "components/Typography";
import useDroppable from "features/healthRecords/utils/useDroppable";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { useUploadDocumentMutation } from "shared/features/healthProfile";
import {
  isDateValid,
  isEmailValid,
} from "features/memberSearch/pages/NewMember/utils/validations";
import { mutateAsync } from "redux-query";
import useProxyMinor from "shared/features/patientProxy/useMinorProxy";
import TextField from "components/TextField";
import { useQueryClient } from "react-query";
import {
  memberSearchResultsQueryKey,
  patientQuickListForStaffUserQueryKey,
} from "shared/features/memberSearch/useMemberSearchResults";
import { selectLoggedInUser } from "shared/features/user/selectors";
import useRevokeProxyMinor from "shared/features/patientProxy/useRevokeMinorProxy";
import { deleteDocument } from "shared/api/src/apis/DocumentsApi";
import { DocumentObjectDetails } from "shared/api/src/models/DocumentObjectDetails";
import { OutputMemberSearchSchemaProps } from "shared/fetch/src/models/OutputMemberSearchSchemaProps";
import { ProxyMinorRequestBodyConsentTypeEnum } from "shared/fetch/src/models/ProxyMinorRequestBody";
import { RevokeProxyRequestBodyConsentTypeEnum } from "shared/fetch/src/models/RevokeProxyRequestBody";
import { ListMembersRequest } from "shared/fetch/src/apis/MemberSearchResultsApi";

function PaperComponent(props: PaperProps) {
  return <Paper {...props} classes={{ root: classes.paper }} />;
}

interface IProps {
  open: boolean;
  closeModal: () => void;
  member: OutputMemberSearchSchemaProps;
  isGreenPlusFlow?: boolean;
  searchRequest?: ListMembersRequest;
  revokeProxy?: boolean;
}

const ProxyModal: React.FC<IProps> = ({
  open,
  closeModal,
  member,
  isGreenPlusFlow = false,
  searchRequest,
  revokeProxy,
}) => {
  const today = new Date();
  const formatDate = useFormatDate();
  const defaultDate = formatDate(today, "yyyy-MM-dd");
  const [consentedOnDate, setConsentedOnDate] = useState(defaultDate);
  const [selectedDocument, setSelectedDocument] = useState<File | null>(null);
  const [uploadedDocument, setUploadedDocument] =
    useState<DocumentObjectDetails | null>(null);
  const [dateError, setDateError] = useState<string>("");
  const { mutateAsync: proxyMinor } = useProxyMinor(member?.firstName);
  const { mutateAsync: revokeMinorProxy } = useRevokeProxyMinor(
    member?.firstName
  );
  const [isSignedDateUpdated, setIsSignedDateUpdated] = useState(false);
  const isSubmitDisabled = !uploadedDocument || !consentedOnDate || !!dateError;
  const [email, setEmail] = useState(undefined);
  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();
  const acceptedMimeTypesString = "application/pdf";
  const isAcceptedMimeType = (fileType: string) => {
    return fileType === acceptedMimeTypesString;
  };
  const queryClient = useQueryClient();
  const user = selectLoggedInUser();

  const handleDateChange = (e: any) => {
    const dateValue = e.target.value;
    if (!dateValue) {
      setDateError("Date of consent is required");
    } else if (!isDateValid(dateValue)) {
      setDateError("Enter a valid date");
    } else if (new Date(dateValue) > today) {
      setDateError("Enter a past or current date");
    } else {
      setDateError("");
      setConsentedOnDate(dateValue);
      setIsSignedDateUpdated(true);
    }
  };

  const handleEmailChange = (e: any) => {
    const emailValue = e.target.value;
    if (!emailValue) {
      setEmailError("");
      setEmail(undefined);
    } else if (!isEmailValid(emailValue)) {
      setEmailError("Enter a valid email");
    } else if (isEmailValid(emailValue)) {
      setEmailError("");
      setEmail(emailValue);
    }
  };

  const handleSelectedFile = (file: File) => {
    if (!file) {
      return;
    }
    if (isAcceptedMimeType(file.type)) {
      setSelectedDocument(file);
    } else {
      dispatch(
        showSnackbar(
          "Sorry, this file cannot be uploaded. The file type is not supported.",
          "danger"
        )
      );
    }
  };

  const deleteDocumentRequest = (documentId: string) => {
    return mutateAsync(deleteDocument({ id: documentId }));
  };

  const handleDeleteFile = (documentId: string) => {
    setSelectedDocument(null);
    setUploadedDocument(null);
    dispatch(deleteDocumentRequest(documentId));
  };

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleClickInput = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const isModal = true;
  const { setDropZoneNodeRef } = useDroppable(handleSelectedFile, isModal);

  // @ts-ignore: Object is possibly 'null'.
  const parsedDoB = parseDoB(member?.dob);
  const memberDoB = formatDate(parsedDoB, "MM/dd/yyyy");
  const { mutate: uploadDocument, isLoading: createDocumentLoading } =
    useUploadDocumentMutation({ skipSuccessToast: true });

  const handleUploadConsent = (displayName: string) => {
    uploadDocument(
      {
        // @ts-ignore: Object is possibly 'null'.
        id: member?.id?.toString(),
        // @ts-ignore: Object is possibly 'null'.
        file: selectedDocument,
        // @ts-ignore
        title: displayName,
        docType: "consent",
      },
      {
        onSuccess: (data: DocumentObjectDetails) => {
          setUploadedDocument(data);
        },
      }
    );
  };

  React.useEffect(() => {
    if (selectedDocument) {
      handleUploadConsent(selectedDocument?.name || "");
    }
  }, [selectedDocument]);

  const proxyTitleText = revokeProxy
    ? "Are you sure you want to revoke proxy account access for this account?"
    : "Submit Proxy Approval";

  const proxyHeaderText = revokeProxy
    ? "Revoking access will result in separate account access for the teen and the parent/guardian will no longer have access."
    : "Once a proxy approval is submitted, the teen will not have separate access to their own account.";

  const getConsentedonDate = () => {
    return isSignedDateUpdated
      ? consentedOnDate.toString()
      : formatDate(today, "MMM d, yyyy @ h:mm:ssaaa zzz").toString();
  };

  const onConfirm = async () => {
    const consentedDate = getConsentedonDate();

    proxyMinor({
      memberId: member?.primaryAccountHolder?.id?.toString() || "",
      minorId: member.id || "",
      proxyMinorRequestBody: {
        documentId: uploadedDocument?.id,
        consentedDate,
        consentType: ProxyMinorRequestBodyConsentTypeEnum.Attachment,
        consentRequired: true,
      },
    }).then((_response) => {
      if (searchRequest) {
        queryClient.invalidateQueries(
          memberSearchResultsQueryKey(searchRequest)
        );
      }
      if (user?.id) {
        queryClient.invalidateQueries(
          patientQuickListForStaffUserQueryKey(user?.id)
        );
      }
      closeModal();
      setSelectedDocument(null);
      setUploadedDocument(null);
      setIsSignedDateUpdated(false);
    });
  };

  const onConfirmRevokeProxy = async () => {
    const consentedDate = getConsentedonDate();

    revokeMinorProxy({
      memberId: member?.primaryAccountHolder?.id?.toString() || "",
      minorId: member.id || "",
      revokeProxyRequestBody: {
        documentId: uploadedDocument?.id,
        consentedDate,
        consentType: RevokeProxyRequestBodyConsentTypeEnum.Attachment,
        consentRequired: true,
        email,
      },
    }).then((_response) => {
      if (searchRequest) {
        queryClient.invalidateQueries(
          memberSearchResultsQueryKey(searchRequest)
        );
      }
      if (user?.id) {
        queryClient.invalidateQueries(
          patientQuickListForStaffUserQueryKey(user?.id)
        );
      }
      closeModal();
      setSelectedDocument(null);
      setUploadedDocument(null);
      setEmail(undefined);
      setIsSignedDateUpdated(false);
    });
  };

  const onClose = () => {
    closeModal();
    if (uploadedDocument?.id) {
      handleDeleteFile(uploadedDocument?.id);
    }
    setSelectedDocument(null);
    setUploadedDocument(null);
  };

  const uploadState = () => {
    if (createDocumentLoading && !uploadedDocument) {
      return "Uploading file...";
    } else if (uploadedDocument) {
      return "Uploaded file";
    } else {
      return false;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        ariaLabelledBy="proxy-approval-dialog-title"
        onClose={onClose}
      >
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        <DialogTitle
          id="proxy-approval-dialog-title"
          data-e2e="proxy-approval-modal"
          className={classes.titleContainer}
        >
          {isGreenPlusFlow
            ? `Success! ${member?.firstName}'s account is now activated`
            : proxyTitleText}
        </DialogTitle>
        <CardSection bottomDivider style={{ padding: 0 }}>
          <DialogContent className={classes.dialogContent}>
            <Typography className={classes.subHead} appearance="smallBody">
              {isGreenPlusFlow
                ? "Please submit proof of consent to enable proxy access."
                : proxyHeaderText}
            </Typography>
            <div className={classes.memberInfoBorder}>
              <div className={classes.memberInfo}>
                <div>
                  {member?.firstName} {member?.lastName}
                </div>
                <div>{memberDoB}</div>
                <div>{member?.employer}</div>
              </div>
            </div>
            {revokeProxy && (
              <TextField
                type="email"
                variant="filled"
                label="Teen's email"
                className={classes.emailField}
                value={email}
                onChange={handleEmailChange}
                error={emailError?.length > 1}
              />
            )}
            {!uploadedDocument && (
              <div
                ref={setDropZoneNodeRef}
                className={classes.dragAndDropBorder}
              >
                <div className={classes.dragAndDropContents}>
                  <Asset
                    className={classes.uploadIcon}
                    name="icon-upload-cloud"
                  />
                  <Typography
                    fontWeightBold
                    appearance="body"
                    className={classes.dragAndDropText}
                  >
                    Drag and drop files or
                    <input
                      id="attach-file-input"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      type="file"
                      onChange={({ target }) => {
                        const { files } = target;
                        if (files && files.length) {
                          handleSelectedFile(files[0]);
                        }
                      }}
                      data-e2e="attach-file-button"
                      accept={acceptedMimeTypesString}
                      multiple={false}
                    />
                    <Button
                      data-testId="proxy-consent-browse-button"
                      onClick={handleClickInput}
                      className={classes.browseButton}
                    >
                      <Typography
                        fontWeightBold
                        appearance="body"
                        className={classes.browseText}
                      >
                        Browse
                      </Typography>
                    </Button>
                  </Typography>
                  <Typography
                    appearance="smallBody"
                    className={classes.dragAndDropSupported}
                  >
                    Supported formats: PDF
                  </Typography>
                </div>
              </div>
              // ) : (
              //   <div className={classes.documentAlreadyExists} />
            )}
            <div>
              {uploadState() && (
                <Typography
                  className={classes.uploadingStatus}
                  fontWeightBold
                  appearance="smallBody"
                >
                  {uploadState()}
                </Typography>
              )}
            </div>
            {(createDocumentLoading || uploadedDocument) && (
              <div className={classes.uploadInfoContainer}>
                <div className={classes.uploadedFileNameAndStatus}>
                  <Asset
                    name="icon-attachment-pdf-type"
                    className={classes.pdfIcon}
                  />
                  <Typography
                    appearance="smallBody"
                    className={classes.uploadedFileName}
                  >
                    {selectedDocument?.name}
                  </Typography>
                  {uploadedDocument && (
                    <>
                      <Asset
                        name="icon-upload-check-mark"
                        className={classes.uploadCheckIcon}
                      />
                      <Typography
                        appearance="caption"
                        className={classes.uploadDoneStatus}
                      >
                        Done
                      </Typography>
                    </>
                  )}
                </div>
                {uploadedDocument && (
                  <Button
                    onClick={() => handleDeleteFile(uploadedDocument?.id || "")}
                    className={classes.deleteButton}
                  >
                    <Asset
                      name="icon-close-attachment"
                      className={classes.deleteIcon}
                    />
                  </Button>
                )}
              </div>
            )}
            <Typography
              className={classes.dateOfConsentText}
              appearance="smallBody"
              fontWeightBold
            >
              Date of Consent
            </Typography>
            <div>
              <TextField
                type="date"
                variant="filled"
                label="Consent provided on"
                className={classes.dateContainer}
                value={consentedOnDate}
                onChange={handleDateChange}
                inputProps={{
                  max: defaultDate,
                }}
              />
              {!!dateError && (
                <FormHelperText className={classes.dateHelperText} role="alert">
                  {dateError}
                </FormHelperText>
              )}
            </div>
            <span id="date-format-helper-text" className="sr-only">
              Please enter the date in the following format: two-digit month,
              two-digit day, four-digit year
            </span>
          </DialogContent>
        </CardSection>
        <DialogActions horizontal className={classes.actions}>
          <Button
            color="primary"
            data-e2e="proxy-approval-submit-button"
            onClick={revokeProxy ? onConfirmRevokeProxy : onConfirm}
            disabled={isSubmitDisabled}
          >
            {revokeProxy ? "Yes, revoke access" : "Submit"}
          </Button>
          {revokeProxy ? (
            <Button
              color="secondary"
              data-testid="cancel-button"
              onClick={onClose}
            >
              No, keep it
            </Button>
          ) : (
            <Button
              color="link-secondary"
              data-testid="cancel-button"
              onClick={onClose}
            >
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProxyModal;
