import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme, useMediaQuery, Theme, Portal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { State } from "@types";
import { clearSnackbar, AUTO_HIDE_DURATION } from "shared/state/ui/snackbar";
import { screenReaderSpeak } from "shared/utils/screenReaderSpeak";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import Snackbar from "components/Snackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    anchorOriginBottomLeft: {
      bottom: "70px",
      [theme.breakpoints.only("xs")]: {
        bottom: "85px",
      },
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: theme.typography?.bodySmall?.fontSize,
    },
  })
);

export default function Toast() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { anchorOriginBottomLeft, text } = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const vertical = "bottom";
  const horizontal = matches ? "left" : "center";

  const { message, open, appearance, width } = useSelector(
    (state: State) => state.ui.snackbar
  );

  React.useEffect(() => {
    if (open && message) {
      screenReaderSpeak(message, "assertive");
    }
  }, [open]);

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Portal>
      <Snackbar
        open={open}
        className={anchorOriginBottomLeft}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        transitionDuration={0}
        ContentProps={{
          "aria-labelledby": "message-id",
        }}
      >
        <SnackbarContent
          style={{ width }}
          onClose={handleClose}
          appearance={appearance}
          hasCloseButton
          message={
            <span className={text} id="message-id">
              {message}
            </span>
          }
        />
      </Snackbar>
    </Portal>
  );
}
