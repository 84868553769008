import React from "react";
import Asset from "components/Asset";

const NoActiveProvidersMessage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "25px",
      }}
    >
      <p>There are no active providers available for this date</p>{" "}
      <Asset
        name="error-account"
        alt=""
        style={{ width: "215px", paddingTop: "25px" }}
      />
    </div>
  );
};

export default NoActiveProvidersMessage;
