import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ITimeZone } from "shared/state/ui/careAccess";
import { timezoneOptions } from "shared/utils/timeZone";
import Autocomplete from "components/Autocomplete";
import TextField from "components/TextField";

interface IProps {
  timezone: ITimeZone;
  handleSetTimeZone: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  autoComplete: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.text.tertiary}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.secondary.main}`,
    },
    "& .Mui-focused": {
      "& .MuiSvgIcon-root": {
        color: palette.secondary.main,
      },
    },
    "& .MuiInputBase-root": {
      width: "332px",
      borderRadius: "8px",
      [breakpoints.down("md")]: {
        width: "304px",
      },
      backgroundColor: (props: any) =>
        props.isPrimary ? palette.bluePastelAlt : palette.white,
    },
  },
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "&[aria-selected='true']": {
          backgroundColor: palette?.appBackground?.blue,
          color: palette.white,
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
      },
    },
  },
}));

const TimezoneDropdown: FunctionComponent<IProps> = ({
  timezone,
  handleSetTimeZone,
}): JSX.Element => {
  const classes = useStyles({});

  const getOptionSelected = (option: any, value: any) => {
    if (!value) {
      return false;
    } else {
      return value.code === option.timezone;
    }
  };

  return (
    <Autocomplete
      id="current-timezone"
      disableClearable
      autoHighlight
      className={classes.autoComplete}
      options={timezoneOptions}
      value={timezone}
      getOptionLabel={(option) => option.name || ""}
      isOptionEqualToValue={getOptionSelected}
      size="medium"
      ListboxProps={{
        className: classes.listboxItem,
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      onChange={(_e: any, value: any) => {
        handleSetTimeZone(value);
      }}
    />
  );
};

export default TimezoneDropdown;
