import React, { FunctionComponent, useState, useEffect, useMemo } from "react";
import Button from "components/Button";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

interface ModalProps {
  open: boolean;
  toggle: () => void;
  link: string;
  title: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    consent: {
      "& h4": {
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
  })
);

const getConsentUrl = (url: string) => {
  return `${location.protocol}//${location.host}${url}`;
};

const ConsentModal: FunctionComponent<ModalProps> = ({
  open,
  toggle,
  link,
  title,
}) => {
  const [consentHtml, setContentHtml] = useState("loading...");
  const classes = useStyles();
  useEffect(() => {
    fetch(getConsentUrl(link))
      .then((response) => response.text())
      .then((consents) => setContentHtml(consents));
  }, [link]);
  const consentContentIsInvalid = useMemo(
    () => consentHtml.indexOf("<html") !== -1,
    [consentHtml]
  );

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={toggle}
      ariaLabelledBy="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent
        dividers
        dangerouslySetInnerHTML={{
          __html: consentContentIsInvalid ? "Failed to load." : consentHtml,
        }}
        className={classes.consent}
      />
      <DialogActions>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentModal;
