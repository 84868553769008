// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeleteDateRangeSlotsSlots,
    DeleteDateRangeSlotsSlotsFromJSON,
    DeleteDateRangeSlotsSlotsToJSON,
} from './DeleteDateRangeSlotsSlots';


/**
 * 
 * @export
 * @interface DeleteDateRangeSlots
 */
export interface DeleteDateRangeSlots  {
    /**
     * 
     * @type {Array<DeleteDateRangeSlotsSlots>}
     * @memberof DeleteDateRangeSlots
     */
    slots?: Array<DeleteDateRangeSlotsSlots>;
}

export function DeleteDateRangeSlotsFromJSON(json: any): DeleteDateRangeSlots {
    return {
        'slots': !exists(json, 'slots') ? undefined : (json['slots'] as Array<any>).map(DeleteDateRangeSlotsSlotsFromJSON),
    };
}

export function DeleteDateRangeSlotsToJSON(value?: DeleteDateRangeSlots): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slots': value.slots === undefined ? undefined : (value.slots as Array<any>).map(DeleteDateRangeSlotsSlotsToJSON),
    };
}


