// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyScheduleResponseCopayCharge
 */
export interface DailyScheduleResponseCopayCharge  {
    /**
     * 
     * @type {number}
     * @memberof DailyScheduleResponseCopayCharge
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseCopayCharge
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseCopayCharge
     */
    status?: DailyScheduleResponseCopayChargeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseCopayCharge
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseCopayCharge
     */
    updatedAt?: string;
}

export function DailyScheduleResponseCopayChargeFromJSON(json: any): DailyScheduleResponseCopayCharge {
    return {
        'amount': json['amount'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function DailyScheduleResponseCopayChargeToJSON(value?: DailyScheduleResponseCopayCharge): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'id': value.id,
        'status': value.status,
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

/**
* @export
* @enum {string}
*/
export enum DailyScheduleResponseCopayChargeStatusEnum {
    Set = 'set',
    Unpaid = 'unpaid',
    UnpaidError = 'unpaid_error',
    Paid = 'paid',
    Deleted = 'deleted',
    SetByCtm = 'set_by_ctm'
}


