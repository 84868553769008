import React from "react";

const CalendarWarningIcon = ({ width = 32, height = 32 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_4445_86726)">
        <path
          d="M26.8612 10.2602V5.13886C26.8612 4.79997 26.7266 4.47497 26.487 4.23534C26.2473 3.99571 25.9223 3.86108 25.5834 3.86108H2.58344C2.24455 3.86108 1.91955 3.99571 1.67992 4.23534C1.44029 4.47497 1.30566 4.79997 1.30566 5.13886V10.2602H26.8612Z"
          fill="#E17B80"
        />
        <path
          d="M26.8612 6.41664V5.13886C26.8612 4.79997 26.7266 4.47497 26.487 4.23534C26.2473 3.99571 25.9223 3.86108 25.5834 3.86108H2.58344C2.24455 3.86108 1.91955 3.99571 1.67992 4.23534C1.44029 4.47497 1.30566 4.79997 1.30566 5.13886V6.41664H26.8612Z"
          fill="#E17B80"
        />
        <path
          d="M1.30566 10.2602V23.0278C1.30566 23.3667 1.44029 23.6917 1.67992 23.9313C1.91955 24.1709 2.24455 24.3056 2.58344 24.3056H25.5834C25.9223 24.3056 26.2473 24.1709 26.487 23.9313C26.7266 23.6917 26.8612 23.3667 26.8612 23.0278V10.25L1.30566 10.2602Z"
          fill="white"
        />
        <path
          d="M24.0984 16.6389C23.9948 16.4449 23.8405 16.2827 23.6519 16.1696C23.4633 16.0566 23.2475 15.9968 23.0276 15.9968C22.8077 15.9968 22.592 16.0566 22.4034 16.1696C22.2148 16.2827 22.0604 16.4449 21.9569 16.6389L15.4964 28.949C15.3518 29.224 15.3211 29.5448 15.411 29.8422C15.5009 30.1396 15.7041 30.3898 15.9769 30.5386C16.1583 30.6382 16.3602 30.6919 16.5672 30.6906H29.4881C29.6945 30.6911 29.8976 30.6387 30.0781 30.5385C30.2586 30.4383 30.4105 30.2936 30.5192 30.1182C30.6268 29.9435 30.6871 29.7439 30.694 29.5389C30.701 29.3338 30.6544 29.1305 30.5589 28.949L24.0984 16.6389Z"
          fill="#FFE9C9"
        />
        <path
          d="M23.028 16C22.8083 15.999 22.5924 16.058 22.4038 16.1706C22.2151 16.2831 22.0607 16.4451 21.9572 16.6389L15.4968 28.949C15.3518 29.2237 15.321 29.5445 15.4109 29.8418C15.5009 30.1391 15.7043 30.389 15.9772 30.5373C16.1574 30.6382 16.3605 30.6906 16.5675 30.6906H23.028V16Z"
          fill="#FFE9C9"
        />
        <path
          d="M24.0984 16.6389C23.9948 16.4449 23.8405 16.2827 23.6519 16.1696C23.4633 16.0566 23.2475 15.9968 23.0276 15.9968C22.8077 15.9968 22.592 16.0566 22.4034 16.1696C22.2148 16.2827 22.0604 16.4449 21.9569 16.6389L15.4964 28.949C15.3518 29.224 15.3211 29.5448 15.411 29.8422C15.5009 30.1396 15.7041 30.3898 15.9769 30.5386C16.1583 30.6382 16.3602 30.6919 16.5672 30.6906H29.4881C29.6945 30.6911 29.8976 30.6387 30.0781 30.5385C30.2586 30.4383 30.4105 30.2936 30.5192 30.1182C30.6268 29.9435 30.6871 29.7439 30.694 29.5389C30.701 29.3338 30.6544 29.1305 30.5589 28.949L24.0984 16.6389Z"
          stroke="#606A76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0273 24.3068V20.4735"
          stroke="#606A76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8057 24.3055H2.58344C2.24455 24.3055 1.91955 24.1709 1.67992 23.9313C1.44029 23.6916 1.30566 23.3666 1.30566 23.0278V5.13886C1.30566 4.97106 1.33871 4.8049 1.40293 4.64988C1.46714 4.49485 1.56126 4.35399 1.67992 4.23534C1.91955 3.99571 2.24455 3.86108 2.58344 3.86108H25.5834C25.7512 3.86108 25.9174 3.89413 26.0724 3.95835C26.2275 4.02256 26.3683 4.11668 26.487 4.23534C26.6056 4.35399 26.6997 4.49485 26.764 4.64988C26.8282 4.8049 26.8612 4.97106 26.8612 5.13886V13.4444"
          stroke="#606A76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.69434 1.30682V7.69571"
          stroke="#606A76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4727 1.30682V7.69571"
          stroke="#606A76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.30566 10.2513H26.8612"
          stroke="#606A76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0275 27.5C22.9427 27.5 22.8615 27.4663 22.8016 27.4064C22.7417 27.3465 22.708 27.2652 22.708 27.1805C22.708 27.0958 22.7417 27.0146 22.8016 26.9546C22.8615 26.8947 22.9427 26.8611 23.0275 26.8611"
          stroke="#606A76"
        />
        <path
          d="M23.0273 27.5C23.1121 27.5 23.1933 27.4663 23.2532 27.4064C23.3131 27.3465 23.3468 27.2652 23.3468 27.1805C23.3468 27.0958 23.3131 27.0146 23.2532 26.9546C23.1933 26.8947 23.1121 26.8611 23.0273 26.8611"
          stroke="#606A76"
        />
      </g>
    </svg>
  );
};

export default CalendarWarningIcon;
