import { useMutation, useQueryClient } from "react-query";

import { paymentMethodsQueryKey } from "./useGetPaymentMethods";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { SetPaymentMethodRequest, UpdatePaymentMethodRequest, fetchMemberPaymentsApi } from "shared/fetch/src/apis/MemberPaymentsApi";

const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutateAsync, isLoading } = useMutation(
    (requestParameters: UpdatePaymentMethodRequest) =>
      fetchMemberPaymentsApi
        .updatePaymentMethod(requestParameters)
        .then(() => {
          dispatch(showSnackbar("Update was successful."));
        })
        .catch(() => {
          dispatch(showSnackbar("Update was unsuccessful.", "danger"));
        })
        .finally(() => {
          queryClient.invalidateQueries(
            paymentMethodsQueryKey(requestParameters.id)
          );
        })
  );

  const { mutateAsync: setDefaultPaymentMethod, isLoading: isSetting } =
    useMutation((requestParameters: SetPaymentMethodRequest) =>
      fetchMemberPaymentsApi
        .setPaymentMethod(requestParameters)
        .then(() => {
          dispatch(showSnackbar("Update was successful."));
        })
        .catch(() => {
          dispatch(showSnackbar("Update was unsuccessful.", "danger"));
        })
        .finally(() => {
          queryClient.invalidateQueries(
            paymentMethodsQueryKey(requestParameters.id)
          );
        })
    );

  return {
    setDefaultPaymentMethod,
    updatePaymentMethod: mutateAsync,
    isSetDefaultPaymentMethodLoading: isSetting,
    isUpdatePaymentMethodLoading: isLoading,
  };
};

export default useUpdatePaymentMethod;
