import React from "react";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { Tooltip } from "@mui/material";
import { Group } from "@mui/icons-material";
import styles from "components/XOCal/EventContent.module.css";
import { AppointmentPatientInfoObject } from "shared/fetch/src/models/AppointmentPatientInfoObject";
import { composeTooltipSlotProps } from "../utils";
import PatientInformation from "./PatientInformation";

interface IProps {
  appointments: AppointmentOutput[];
  patientInfo: AppointmentPatientInfoObject | undefined;
  totalNumberOfPatients: number;
}

function GroupPatientInfoTooltipContent({ appointments }: IProps) {
  return (
    <>
      {appointments?.map((appointment, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "5px",
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              {appointment.patientInfo?.name}
            </div>
            <PatientInformation patientInfo={appointment.patientInfo} />
          </div>
        );
      })}
    </>
  );
}

export default function GroupPatientInfo(props: IProps) {
  const { appointments, totalNumberOfPatients } = props;
  return (
    <>
      <Tooltip
        title={
          appointments?.length ? (
            <GroupPatientInfoTooltipContent {...props} />
          ) : undefined
        }
        slotProps={composeTooltipSlotProps({ offset: [70, -10] })}
      >
        <div className={styles.groupIconTextContainer}>
          <div>Group</div>
          <div
            className={
              appointments?.length === totalNumberOfPatients
                ? styles.groupIconContainerFullGroup
                : styles.groupIconContainer
            }
          >
            <div>
              <Group className={styles.groupIcon} />
            </div>

            <span>{`${appointments?.length}/${totalNumberOfPatients}`}</span>
          </div>
        </div>
      </Tooltip>
    </>
  );
}
