import { CareTeamMember } from "../../@types";
import cooper from "../../styles/images/avatars/cooper.jpg";
import {
  JsonUser as User,
  JsonUserFromJSON,
} from "shared/fetch/src/models/JsonUser";
import {
  isValidPhoneNumber,
  isValidZipCode,
  isValidEmail,
} from "utils/validate";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

export const HARDCODED_CTM: CareTeamMember = {
  id: 1,
  avatar: cooper,
  department: "Primary Care",
  displayName: "Cooper F.D.",
};

export const HARDCODED_USER: User = JsonUserFromJSON({
  id: 123,
  avatarUrl: cooper,
  first_name: "Ted",
  preferred_name: "Teddy",
  last_name: "Public",
  full_name: "Teddy Public",
  gender: "M",
  dob: "2019-07-16",
  born_at: new Date("2019-07-16"),
  email: "ted.public@crossoverhealth.com",
  xoid: "D0MPCH",
  employee_id: "123890345",
  insurance_id: "2489023490",
  pcp: "David Doctor, MD",
  status: "Active",
  restricted_dependent: false,
  oauth_sign_in: false,
  permissions: [
    PermissionsEnum.EpisodesCreate,
    PermissionsEnum.EpisodesRead,
    PermissionsEnum.EpisodesUpdate,
    PermissionsEnum.DashboardEpisodesRead,
  ],
  admin: false,
  ctm: true,
  consented: true,
  verified: true,
  default_site: "abc",
  client: "XO",
  preferred_language: "eng",
  account_locked: false,
  account_unlockable: false,
  patient_id: 2384909,
  cell_phone: "929 4453 345",
  workPhone: "929 4453 345",
  home_phone: "929 4453 345",
});

export const HARDCODED_EPISODES: any[] = [
  {
    id: "14",
    state: "active",
    serviceLine: "Medical",
    serviceSubtype: "I am Sick",
    memberInfo: {
      id: "22",
      name: "Jane Test",
      employer: "Crossover Health",
    },
    addedAt: "2019-09-12T12:49:11Z",
    needsActionSince: "2019-09-12T12:49:11Z",
    resourceUrl: "/v1/episodes/14",
    lastCtmUpdater: {
      id: "22",
      name: "Jane Test",
      type: "ctm",
    },
    actionableItems: [
      {
        id: "1",
        status: "active",
        actionType: "episode_created",
        url: "/v1/components/1",
      },
    ],
  },
];
export interface Fields {
  sexAtBirth: any;
  fname: boolean;
  lname: boolean;
  dateOfBirth: boolean;
  preferredFirstName: boolean;
  lang: boolean;
  cellPhone: boolean;
  homePhone: boolean;
  emergencyContactName: boolean;
  emergencyContactPhone: boolean;
  emergencyContactRelationship: boolean;
  address: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  mailingAddress: boolean;
  mailingCity: boolean;
  mailingState: boolean;
  mailingZip: boolean;
  email: boolean;
  race: string;
  ethnicity: string;
}

export const ValidateFields = (fields: any) => {
  const validatedFields: Fields = {} as Fields;
  Object.keys(fields).forEach((field) => {
    const trimmedField = fields[field]?.trim();

    if (field === "zip" || field === "mailingZip") {
      validatedFields[field] = isValidZipCode(trimmedField);
    } else if (
      field === "cellPhone" ||
      field === "emergencyContactPhone" ||
      field === "homePhone"
    ) {
      validatedFields[field] = isValidPhoneNumber(trimmedField);
    } else if (field === "email") {
      validatedFields[field] = isValidEmail(trimmedField);
    } else {
      validatedFields[field] = !!trimmedField;
    }
  });
  return validatedFields;
};

export enum StateRegion {
  WEST = "WEST",
  EAST = "EAST",
  CENTRAL = "CENTRAL",
  NORTHEAST = "NORTHEAST",
}

export interface USAState {
  value: string;
  text: string;
  region?: StateRegion;
}

export const stateList: USAState[] = [
  { value: "AK", text: "Alaska", region: StateRegion.WEST },
  { value: "AL", text: "Alabama", region: StateRegion.EAST },
  { value: "AR", text: "Arkansas", region: StateRegion.CENTRAL },
  { value: "AS", text: "American Samoa" },
  { value: "AZ", text: "Arizona", region: StateRegion.WEST },
  { value: "CA", text: "California", region: StateRegion.WEST },
  { value: "CO", text: "Colorado", region: StateRegion.WEST },
  { value: "CT", text: "Connecticut", region: StateRegion.NORTHEAST },
  { value: "DC", text: "District of Columbia", region: StateRegion.NORTHEAST },
  { value: "DE", text: "Delaware", region: StateRegion.NORTHEAST },
  { value: "FL", text: "Florida", region: StateRegion.EAST },
  { value: "GA", text: "Georgia", region: StateRegion.EAST },
  { value: "GU", text: "Guam" },
  { value: "HI", text: "Hawaii", region: StateRegion.WEST },
  { value: "IA", text: "Iowa", region: StateRegion.CENTRAL },
  { value: "ID", text: "Idaho", region: StateRegion.WEST },
  { value: "IL", text: "Illinois", region: StateRegion.CENTRAL },
  { value: "IN", text: "Indiana", region: StateRegion.EAST },
  { value: "KS", text: "Kansas", region: StateRegion.CENTRAL },
  { value: "KY", text: "Kentucky", region: StateRegion.EAST },
  { value: "LA", text: "Louisiana", region: StateRegion.CENTRAL },
  { value: "MA", text: "Massachusetts", region: StateRegion.NORTHEAST },
  { value: "MD", text: "Maryland", region: StateRegion.NORTHEAST },
  { value: "ME", text: "Maine", region: StateRegion.NORTHEAST },
  { value: "MI", text: "Michigan", region: StateRegion.EAST },
  { value: "MN", text: "Minnesota", region: StateRegion.CENTRAL },
  { value: "MO", text: "Missouri", region: StateRegion.CENTRAL },
  { value: "MS", text: "Mississippi", region: StateRegion.EAST },
  { value: "MT", text: "Montana", region: StateRegion.WEST },
  { value: "NC", text: "North Carolina", region: StateRegion.EAST },
  { value: "ND", text: "North Dakota", region: StateRegion.CENTRAL },
  { value: "NE", text: "Nebraska", region: StateRegion.CENTRAL },
  { value: "NH", text: "New Hampshire", region: StateRegion.NORTHEAST },
  { value: "NJ", text: "New Jersey", region: StateRegion.NORTHEAST },
  { value: "NM", text: "New Mexico", region: StateRegion.WEST },
  { value: "NV", text: "Nevada", region: StateRegion.WEST },
  { value: "NY", text: "New York", region: StateRegion.NORTHEAST },
  { value: "OH", text: "Ohio", region: StateRegion.EAST },
  { value: "OK", text: "Oklahoma", region: StateRegion.CENTRAL },
  { value: "OR", text: "Oregon", region: StateRegion.WEST },
  { value: "PA", text: "Pennsylvania", region: StateRegion.NORTHEAST },
  { value: "PR", text: "Puerto Rico" },
  { value: "RI", text: "Rhode Island", region: StateRegion.NORTHEAST },
  { value: "SC", text: "South Carolina", region: StateRegion.EAST },
  { value: "SD", text: "South Dakota", region: StateRegion.CENTRAL },
  { value: "TN", text: "Tennessee", region: StateRegion.EAST },
  { value: "TX", text: "Texas", region: StateRegion.CENTRAL },
  { value: "UT", text: "Utah", region: StateRegion.WEST },
  { value: "VA", text: "Virginia", region: StateRegion.EAST },
  { value: "VI", text: "Virgin Islands" },
  { value: "VT", text: "Vermont", region: StateRegion.NORTHEAST },
  { value: "WA", text: "Washington", region: StateRegion.WEST },
  { value: "WI", text: "Wisconsin", region: StateRegion.CENTRAL },
  { value: "WV", text: "West Virginia", region: StateRegion.EAST },
  { value: "WY", text: "Wyoming", region: StateRegion.WEST },
];
