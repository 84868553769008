import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import {
  ReplicateSingleSlotIntoSeriesRequest,
  fetchSlotSeriesApi,
} from "shared/fetch/src/apis/SlotSeriesApi";
import { SlotSeriesOutput } from "shared/fetch/src/models/SlotSeriesOutput";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { getFetchSlotQueryKey } from "./useGetSlot";

const useReplicateSingleSlotIntoSeries = (
  config?: UseMutationOptions<
    SlotSeriesOutput,
    Error,
    ReplicateSingleSlotIntoSeriesRequest
  >
): UseMutationResult<
  SlotSeriesOutput,
  Error,
  ReplicateSingleSlotIntoSeriesRequest
> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const mutationFn = (
    requestParameters: ReplicateSingleSlotIntoSeriesRequest
  ) => {
    return fetchSlotSeriesApi.replicateSingleSlotIntoSeries(
      requestParameters
    ) as Promise<SlotSeriesOutput>;
  };

  const defaultConfig: UseMutationOptions<
    SlotSeriesOutput,
    Error,
    ReplicateSingleSlotIntoSeriesRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Created slot series successfully");
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to create slot series");
    },
    onSettled: () => {
      queryClient.invalidateQueries(getFetchSlotQueryKey({}));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    ...config,
  };

  return useMutation<
    SlotSeriesOutput,
    Error,
    ReplicateSingleSlotIntoSeriesRequest
  >(mutationFn, defaultConfig);
};

export default useReplicateSingleSlotIntoSeries;
