import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Heading from "components/DynamicHeadings";
import { Link } from "components/Button";

interface IProps {
  clinicId?: string | null;
}

export default function ConflictsTableTitle({ clinicId }: IProps) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Link aria-label="Go back to XO Cal" to={`/calendar?clinicId=${clinicId}`}>{"<"}</Link>
      <Heading.H sx={{ flex: "1 1 100%" }} appearance="h3" id="table-title">
        Conflicts
      </Heading.H>
    </Toolbar>
  );
}
