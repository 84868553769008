// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalendarStaticDataResponseClinicDetailsInternalHoldTypes,
    CalendarStaticDataResponseClinicDetailsInternalHoldTypesFromJSON,
    CalendarStaticDataResponseClinicDetailsInternalHoldTypesToJSON,
} from './CalendarStaticDataResponseClinicDetailsInternalHoldTypes';
import {
    CalendarStaticDataResponseClinicDetailsOperatingHours,
    CalendarStaticDataResponseClinicDetailsOperatingHoursFromJSON,
    CalendarStaticDataResponseClinicDetailsOperatingHoursToJSON,
} from './CalendarStaticDataResponseClinicDetailsOperatingHours';
import {
    CalendarStaticDataResponseClinicDetailsProvidersByServiceType,
    CalendarStaticDataResponseClinicDetailsProvidersByServiceTypeFromJSON,
    CalendarStaticDataResponseClinicDetailsProvidersByServiceTypeToJSON,
} from './CalendarStaticDataResponseClinicDetailsProvidersByServiceType';


/**
 * 
 * @export
 * @interface CalendarStaticDataResponseClinicDetails
 */
export interface CalendarStaticDataResponseClinicDetails  {
    /**
     * 
     * @type {CalendarStaticDataResponseClinicDetailsOperatingHours}
     * @memberof CalendarStaticDataResponseClinicDetails
     */
    operatingHours?: CalendarStaticDataResponseClinicDetailsOperatingHours;
    /**
     * 
     * @type {string}
     * @memberof CalendarStaticDataResponseClinicDetails
     */
    timeZone?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarStaticDataResponseClinicDetails
     */
    id?: number;
    /**
     * 
     * @type {Array<CalendarStaticDataResponseClinicDetailsInternalHoldTypes>}
     * @memberof CalendarStaticDataResponseClinicDetails
     */
    internalHoldTypes?: Array<CalendarStaticDataResponseClinicDetailsInternalHoldTypes>;
    /**
     * 
     * @type {Array<CalendarStaticDataResponseClinicDetailsProvidersByServiceType>}
     * @memberof CalendarStaticDataResponseClinicDetails
     */
    providersByServiceType?: Array<CalendarStaticDataResponseClinicDetailsProvidersByServiceType>;
}

export function CalendarStaticDataResponseClinicDetailsFromJSON(json: any): CalendarStaticDataResponseClinicDetails {
    return {
        'operatingHours': !exists(json, 'operating_hours') ? undefined : CalendarStaticDataResponseClinicDetailsOperatingHoursFromJSON(json['operating_hours']),
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internalHoldTypes': !exists(json, 'internal_hold_types') ? undefined : (json['internal_hold_types'] as Array<any>).map(CalendarStaticDataResponseClinicDetailsInternalHoldTypesFromJSON),
        'providersByServiceType': !exists(json, 'providers_by_service_type') ? undefined : (json['providers_by_service_type'] as Array<any>).map(CalendarStaticDataResponseClinicDetailsProvidersByServiceTypeFromJSON),
    };
}

export function CalendarStaticDataResponseClinicDetailsToJSON(value?: CalendarStaticDataResponseClinicDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'operating_hours': CalendarStaticDataResponseClinicDetailsOperatingHoursToJSON(value.operatingHours),
        'time_zone': value.timeZone,
        'id': value.id,
        'internal_hold_types': value.internalHoldTypes === undefined ? undefined : (value.internalHoldTypes as Array<any>).map(CalendarStaticDataResponseClinicDetailsInternalHoldTypesToJSON),
        'providers_by_service_type': value.providersByServiceType === undefined ? undefined : (value.providersByServiceType as Array<any>).map(CalendarStaticDataResponseClinicDetailsProvidersByServiceTypeToJSON),
    };
}


