import * as React from "react";
import TableCell from "@mui/material/TableCell";
import Button from "components/Button";
import { ButtonBase } from "@mui/material";
import Popover from "components/Popover";
import MenuList from "components/Menu/MenuList";
import { ListItemButton } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import useUpdateAppointment from "shared/features/xocal/useUpdateAppointment";
import { GetSlotsForDateRangeConflictedEnum } from "shared/fetch/src/apis/SlotsApi";
import { useQueryClient } from "react-query";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { useSearchParams } from "react-router-dom";
import CancelVisitModal from "components/XOCal/SlotActionDrawer/CancelVisitModal";
import { AppointmentPatientInfoObject } from "shared/fetch/src/models/AppointmentPatientInfoObject";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import VisitNotesModal from "components/VisitNotes/VisitNotesModal";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";

interface IProps {
  patientInfo: AppointmentPatientInfoObject;
  actionsOptionsAriaLabel: string;
  slotWithConflictedAppointment: SlotOutput;
  appointmentId: string;
  appointmentType: string;
  appointmentTypeDisplayLabel?: string;
  startTimeStringForRescheduleFlow: string;
  appointment: AppointmentOutput;
}

export default function ConflictsTableRowActions({
  actionsOptionsAriaLabel,
  slotWithConflictedAppointment,
  appointmentId,
  appointmentType,
  appointmentTypeDisplayLabel,
  patientInfo,
  appointment,
  startTimeStringForRescheduleFlow,
}: IProps) {
  const [isActionsExpanded, setIsActionsExpanded] =
    React.useState<boolean>(false);
  const [hasRescheduleRedirect, setHasRescheduleRedirect] =
    React.useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { mutateAsync: updateAppointment } = useUpdateAppointment();
  const hasReschedule = Boolean(
    patientInfo?.hasConflictQueueReschedulePermission
  );

  const openActionsOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsActionsExpanded(true);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      const popoverList = document.getElementById(
        `conflicts-actions-popover-list-${appointmentId}`
      );
      popoverList?.focus();
    }, 100);
  };

  const closeActionsOptions = () => {
    setIsActionsExpanded(false);
    setAnchorEl(null);
  };

  const handleActionsArrowClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!isActionsExpanded) {
      openActionsOptions(event);
    } else {
      closeActionsOptions();
    }
  };

  const handleKeepVisitClick = () => {
    const clinicId = searchParams.get("clinicId");
    updateAppointment(
      {
        id: appointmentId,
        updateAppointmentById: {
          appointmentType,
          conflicted: false,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getFetchSlotsQueryKey({
              clinicId: clinicId as string,
              conflicted: GetSlotsForDateRangeConflictedEnum.True,
            }),
          });
        },
      }
    );
    setIsActionsExpanded(false);
  };

  const handleCancelVisitClick = () => {
    setIsActionsExpanded(false);
    setIsCancelModalOpen(true);
  };

  const handleCancelAndRescheduleClick = () => {
    setHasRescheduleRedirect(true);
    setIsActionsExpanded(false);
    setIsCancelModalOpen(true);
  };

  const [isVisitNotesModalOpen, setIsVisitNotesModalOpen] =
    React.useState(false);

  const confirmButtonDisplayLabel = hasRescheduleRedirect
    ? "Cancel and reschedule"
    : "Cancel visit";

  return (
    <>
      <CancelVisitModal
        title={
          hasRescheduleRedirect ? "Cancel and reschedule visit" : "Cancel visit"
        }
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        confirmButtonDisplayLabel={confirmButtonDisplayLabel}
        values={undefined}
        appointmentType={appointmentType}
        isConflictsQueueModal
        hasRescheduleRedirect={hasRescheduleRedirect}
        slotFromConflictsQueue={slotWithConflictedAppointment}
        patientInfoFromConflictsQueue={patientInfo}
        appointmentTypeDisplayLabel={appointmentTypeDisplayLabel}
        startTimeStringForRescheduleFlow={startTimeStringForRescheduleFlow}
      />
      <VisitNotesModal
        open={isVisitNotesModalOpen}
        onClose={() => setIsVisitNotesModalOpen(false)}
        appointment={appointment}
        appointmentType={appointmentTypeDisplayLabel}
      />
      <TableCell>
        <ButtonBase
          color="inherit"
          sx={{ minWidth: "0px", padding: "0px" }}
          onClick={() => setIsVisitNotesModalOpen(true)}
          // TODO: this will cause duplicate labels in the a11y tree when multiple instances of this component are rendered. FIX IT.
          aria-label="Open visit notes"
        >
          <StickyNote2OutlinedIcon />
        </ButtonBase>
        <Button
          data-testid={`conflicted-appointment-actions-${appointmentId}`}
          color="inherit"
          size="small"
          sx={{ minWidth: "0px", padding: "0px", marginLeft: "10px" }}
          onClick={handleActionsArrowClick}
          aria-label={actionsOptionsAriaLabel}
        >
          {isActionsExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </TableCell>

      <Popover
        open={isActionsExpanded && Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setIsActionsExpanded(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList
          tabIndex={-1}
          data-testid={`conflicts-table-row-actions-${appointmentId}`}
          id={`conflicts-actions-popover-list-${appointmentId}`}
          // TODO: this will cause duplicate labels in the a11y tree when multiple instances of this component are rendered. FIX IT.
          aria-label="Conflicted Visit Actions"
        >
          <ListItemButton onClick={handleKeepVisitClick}>
            Keep visit
          </ListItemButton>
          <ListItemButton onClick={handleCancelVisitClick}>
            Cancel visit
          </ListItemButton>
          {hasReschedule && (
            <ListItemButton onClick={handleCancelAndRescheduleClick}>
              Cancel and reschedule
            </ListItemButton>
          )}
        </MenuList>
      </Popover>
    </>
  );
}
