import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { getUser } from "shared/features/user";
import { showSnackbar } from "shared/state/ui/snackbar";
import usePatchMemberAccountInfo from "shared/features/memberProfile/usePatchMemberAccountInfo";
import Button from "components/Button";
import IconButton from "components/Button/IconButton";
import Heading from "components/DynamicHeadings";
import DialogContentText from "components/Dialog/DialogContentText";
import Box from "components/Box";
import Typography from "components/Typography";
import RadioGroup from "components/Radio/RadioGroup";
import FormControlLabel from "components/Form/FormControlLabel";
import Radio from "components/Radio";
import Asset from "components/Asset";
import Dialog from "components/Dialog";
import TextField from "components/TextField";
import { isValidPhoneNumber } from "utils/validate";
import formatPhoneNumber from "utils/formatPhoneNumber";

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  missingNumberModal: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      width: "400px",
      justifyContent: "center",
      alignItems: "center",
      [breakpoints.down("md")]: {
        width: "332px",
      },
    },
  },
  missingNumberModalContent: {
    margin: spacing(3),
    [breakpoints.down("md")]: {
      margin: spacing(2),
    },
  },
  missingNumberIcon: {
    display: "flex",
    justifyContent: "center",
    marginBottom: spacing(3),
    [breakpoints.down("md")]: {
      marginBottom: spacing(2),
    },
  },
  title: {
    textAlign: "center",
    marginBottom: spacing(2),
    [breakpoints.down("md")]: {
      marginBottom: spacing(2),
    },
  },
  subTitle: {
    textAlign: "center",
    marginBottom: spacing(4),
    [breakpoints.down("md")]: {
      marginBottom: spacing(2),
    },
  },
  missingNumberInput: {
    marginBottom: spacing(3),
    marginTop: 0,
  },
  missingNumberType: {
    marginBottom: spacing(1),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: palette.text.secondary,
  },
  radio: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 4,
  },
}));

interface ModalProps {
  openModal: boolean;
  toggle: () => void;
  handleUpdateSuccess: (e?: any) => void;
}

const MissingPhoneModal: React.FC<ModalProps> = ({
  openModal,
  toggle,
  handleUpdateSuccess,
}) => {
  const classes = useStyles();
  const { palette } = useTheme<Theme>();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [phone, setPhone] = useState("");
  const [touched, setTouched] = useState(false);
  const [phoneNumberTypeValue, setPhoneNumberTypeValue] = useState("");
  const isPhoneNumberValid = isValidPhoneNumber(phone);
  const hasError = touched && !isPhoneNumberValid;
  const { updateUserInfo, isLoading: isUpdatePending } =
    usePatchMemberAccountInfo();

  const isAddButtonDisabled =
    !phoneNumberTypeValue || !phone || hasError || isUpdatePending;

  const handlePhoneChange = (e: any) => {
    setPhone(formatPhoneNumber(e.target.value));
  };
  const handlePhoneBlur = () => {
    setTouched(true);
  };

  const handleToggleSetPhoneNumberTypeValue = (e: any) => {
    setPhoneNumberTypeValue(e.target.value);
  };

  const handlePhoneNumberUpdate = () => {
    updateUserInfo(
      {
        id: user?.id?.toString() || "",
        body:
          phoneNumberTypeValue === "mobile"
            ? { cellPhone: phone }
            : { homePhone: phone },
      },
      {
        onSuccess: () => {
          dispatch(showSnackbar("Your phone number has been added"));
          setPhone("");
          setPhoneNumberTypeValue("");
        },
        onError: () => {
          dispatch(
            showSnackbar(
              "There was a problem updating your phone number.",
              "danger"
            )
          );
        },
        onSettled: handleUpdateSuccess,
      }
    );
  };

  return (
    <Dialog
      open={openModal}
      scroll="paper"
      role="dialog"
      ariaLabelledBy="missing-phone-number-modal"
      PaperProps={{
        // necessary for getting testid applied to root component
        "data-testid": "missing-phone-number-modal",
      }}
      className={classes.missingNumberModal}
    >
      <IconButton
        aria-label="Close missing phone number modal"
        data-testid="dismiss-btn"
        data-e2e="close-btn"
        className={classes.closeButton}
        onClick={toggle}
        size="large"
      >
        <CloseIcon />
      </IconButton>

      <div
        className={classes.missingNumberModalContent}
        data-e2e="missing-phone-number-modal-content"
      >
        <div className={classes.missingNumberIcon}>
          <Asset name="missing-number" hiddenFromScreenReader />
        </div>

        <Heading.LevelBoundary levelOverride={4}>
          <Heading.H
            id="missing-phone-number-modal"
            color="textPrimary"
            appearance="h5"
            className={classes.title}
          >
            Before you can submit, we need your phone number
          </Heading.H>
        </Heading.LevelBoundary>

        <DialogContentText component="div">
          <Typography
            appearance="body"
            color="textPrimary"
            className={classes.subTitle}
          >
            Adding your phone number lets your Care Team get you the best care.
          </Typography>

          <TextField
            id="phone-number"
            label="Phone number"
            fullWidth
            margin="dense"
            variant="filled"
            name="phoneNumber"
            inputProps={{
              "data-testid": "phone-number",
            }}
            className={classes.missingNumberInput}
            value={phone}
            error={hasError}
            helperText={hasError ? "Please enter a valid phone number" : ""}
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
          />

          <Box className={classes.missingNumberType}>
            <Typography color="textPrimary">
              Select phone number type
            </Typography>
          </Box>

          <RadioGroup
            aria-label="Select phone number type"
            name="PhoneNumberType"
            value={phoneNumberTypeValue}
            onChange={handleToggleSetPhoneNumberTypeValue}
            row
          >
            <FormControlLabel
              checked={phoneNumberTypeValue === "mobile"}
              value="mobile"
              control={<Radio className={classes.radio} />}
              label="Mobile"
              aria-label="Mobile phone number"
              id="mobile-phone-number"
              style={{
                color: palette.text.primary,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            />
            <FormControlLabel
              checked={phoneNumberTypeValue === "home"}
              value="home"
              control={<Radio className={classes.radio} />}
              label="Home"
              aria-label="Home phone number"
              id="home-phone-number"
              style={{
                color: palette.text.primary,
              }}
            />
          </RadioGroup>
        </DialogContentText>

        <div className={classes.buttonsContainer}>
          <Button
            aria-disabled={isAddButtonDisabled}
            disabled={isAddButtonDisabled}
            color="primary"
            onClick={handlePhoneNumberUpdate}
            data-testid="add-phone-number-btn"
          >
            {isUpdatePending ? "Adding..." : "Add number"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MissingPhoneModal;
