// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutputMemberSearchSchemaPropsEpisodes,
    OutputMemberSearchSchemaPropsEpisodesFromJSON,
    OutputMemberSearchSchemaPropsEpisodesToJSON,
} from './OutputMemberSearchSchemaPropsEpisodes';
import {
    OutputMemberSearchSchemaPropsPrimaryAccountHolder,
    OutputMemberSearchSchemaPropsPrimaryAccountHolderFromJSON,
    OutputMemberSearchSchemaPropsPrimaryAccountHolderToJSON,
} from './OutputMemberSearchSchemaPropsPrimaryAccountHolder';


/**
 * 
 * @export
 * @interface OutputMemberSearchSchemaProps
 */
export interface OutputMemberSearchSchemaProps  {
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    age?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    dob?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    registered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    beneficiary?: OutputMemberSearchSchemaPropsBeneficiaryEnum;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    workPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    xoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    pcpName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    coreCareTeamName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    corePodName?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputMemberSearchSchemaProps
     */
    activeEpisodeCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    isVip?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    dependent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    minor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    metAgeOfConsent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    hasProxyOption?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    hasRevokeOption?: boolean;
    /**
     * 
     * @type {OutputMemberSearchSchemaPropsPrimaryAccountHolder}
     * @memberof OutputMemberSearchSchemaProps
     */
    primaryAccountHolder?: OutputMemberSearchSchemaPropsPrimaryAccountHolder;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    nestedMinor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    consentedNested?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    hasNestedBadge?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMemberSearchSchemaProps
     */
    locked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaProps
     */
    clinicsId?: string;
    /**
     * 
     * @type {Array<OutputMemberSearchSchemaPropsEpisodes>}
     * @memberof OutputMemberSearchSchemaProps
     */
    episodes?: Array<OutputMemberSearchSchemaPropsEpisodes>;
}

export function OutputMemberSearchSchemaPropsFromJSON(json: any): OutputMemberSearchSchemaProps {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'beneficiary': !exists(json, 'beneficiary') ? undefined : json['beneficiary'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'workPhone': !exists(json, 'work_phone') ? undefined : json['work_phone'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'xoid': !exists(json, 'xoid') ? undefined : json['xoid'],
        'pcpName': !exists(json, 'pcp_name') ? undefined : json['pcp_name'],
        'coreCareTeamName': !exists(json, 'core_care_team_name') ? undefined : json['core_care_team_name'],
        'corePodName': !exists(json, 'core_pod_name') ? undefined : json['core_pod_name'],
        'activeEpisodeCount': !exists(json, 'active_episode_count') ? undefined : json['active_episode_count'],
        'isVip': !exists(json, 'is_vip') ? undefined : json['is_vip'],
        'dependent': !exists(json, 'dependent') ? undefined : json['dependent'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'metAgeOfConsent': !exists(json, 'met_age_of_consent') ? undefined : json['met_age_of_consent'],
        'hasProxyOption': !exists(json, 'has_proxy_option') ? undefined : json['has_proxy_option'],
        'hasRevokeOption': !exists(json, 'has_revoke_option') ? undefined : json['has_revoke_option'],
        'primaryAccountHolder': !exists(json, 'primary_account_holder') ? undefined : OutputMemberSearchSchemaPropsPrimaryAccountHolderFromJSON(json['primary_account_holder']),
        'nestedMinor': !exists(json, 'nested_minor') ? undefined : json['nested_minor'],
        'consentedNested': !exists(json, 'consented_nested') ? undefined : json['consented_nested'],
        'hasNestedBadge': !exists(json, 'has_nested_badge') ? undefined : json['has_nested_badge'],
        'locked': !exists(json, 'locked') ? undefined : json['locked'],
        'clinicsId': !exists(json, 'clinics_id') ? undefined : json['clinics_id'],
        'episodes': !exists(json, 'episodes') ? undefined : (json['episodes'] as Array<any>).map(OutputMemberSearchSchemaPropsEpisodesFromJSON),
    };
}

export function OutputMemberSearchSchemaPropsToJSON(value?: OutputMemberSearchSchemaProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'first_name': value.firstName,
        'preferred_name': value.preferredName,
        'last_name': value.lastName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'age': value.age,
        'sex_at_birth': value.sexAtBirth,
        'dob': value.dob,
        'registered': value.registered,
        'employer': value.employer,
        'legal_name': value.legalName,
        'status': value.status,
        'beneficiary': value.beneficiary,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'email': value.email,
        'work_phone': value.workPhone,
        'cell_phone': value.cellPhone,
        'home_phone': value.homePhone,
        'avatar_url': value.avatarUrl,
        'address': value.address,
        'xoid': value.xoid,
        'pcp_name': value.pcpName,
        'core_care_team_name': value.coreCareTeamName,
        'core_pod_name': value.corePodName,
        'active_episode_count': value.activeEpisodeCount,
        'is_vip': value.isVip,
        'dependent': value.dependent,
        'minor': value.minor,
        'met_age_of_consent': value.metAgeOfConsent,
        'has_proxy_option': value.hasProxyOption,
        'has_revoke_option': value.hasRevokeOption,
        'primary_account_holder': OutputMemberSearchSchemaPropsPrimaryAccountHolderToJSON(value.primaryAccountHolder),
        'nested_minor': value.nestedMinor,
        'consented_nested': value.consentedNested,
        'has_nested_badge': value.hasNestedBadge,
        'locked': value.locked,
        'clinics_id': value.clinicsId,
        'episodes': value.episodes === undefined ? undefined : (value.episodes as Array<any>).map(OutputMemberSearchSchemaPropsEpisodesToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum OutputMemberSearchSchemaPropsBeneficiaryEnum {
    Primary = 'Primary',
    Dependent = 'Dependent'
}


