import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase, List, ListItem } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Button";
import Typography from "components/Typography";
import React from "react";
import classes from "./index.module.css";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import useDeleteSlot from "shared/features/xocal/useDeleteSlot";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { format } from "date-fns";

interface IProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slot: SlotOutput | undefined;
  handleCreateSlot: () => Promise<void>;
  handleSlotSeriesEditUpdate: (shouldKeep: boolean) => Promise<void>;
  handleCreateSlotSeries: () => Promise<void>;
  isCreatingSeriesOutOfBookedSlot: boolean;
  conflictingAppointments: AppointmentOutput[] | undefined;
}

const EditScheduledSlotModal = ({
  modalOpen,
  setModalOpen,
  slot,
  handleCreateSlot,
  handleSlotSeriesEditUpdate,
  isCreatingSeriesOutOfBookedSlot,
  handleCreateSlotSeries,
  conflictingAppointments,
}: IProps) => {
  const actionButtonRef = React.useRef<HTMLButtonElement>(null);

  const { mutateAsync: deleteSlot } = useDeleteSlot();
  const conflictingAppointmentSlotIds: number[] = [];
  conflictingAppointments?.forEach((appointment) => {
    appointment.slotId &&
      conflictingAppointmentSlotIds.push(appointment.slotId);
  });

  const setOfSlotIds = new Set(conflictingAppointmentSlotIds);

  return (
    <Dialog open={modalOpen} maxWidth={"xs"}>
      <div
        className={classes.actionModal}
        data-testid="edit-scheduled-slots-modal"
      >
        <DialogTitle className={classes.actionModalTitle}>
          <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
            {" "}
            Edit scheduled slot{" "}
          </Typography>
          <ButtonBase
            className={classes.actionModalCancel}
            role="button"
            aria-label={"Close edit scheduled slot modal"}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant={"body1"}>
            <Typography className={classes.text}>
              {conflictingAppointments?.length
                ? `This slot series has ${conflictingAppointments.length} ${
                    conflictingAppointments.length === 1 ? "visit" : "visits"
                  } scheduled on ${setOfSlotIds.size} ${
                    setOfSlotIds.size === 1 ? "slot" : "slots"
                  }. Would you like to keep ${
                    conflictingAppointments.length === 1
                      ? "this visit"
                      : "these visits"
                  }
              scheduled or make the changes and send ${
                conflictingAppointments.length === 1 ? "visit" : "visits"
              } to the conflict queue?`
                : `This slot has ${slot?.appointments?.length} scheduled ${
                    slot?.appointments?.length === 1 ? "visit" : "visits"
                  }. Would you like to
              keep ${
                slot?.appointments?.length === 1 ? "this visit" : "these visits"
              }
              scheduled or make the changes and send ${
                slot?.appointments?.length === 1 ? "visit" : "visits"
              } to the conflict queue?`}
            </Typography>
            <List className={classes.deleteBookedSlotConfirmationModalList}>
              {conflictingAppointments?.length
                ? conflictingAppointments.map((appointment, index) => {
                    return (
                      <ListItem
                        key={index}
                        alignItems="flex-start"
                        sx={{
                          display: "list-item",
                          paddingLeft: "5px",
                          fontSize: "16px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <p style={{ fontWeight: "bold", marginRight: 4 }}>
                            {appointment?.patientInfo?.name}
                          </p>
                          <p> on {format(appointment.startAt, "MM-dd-yyyy")}</p>
                        </div>
                      </ListItem>
                    );
                  })
                : slot?.appointments?.map((appointment, index) => {
                    return (
                      <ListItem
                        key={index}
                        alignItems="flex-start"
                        sx={{
                          display: "list-item",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                          fontSize: "16px",
                        }}
                      >
                        {appointment?.patientInfo?.name}
                      </ListItem>
                    );
                  })}
            </List>
          </DialogContentText>
          <DialogActions className={classes.editScheudledSlotActions}>
            <Button
              ref={actionButtonRef}
              color={"primary"}
              onClick={() => {
                if (slot?.slotSeries) {
                  handleSlotSeriesEditUpdate(true);
                } else if (isCreatingSeriesOutOfBookedSlot) {
                  handleCreateSlotSeries();
                } else {
                  handleCreateSlot();
                }
                setModalOpen(false);
              }}
              aria-label={`Keep visits`}
            >
              {"Keep visits"}
            </Button>
            <Button
              color={"secondary"}
              onClick={() => {
                if (slot?.slotSeries) {
                  handleSlotSeriesEditUpdate(false);
                } else if (isCreatingSeriesOutOfBookedSlot) {
                  slot?.id && deleteSlot({ id: slot?.id.toString() });
                  handleCreateSlotSeries();
                } else {
                  slot?.id && deleteSlot({ id: slot?.id.toString() });
                  handleCreateSlot();
                }
                setModalOpen(false);
              }}
              aria-label={`Cancel visits`}
            >
              Cancel visits
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default EditScheduledSlotModal;
