import React from "react";
import { Tooltip } from "@mui/material";
import { AppointmentPatientInfoObject } from "shared/fetch/src/models/AppointmentPatientInfoObject";

interface IProps {
  patientInfo: AppointmentPatientInfoObject | undefined;
}

export default function ScheduledPatient({ patientInfo }: IProps) {
  if (!patientInfo) {
    return null;
  }
  const patientDobAgeGender = `${patientInfo?.dob} | ${patientInfo?.age} | ${patientInfo?.gender} `;
  const { preferredPhone, cellPhone, homePhone, name } = patientInfo;
  const phoneNumber = preferredPhone || cellPhone || homePhone;

  const TooltipContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {patientDobAgeGender}
      {/* if no phone number, do not show */}
      {phoneNumber && (
        <div>
          <p>{phoneNumber}</p>
        </div>
      )}
    </div>
  );
  return (
    <Tooltip title={TooltipContent} placement="bottom-start">
      <div data-testid={"member-name"}>{name}</div>
    </Tooltip>
  );
}
