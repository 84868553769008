// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPlanPodAssignmentFiltersResponseResultsAccessPlans
 */
export interface AccessPlanPodAssignmentFiltersResponseResultsAccessPlans  {
    /**
     * 
     * @type {number}
     * @memberof AccessPlanPodAssignmentFiltersResponseResultsAccessPlans
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccessPlanPodAssignmentFiltersResponseResultsAccessPlans
     */
    label: string;
}

export function AccessPlanPodAssignmentFiltersResponseResultsAccessPlansFromJSON(json: any): AccessPlanPodAssignmentFiltersResponseResultsAccessPlans {
    return {
        'id': json['id'],
        'label': json['label'],
    };
}

export function AccessPlanPodAssignmentFiltersResponseResultsAccessPlansToJSON(value?: AccessPlanPodAssignmentFiltersResponseResultsAccessPlans): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'label': value.label,
    };
}


