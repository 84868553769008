/* eslint-disable  @typescript-eslint/no-shadow */
export interface IState {
  loggedInUserFeatureFlags: string[];
  memberUserFeatureFlags: string[];
  availableFeatureFlags: IFeatureGetter;
  memberUserFeatureFlagsId: string | null;
}

export interface IFeatureGetter {
  hasCenterVisitReferrals: () => boolean;
  hasVirtualVisitReferrals: () => boolean;
  hasEpisodeBilling: () => boolean;
  hasSelfSchedule: () => boolean;
  hasVisitReferrals: () => boolean;
  hasCheckin: () => boolean;
  hasAmatHra: () => boolean;
  hasBeWell: () => boolean;
  hasConversationCard: () => boolean;
  hasOutOfStateScheduling: () => boolean;
  hasAffinity: () => boolean;
  hasTertiaryNav: () => boolean;
  hasDirectMessaging: () => boolean;
  hasHealthProfileDocs: () => boolean;
  hasHealthProfile: () => boolean;
  hasHealthProfileConsents: () => boolean;
  hasMembershipType: () => boolean;
  hasPaymentsNext: () => boolean;
  hasMobileAccountUpdates: () => boolean;
  hasNotificationCenter: () => boolean;
  hasMissedAppointmentPolicy: () => boolean;
  hasAdultDependents: () => boolean;
  hasEngagementActivation: () => boolean;
  hasIcd10CodesFromApi: () => boolean;
  hasMinorDependents: () => boolean;
  hasGreenPlus: () => boolean;
  hasElationCCDA: () => boolean;
  hasMemberAccessToken: () => boolean;
  hasVisitSeparation: () => boolean;
  hasMemberInlineMessaging: () => boolean;
  hasMemberAccessSubNav: () => boolean;
  hasMinorSearchResults: () => boolean;
  hasManualMinors: () => boolean;
  hasInsuranceOnboarding: () => boolean;
  hasRevokeProxy: () => boolean;
  hasSOGI: () => boolean;
  hasCtmMesageDocking: () => boolean;
  hasCtmMinorAccess: () => boolean;
  hasReasonableAccommodations: () => boolean;
  hasCareAccessMenus: () => boolean;
  hasCtmCalculatorEnhancements: () => boolean;
  hasRaceAndEthnicity: () => boolean;
  hasEditCenterAndPcp: () => boolean;
  hasSelfScheduleEnhancements: () => boolean;
  hasVisitSummaries: () => boolean;
  hasAfterHoursWidget: () => boolean;
  hasDisableBiometricLogin: () => boolean;
  hasMfaEnabled: () => boolean;
  hasVirtualUrgentCare: () => boolean;
  hasDailyScheduleXop: () => boolean;
  hasXOCal: () => boolean;
  hasPhoneModal: () => boolean;
}

const SET_LOGGED_IN_USER_FEATURES = "SET_LOGGED_IN_USER_FEATURES";
const SET_MEMBER_USER_FEATURES = "SET_MEMBER_USER_FEATURES";
const CLEAR_MEMBER_USER_FEATURES = "CLEAR_MEMBER_USER_FEATURES";

const generateFeatureFunction = (featureFlags: string[]): IFeatureGetter => {
  return {
    hasCenterVisitReferrals: () => {
      return featureFlags.includes("center_visit_referrals");
    },
    hasVirtualVisitReferrals: () => {
      return featureFlags.includes("virtual_visit_referrals");
    },
    hasEpisodeBilling: () => featureFlags.includes("episode_billing"),
    hasSelfSchedule: () => featureFlags.includes("self_schedule"),
    hasVisitReferrals: () => featureFlags.includes("visit_referrals"),
    hasCheckin: () => featureFlags.includes("checkin"),
    hasBeWell: () => featureFlags.includes("be_well"),
    hasAmatHra: () => featureFlags.includes("amat_hra"),
    hasConversationCard: () => featureFlags.includes("conversation_cards"),
    hasOutOfStateScheduling: () =>
      featureFlags.includes("out_of_state_scheduling"),
    hasTertiaryNav: () => featureFlags.includes("tertiary_nav"),
    hasAffinity: () => featureFlags.includes("affinity"),
    hasHealthProfileDocs: () => featureFlags.includes("documents"),
    hasHealthProfileConsents: () => featureFlags.includes("consents"),
    hasHealthProfile: () =>
      featureFlags.includes("documents") || featureFlags.includes("consents"),
    hasDirectMessaging: () =>
      featureFlags.includes("general_and_direct_messaging"),
    hasMembershipType: () => featureFlags.includes("membership_type"),
    hasPaymentsNext: () => featureFlags.includes("payments_next"),
    hasMobileAccountUpdates: () =>
      featureFlags.includes("mobile_account_page_updates"),
    hasNotificationCenter: () => featureFlags.includes("notification_center"),
    hasMissedAppointmentPolicy: () =>
      featureFlags.includes("missed_appointment_policy"),
    hasAdultDependents: () => featureFlags.includes("adult_dependents"),
    hasEngagementActivation: () =>
      featureFlags.includes("engagement_activation"),
    hasIcd10CodesFromApi: () => featureFlags.includes("icd10_codes_from_api"),
    hasMinorDependents: () => featureFlags.includes("minor_dependents"),
    hasGreenPlus: () => featureFlags.includes("green_plus"),
    hasElationCCDA: () => featureFlags.includes("elation_ccda"),
    hasMemberAccessToken: () => featureFlags.includes("member_access_token"),
    hasVisitSeparation: () =>
      featureFlags.includes("visit_conversation_separation"),
    hasMemberInlineMessaging: () =>
      featureFlags.includes("member_inline_messaging"),
    hasMemberAccessSubNav: () => featureFlags.includes("member_access_page"),
    hasMinorSearchResults: () => featureFlags.includes("minor_search_results"),
    hasManualMinors: () => featureFlags.includes("manual_minors"),
    hasInsuranceOnboarding: () => featureFlags.includes("insurance_onboarding"),
    hasRevokeProxy: () => featureFlags.includes("revoke_proxy"),
    hasSOGI: () => featureFlags.includes("sogi"),
    hasCtmMesageDocking: () => featureFlags.includes("ctm_message_docking"),
    hasCtmMinorAccess: () => featureFlags.includes("ctm_minor_access"),
    hasReasonableAccommodations: () =>
      featureFlags.includes("reasonable_accommodations"),
    hasCareAccessMenus: () => featureFlags.includes("care_access_menus"),
    hasCtmCalculatorEnhancements: () =>
      featureFlags.includes("ctm_calculator_enhancements"),
    hasRaceAndEthnicity: () => featureFlags.includes("race_and_ethnicity"),
    hasEditCenterAndPcp: () => featureFlags.includes("edit_center_and_pcp"),
    hasSelfScheduleEnhancements: () =>
      featureFlags.includes("self_schedule_enhancements"),
    hasVisitSummaries: () => featureFlags.includes("visit_summaries"),
    hasAfterHoursWidget: () => featureFlags.includes("after_hours_widget"),
    hasDisableBiometricLogin: () =>
      featureFlags.includes("disable_biometric_login"),
    hasMfaEnabled: () => featureFlags.includes("mfa_enabled"),
    hasVirtualUrgentCare: () => featureFlags.includes("virtual_urgent_care"),
    hasDailyScheduleXop: () => featureFlags.includes("daily_schedule_xop"),
    hasXOCal: () => featureFlags.includes("xocal"),
    hasPhoneModal: () => featureFlags.includes("show_phone_modal"),
  };
};

export const featureFlagsReducer = (
  state: IState = getInitialFeatureFlagState(),
  action: any
): IState => {
  switch (action.type) {
    case SET_LOGGED_IN_USER_FEATURES:
      const thing = {
        ...state,
        loggedInUserFeatureFlags: action.payload.featureFlags,
        availableFeatureFlags: generateFeatureFunction([
          ...state.memberUserFeatureFlags,
          ...action.payload.featureFlags,
        ]),
      };

      return thing;

    case SET_MEMBER_USER_FEATURES:
      return {
        ...state,
        memberUserFeatureFlags: action.payload.featureFlags,
        memberUserFeatureFlagsId: action.payload.id,
        availableFeatureFlags: generateFeatureFunction([
          ...state.loggedInUserFeatureFlags,
          ...action.payload.featureFlags,
        ]),
      };

    case CLEAR_MEMBER_USER_FEATURES:
      return {
        memberUserFeatureFlags: [],
        memberUserFeatureFlagsId: null,
        loggedInUserFeatureFlags: state.loggedInUserFeatureFlags,
        availableFeatureFlags: generateFeatureFunction([
          ...state.loggedInUserFeatureFlags,
        ]),
      };

    default:
      return state;
  }
};

export const setLoggedInUserFeatureFlags = (featureFlags: string[]) => ({
  type: SET_LOGGED_IN_USER_FEATURES,
  payload: { featureFlags },
});

export const setMemberUserFeatureFlags = (
  featureFlags: string[],
  id: string
) => ({
  type: SET_MEMBER_USER_FEATURES,
  payload: { featureFlags, id },
});

export const clearMemberUserFeaturesFlags = () => ({
  type: CLEAR_MEMBER_USER_FEATURES,
});

/**
 * Gets memberId state from storage.
 */
export const getInitialFeatureFlagState = (): IState => {
  return {
    loggedInUserFeatureFlags: [],
    memberUserFeatureFlags: [],
    memberUserFeatureFlagsId: null,
    availableFeatureFlags: generateFeatureFunction([]),
  };
};
