import React from "react";
import MemberSearchField from "features/memberSearch/components/MemberSearchField";
import { getUser } from "shared/features/user";
import { useSelector } from "react-redux";
import { JsonUser } from "shared/api/src/models/JsonUser";
// TODO: we should ideally import our own version of these so we can control the typings
import { Typography } from "@mui/material";
import classes from "./index.module.css";
import { useSearchParams } from "react-router-dom";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import SelectVisitType from "./SelectVisitType/SelectVisitType";
import { ValuesType } from "./utils";
import TextField from "@mui/material/TextField";
import MemberInformation from "./MemberInformation";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import LinkAConversation from "./LinkAConversation";

const SearchAndScheduleMember = ({
  setField,
  handleChange,
  additionalMember,
  editMember,
  visitReason,
  setVisitReason,
  slot,
  values,
  timezone,
}: {
  setField: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<any>) => void;
  additionalMember: boolean;
  editMember: boolean;
  visitReason?: string | undefined;
  setVisitReason?: React.Dispatch<React.SetStateAction<string | undefined>>;
  slot: SlotOutput | undefined;
  values: ValuesType;
  timezone: string;
}) => {
  // @ts-ignore
  const user: JsonUser = useSelector(getUser);
  const [searchParams] = useSearchParams();
  const member = searchParams.get("member");
  const isEditingAppointment = !!searchParams.get("appointmentId");
  const scheduledMemberId =
    values?.appointments && values?.appointments[0]?.patientId;
  const memberId = member || scheduledMemberId?.toString();
  const memberInfo = useGetAccountInfo({ id: memberId ?? "" });

  const hasScheduledMember = slot && slot.appointments?.length;

  return (
    <>
      {!member && (!hasScheduledMember || additionalMember) && (
        <MemberSearchField user={user} xocal slot={slot} />
      )}
      {member && memberInfo.isSuccess && (
        <>
          {(!isEditingAppointment || editMember || additionalMember) && (
            <MemberInformation
              values={values}
              editMember={editMember}
              appointmentType={undefined}
            />
          )}
          {(!hasScheduledMember || additionalMember || editMember) && (
            <>
              <div style={{ paddingTop: "24px", paddingBottom: "8px" }}>
                <Typography className={classes.sectionTitle}>
                  {" "}
                  Visit type
                </Typography>
                <SelectVisitType
                  selectedVisitTypes={values?.appointmentTypes || []}
                  appointmentType={values?.appointmentType || ""}
                  handleChange={handleChange}
                  visibility={values?.visibility}
                  maxPatients={values?.maxPatients}
                  providerId={values?.selectedProviderId}
                  isScheduleVisitsTab
                  values={values}
                  slot={slot}
                  maxOverbook={values?.maxOverbook}
                  hasMultipleMembersScheduled={additionalMember}
                  setField={setField}
                />
              </div>
              <div style={{ paddingTop: "8px", paddingRight: "8px" }}>
                <Typography className={classes.sectionTitle}>
                  {" "}
                  Visit reason
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  className={classes.visitReasonTextBox}
                  value={visitReason ? visitReason : undefined}
                  placeholder={"Reason for visit - optional"}
                  onChange={(event) => {
                    setField("reason", event.target.value);
                    setVisitReason && setVisitReason(event.target.value);
                    handleChange(event);
                  }}
                />
              </div>
              <div style={{ paddingTop: "36px", paddingRight: "8px" }}>
                <LinkAConversation
                  timezone={timezone}
                  setField={setField}
                  values={values}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SearchAndScheduleMember;
