import React, { FunctionComponent, useState, useEffect } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { MemberPaymentMethods } from "shared/fetch/src/models/MemberPaymentMethods";
import { GetPaymentHistoryStatusEnum } from "shared/fetch/src/apis/MemberPaymentsApi";
import { DailyScheduleResponseCopayCharge } from "shared/fetch/src/models/DailyScheduleResponseCopayCharge";
import { MemberPaymentHistory } from "shared/fetch/src/models/MemberPaymentHistory";
import { showSnackbar } from "shared/state/ui/snackbar";
import { isEmpty, capitalize } from "lodash";
import Dialog from "components/Dialog";
import Box from "components/Box";
import Alert from "components/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "components/Button/IconButton";
import RadioGroup from "components/Radio/RadioGroup";
import Radio from "components/Radio";
import Button, { Link } from "components/Button";
import DialogActions from "components/Dialog/DialogActions";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import Typography from "components/Typography";
import TextField from "components/TextField";
import { FormGroup, InputAdornment } from "@mui/material";
import FormControlLabel from "components/Form/FormControlLabel";
import Checkbox from "components/Checkbox";
import MemberPaymentDetails from "./MemberPaymentDetails";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import MethodFields from "features/payments/components/MethodFields";
import useFormAPI from "features/payments/utils/useFormAPI";
import useAddAPI from "features/payments/utils/useAddAPI";
import usePayAPI from "features/payments/utils/usePayAPI";
import useUpdateCopay from "shared/features/dailySchedule/useUpdateCopay";
import Badge from "components/Badge";
import useGetAllUnpaidHistory from "shared/features/payments/paymentsHistory/useGetAllUnpaidHistory";

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    "& .MuiDialog-paper": {
      overflowY: "unset",
      borderRadius: "10px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme?.breakpoints?.down("sm")]: {
      "& .MuiBackdrop-root": {
        backgroundColor: theme?.palette?.lightGrayBackdrop,
      },
      "& .MuiDialog-paper": {
        marginTop: "100px",
        width: "325px",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
    ["@media (max-width:360px)"]: {
      "& .MuiDialog-paper": {
        marginTop: "10px",
      },
    },
    ["@media (max-width:320px)"]: {
      "& .MuiDialog-paper": {
        marginTop: "2px",
      },
    },
  },
  totalDueContainer: {
    border: `1px solid ${theme?.palette?.borderGray}`,
    padding: "8px 16px 8px 16px",
    borderRadius: "10px",
    marginBottom: "8px",
  },
  editAmount: {
    margin: "10px",
  },
  editAmountButtons: {
    marginTop: "10px",
  },
  totalDueText: {
    color: theme?.palette?.text.secondary,
  },
  totalDue: {
    padding: "5px 10px",
    border: `1px solid ${theme?.palette?.text.strokes}`,
    borderRadius: "5px",
    marginBottom: "10px",
  },
  totalDueTitle: {
    fontSize: "13.3px",
    color: theme?.palette?.darkGray,
  },
  totalDueAmount: {
    fontSize: "33.3px",
    padding: "10px 0",
    fontWeight: "700",
    color: theme?.palette?.darkestGray,
  },
  dialogBody: {
    overflowY: "hidden",
    [theme?.breakpoints?.down("sm")]: {
      width: "unset",
      padding: "16px 0px 24px 0px",
    },
  },
  dimDialogBody: {
    backgroundColor: theme?.palette?.text?.mediumDarkGray,
    padding: "16px 0px 24px 0px",
  },
  dialogTitle: {
    height: "65px",
    padding: 0,
    "& h1": {
      margin: "48px 0px 0px 24px",
    },
    "& button": {
      marginBottom: "13px",
      "& .MuiButton-label": {
        height: "14px",
        width: "14px",
      },
    },
    [theme?.breakpoints?.down("sm")]: {
      padding: 0,
      "& h1": {
        margin: "32px 0px 0px 16px",
        fontSize: "16.8px",
        lineHeight: "21.84px",
      },
      "& button": {
        marginBottom: "13px",
        "& .MuiButton-label": {
          height: "14px",
          width: "14px",
        },
      },
    },
  },
  titleSticky: {
    top: 0,
    position: "sticky",
    backgroundColor: "#fff",
    zIndex: 20,
  },
  dialogContent: {
    maxHeight: "calc(100vh - 200px)",
    borderRadius: "inherit",
    overflowY: "auto",
    borderTop: 0,
    padding: "24px",
    [theme?.breakpoints?.down("sm")]: {
      padding: 0,
      borderTop: 0,
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  },
  dimDialogContent: {
    filter: "brightness(0.9)",
    [theme?.breakpoints?.down("sm")]: {
      padding: 0,
      borderTop: 0,
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  },
  notesField: {
    backgroundColor: theme?.palette?.common?.white,
  },
  charLimit: {
    paddingTop: "4px",
    color: theme?.palette?.text.secondary,
  },
  secondaryText: {
    color: theme?.palette?.text.secondary,
    marginBottom: "4px",
  },
  paymentMethod: {
    display: "flex",
  },
  badge: {
    backgroundColor: theme?.palette?.appBackground?.darkestGray,
    color: theme?.palette?.common?.white,
    padding: "2px 2px 0 2px",
    borderRadius: "2px",
  },
  addFirstMethod: {
    marginTop: theme?.spacing(2),
    marginBottom: theme?.spacing(2),
  },
  dialogActions: {
    borderTop: "1px solid rgba(0, 0, 0, .12)",
    backgroundColor: theme?.palette?.common.white,
    zIndex: 10,
    position: "sticky",
    bottom: 32,
    padding: "24px 0px 24px 24px",
    [theme?.breakpoints?.down("sm")]: {
      padding: "16px 0 0 16px",
      "& .MuiBox-root": {
        padding: 0,
      },
    },
    "& a": {
      marginLeft: "24px",
      color: theme?.palette?.text?.link,
    },
  },
  buttonEnabled: {
    height: "44px",
    width: "109px",
  },
  buttonDisabled: {
    height: "44px",
    width: "109px",
    backgroundColor: `${theme?.palette?.lightGrey} !important`,
  },
  paymentCardWrapper: {
    marginTop: "16px",
  },
  warningIcon: {
    color: theme?.palette?.text?.secondary,
  },
  warningText: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
  },
  alert: {
    "& .MuiAlert-message": {
      width: "100%",
    },
  },
  alertMessage: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  errorMessage: {
    maxWidth: "50ch",
  },
  paymentItemsSection: {
    marginBottom: "16px",
  },
  radioGroup: {
    [theme?.breakpoints?.down("sm")]: {
      "& .MuiIconButton-label": {
        height: "20px",
        width: "20px",
      },
    },
  },
  circularProgress: {
    color: theme?.palette?.text?.primary,
    width: "23px !important",
    height: "24px !important",
  },
  dialogSelectPayment: {
    [theme?.breakpoints?.down("sm")]: {
      padding: "16px",
    },
  },
  alertWrapper: {
    marginBottom: "16px",
  },
  itemsCheckbox: {
    padding: "0 7px",
    margin: "0px",
  },
  itemsLabel: {
    padding: "0px 5px 15px 0",
    marginLeft: "-10px",
  },
  paymentsOwed: {
    marginBottom: "10px",
    color: theme.palette.text.mediumDarkGray,
    fontSize: "13.3px",
  },
  showAll: {
    cursor: "pointer",
    color: theme.palette.links?.primary,
    marginBottom: "20px",
    textDecoration: "underline",
    fontSize: "16px",
  },
  cancelButton: {
    "&:hover": {
      color: theme?.palette?.blueHover,
    },
    textDecoration: "underline",
  },
  editCopayAmount: {
    height: "40px",
    marginBottom: "16px",
    "&:has([readonly]) ": {
      "& label.Mui-focused": {
        color: theme.palette.appBackground?.lightGrey,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.appBackground?.lightGrey,
        },
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      fontWeight: "bold",
    },
  },
  addNotes: {
    "& .MuiOutlinedInput-root": {
      height: "85px",
      borderRadius: "10px",
      backgroundColor: theme.palette.white,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.mediumDarkGray}`,
    },
  },
  stickySection: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    paddingBottom: "16px",
  },
}));

export interface IMemberSummaryDSPayment {
  memberName: string;
  memberAge: string;
  memberDob: string;
  memberPhone: string;
  memberPronouns: string;
  memberSex: string;
  summary: string | undefined;
  dateTime: string | Date;
}
interface IProps {
  memberSummary: IMemberSummaryDSPayment;
  charge?: DailyScheduleResponseCopayCharge | undefined;
  memberId?: string;
  methods?: MemberPaymentMethods[];
  providerName?: string;
  amount?: string;
  onClose: () => void;
  appointmentId?: string;
  charges?: string[];
  site: string;
  date: string;
}

const ProcessPaymentModal: FunctionComponent<IProps> = ({
  memberSummary,
  charge,
  memberId,
  methods,
  onClose,
  providerName,
  amount,
  appointmentId,
  site,
  date,
}) => {
  const newPaymentID = "";
  const dispatch = useDispatch();
  const classes = useStyle();

  const [showAll, setShowAll] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [note, setNote] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(
    (parseFloat(amount, 10) / 100).toFixed(2)
  );
  const [editedAmount, setEditedAmount] = useState(paymentAmount);

  const [amountError, setAmountError] = useState("");
  const [charges, setCharges] = useState<any[]>([]);

  const [methodID, setMethodID] = useState(newPaymentID);

  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [totalPayment, setTotalPayment] = useState(parseFloat(paymentAmount));

  const { updateCopay } = useUpdateCopay(site, date);

  const { results: paymentsHistory, unpaidItemsCount } = useGetAllUnpaidHistory(
    {
      id: memberId || "",
      status: GetPaymentHistoryStatusEnum.Unpaid,
    }
  );

  const { addMethod } = useAddAPI(memberId || "");
  const { isPayNowLoading, errorMessage, clearError, payNow } = usePayAPI(
    memberId || "",
    { site, date }
  );

  const {
    country,
    zip,
    nickName,
    isPrimary,
    isFsaHsa,
    elementErrors,
    isBtnDisabled,
    getErrors,
    getValues,
    handleElementErrors,
  } = useFormAPI({
    primary: isEmpty(methods),
    handleElementErrorCallback: clearError,
  });
  const disableButton = methodID === newPaymentID ? isBtnDisabled : !methodID;
  const fieldRequirements = React.useMemo(() => {
    return [
      {
        fieldName: "editedAmount",
        condition: true,
        value: editedAmount,
        readableName: "editedAmount",
      },
    ];
  }, [editedAmount]);

  const generateErrorMessage = (fieldName: string) => {
    const field = fieldRequirements.filter(
      (fields) => fields.fieldName === fieldName
    );
    const readableName = field[0]?.readableName;

    const amountMessage = `Please enter an amount between 0 and $1M`;
    const generalMessage = `Please enter a valid ${readableName}.`;
    if (fieldName === "editedAmount") {
      return amountMessage;
    }
    return generalMessage;
  };

  const ValidateFields = (fields: any) => {
    const validatedFields: any = {};
    Object.keys(fields).forEach((field) => {
      if (field === "editedAmount") {
        validatedFields[field] =
          parseFloat(fields[field]) >= 0 &&
          parseFloat(fields[field]) <= 999999.99;
      }
    });
    return validatedFields;
  };

  const getRequiredFieldsForUser = () => {
    const required = fieldRequirements.filter((field) => !!field.condition);
    return required.reduce((acc, current) => {
      acc[current.fieldName] = current.value || "";
      return acc;
    }, {});
  };

  useEffect(() => {
    //set payment method on load
    const newMethodId = methods?.find((method) => method?.primary)?.cardId;
    if (newMethodId) {
      setMethodID(newMethodId);
    }
    //add charge to list of charges to be paid.
    setCharges([...charges, String(charge?.id)]);
  }, []);

  const getPaymentAmount = (id: string) => {
    const payment = paymentsHistory?.filter(
      (paymentItem: MemberPaymentHistory) => paymentItem.id === id
    );
    if (payment && payment[0]?.amount) {
      return `Add ${payment[0].amount} dollars to payment total.`;
    } else {
      return "amount unavailable.";
    }
  };

  const filteredPaymentsList = paymentsHistory?.filter(
    (payment) => payment.id?.toString() !== charge?.id?.toString()
  );

  const checkboxState = filteredPaymentsList?.reduce((acc, payment) => {
    acc[payment.id] = checkedIds.includes(payment.id);
    return acc;
  }, {} as Record<string, boolean>);

  useEffect(() => {
    const additionalAmount = checkedIds.reduce((total, id) => {
      const payment = paymentsHistory?.find(
        (paymentItem: MemberPaymentHistory) => paymentItem.id === id
      );
      return total + (payment?.amount ? parseFloat(payment.amount) : 0);
    }, 0);
    setTotalPayment(parseFloat(paymentAmount) + additionalAmount);
  }, [checkedIds, paymentAmount]);

  const handleCheckboxChange = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedIds((prevIds) => [...prevIds, id]);
      setCharges([...charges, String(id)]);
    } else {
      setCheckedIds((prevIds) =>
        prevIds.filter((checkboxId) => checkboxId !== id)
      );
      setCharges((prevCharges) =>
        prevCharges.filter((checkboxId) => checkboxId !== String(id))
      );
    }
  };

  const getPaymentLabel = (id: string) => {
    const payment = paymentsHistory?.filter(
      (paymentItem: MemberPaymentHistory) => paymentItem.id === id
    )[0];
    const isWholeDollar = payment?.amount?.split(".")[1] === "00";
    let label = "";
    if (payment?.item) {
      //display $ amount without cents when possible.
      if (isWholeDollar) {
        label += "$" + payment.amount?.split(".")[0];
      } else {
        label += "$" + payment.amount;
      }
    }
    return [label, payment?.item];
  };
  //type in a new amt owed.
  const handleEditAmount = (e: any) => {
    setEditedAmount(e.target.value);
  };

  const handleSelectCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setMethodID(value);
  };

  const handleUpdateCopay = () => {
    if (!amountError) {
      setPaymentAmount(editedAmount);
      updateCopay(
        {
          updateCopaySchema: {
            patientId: memberId?.toString(),
            appointmentId: appointmentId?.toString(),
            amount: parseInt((parseFloat(editedAmount) * 100).toFixed(2), 10),
          },
        },
        {
          onSuccess: () => {
            dispatch(showSnackbar("The visit copay amount has been updated."));
          },
          onError: () => {
            dispatch(
              showSnackbar(
                "There was an error updating the visit copay amount.",
                "danger"
              )
            );
          },
        }
      );
      setIsEditingMode(false);
    }
  };

  const handleCancelEdit = () => {
    setAmountError("");
    setEditedAmount(paymentAmount);
    setIsEditingMode(false);
  };

  const handleBlur = (e: any) => {
    setEditedAmount(parseFloat(e.target.value).toFixed(2));

    setAmountError("");
    const fieldsToValidate = getRequiredFieldsForUser();
    const validatedFields = ValidateFields(fieldsToValidate);
    if (!validatedFields.editedAmount) {
      setAmountError(generateErrorMessage("editedAmount"));
    }
  };

  const handlePay = async () => {
    let res;
    try {
      if (methodID) {
        res = await payNow(charges, {
          cardId: methodID,
          isDailySchedule: true,
        });
      } else {
        const hasFieldError = !!getErrors();
        const data = await addMethod(getValues());
        if (data && data.token && !hasFieldError) {
          res = await payNow(charges, {
            cardToken: data.token.id,
            cardLabel: nickName.state,
            primary: isPrimary.state || !methods?.length,
            isHsaOrFsa: isFsaHsa.state,
            isDailySchedule: true,
          });
        }
      }
    } catch {
      return;
    }
    if (res) {
      dispatch(
        showSnackbar(
          `The payment for ${memberSummary.memberName} was successfully processed.`
        )
      );
      onClose();
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      data-e2e="process-payment-modal"
      ariaLabelledBy="alert-dialog-title"
      maxWidth={false} // Disable default maxWidth breakpoints
      fullWidth={false} // Ensure fullWidth doesn't override your custom width
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "493px", // Set your desired width
          width: "100%", // Ensure it scales properly
        },
      }}
      PaperProps={{ style: { overflowY: "hidden" } }}
    >
      <div
        className={isPayNowLoading ? classes.dimDialogBody : classes.dialogBody}
      >
        <DialogTitle
          className={classes.titleSticky}
          onClose={onClose}
          id="alert-dialog-title"
        >
          <Typography variant="inherit"> Process payment </Typography>
        </DialogTitle>
        <DialogContent
          className={
            isPayNowLoading ? classes.dimDialogContent : classes.dialogContent
          }
        >
          <Box className={classes.stickySection}>
            {totalPayment >= 0 && (
              <Box className={classes.totalDue}>
                <Typography className={classes.totalDueTitle}>
                  Total due
                </Typography>
                <Typography className={classes.totalDueAmount}>
                  ${totalPayment.toFixed(2)}
                </Typography>
              </Box>
            )}
            <MemberPaymentDetails
              memberSummary={memberSummary}
              providerName={providerName}
            />
          </Box>
          <Box className={classes.paymentItemsSection}>
            <Typography
              className={classes.secondaryText}
              appearance="smallBody"
            >
              Visit copay
            </Typography>
            <TextField
              name="visit-copay"
              id="visit-copay"
              type="number"
              className={classes.editCopayAmount}
              InputProps={{
                readOnly: !isEditingMode,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              value={editedAmount}
              error={!!amountError}
              helperText={amountError}
              onChange={handleEditAmount}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onBlur={handleBlur}
            />
            {!isEditingMode && (
              <ButtonStyledAsLink
                className={classes.editAmount}
                onClick={() => setIsEditingMode(!isEditingMode)}
              >
                Edit amount
              </ButtonStyledAsLink>
            )}
            {isEditingMode && (
              <>
                <ButtonStyledAsLink
                  noPadding
                  onClick={handleUpdateCopay}
                  className={classes.editAmountButtons}
                >
                  Save
                </ButtonStyledAsLink>
                <ButtonStyledAsLink
                  noPadding
                  onClick={() => handleCancelEdit()}
                  className={classes.editAmountButtons}
                >
                  Cancel
                </ButtonStyledAsLink>
              </>
            )}
          </Box>
          <Box>
            {(unpaidItemsCount || 0) > 0 && (
              <>
                <Typography
                  className={classes.paymentsOwed}
                  appearance="smallBody"
                >
                  Add payments owed
                </Typography>
                <FormGroup>
                  {Object.keys(checkboxState)
                    .slice(0, showAll ? Object.keys(checkboxState).length : 3)
                    .map((checkbox, i) => (
                      <Box
                        display="flex"
                        key={`unpaid-item-checkbox-${i}`}
                        alignItems="center"
                        className={classes.itemsLabel}
                      >
                        <>
                          <Checkbox
                            id={checkbox}
                            onChange={(e) =>
                              handleCheckboxChange(checkbox, e.target.checked)
                            }
                            inputProps={{
                              "aria-label": getPaymentAmount(checkbox),
                            }}
                            name={getPaymentLabel(checkbox)[1]}
                            checked={checkboxState[checkbox]}
                            className={classes.itemsCheckbox}
                          />
                          <span
                            style={{ marginRight: "5px", fontWeight: "bold" }}
                          >
                            {getPaymentLabel(checkbox)[0]}
                          </span>
                          <Typography
                            sx={{ cursor: "default", userSelect: "none" }}
                          >
                            {getPaymentLabel(checkbox)[1]}
                          </Typography>
                        </>
                      </Box>
                    ))}
                </FormGroup>
                {Object.keys(checkboxState).length > 3 && (
                  <ButtonStyledAsLink
                    role="button"
                    onClick={() => setShowAll(!showAll)}
                    className={classes.showAll}
                  >
                    {showAll ? "Show Less" : "Show More"}
                  </ButtonStyledAsLink>
                )}
              </>
            )}
          </Box>
          <Box>
            <TextField
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setNote(e.target.value);
              }}
              className={classes.addNotes}
              maxChars={60}
              displayMaxLengthTypography={false}
              inputProps={{
                style: {
                  backgroundColor: "#fff",
                  height: "75px",
                  borderRadius: "10px",
                  paddingLeft: "5px",
                  paddingTop: "10px",
                },
              }}
              placeholder="Notes - optional"
              fullWidth
              multiline
              value={note}
            >
              {note}
            </TextField>
            <Typography
              className={classes.charLimit}
              appearance="caption"
              align="right"
            >
              {`Character limit ${note.length}/60`}
            </Typography>
          </Box>

          <Box className={classes.paymentMethod}>
            {/*
            if exsting method, delete "set up a payment method"
            if not, no radio buttons and methodID === newPaymentID

            */}
            {methods && methods.length ? (
              <RadioGroup
                aria-label="Select payment"
                className={classes.radioGroup}
                id="card_payment"
                value={methodID}
                onChange={handleSelectCard}
              >
                {methods?.map((method: any) => (
                  <Box key={`payment-method-${method.id}`}>
                    <FormControlLabel
                      control={<Radio />}
                      value={method.cardId}
                      checked={methodID === method.cardId}
                      label={`Pay with ${
                        capitalize(method.cardBrand) || "card"
                      } ending in ${method.last4}`}
                    />
                    {method?.primary && (
                      <Badge className={classes.badge}>
                        <Typography appearance="miniCaption">
                          DEFAULT
                        </Typography>
                      </Badge>
                    )}
                  </Box>
                ))}
                <FormControlLabel
                  checked={methodID === newPaymentID}
                  value={newPaymentID}
                  control={<Radio />}
                  label="Pay with new credit card"
                />
              </RadioGroup>
            ) : (
              <Typography className={classes.addFirstMethod}>
                Add credit card
              </Typography>
            )}
          </Box>

          <Box className={!!errorMessage ? classes.alertWrapper : ""}>
            {!!errorMessage && (
              <Alert severity="error" className={classes.alert}>
                <div className={classes.alertMessage}>
                  <Typography
                    appearance="smallBody"
                    color="textPrimary"
                    className={classes.warningText}
                  >
                    <strong>There was a problem</strong>
                    <span className={classes.errorMessage}>{errorMessage}</span>
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => clearError()}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Alert>
            )}
          </Box>

          {methodID === newPaymentID && (
            <Box>
              <MethodFields
                country={country}
                zip={zip}
                nickName={nickName}
                isPrimary={isPrimary}
                isFsaHsa={isFsaHsa}
                elementErrors={elementErrors}
                showPrimaryButton
                handleElementErrors={handleElementErrors}
                isPaymentModal
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions horizontal className={classes.dialogActions}>
          <Box display="flex">
            <Button
              color="primary"
              className={
                disableButton || isPayNowLoading
                  ? classes.buttonDisabled
                  : classes.buttonEnabled
              }
              disabled={disableButton || isPayNowLoading}
              onClick={handlePay}
            >
              Pay now
            </Button>
            <Link
              aria-label="cancel and close dialog"
              className={classes.cancelButton}
              onClick={onClose}
            >
              Cancel
            </Link>
          </Box>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProcessPaymentModal;
