import React from "react";
import classes from "./index.module.css";
import ButtonBase from "components/Button/ButtonBase";
import GroupIcon from "@mui/icons-material/Group";
import Typography from "components/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IProps {
  isSectionExpanded: boolean;
  setIsSectionExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NumberOfMembersTitleWithCollapseExpandButton({
  isSectionExpanded,
  setIsSectionExpanded,
}: IProps) {
  return (
    <div className={classes.numberOfMembersHeading}>
      <Typography className={classes.sectionTitle}>
        <GroupIcon
          style={{ marginBottom: "-5px" }}
          className={classes.drawerIcons}
        />
        Number of Members
      </Typography>
      <ButtonBase
        onClick={() => setIsSectionExpanded(!isSectionExpanded)}
        aria-label={`${
          isSectionExpanded ? "Collapse" : "Expand"
        } number of members section`}
      >
        <>
          {isSectionExpanded ? (
            <KeyboardArrowUpIcon
              fontSize="medium"
              data-testid="number-of-members-collapse-arrow"
            />
          ) : (
            <KeyboardArrowDownIcon
              fontSize="medium"
              data-testid="number-of-members-expand-arrow"
            />
          )}
        </>
      </ButtonBase>
    </div>
  );
}
