import { useState } from "react";
import { useQueryClient } from "react-query";
import { usePostPayNow } from "shared/features/payments/paymentsHistory";
import { getDailyScheduleQueryKey } from "shared/features/dailySchedule/useGetDailySchedule";
import { GetDailyScheduleRequest } from "shared/fetch/src/apis";

const usePayAPI = (
  userId: string,
  getDailyScheduleRequest?: GetDailyScheduleRequest
) => {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState("");
  const { postPayNow, isPayNowLoading } = usePostPayNow(userId);

  const clearError = () => setErrorMessage("");

  const payNow = async (
    chargeIds: string[],
    options: {
      cardToken?: string;
      cardId?: string;
      primary?: boolean;
      cardLabel?: string;
      isHsaOrFsa?: boolean;
      isDailySchedule?: boolean;
    }
  ) => {
    await postPayNow(
      {
        id: userId,
        payNowSchema: { chargeIds, ...options },
      },
      {
        onSuccess: () => {
          setErrorMessage("");
          if (getDailyScheduleRequest) {
            queryClient.invalidateQueries(
              getDailyScheduleQueryKey(getDailyScheduleRequest)
            );
          }
        },
        onError: async (error: any) => {
          const message = await error?.json();
          setErrorMessage(
            message?.error ||
              "Unfortunately, we were unable to process your payment at this time. Please contact your card issuer for additional information."
          );
        },
      }
    );
    return errorMessage ? null : "successful";
  };

  return {
    isPayNowLoading,
    errorMessage,
    payNow,
    clearError,
  };
};

export default usePayAPI;
