// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GreenPlusPatientRequestBody
 */
export interface GreenPlusPatientRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof GreenPlusPatientRequestBody
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GreenPlusPatientRequestBody
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof GreenPlusPatientRequestBody
     */
    homePhone?: string;
    /**
     * id of the user record in the eligibility table
     * @type {string}
     * @memberof GreenPlusPatientRequestBody
     */
    baseId: string;
}

export function GreenPlusPatientRequestBodyFromJSON(json: any): GreenPlusPatientRequestBody {
    return {
        'email': !exists(json, 'email') ? undefined : json['email'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'baseId': json['base_id'],
    };
}

export function GreenPlusPatientRequestBodyToJSON(value?: GreenPlusPatientRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'cell_phone': value.cellPhone,
        'home_phone': value.homePhone,
        'base_id': value.baseId,
    };
}


