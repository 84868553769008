import { useMemo } from "react";
import useGetCalendarStaticData from "./useGetCalendarStaticData";
import { dayOfWeekAbbreviations } from "components/XOCal/utils";
import { CalendarStaticDataResponseClinicDetailsOperatingHours } from "shared/fetch/src/models/CalendarStaticDataResponseClinicDetailsOperatingHours";

interface BusinessHours {
  daysOfWeek: number[];
  startTime: string;
  endTime: string;
}
interface ClinicHours {
  operatingDays: string[];
  businessHours: BusinessHours[];
  operatingDaysNumerical: number[];
  expandedTimes: {
    expandedStart: string;
    expandedEnd: string;
  };
  clinicVisibleStartTimeString: string;
  clinicVisibleEndTimeString: string;
}

function useClinicHours(
  clinicId: string | null,
): ClinicHours {
  const { data } = useGetCalendarStaticData({ clinicId: clinicId! });
  const operatingHours:
    | CalendarStaticDataResponseClinicDetailsOperatingHours
    | undefined = data?.clinicDetails?.operatingHours;

  const { operatingDays, businessHours } = useMemo(() => {
    const days: string[] = [];
    const hours: any[] = [];

    for (const abbrev of dayOfWeekAbbreviations) {
      if (
        operatingHours &&
        (operatingHours[abbrev as keyof typeof operatingHours] ||
          operatingHours[abbrev.toLowerCase() as keyof typeof operatingHours])
      ) {
        days.push(abbrev);
        const dayHours =
          operatingHours[
          abbrev as keyof CalendarStaticDataResponseClinicDetailsOperatingHours
          ] ||
          operatingHours[
          abbrev.toLowerCase() as keyof CalendarStaticDataResponseClinicDetailsOperatingHours
          ];
        if (dayHours?.length === 2) {
          hours.push({
            daysOfWeek: [dayOfWeekAbbreviations.indexOf(abbrev)],
            startTime: dayHours[0],
            endTime: dayHours[1],
          });
        }
      }
    }

    return { operatingDays: days, businessHours: hours };
  }, [operatingHours]);

  // Convert operating days to numerical values
  const operatingDaysNumerical = useMemo(
    () => operatingDays.map((abbrev) => dayOfWeekAbbreviations.indexOf(abbrev)),
    [operatingDays]
  );

  // Calculate visible time strings
  const { clinicVisibleStartTimeString, clinicVisibleEndTimeString } =
    useMemo(() => {
      if (!businessHours?.length) {
        return {
          clinicVisibleStartTimeString: "00:00",
          clinicVisibleEndTimeString: "23:59",
        };
      }

      const { earliestStart, latestEnd } = businessHours.reduce(
        (acc, hours) => ({
          earliestStart:
            hours.startTime < acc.earliestStart
              ? hours.startTime
              : acc.earliestStart,
          latestEnd:
            hours.endTime > acc.latestEnd ? hours.endTime : acc.latestEnd,
        }),
        { earliestStart: "23:59", latestEnd: "00:00" }
      );

      return {
        clinicVisibleStartTimeString: earliestStart,
        clinicVisibleEndTimeString: latestEnd,
      };
    }, [businessHours]);

  // Calculate expanded times for calendar display
  const expandedTimes = useMemo(() => {
    let expandedStart = clinicVisibleStartTimeString;
    let expandedEnd = clinicVisibleEndTimeString;

    const [startHour] = clinicVisibleStartTimeString.split(":");
    if (Number(startHour) >= 2) {
      expandedStart = `${String(Number(startHour) - 1).padStart(2, "0")}:00`;
    }

    const [endHour] = clinicVisibleEndTimeString.split(":");
    if (Number(endHour) <= 21) {
      expandedEnd = `${String(Number(endHour) + 1).padStart(2, "0")}:00`;
    }

    return { expandedStart, expandedEnd };
  }, [clinicVisibleStartTimeString, clinicVisibleEndTimeString]);

  return {
    operatingDays,
    businessHours,
    operatingDaysNumerical,
    expandedTimes,
    clinicVisibleStartTimeString,
    clinicVisibleEndTimeString,
  };
}

export default useClinicHours;
