import React from "react";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Tooltip } from "@mui/material";
import styles from "components/XOCal/EventContent.module.css";
import { composeTooltipSlotProps } from "../utils";

interface IProps {
  appointments: AppointmentOutput[];
}

export default function VisitNotesIcon({ appointments }: IProps) {
  const getNotesToDisplay = () => {
    const notes =
      appointments &&
      appointments?.map((appointment) => appointment.appointmentNotes);

    const noteMessages: string[] = [];
    notes?.forEach((note) =>
      note?.forEach((indNote) => {
        if (indNote.noteType !== "scheduled" && indNote.note) {
          noteMessages.push(indNote.note);
        }
      })
    );
    return noteMessages;
  };
  const allMessages = getNotesToDisplay();
  const shouldRender = Boolean(allMessages.length);
  if (!shouldRender) {
    return null;
  }

  return (
    <Tooltip
      title={<NotesTooltipContent appointments={appointments} />}
      slotProps={composeTooltipSlotProps({ offset: [20, -10] })}
    >
      <div>
        <StickyNote2Icon
          style={{ fontSize: "18px" }}
          className={styles.visibilityIcon}
        />
      </div>
    </Tooltip>
  );
}

function NotesTooltipContent({ appointments }: IProps) {
  const getNotesToDisplay = () => {
    const notes =
      appointments &&
      appointments?.map((appointment) => appointment.appointmentNotes);

    const noteMessages: string[] = [];
    notes?.forEach((note) =>
      note?.forEach((indNote) => {
        if (indNote.noteType !== "scheduled" && indNote.note) {
          noteMessages.push(indNote.note);
        }
      })
    );
    return noteMessages;
  };

  const allMessages = getNotesToDisplay();
  if (!Boolean(allMessages.length)) {
    return null;
  }

  return (
    <div style={{ maxWidth: "400px" }}>
      <div>Appointment Notes:</div>
      <ul style={{ paddingTop: "3px", paddingLeft: "25px" }}>
        {allMessages?.map((message, index) => {
          return <li key={index}>{message}</li>;
        })}
      </ul>
    </div>
  );
}
