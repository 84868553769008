import React, { useState } from "react";
import usePostPayLater from "shared/features/dailySchedule/usePostPayLater";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import OptionsDropdownButton from "./OptionsDropdownButton";
import { DailyScheduleResponseAppointments } from "shared/fetch/src/models/DailyScheduleResponseAppointments";
import { DailyScheduleResponseList } from "shared/fetch/src/models/DailyScheduleResponseList";
import palette from "styles/mui/palette";
import { SxProps, Theme } from "@mui/material";
import PayLaterModal from "features/dailySchedule/components/PayLaterModal";
import { showSnackbar } from "shared/state/ui/snackbar";
import { useDispatch } from "react-redux";

interface IProps {
  appointment: DailyScheduleResponseAppointments;
  providerNameLabel: DailyScheduleResponseList["name"];
  site: string;
  date: string;
  memberSummary: any;
}

export interface MoreOptions {
  label: string;
  clickHandler: React.MouseEventHandler<HTMLElement> | undefined;
  isRendered: (mainAction: MainActionLabel) => boolean;
  isDisabled?: boolean;
}

type MainActionLabel = "Check in" | "Review" | "Finalize" | "1-Click" | "";

export default function ActionsButtonGroup({
  appointment,
  providerNameLabel,
  site,
  date,
  memberSummary,
}: IProps) {
  const {
    actionStatus,
    xop: isXopAppointment,
    legalAndPreferredName,
    patientId,
  } = appointment;

  const [isOpen, setIsOpen] = useState(false);
  const { generateCharge } = usePostPayLater(
    patientId?.toString() || "",
    site,
    date
  );
  const [isActionsExpanded, setIsActionsExpanded] = useState<boolean>(false);
  const showPayLater =
    appointment.copayCharge?.status === "set" ||
    appointment.copayCharge?.status === "set_by_ctm";

  const dispatch = useDispatch();

  const buttons: any = {
    "Check in": {
      label: "Check in",
      handleMainButtonClick: () => true,
      background: palette.blue,
      textAndBorderColor: palette.white,
    },
    Finalize: {
      label: "Finalize",
      handleMainButtonClick: () => true,
      background: palette.blue,
      textAndBorderColor: palette.white,
    },
    Review: {
      label: "Review",
      handleMainButtonClick: () => true,
      background: palette.white,
      textAndBorderColor: palette.black,
    },
    "1-Click": {
      label: "1-Click",
      handleMainButtonClick: () => true, // handler should send a POST /v1/checkin (with one_click = true in the payload)
      background: palette.appBackground.lightBlue,
      textAndBorderColor: palette.blue,
    },
  };

  const shouldPayLaterRender = (_mainAction: MainActionLabel) => {
    return showPayLater;
  };
  const shouldCancelVisitRender = (mainAction: MainActionLabel) => {
    return Boolean(mainAction === "Check in" || mainAction === "1-Click");
  };
  const shouldLateCancelRender = (mainAction: MainActionLabel) => {
    return Boolean(mainAction === "Check in" || mainAction === "1-Click");
  };
  const shouldNoShowRender = (mainAction: MainActionLabel) => {
    return Boolean(mainAction === "Check in" || mainAction === "1-Click");
  };
  const shouldFastTrackRender = (mainAction: MainActionLabel) => {
    return Boolean(mainAction === "Check in" || mainAction === "1-Click");
  };
  const shouldRevokeCheckInRender = (mainAction: MainActionLabel) => {
    return Boolean(mainAction === "Review" || mainAction === "Finalize");
  };

  const options: MoreOptions[] = [
    {
      label: "Pay later",
      clickHandler: () => onDialogOpen(),
      isRendered: shouldPayLaterRender,
      isDisabled: appointment?.copayCharge?.amount === 0,
    },
    {
      label: "Cancel visit",
      clickHandler: () => true,
      isRendered: shouldCancelVisitRender,
    },
    {
      label: "Late cancel visit",
      clickHandler: () => true,
      isRendered: shouldLateCancelRender,
    },
    {
      label: "No show",
      clickHandler: () => true,
      isRendered: shouldNoShowRender,
    },
    {
      label: "Fast track check in",
      clickHandler: () => true,
      isRendered: shouldFastTrackRender,
    },
    {
      label: "Revoke checkin",
      clickHandler: () => true,
      isRendered: shouldRevokeCheckInRender,
    },
  ];

  const getMainActionLabel = (status: string): MainActionLabel => {
    switch (status) {
      case "checkin":
        return "Check in";
      case "passed":
        return "Check in";
      case "forced_checkin":
        return "Finalize";
      case "review":
        return "Review";
      case "click":
        return "1-Click";
      case "screen":
        return "1-Click";
      default:
        return "";
    }
  };

  const mainActionLabel = getMainActionLabel(actionStatus!);
  const buttonInfo = buttons[mainActionLabel];
  const { textAndBorderColor, background, handleMainButtonClick } = buttonInfo;

  const sxProps: SxProps<Theme> = {
    borderRadius: "8px",
    color: textAndBorderColor,
    backgroundColor: background,
    "&.MuiButtonGroup-lastButton": {
      minWidth: "25%",
    },
    "&.MuiButtonGroup-firstButton": {
      borderRight: `1px solid ${palette.white}`,
    },
  };

  const handlePayLater = () => {
    generateCharge(
      {
        patientId: patientId?.toString() || "",
        appointmentId: appointment?.id?.toString() || "",
        status: "unpaid",
        amount: appointment?.copayCharge?.amount,
      },
      {
        onSuccess: () => {
          dispatch(
            showSnackbar(
              `Success! The payment item for ${memberSummary.memberName} has been sent to the unpaid list.`
            )
          );
        },
        onError: () => {
          dispatch(
            showSnackbar(
              "There was an error sending the payment item to the unpaid list.",
              "danger"
            )
          );
        },
      }
    );
    setIsActionsExpanded(false);
    onDialogClose();
  };

  const onDialogOpen = () => {
    setIsActionsExpanded(false);
    setIsOpen(true);
  };
  const onDialogClose = () => {
    setIsOpen(false);
  };

  const filteredOptions = options.filter((option) =>
    option.isRendered(mainActionLabel)
  );
  const appointmentDetailsLabel = `${legalAndPreferredName} with ${providerNameLabel}`;

  if (!Boolean(isXopAppointment)) {
    return null;
  }

  return (
    <ButtonGroup
      variant="contained"
      disableElevation
      size="small"
      sx={{ ...sxProps, height: "32px", maxWidth: "106px" }}
      aria-label={`Actions for visit scheduled for ${appointmentDetailsLabel}`}
    >
      <Button
        sx={{
          ...sxProps,
          whiteSpace: "nowrap",
          overflow: "hidden",
          "&:hover": {
            backgroundColor: palette.calendar.selectedBackground,
            color: palette.white,
          },
        }}
        onClick={handleMainButtonClick}
        aria-label={`${mainActionLabel} visit for ${appointmentDetailsLabel}`}
      >
        {mainActionLabel}
      </Button>
      <OptionsDropdownButton
        appointment={appointment}
        isActionsExpanded={isActionsExpanded}
        setIsActionsExpanded={setIsActionsExpanded}
        sxProps={sxProps}
        options={filteredOptions}
        appointmentDetailsLabel={appointmentDetailsLabel}
      />
      {isOpen && (
        <PayLaterModal
          isOpen={isOpen}
          onClose={onDialogClose}
          handlePayLater={handlePayLater}
          memberSummary={memberSummary}
          providerName={providerNameLabel}
        />
      )}
    </ButtonGroup>
  );
}
