import React from "react";
import { Tooltip } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import styles from "components/XOCal/EventContent.module.css";
import { composeTooltipSlotProps } from "../utils";

export default function ConflictsIcon({
  isConflicted,
}: {
  isConflicted: boolean;
}) {
  if (!isConflicted) {
    return null;
  }
  return (
    <Tooltip
      title={"In Conflict queue"}
      slotProps={composeTooltipSlotProps({ offset: [50, -10] })}
    >
      <div>
        <WarningIcon className={styles.visibilityIconError} />
      </div>
    </Tooltip>
  );
}
