// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AvailableTimeslotsResponse,
    AvailableTimeslotsResponseFromJSON,
    AvailableTimeslotsResponseToJSON,
} from '../models/AvailableTimeslotsResponse'
import {
    CapacityFetchResponse,
    CapacityFetchResponseFromJSON,
    CapacityFetchResponseToJSON,
} from '../models/CapacityFetchResponse'
import {
    CreateSlotCore,
    CreateSlotCoreFromJSON,
    CreateSlotCoreToJSON,
} from '../models/CreateSlotCore'
import {
    DeleteDateRangeSlots,
    DeleteDateRangeSlotsFromJSON,
    DeleteDateRangeSlotsToJSON,
} from '../models/DeleteDateRangeSlots'
import {
    DeleteSlotSeriesRequestBody,
    DeleteSlotSeriesRequestBodyFromJSON,
    DeleteSlotSeriesRequestBodyToJSON,
} from '../models/DeleteSlotSeriesRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    GetSlotsForDateOutput,
    GetSlotsForDateOutputFromJSON,
    GetSlotsForDateOutputToJSON,
} from '../models/GetSlotsForDateOutput'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NextAvailableSlotsFetchResponse,
    NextAvailableSlotsFetchResponseFromJSON,
    NextAvailableSlotsFetchResponseToJSON,
} from '../models/NextAvailableSlotsFetchResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from '../models/SlotOutput'
import {
    TimeSlotsFetchResponse,
    TimeSlotsFetchResponseFromJSON,
    TimeSlotsFetchResponseToJSON,
} from '../models/TimeSlotsFetchResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface CreateSlotRequest {
    createSlotCore?: CreateSlotCore;
}

export interface DeleteSlotRequest {
    id: string;
}

export interface DeleteSlotDateRangeRequest {
    deleteSlotSeriesRequestBody?: DeleteSlotSeriesRequestBody;
}

export interface GetAvailableTimeslotsRequest {
    id: string;
    startDate: string;
    itemCount: string;
    timezone: string;
    usState: string;
    appointmentType: string;
    schedulingType: GetAvailableTimeslotsSchedulingTypeEnum;
    clinicId?: string;
    staffGlobalId?: string;
    serviceLineId?: string;
}

export interface GetBookableSlotsRequest {
    id: string;
    startDate: string;
    appointmentType: string;
    page?: string;
    endDate?: string;
    staffGlobalId?: string;
    timezone?: string;
    clinicId?: string;
    schedulingType?: GetBookableSlotsSchedulingTypeEnum;
}

export interface GetCapacityRequest {
    id: string;
    startDate: string;
    daysCount: string;
    itemCount: string;
    timezone: string;
    appointmentType: string;
    schedulingType: GetCapacitySchedulingTypeEnum;
    usState: string;
    page?: string;
    clinicId?: string;
    staffGlobalId?: string;
    serviceLineId?: string;
}

export interface GetNextAvailableSlotsRequest {
    id: string;
    appointmentType: string;
    schedulingType: GetNextAvailableSlotsSchedulingTypeEnum;
    usState: string;
    timezone?: string;
    clinicId?: string;
    serviceLineId?: string;
}

export interface GetSlotByIDRequest {
    id: string;
}

export interface GetSlotsForDateRangeRequest {
    clinicId?: string;
    appointmentType?: string;
    providerId?: string;
    providerType?: string;
    conflicted?: GetSlotsForDateRangeConflictedEnum;
    start?: string;
    end?: string;
    flatten?: GetSlotsForDateRangeFlattenEnum;
}

export interface UpdateSlotRequest {
    id: string;
    createSlotCore?: CreateSlotCore;
}


/**
 * Create a slot
 * Create a slot
 */
function createSlotRaw<T>(requestParameters: CreateSlotRequest, requestConfig: runtime.TypedQueryConfig<T, SlotOutput> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/slots`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CreateSlotCoreToJSON(requestParameters.createSlotCore),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SlotOutputFromJSON(body), text);
    }

    return config;
}

/**
* Create a slot
* Create a slot
*/
export function createSlot<T>(requestParameters: CreateSlotRequest, requestConfig?: runtime.TypedQueryConfig<T, SlotOutput>): QueryConfig<T> {
    return createSlotRaw(requestParameters, requestConfig);
}

/**
 * Delete a slot
 * Delete a slot
 */
function deleteSlotRaw<T>(requestParameters: DeleteSlotRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSlot.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/slots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a slot
* Delete a slot
*/
export function deleteSlot<T>(requestParameters: DeleteSlotRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteSlotRaw(requestParameters, requestConfig);
}

/**
 * Delete a range of slots
 * Delete a range of slots
 */
function deleteSlotDateRangeRaw<T>(requestParameters: DeleteSlotDateRangeRequest, requestConfig: runtime.TypedQueryConfig<T, DeleteDateRangeSlots> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm", "provider_roles.admin"]];
    const config: QueryConfig<T> = {
        url: `/v1/slots/delete_date_range`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DeleteSlotSeriesRequestBodyToJSON(requestParameters.deleteSlotSeriesRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DeleteDateRangeSlotsFromJSON(body), text);
    }

    return config;
}

/**
* Delete a range of slots
* Delete a range of slots
*/
export function deleteSlotDateRange<T>(requestParameters: DeleteSlotDateRangeRequest, requestConfig?: runtime.TypedQueryConfig<T, DeleteDateRangeSlots>): QueryConfig<T> {
    return deleteSlotDateRangeRaw(requestParameters, requestConfig);
}

/**
 * Returns all non booked timeslots for given appt details
 * Fetch available timeslots
 */
function getAvailableTimeslotsRaw<T>(requestParameters: GetAvailableTimeslotsRequest, requestConfig: runtime.TypedQueryConfig<T, AvailableTimeslotsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAvailableTimeslots.');
    }

    if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
        throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getAvailableTimeslots.');
    }

    if (requestParameters.itemCount === null || requestParameters.itemCount === undefined) {
        throw new runtime.RequiredError('itemCount','Required parameter requestParameters.itemCount was null or undefined when calling getAvailableTimeslots.');
    }

    if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
        throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling getAvailableTimeslots.');
    }

    if (requestParameters.usState === null || requestParameters.usState === undefined) {
        throw new runtime.RequiredError('usState','Required parameter requestParameters.usState was null or undefined when calling getAvailableTimeslots.');
    }

    if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
        throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getAvailableTimeslots.');
    }

    if (requestParameters.schedulingType === null || requestParameters.schedulingType === undefined) {
        throw new runtime.RequiredError('schedulingType','Required parameter requestParameters.schedulingType was null or undefined when calling getAvailableTimeslots.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = requestParameters.startDate;
    }


    if (requestParameters.itemCount !== undefined) {
        queryParameters['item_count'] = requestParameters.itemCount;
    }


    if (requestParameters.timezone !== undefined) {
        queryParameters['timezone'] = requestParameters.timezone;
    }


    if (requestParameters.usState !== undefined) {
        queryParameters['us_state'] = requestParameters.usState;
    }


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }


    if (requestParameters.staffGlobalId !== undefined) {
        queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
    }


    if (requestParameters.appointmentType !== undefined) {
        queryParameters['appointment_type'] = requestParameters.appointmentType;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }


    if (requestParameters.serviceLineId !== undefined) {
        queryParameters['service_line_id'] = requestParameters.serviceLineId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/available_timeslots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AvailableTimeslotsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Returns all non booked timeslots for given appt details
* Fetch available timeslots
*/
export function getAvailableTimeslots<T>(requestParameters: GetAvailableTimeslotsRequest, requestConfig?: runtime.TypedQueryConfig<T, AvailableTimeslotsResponse>): QueryConfig<T> {
    return getAvailableTimeslotsRaw(requestParameters, requestConfig);
}

/**
 * Fetch all bookable slots
 * Fetch all bookable slots
 */
function getBookableSlotsRaw<T>(requestParameters: GetBookableSlotsRequest, requestConfig: runtime.TypedQueryConfig<T, TimeSlotsFetchResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookableSlots.');
    }

    if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
        throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBookableSlots.');
    }

    if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
        throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getBookableSlots.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = requestParameters.startDate;
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = requestParameters.endDate;
    }


    if (requestParameters.staffGlobalId !== undefined) {
        queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
    }


    if (requestParameters.timezone !== undefined) {
        queryParameters['timezone'] = requestParameters.timezone;
    }


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }


    if (requestParameters.appointmentType !== undefined) {
        queryParameters['appointment_type'] = requestParameters.appointmentType;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/timeslots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimeSlotsFetchResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch all bookable slots
* Fetch all bookable slots
*/
export function getBookableSlots<T>(requestParameters: GetBookableSlotsRequest, requestConfig?: runtime.TypedQueryConfig<T, TimeSlotsFetchResponse>): QueryConfig<T> {
    return getBookableSlotsRaw(requestParameters, requestConfig);
}

/**
 * Fetch the number of slots per day for given appointment details
 * Fetch capacity
 */
function getCapacityRaw<T>(requestParameters: GetCapacityRequest, requestConfig: runtime.TypedQueryConfig<T, CapacityFetchResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCapacity.');
    }

    if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
        throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getCapacity.');
    }

    if (requestParameters.daysCount === null || requestParameters.daysCount === undefined) {
        throw new runtime.RequiredError('daysCount','Required parameter requestParameters.daysCount was null or undefined when calling getCapacity.');
    }

    if (requestParameters.itemCount === null || requestParameters.itemCount === undefined) {
        throw new runtime.RequiredError('itemCount','Required parameter requestParameters.itemCount was null or undefined when calling getCapacity.');
    }

    if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
        throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling getCapacity.');
    }

    if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
        throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getCapacity.');
    }

    if (requestParameters.schedulingType === null || requestParameters.schedulingType === undefined) {
        throw new runtime.RequiredError('schedulingType','Required parameter requestParameters.schedulingType was null or undefined when calling getCapacity.');
    }

    if (requestParameters.usState === null || requestParameters.usState === undefined) {
        throw new runtime.RequiredError('usState','Required parameter requestParameters.usState was null or undefined when calling getCapacity.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = requestParameters.startDate;
    }


    if (requestParameters.daysCount !== undefined) {
        queryParameters['days_count'] = requestParameters.daysCount;
    }


    if (requestParameters.itemCount !== undefined) {
        queryParameters['item_count'] = requestParameters.itemCount;
    }


    if (requestParameters.timezone !== undefined) {
        queryParameters['timezone'] = requestParameters.timezone;
    }


    if (requestParameters.appointmentType !== undefined) {
        queryParameters['appointment_type'] = requestParameters.appointmentType;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }


    if (requestParameters.staffGlobalId !== undefined) {
        queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
    }


    if (requestParameters.usState !== undefined) {
        queryParameters['us_state'] = requestParameters.usState;
    }


    if (requestParameters.serviceLineId !== undefined) {
        queryParameters['service_line_id'] = requestParameters.serviceLineId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/capacity`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CapacityFetchResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch the number of slots per day for given appointment details
* Fetch capacity
*/
export function getCapacity<T>(requestParameters: GetCapacityRequest, requestConfig?: runtime.TypedQueryConfig<T, CapacityFetchResponse>): QueryConfig<T> {
    return getCapacityRaw(requestParameters, requestConfig);
}

/**
 * Get the next available slots for given appointment details
 */
function getNextAvailableSlotsRaw<T>(requestParameters: GetNextAvailableSlotsRequest, requestConfig: runtime.TypedQueryConfig<T, NextAvailableSlotsFetchResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNextAvailableSlots.');
    }

    if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
        throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getNextAvailableSlots.');
    }

    if (requestParameters.schedulingType === null || requestParameters.schedulingType === undefined) {
        throw new runtime.RequiredError('schedulingType','Required parameter requestParameters.schedulingType was null or undefined when calling getNextAvailableSlots.');
    }

    if (requestParameters.usState === null || requestParameters.usState === undefined) {
        throw new runtime.RequiredError('usState','Required parameter requestParameters.usState was null or undefined when calling getNextAvailableSlots.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.timezone !== undefined) {
        queryParameters['timezone'] = requestParameters.timezone;
    }


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }


    if (requestParameters.appointmentType !== undefined) {
        queryParameters['appointment_type'] = requestParameters.appointmentType;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }


    if (requestParameters.usState !== undefined) {
        queryParameters['us_state'] = requestParameters.usState;
    }


    if (requestParameters.serviceLineId !== undefined) {
        queryParameters['service_line_id'] = requestParameters.serviceLineId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/next_available_slots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(NextAvailableSlotsFetchResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get the next available slots for given appointment details
*/
export function getNextAvailableSlots<T>(requestParameters: GetNextAvailableSlotsRequest, requestConfig?: runtime.TypedQueryConfig<T, NextAvailableSlotsFetchResponse>): QueryConfig<T> {
    return getNextAvailableSlotsRaw(requestParameters, requestConfig);
}

/**
 * Get slot by ID
 * Get slot by ID
 */
function getSlotByIDRaw<T>(requestParameters: GetSlotByIDRequest, requestConfig: runtime.TypedQueryConfig<T, SlotOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSlotByID.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/slots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SlotOutputFromJSON(body), text);
    }

    return config;
}

/**
* Get slot by ID
* Get slot by ID
*/
export function getSlotByID<T>(requestParameters: GetSlotByIDRequest, requestConfig?: runtime.TypedQueryConfig<T, SlotOutput>): QueryConfig<T> {
    return getSlotByIDRaw(requestParameters, requestConfig);
}

/**
 * Get all slots for a given date range
 * Get all slots for a given date range
 */
function getSlotsForDateRangeRaw<T>(requestParameters: GetSlotsForDateRangeRequest, requestConfig: runtime.TypedQueryConfig<T, GetSlotsForDateOutput> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }


    if (requestParameters.appointmentType !== undefined) {
        queryParameters['appointment_type'] = requestParameters.appointmentType;
    }


    if (requestParameters.providerId !== undefined) {
        queryParameters['provider_id'] = requestParameters.providerId;
    }


    if (requestParameters.providerType !== undefined) {
        queryParameters['provider_type'] = requestParameters.providerType;
    }


    if (requestParameters.conflicted !== undefined) {
        queryParameters['conflicted'] = requestParameters.conflicted;
    }


    if (requestParameters.start !== undefined) {
        queryParameters['start'] = requestParameters.start;
    }


    if (requestParameters.end !== undefined) {
        queryParameters['end'] = requestParameters.end;
    }


    if (requestParameters.flatten !== undefined) {
        queryParameters['flatten'] = requestParameters.flatten;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/slots`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GetSlotsForDateOutputFromJSON(body), text);
    }

    return config;
}

/**
* Get all slots for a given date range
* Get all slots for a given date range
*/
export function getSlotsForDateRange<T>(requestParameters: GetSlotsForDateRangeRequest, requestConfig?: runtime.TypedQueryConfig<T, GetSlotsForDateOutput>): QueryConfig<T> {
    return getSlotsForDateRangeRaw(requestParameters, requestConfig);
}

/**
 * Update a slot
 * Update a slot
 */
function updateSlotRaw<T>(requestParameters: UpdateSlotRequest, requestConfig: runtime.TypedQueryConfig<T, CreateSlotCore> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSlot.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/slots/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || CreateSlotCoreToJSON(requestParameters.createSlotCore),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CreateSlotCoreFromJSON(body), text);
    }

    return config;
}

/**
* Update a slot
* Update a slot
*/
export function updateSlot<T>(requestParameters: UpdateSlotRequest, requestConfig?: runtime.TypedQueryConfig<T, CreateSlotCore>): QueryConfig<T> {
    return updateSlotRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetAvailableTimeslotsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetBookableSlotsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCapacitySchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetNextAvailableSlotsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
/**
    * @export
    * @enum {string}
    */
export enum GetSlotsForDateRangeConflictedEnum {
    True = 'true',
    False = 'false'
}
/**
    * @export
    * @enum {string}
    */
export enum GetSlotsForDateRangeFlattenEnum {
    True = 'true',
    False = 'false'
}
