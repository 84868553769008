// When doing XoCal work some of the "any" typing in the original useFormState
// hook was removed as well as other refactors. The changes seemed to be backwards
// compatible with the code in master, but during development of XoCal our CMS
// microservice was moved into virtual-app/web/src/features/cms the code CMS code
// relied heavily on the older version of useFormState and broke the build in over a hundred
// places. CMS calls useFormState many times, while XoCal calls it once. While not ideal,
// we've made our own version of useFormState for XoCal to be certain we do not introduce
// any problems into CMS or existing platform code.
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import toPath from "lodash/toPath";
import React from "react";

export const isObject = (obj: any): obj is Object =>
  obj !== null && typeof obj === "object";

export const isInteger = (obj: any): boolean =>
  String(Math.floor(Number(obj))) === obj;

export interface FormState<T extends object> {
  initialValues: T;
  enableReinitialize?: boolean;
  mode?: "onBlur" | "onChange";
  validateSchema?: ValidationSchema<T>;
}

export type ValidationSchema<T> = (
  values: T,
  allValues: T
) => Record<string, string>;

export function getIn(
  obj: any,
  key: string | string[],
  def?: any,
  p: number = 0
) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
}

export function setIn(obj: any, path: string, value: any): any {
  const res: any = clone(obj);
  let resVal: any = res;
  let i = 0;
  const pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath: string = pathArray[i];
    const currentObj: any = getIn(obj, pathArray.slice(0, i + 1));

    if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath: string = pathArray[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  // Return original object if new value is the same as current
  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }
  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }
  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
}

interface State<T extends object> {
  values: T;
  isSubmitting: boolean;
  isValidating: boolean;
  isValid: boolean;
  submitCount: number;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

type Action<T> =
  | { type: "SET_FIELD_VALUE"; payload: { field: string; value: any } }
  | {
      type: "SET_ERRORS";
      payload: { errors: Record<string, string>; isValid: boolean };
    }
  | {
      type: "RESET";
      payload: { values: T; errors: Record<string, string>; isValid: boolean };
    };

const reducer = <T extends object>(
  state: State<T>,
  action: Action<T>
): State<T> => {
  switch (action.type) {
    case "SET_FIELD_VALUE":
      return {
        ...state,
        values: setIn(state.values, action.payload.field, action.payload.value),
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload.errors,
        isValid: action.payload.isValid,
      };
    case "RESET":
      return {
        ...state,
        values: action.payload.values,
        errors: action.payload.errors,
        isValid: action.payload.isValid,
      };
    default:
      return state;
  }
};

// inspired by formik
export default function useFormState<T extends object>({
  initialValues,
  enableReinitialize = false,
  validateSchema,
  mode = "onChange",
}: FormState<T>) {
  const values = React.useRef(initialValues);
  const isMounted = React.useRef<boolean>(false);

  const [state, dispatch] = React.useReducer<
    React.Reducer<State<T>, Action<T>>
  >(reducer, {
    values: initialValues,
    isSubmitting: false,
    isValidating: false,
    isValid: false,
    submitCount: 0,
    errors: {},
    touched: {},
  } as State<T>);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (
      isMounted.current === true &&
      !isEqual(values.current, initialValues) &&
      enableReinitialize
    ) {
      values.current = initialValues;
      dispatch({
        type: "RESET",
        payload: {
          values: initialValues,
          errors: {},
          isValid: false,
        },
      });
    }
  }, [enableReinitialize, initialValues]);

  const setField = (field: string, value: any) => {
    dispatch({
      type: "SET_FIELD_VALUE",
      payload: {
        field,
        value,
      },
    });
  };

  const resetFields = (resetValues: T) => {
    dispatch({
      type: "RESET",
      payload: {
        values: resetValues,
        errors: {},
        isValid: false,
      },
    });
  };

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<any>) => {
      const { name, value } = event.target;
      setField(name, value);
      if (mode === "onChange") {
        validate();
        dispatch({
          type: "SET_FIELD_VALUE",
          payload: {
            field: "touched",
            value: { ...state.touched, [name]: true },
          },
        });
      }
    },
    [mode, setField, state.touched]
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<any>) => {
      const { name } = event.target;
      if (mode === "onBlur") {
        validate();
        dispatch({
          type: "SET_FIELD_VALUE",
          payload: {
            field: "touched",
            value: { ...state.touched, [name]: true },
          },
        });
      }
    },
    [mode, state.touched]
  );

  const validateField = React.useCallback(
    (fieldName: string) => {
      const errors = validateSchema
        ? validateSchema(
            { [fieldName]: state.values[fieldName] } as T,
            state.values
          )
        : {};

      const errorValue = errors[fieldName] || "";
      const newErrors = { [fieldName]: errorValue };

      const isValid = Object.values(newErrors).every((e) => e === "");
      dispatch({
        type: "SET_ERRORS",
        payload: { errors: newErrors, isValid },
      });

      return newErrors;
    },
    [state.values, validateSchema]
  );

  const validate = React.useCallback(
    (fieldName?: string) => {
      let errors: Record<string, string> = {};
      if (fieldName) {
        errors = validateField(fieldName);
      } else {
        errors = validateSchema
          ? validateSchema(state.values, state.values)
          : {};

        if (Object.keys(errors).length > 0) {
          errors = Object.entries(errors)
            .filter(([key, _]) => key in state.values)
            .reduce((obj, [key, value]) => {
              obj[key] = value;
              return obj;
            }, {} as Record<string, string>);
        }
      }
      const isValid = Object.values(errors).every((e) => e === "");
      dispatch({
        type: "SET_ERRORS",
        payload: { errors, isValid },
      });

      return errors;
    },
    [state.values, validateSchema, validateField]
  );

  return {
    ...state,
    initialValues: values.current,
    handleChange,
    handleBlur,
    setField,
    touched: state.touched,
    validate,
    validateField,
    resetFields,
  };
}
