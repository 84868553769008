import React, { FunctionComponent } from "react";
import Typography from "components/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  timezoneDisplayLabel: {
    color: "#1C74AB",
  },
  timezoneDisplayOffset: {
    color: "#606A76",
  },
}));

interface TimezoneDisplayProps {
  defaultTimeZone: {
    name: string;
    timezone: string;
  };
  className: string;
}

const TimezoneDisplay: FunctionComponent<TimezoneDisplayProps> = ({
  defaultTimeZone,
  className,
}): JSX.Element => {
  const classes = useStyles();
  const timeZoneDisplay = defaultTimeZone.name;
  const now = new Date();

  const options: Intl.DateTimeFormatOptions = {
    timeZoneName: "longOffset",
    timeZone: defaultTimeZone.timezone,
  };
  const formattedDate: string = now.toLocaleDateString(undefined, options);
  const offSetDisplay = formattedDate.split(", ")[1];

  return (
    <div className={className}>
      <Typography className={classes.timezoneDisplayLabel} fontSize="11.1px">
        {timeZoneDisplay}
      </Typography>
      <Typography className={classes.timezoneDisplayOffset} fontSize="11.1px">
        {offSetDisplay}
      </Typography>
    </div>
  );
};

export default TimezoneDisplay;
