import React from "react";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers/PickersCalendarHeader";
import makeStyles from "@mui/styles/makeStyles";
import { addMonths, subMonths, format, parse } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { WEEK_VIEW } from "../utils";
import { datePickerStyles } from "./datePickerStyles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
  customCalendarHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "8px",
    alignItems: "center",
  },
}));

export const CustomCalendarHeader = (
  props: PickersCalendarHeaderProps<dateFns>
) => {
  const { currentMonth, onMonthChange, sx } = props;
  const classes = useStyles();

  const selectNextMonth = () => {
    /* TODO: figure out type issues */
    /* @ts-ignore */
    onMonthChange(addMonths(currentMonth, 1), "left");
  };
  const selectPreviousMonth = () => {
    /* TODO: figure out type issues */
    /* @ts-ignore */
    onMonthChange(subMonths(currentMonth, 1), "right");
  };

  return (
    <Box
      className={classes.customCalendarHeader}
      data-testid="xo-cal-date-picker-header"
      sx={sx}
    >
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectPreviousMonth} title="Previous month">
          <ChevronLeft />
        </IconButton>
      </Stack>
      <Typography variant="body2">
        {/* @ts-ignore */}
        {format(currentMonth, "MMMM y")}
      </Typography>
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectNextMonth} title="Next month">
          <ChevronRight />
        </IconButton>
      </Stack>
    </Box>
  );
};

const XOCalDatePicker = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = React.useState<
    dateFns | null | Date
  >(null);
  const [month, setMonth] = React.useState<dateFns | null>(null);

  const view = searchParams.get("view");
  const date = searchParams.get("date");

  React.useEffect(() => {
    if (date) {
      try {
        const parsedDate = parse(date, "yyyy-MM-dd", new Date());
        setSelectedValue(parsedDate);
      } catch (error) {
        setSelectedValue(null);
      }
    } else {
      setSelectedValue(null);
    }
  }, [date]);

  React.useLayoutEffect(() => {
    const thisWeek = document.querySelector(
      ".MuiPickersDay-today"
    )?.parentElement;
    const selectedWeek = document.querySelector(
      ".MuiPickersDay-root.Mui-selected"
    )?.parentElement;
    if (view === WEEK_VIEW) {
      if (selectedWeek) {
        document.querySelector(".this-week")?.classList.remove("this-week");
        selectedWeek && selectedWeek?.classList.add("this-week");
      } else {
        document.querySelector(".this-week")?.classList.remove("this-week");
        thisWeek && thisWeek?.classList.add("this-week");
      }
    } else {
      document.querySelector(".this-week")?.classList.remove("this-week");
    }
  }, [view, selectedValue, month]);

  React.useEffect(() => {
    // The StaticDatePicker MUI component includes a div with a grid role with an aria-labelledby by attr which points to a non-existent id
    // so we need to remove the aria-labelledby attr since it is invalid
    const divWithInvalidAria = document.querySelector("div[role='grid']");
    divWithInvalidAria?.removeAttribute("aria-labelledby");
  });

  const handleDateChange = (value: dateFns | null) => {
    if (value) {
      setSelectedValue(value);
      searchParams.delete("date");
      // TODO: figure out type issues
      // @ts-ignore
      searchParams.append("date", format(value, "yyyy-MM-dd"));
      setSearchParams(searchParams);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        showDaysOutsideCurrentMonth
        // TODO: figure out type issues
        // @ts-ignore
        value={selectedValue}
        onMonthChange={(newMonth) => {
          setMonth(newMonth);
        }}
        onChange={handleDateChange}
        sx={datePickerStyles}
        slotProps={{
          toolbar: {
            hidden: true,
          },
          actionBar: {
            actions: [],
          },
        }}
        slots={{ calendarHeader: CustomCalendarHeader }}
      />
    </LocalizationProvider>
  );
};

export default XOCalDatePicker;
