import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ConflictsTable from "./ConflictsTable";
import useGetSlots from "shared/features/xocal/useGetSlots";
import { GetSlotsForDateRangeConflictedEnum } from "shared/fetch/src/apis/SlotsApi";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import {
  getProvidersObjectKeyedById,
  getAppointmentObjectKeyedById,
} from "components/XOCal/utils";

export interface SlotOutputWithOneAppointment extends SlotOutput {
  appointments: [AppointmentOutput]; // that appointments array must have one and only one item
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  wrapper: {
    display: "flex",
    height: "100%",
  },
  page: {
    backgroundColor: palette?.white,
    padding: "20px",
    paddingLeft: spacing(10),
    paddingRight: spacing(10),
    width: "100%",
    height: "100%",
  },
}));

const ConflictsQueueContainer = () => {
  const classes = useStyles();
  const [conflicts, setConflicts] = React.useState<
    SlotOutputWithOneAppointment[] | undefined
  >(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const clinicId = searchParams.get("clinicId");

  const requestParams = {
    clinicId: clinicId as string,
    conflicted: GetSlotsForDateRangeConflictedEnum.True,
  };

  // get the cached calendarStatic data
  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: clinicId?.toString() as string,
  });

  const providersObject = getProvidersObjectKeyedById(calendarStaticData);
  const appointmentObjectKeyedById =
    getAppointmentObjectKeyedById(calendarStaticData);

  // each conflicted slot for display will have an appointments property with a array containing
  // one apptafter being passed through this function

  // refactor to only pass the appointment objects, cloning the slot is confusing
  const transformConflictsForDisplay = (
    conflictData: SlotOutput[] | undefined
  ) => {
    const results: SlotOutputWithOneAppointment[] = [];
    conflictData?.map((slot: SlotOutput) => {
      // slots with visits that have been cancelled have an empty appointments array and need to be
      // removed from the array we pass to the display component
      if (
        !Boolean(slot.appointments?.length) ||
        slot.appointments?.length === undefined
      ) {
        return;
      }
      // if a conflicted slot has an appointments array containing one appt we can pass that
      // directly to the table as one row
      if (slot.appointments?.length === 1) {
        results.push(slot as SlotOutputWithOneAppointment);
      }
      // but conflicted slots can have multiple appointments
      if (slot.appointments?.length > 1) {
        // we want each conflicted slot to have ony one appointment
        // and correspond to one display row in the conflicts table
        slot.appointments?.map((appointment: AppointmentOutput) => {
          const transformedSlotWithOneAppointment = Object.assign({}, slot);
          transformedSlotWithOneAppointment.appointments = [appointment];
          results.push(
            transformedSlotWithOneAppointment as SlotOutputWithOneAppointment
          );
        });
      }

      // for slots that don't have an appointmentType but have appointments
      if (slot.appointments.length >= 1 && !slot.appointmentType) {
        slot.appointmentType = slot.appointments[0].appointmentType;
      }
    });
    return results;
  };

  const { data: slots } = useGetSlots(requestParams);

  React.useEffect(() => {
    searchParams.set("conflicts", slots?.conflicts?.toString() || "0");
    setSearchParams(searchParams);
  }, [slots?.conflicts]);

  React.useEffect(() => {
    if (Boolean(slots) && Boolean(slots?.results)) {
      const conflicts = transformConflictsForDisplay(slots?.results);
      setConflicts(conflicts);
    }
  }, [slots]);

  return (
    <>
      {Boolean(conflicts) && (
        <div className={classes.wrapper}>
          <div className={classes.page}>
            <ConflictsTable
              clinicId={clinicId}
              hasConflicts={searchParams.get("conflicts") !== "0"}
              conflicts={conflicts!}
              roles={calendarStaticData?.roles}
              providersObject={providersObject}
              appointmentObjectKeyedById={appointmentObjectKeyedById}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConflictsQueueContainer;
