import Skeleton from "./";
import React from "react";

const Avatar = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20px",
        width: "25%",
      }}
    >
      <div style={{ paddingBottom: "5px" }}>
        <Skeleton variant="circular" width={50} height={50} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Skeleton variant="rectangular" width={90} height={15} />
        <div style={{ paddingLeft: "5px" }}>
          <Skeleton variant="rectangular" width={10} height={10} />
        </div>
      </div>
    </div>
  );
};

const XOCalSkeleton = () => (
  <div style={{ display: "flex", flexDirection: "row", paddingTop: "24px" }}>
    <div style={{ width: "71px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingRight: "5px",
          alignItems: "flex-end",
          paddingTop: "35px",
        }}
      >
        <div style={{ paddingBottom: "5px" }}>
          <Skeleton variant="rectangular" height={15} width={60} />
        </div>
        <div style={{ paddingBottom: "70px" }}>
          <Skeleton variant="rectangular" height={15} width={40} />
        </div>
        <div style={{ paddingBottom: "60px" }}>
          <Skeleton variant="rectangular" height={15} width={35} />
        </div>
        <div style={{ paddingBottom: "60px" }}>
          <Skeleton variant="rectangular" height={15} width={35} />
        </div>{" "}
        <div style={{ paddingBottom: "60px" }}>
          <Skeleton variant="rectangular" height={15} width={35} />
        </div>{" "}
        <div style={{ paddingBottom: "60px" }}>
          <Skeleton variant="rectangular" height={15} width={35} />
        </div>{" "}
        <div style={{ paddingBottom: "60px" }}>
          <Skeleton variant="rectangular" height={15} width={35} />
        </div>{" "}
        <div style={{ paddingBottom: "60px" }}>
          <Skeleton variant="rectangular" height={15} width={35} />
        </div>{" "}
      </div>
    </div>
    <div style={{ width: "100%" }}>
      <div
        style={{ position: "relative", zIndex: 1, padding: "0px !important" }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F7F7F8",
              paddingBottom: "15px",
            }}
          >
            <Avatar />
            <Avatar />
            <Avatar />
            <Avatar />
          </div>
          <div>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={80}
              sx={{ border: "0.5px solid #DFE1E4" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={80}
              sx={{ bgcolor: "white", border: "0.5px solid #DFE1E4" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={80}
              sx={{ border: "0.5px solid #DFE1E4" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={80}
              sx={{ bgcolor: "white", border: "0.5px solid #DFE1E4" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={80}
              sx={{ border: "0.5px solid #DFE1E4" }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={80}
              sx={{ bgcolor: "white", border: "0.5px solid #DFE1E4" }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default XOCalSkeleton;
