import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotQueryKey } from "./useGetSlot";
import {
  DeleteProviderOOONotificationRequest,
  fetchProviderOooNotificationApi,
} from "shared/fetch/src/apis/ProviderOooNotificationApi";
import { getFetchOOOQueryKey } from "./useGetOOO";

type DeleteNoteMutationFn = (
  requestParameters: DeleteProviderOOONotificationRequest
) => Promise<void>;

const useDeleteOOO = (
  config?: UseMutationOptions<void, Error, DeleteProviderOOONotificationRequest>
): UseMutationResult<void, Error, DeleteProviderOOONotificationRequest> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const mutationFn: DeleteNoteMutationFn = (
    requestParameters: DeleteProviderOOONotificationRequest
  ) => {
    return fetchProviderOooNotificationApi.deleteProviderOOONotification(
      requestParameters
    ) as Promise<void>;
  };

  const defaultConfig: UseMutationOptions<
    void,
    Error,
    DeleteProviderOOONotificationRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("OOO deleted successfully.");
      queryClient.invalidateQueries(getFetchSlotQueryKey({}));
      queryClient.invalidateQueries(getFetchOOOQueryKey({}));
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            Array.isArray(query.queryKey) &&
            query.queryKey[0] === "oooNotifications"
          );
        },
      });
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to delete OOO");
    },
    ...config,
  };

  return useMutation<void, Error, DeleteProviderOOONotificationRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useDeleteOOO;
