import { useMutation, useQueryClient } from "react-query";
import { paymentMethodsQueryKey } from "./useGetPaymentMethods";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import {
  DeletePaymentMethodRequest,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";

export default () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const mutation = useMutation(
    (requestParameters: DeletePaymentMethodRequest) => {
      return fetchMemberPaymentsApi
        .deletePaymentMethod(requestParameters)
        .then(() => {
          dispatch(showSnackbar("Payment method removed."));
        })
        .catch(() => {
          dispatch(showSnackbar("Failed to delete payment method", "danger"));
        })
        .finally(() => {
          queryClient.invalidateQueries(
            paymentMethodsQueryKey(requestParameters.id)
          );
        });
    }
  );

  return mutation;
};
