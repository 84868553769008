import React, { FunctionComponent } from "react";
import classes from "./index.module.css";
import Avatar from "components/Avatar";
import DynamicHeading from "components/DynamicHeadings";

interface IMenuItemProps {
  title: string;
  type: string;
  avatarUrl: string;
  hasNoAvatarLeftMargin?: boolean;
}
export const ProviderMenuItem: FunctionComponent<IMenuItemProps> = ({
  title,
  type,
  avatarUrl,
  hasNoAvatarLeftMargin,
}) => {
  return (
    <div
      className={classes.profileContainer}
      aria-hidden
      data-testid="provider-menu-item"
    >
      <Avatar
        containerStyle={{
          marginLeft: `${hasNoAvatarLeftMargin ? "0px" : "10px"}`,
        }}
        alt={title}
        src={avatarUrl}
        size="small"
      />
      <div className={classes.profileInfo}>
        {/* TODO: yikes, this probably shouldn't be a heading, it is within a button */}
        <DynamicHeading.H
          appearance="smallBody"
          className={classes.profileTitle}
        >
          {title}
        </DynamicHeading.H>
        <DynamicHeading.H
          appearance="smallBody"
          className={classes.profileSubTitle}
        >
          {type}
        </DynamicHeading.H>
      </div>
    </div>
  );
};
