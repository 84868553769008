export const timezoneOptions = [
  {
    timezone: "America/New_York",
    name: "Eastern Time",
  },
  {
    timezone: "America/Chicago",
    name: "Central Time",
  },
  {
    timezone: "America/Denver",
    name: "Mountain Time",
  },
  {
    timezone: "America/Phoenix",
    name: "Mountain Time - Arizona",
  },

  {
    timezone: "America/Los_Angeles",
    name: "Pacific Time",
  },
  {
    timezone: "America/Juneau",
    name: "Alaska Time",
  },
  {
    timezone: "Pacific/Honolulu",
    name: "Hawaii Time",
  },
  {
    timezone: "America/Louisville",
    name: "Eastern Time - Kentucky",
  },
];

export const setDefaultTimeZone = (
  guessTimezone: string,
  timezone?: string
) => {
  const defaultTimeZone =
    timezoneOptions.find((tz) => tz.timezone === timezone) ||
    timezoneOptions.find((tz) => tz.timezone === guessTimezone) ||
    timezoneOptions[0];
  return defaultTimeZone;
};
