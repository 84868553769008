import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { paymentMethodsQueryKey } from "./useGetPaymentMethods";
import { PostPaymentMethodSchema } from "shared/fetch/src/models/PostPaymentMethodSchema";
import { fetchMemberPaymentsApi } from "shared/fetch/src/apis/MemberPaymentsApi";

const usePostPaymentMethod = (id: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (requestParameters: PostPaymentMethodSchema) => {
      return fetchMemberPaymentsApi
        .postPaymentMethod({
          id,
          postPaymentMethodSchema: requestParameters,
        })
        .then(() => {
          dispatch(showSnackbar("Payment method has been added."));
        })
        .catch(() => {
          dispatch(showSnackbar("Failed to add payment method", "danger"));
        })
        .finally(() => {
          queryClient.invalidateQueries(paymentMethodsQueryKey(id));
        });
    }
  );

  return {
    addPaymentMethod: mutate,
    isAddPaymentLoading: isLoading,
  };
};

export default usePostPaymentMethod;
