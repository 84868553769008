// Stubbed data for consistency between review environments and local environment

export const inputEvents = [
  {
    id: "10",
    visibility: "accessible",
    appointmentTypes: ["12086"],
    providerId: "david-doctor-md",
    clinicId: "2698",
    maxPatients: 2,
    maxOverbook: 0,
    clinicTimeZone: "America/New_York",
    start: `${new Date().toISOString().split("T")[0]}T09:30:00`,
    end: `${new Date().toISOString().split("T")[0]}T10:00:00`,
    resourceId: "david-doctor-md",
    title: "Patient Jane",
  },
  {
    id: "11",
    visibility: "hold",
    appointmentTypes: ["12086"],
    providerId: "mike-demo-md",
    clinicId: "2698",
    maxPatients: 3,
    maxOverbook: 0,
    clinicTimeZone: "America/Los_Angeles",
    start: `${new Date().toISOString().split("T")[0]}T11:00:00`,
    end: `${new Date().toISOString().split("T")[0]}T11:30:00`,
    resourceId: "mike-demo-md",
    title: "Patient Joe",
  },
  {
    id: "12",
    visibility: "",
    appointmentTypes: ["12086"],
    providerId: "chuck-chiropractor-dc",
    clinicId: "2698",
    maxPatients: 1,
    maxOverbook: 0,
    clinicTimeZone: "America/Los_Angeles",
    start: `${new Date().toISOString().split("T")[0]}T11:00:00`,
    end: `${new Date().toISOString().split("T")[0]}T11:15:00`,
    resourceId: "chuck-chiropractor-dc",
    title: "Patient John with a long name",
  },
  {
    id: "13",
    visibility: "accessible",
    appointmentTypes: ["12086"],
    providerId: "mike-demo-md",
    clinicId: "2698",
    maxPatients: 4,
    maxOverbook: 0,
    clinicTimeZone: "America/Los_Angeles",
    start: `${new Date().toISOString().split("T")[0]}T10:00:00`,
    end: `${new Date().toISOString().split("T")[0]}T11:00:00`,
    resourceId: "mike-demo-md",
    title: "Patient Sawyer with a long name",
  },

    {
    id: "14",
    visibility: "accessible",
    appointmentTypes: ["12086"],
    providerId: "david-doctor-md",
    clinicId: "2698",
    maxPatients: 4,
    maxOverbook: 0,
    clinicTimeZone: "America/Los_Angeles",
    start: `${new Date('2024-05-19').toISOString().split("T")[0]}T09:00:00`,
    end: `${new Date('2024-05-19').toISOString().split("T")[0]}T12:00:00`,
    resourceId: "david-doctor-md",
    title: "Different Time",
  },
];

// export const schedulerProviders = [
//   {
//     id: "darin-diaz-md",
//     title: "Darin Diaz, MD",
//     type: "Physician",
//     avatarUrl: "/images/image-placeholder.jpg",
//   },
//   {
//     id: "alan-aker-md",
//     title: "Alan Aker, MD",
//     type: "Physician",
//     avatarUrl: "/images/image-placeholder.jpg",
//   },
//   {
//     id: "john-greer-lmt",
//     title: "John Greer, LMT",
//     type: "Licensed Massage Therapist",
//     avatarUrl: "/images/image-placeholder.jpg",
//   },
//   {
//     id: "josephine-diaz-pt",
//     title: "Josephine Diaz, PT",
//     type: "Physical Therapist",
//     avatarUrl: "/images/image-placeholder.jpg",
//   },
//   {
//     id: "casper-jones-rd",
//     title: "Casper Jones, RD",
//     type: "Registered Dietitian",
//     avatarUrl: "/images/image-placeholder.jpg",
//   },
//   {
//     id: "crossoverhealth",
//     title: "David Doctor, MD",
//     type: "Physician",
//     avatarUrl: "/images/image-placeholder.jpg",
//   },
// ];

export const appointmentTypesByModality = [
  {
    modality: "Primary & Urgent Care",
    id: "38000",
    name: "Office Visit Virtual (30 min)",
    duration: 30,
  },
  {
    modality: "Primary & Urgent Care",
    id: "12086",
    name: "Office Visit (30 min)",
    duration: 30,
  },
  {
    modality: "Primary & Urgent Care",
    id: "13644",
    name: "Annual Physical Exam (60 min)",
    duration: 60,
  },
  {
    modality: "Ancillary Care",
    id: "13893",
    name: "Health Coaching Initial (60 min)",
    duration: 60,
  },
  {
    modality: "Ancillary Care",
    id: "11244",
    name: "Chiropractic (30 min)",
    duration: 30,
  },
  {
    modality: "Internal Visits",
    id: "37012",
    name: "Sample Internal Hold Type",
  },
];

export const sites = [
  {
    id: "2698",
    name: "Aliso Viejo",
    zone: "America/Los_Angeles",
    dial: "(949) 555-1212",
    state: "CA",
    nearsite: true,
    zip: ["90201", "90202"],
    hoursOfOperation: {
      M: ["08:00", "18:00"],
      T: ["08:00", "18:00"],
      Th: ["08:00", "18:00"],
      F: ["08:00", "18:00"],
      S: ["09:00", "17:00"],
    },
  },
];
