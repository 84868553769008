// TODO: use this when no data is fixed
// import useGetCalendarStaticData from "./useGetCalendarStaticData";

import capitalize from "lodash/capitalize";
import useGetSynopsis from "./useGetSynopsis";
import { SynopsisAppointment } from "shared/fetch/src/models/SynopsisAppointment";
import useGetCalendarStaticData from "./useGetCalendarStaticData";
import { useMemo } from "react";

function getDuration(serviceName?: string) {
  const reDuration = /\s*\((\d+)\s*min\)/i;
  const match = reDuration.exec(serviceName || "");
  if (match) {
    return parseInt(match[1], 10).toString();
  }
  return;
}

type VisitType = Omit<SynopsisAppointment, "id"> & {
  modalityType?: string;
  serviceName?: string;
  duration?: string;
  id: string;
};

// TODO: this can't be used in our code, it uses synopsis
export const useGetVisitTypes = (providerUser: string, siteId: string) => {
  const synopsis = useGetSynopsis();
  const sites = synopsis.data?.sites || [];
  const appointments = synopsis.data?.appointments || [];
  const { data } = useGetCalendarStaticData({
    clinicId: siteId,
  });
  const internalHoldType = data?.clinicDetails?.internalHoldTypes || [];
  const visitTypes = useMemo(() => {
    if (!providerUser || !siteId || !synopsis) {
      return [];
    }
    const site = sites.find((s) => s.id?.toString() === siteId);
    if (!site) {
      return [];
    }

    const services: VisitType[] = [];
    site?.modalities?.forEach((modality) => {
      if (!modality.list) {
        return;
      }
      modality.list.forEach((serviceId) => {
        const service = appointments.find(
          (appointment) => appointment.id === serviceId
        );
        if (service) {
          const provider = site?.providers?.find(
            (p) => p.user === providerUser && p?.uses?.includes(serviceId)
          );
          if (provider) {
            const duration = getDuration(service.name);
            const modalityType = modality.type;
            services.push({
              modalityType,
              name: service.name,
              ...service,
              serviceName: service.name,
              duration,
              id: service?.id?.toString() || "",
            });
          }
        }
      });
    });

    services.push(
      ...internalHoldType.map(({ name }) => ({
        id: name as string,
        name: capitalize(name as string),
        modalityType: "Internal Visits",
        serviceName: capitalize(name as string),
      }))
    );

    return services;
  }, [providerUser, siteId, synopsis]);

  return visitTypes;
};

// TODO: this function also can't be used
export const useModalitiesWithColors = () => {
  const synopsis = useGetSynopsis();

  if (!synopsis?.data) {
    return [];
  }

  if (!synopsis?.data?.appointments) {
    return [];
  }

  const modalityMap: {
    [modalityType: string]: string;
  } = {};

  synopsis.data.appointments?.forEach((modality) => {
    if (!modality.color) {
      return;
    }

    if (!modality.id) {
      return;
    }

    modalityMap[modality.id] = modality.color;
  });

  return modalityMap;
};
