import React from "react";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { Tooltip } from "@mui/material";
import {
  capitalizeFirstLetterOfEveryWord,
  ClinicAppointmentsKeyedByAppointmentId,
} from "../../utils";
import { composeTooltipSlotProps } from "components/XOCal/Slot/utils";
import styles from "components/XOCal/EventContent.module.css";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";

interface IProps {
  slot: SlotOutput;
  appointmentInfoByAppointmentId: ClinicAppointmentsKeyedByAppointmentId;
}

// TODO: Look at the return This is Tooltip, yo. Rename
export default function EventSummary({
  slot,
  appointmentInfoByAppointmentId,
}: IProps) {
  const { appointments, appointmentTypes } = slot;
  const hasMultipleVisitTypes =
    appointmentTypes && appointmentTypes?.length > 1;
  const hasOneOrMoreVisitsScheduled = appointments?.length !== 0;

  const getInternalHoldTypeLabel = (apptTypes: string[] | undefined) => {
    return apptTypes
      ?.map((apptType) => {
        return capitalizeFirstLetterOfEveryWord(apptType);
      })
      .join(" | ");
  };

  const getSlotDisplayLabel = () => {
    const isInternalHoldSlot = slot.visibility === SlotVisibilityEnum.Hold;
    const hasLabelOnSlotObject = Boolean(slot.label);

    if (hasLabelOnSlotObject && !hasOneOrMoreVisitsScheduled) {
      return slot.label;
    } else if (isInternalHoldSlot && !hasOneOrMoreVisitsScheduled) {
      return getInternalHoldTypeLabel(slot.appointmentTypes!);
      // if a slot does not have a label,
      // it means if does not have any slot.appointmentType it is an internal hold

      // however, if an appointment is scheduled on such a slot, there will an abbreviatedDisplayLabel
      // on the appointment objects
    } else if (hasOneOrMoreVisitsScheduled) {
      return appointmentInfoByAppointmentId[appointments![0].appointmentType]
        ?.abbreviatedDisplayLabel;
      // BUT! do we need to handle for the case of several appointments on the slot, can those
      // appointment_types conflict w/ one another?
    } else {
      return slot.label;
    }
  };

  const allEventLabels = () => {
    const allTypes = slot.appointmentTypes;
    const scheduledAppointmentType = slot.appointments?.[0]?.appointmentType;
    const anchorVisitPosition = allTypes?.findIndex(
      (type) => type === slot.appointmentType
    );

    // needed to put anchor visit first in tooltip
    if (anchorVisitPosition && anchorVisitPosition !== 0) {
      allTypes?.splice(0, 0, allTypes.splice(anchorVisitPosition, 1)[0]);
    }

    if (hasOneOrMoreVisitsScheduled) {
      return (
        scheduledAppointmentType && (
          <div>
            {appointmentInfoByAppointmentId[scheduledAppointmentType]?.name}
          </div>
        )
      );
    } else {
      return allTypes?.map((type, index) => {
        return (
          <div key={index}>{appointmentInfoByAppointmentId[type]?.name}</div>
        );
      });
    }
  };

  const eventDisplayLabel = allEventLabels();
  const slotDisplayLabel = getSlotDisplayLabel();
  const shouldRender = Boolean(slotDisplayLabel) || hasOneOrMoreVisitsScheduled;

  const getVisitTypeLabel = () => {
    // slot has multiple types but no visit are scheduled
    if (hasMultipleVisitTypes && !hasOneOrMoreVisitsScheduled) {
      return appointmentTypes?.length;
    } else {
      return slotDisplayLabel;
    }
  };

  return (
    <>
      {shouldRender && (
        <Tooltip
          title={eventDisplayLabel ? eventDisplayLabel : undefined}
          slotProps={composeTooltipSlotProps({ offset: [60, 10] })}
        >
          <div
            className={styles.eventDisplayLabel}
            data-testid="visit-type-label"
          >
            {getVisitTypeLabel()}
          </div>
        </Tooltip>
      )}
    </>
  );
}
