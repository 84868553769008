// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessPlanPodAssignment,
    AccessPlanPodAssignmentFromJSON,
    AccessPlanPodAssignmentToJSON,
} from './AccessPlanPodAssignment';


/**
 * 
 * @export
 * @interface AccessPlanPodAssignmentResponse
 */
export interface AccessPlanPodAssignmentResponse  {
    /**
     * 
     * @type {string}
     * @memberof AccessPlanPodAssignmentResponse
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessPlanPodAssignmentResponse
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessPlanPodAssignmentResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof AccessPlanPodAssignmentResponse
     */
    last: number;
    /**
     * 
     * @type {Array<AccessPlanPodAssignment>}
     * @memberof AccessPlanPodAssignmentResponse
     */
    results?: Array<AccessPlanPodAssignment>;
}

export function AccessPlanPodAssignmentResponseFromJSON(json: any): AccessPlanPodAssignmentResponse {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': json['page'],
        'last': json['last'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(AccessPlanPodAssignmentFromJSON),
    };
}

export function AccessPlanPodAssignmentResponseToJSON(value?: AccessPlanPodAssignmentResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(AccessPlanPodAssignmentToJSON),
    };
}


