import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { ButtonBase, Drawer } from "@mui/material";
import Button from "components/Button";
import Divider from "components/Divider";
import Heading from "components/DynamicHeadings";
import React from "react";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import useGetSlot from "shared/features/xocal/useGetSlot";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { UpdateSlotSeriesConflictResolutionEnum } from "shared/fetch/src/models/UpdateSlotSeries";
import CancelConfirmModal from "./CancelConfirmModal";
import DeleteBookedSlotConfirmationModal from "./DeleteBookedSlotConfirmationModal";
import classes from "./index.module.css";
import ScheduleVisitTab from "./ScheduleVisitTab";
import ProviderDisplayAndTabs from "./ProviderDisplayAndTabs";
import SettingsTab from "./SettingsTab";
import SlotSeriesActionConfirmModal, {
  SlotSeriesActionOptions,
} from "./SlotSeriesActionConfirmModal";
import useSlotActionDrawer from "./useSlotActionDrawer";
import { findDisabledDates, WEEK_VIEW } from "../utils";
import { screenReaderSpeak } from "shared/utils/screenReaderSpeak";
import EditScheduledSlotModal from "./EditScheduledSlotModal";
import useUpdateSlotSeries from "shared/features/xocal/useUpdateSlotSeries";
import { UpdateSlotSeriesDryRunEnum } from "shared/fetch/src/apis";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { isAfter, isEqual } from "date-fns";
import useDeleteSlot from "shared/features/xocal/useDeleteSlot";

interface ISlotActionDrawerProps {
  open: boolean;
  onClose: () => void;
  isSlotCreation: boolean;
  timezone: string;
  provider?: XOCalProvider | undefined;
  clinicId?: string;
  slotId?: string;
  oooDays: Set<{
    start: string;
    end: string;
    provider: string;
  }>;
  operatingDaysNumerical: Number[];
  view: string | null;
}

const SlotActionDrawer: React.FC<ISlotActionDrawerProps> = ({
  open,
  onClose,
  isSlotCreation,
  timezone,
  provider,
  clinicId,
  slotId,
  oooDays,
  operatingDaysNumerical,
  view,
}) => {
  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: clinicId as string,
  });
  const site = calendarStaticData?.clinicDetails; // change name to clinic

  const { data: slot } = useGetSlot(
    { id: slotId },
    { enabled: !isSlotCreation && !!slotId }
  );

  const {
    activeTab,
    setActiveTab,
    deleteModalOpen,
    setDeleteModalOpen,
    deleteBookedSlotConfirmationModalOpen,
    setDeleteBookedSlotConfirmationModalOpen,
    setConflictingAppointments,
    conflictingAppointments,
    deleteSlotSeriesModalOpen,
    setDeleteSlotSeriesModalOpen,
    slotSeriesOptions,
    setSlotSeriesOptions,
    updateSlotSeriesModalOpen,
    setUpdateSlotSeriesModalOpen,
    isCreateSlotLoading,
    isCreateSlotSeriesLoading,
    isReplicateSingleSlotIntoSeriesLoading,
    isUpdateSlotLoading,
    isUpdateSlotSeriesLoading,
    isScheduleAppointmentLoading,
    values,
    handleChange,
    setField,
    errors,
    handleClose,
    handleClickDeleteButton,
    handleCreateButtonClick,
    handleUpdateClick,
    handleScheduleMemberClick,
    hasFullXoCalPermissions,
    performUpdate,
    runUpdateSlotSeries,
    handleCreateSlot,
    selectedMember,
    updateSlot,
    slotDrawerProvider,
    updateData,
    isReadOnly,
    isSlotDatePast,
    isEditScheduledSlot,
    setIsEditScheduledSlot,
    setIsKeepOrCancelFlow,
    isKeepOrCancelFlow,
    isChangingVisitType,
    setIsChangingVisitType,
    isCreatingSeriesOutOfBookedSlot,
    setIsCreatingSeriesOutOfBookedSlot,
    handleCreateSlotSeries,
  } = useSlotActionDrawer(
    isSlotCreation,
    onClose,
    provider,
    clinicId,
    slotId,
    slot,
    timezone
  );

  // TODO: can't all this code be moved to the controller?
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    values?.selectedDate
  );

  const { mutateAsync: updateSlotSeries } = useUpdateSlotSeries();

  const { mutateAsync: deleteSlot } = useDeleteSlot();

  const selectedAndOOO: boolean | undefined =
    (selectedDate &&
      oooDays &&
      findDisabledDates(selectedDate, oooDays, operatingDaysNumerical)) ||
    undefined;

  const getDrawerTitle = (isCreation: boolean) => {
    return isCreation ? "Create a slot" : "Update slot settings";
  };

  const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (activeTab === 0 && isSlotCreation) {
      handleCreateButtonClick();
    } else if (activeTab === 0 && isKeepOrCancelFlow && slot?.slotSeries) {
      setUpdateSlotSeriesModalOpen(true);
    } else if (activeTab === 0 && !isSlotCreation) {
      handleUpdateClick();
    } else if (activeTab === 1) {
      handleScheduleMemberClick();
    }
  };

  const findUpdateSlotData = (
    options: SlotSeriesActionOptions,
    keep: boolean
  ) => {
    const allSlotsSeriesStart = values.seriesStart
      ? values.seriesStart
      : undefined;
    return {
      ...slot,
      appointmentTypes: values.appointmentTypes,
      daysActive: values.daysActive,
      startTime: values.startTime!,
      endTime: values.endTime!,
      id: slot?.slotSeries?.id?.toString(),
      startAt: values.startAt,
      endAt: values.endAt,
      seriesStart:
        options === SlotSeriesActionOptions.ALL_SLOTS
          ? allSlotsSeriesStart
          : slot?.startAt,
      seriesEnd: values.seriesEnd ? values.seriesEnd : undefined,
      restrictedTo: values.restrictedTo,
      conflictResolution: keep
        ? UpdateSlotSeriesConflictResolutionEnum.Keep
        : UpdateSlotSeriesConflictResolutionEnum.Cancel,
    };
  };

  const handleSlotSeriesEditUpdate = async (shouldKeep: boolean) => {
    if (slotSeriesOptions === SlotSeriesActionOptions.THIS_SLOT) {
      if (!shouldKeep) {
        slot?.id && deleteSlot({ id: slot?.id.toString() });
      }
      handleCreateSlot();
    } else if (slot?.slotSeries?.id?.toString() && slotSeriesOptions) {
      updateSlotSeries({
        id: slot?.slotSeries?.id?.toString(),
        dryRun: UpdateSlotSeriesDryRunEnum.False,
        updateSlotSeries: findUpdateSlotData(slotSeriesOptions, shouldKeep),
      });
    }
    onClose();
  };

  const handleSlotSeriesUpdate = async () => {
    if (slotSeriesOptions === SlotSeriesActionOptions.THIS_SLOT) {
      await updateSlot({
        id: slotId!,
        createSlotCore: updateData,
      });
      onClose();
      return;
    }

    const result = await runUpdateSlotSeries({
      dryRun: true,
    });

    if (result.success) {
      if (
        slotSeriesOptions === SlotSeriesActionOptions.THIS_AND_FUTURE &&
        result.data?.summary?.conflictingAppointments?.length
      ) {
        const conflicting: AppointmentOutput[] = [];
        result.data?.summary?.conflictingAppointments.forEach((appointment) => {
          if (
            slot?.startAt &&
            (isAfter(new Date(appointment.startAt), new Date(slot?.startAt)) ||
              isEqual(new Date(appointment.startAt), new Date(slot?.startAt)))
          ) {
            conflicting.push(appointment);
          }
        });
        setConflictingAppointments(conflicting);
        if (conflicting && conflicting.length > 0) {
          setIsEditScheduledSlot(true);
          return;
        }
      } else {
        const _conflictingAppointments =
          result.data?.summary?.conflictingAppointments;

        setConflictingAppointments(_conflictingAppointments! || []);

        if (_conflictingAppointments && _conflictingAppointments.length > 0) {
          setIsEditScheduledSlot(true);
          return;
        }
      }

      performUpdate();
    }
  };

  const shouldRenderDeleteIcon = !isSlotCreation && Boolean(slot);

  React.useEffect(() => {
    if (isCreateSlotLoading) {
      screenReaderSpeak("Creating slot", "assertive");
    } else if (isUpdateSlotLoading) {
      screenReaderSpeak("Updating slot", "assertive");
    } else if (isUpdateSlotSeriesLoading) {
      screenReaderSpeak("Updating slot series", "assertive");
    } else if (isScheduleAppointmentLoading) {
      screenReaderSpeak("Scheduling visit", "assertive");
    }
  }, [
    isCreateSlotLoading,
    isUpdateSlotSeriesLoading,
    isUpdateSlotLoading,
    isScheduleAppointmentLoading,
  ]);

  if (!isSlotCreation && !Boolean(slot)) {
    return null;
  }

  return (
    <>
      {!isSlotCreation && open && Boolean(slot) && (
        <>
          <CancelConfirmModal
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            handleClose={handleClose}
            slotId={slotId!}
            slotSeriesOptions={slotSeriesOptions}
            slotSeriesId={slot?.slotSeries?.id?.toString()}
            values={values}
            provider={slotDrawerProvider}
            slot={slot}
          />
          <DeleteBookedSlotConfirmationModal
            deleteBookedSlotConfirmationModalOpen={
              deleteBookedSlotConfirmationModalOpen
            }
            setDeleteBookedSlotConfirmationModalOpen={
              setDeleteBookedSlotConfirmationModalOpen
            }
            handleClose={handleClose}
            slotId={slotId!}
            values={values}
            provider={slotDrawerProvider}
            slot={slot}
          />
          <EditScheduledSlotModal
            modalOpen={isEditScheduledSlot}
            isCreatingSeriesOutOfBookedSlot={isCreatingSeriesOutOfBookedSlot}
            handleCreateSlotSeries={handleCreateSlotSeries}
            setModalOpen={setIsEditScheduledSlot}
            slot={slot}
            handleCreateSlot={handleCreateSlot}
            handleSlotSeriesEditUpdate={handleSlotSeriesEditUpdate}
            conflictingAppointments={conflictingAppointments}
          />
          <SlotSeriesActionConfirmModal
            setSlotSeriesOptions={setSlotSeriesOptions}
            slotSeriesOptions={slotSeriesOptions}
            modalOpen={deleteSlotSeriesModalOpen}
            setModalOpen={setDeleteSlotSeriesModalOpen}
            actionType="delete"
            handleAction={(slotToDelete) => {
              setSlotSeriesOptions(slotToDelete);
              setDeleteSlotSeriesModalOpen(false);
              slotId && setDeleteModalOpen(true);
            }}
          />
          <SlotSeriesActionConfirmModal
            modalOpen={updateSlotSeriesModalOpen}
            setModalOpen={setUpdateSlotSeriesModalOpen}
            setSlotSeriesOptions={setSlotSeriesOptions}
            slotSeriesOptions={slotSeriesOptions}
            actionType="update"
            handleAction={async (slotUpdateOption) => {
              setSlotSeriesOptions(slotUpdateOption);
              setUpdateSlotSeriesModalOpen(false);
              if (isKeepOrCancelFlow) {
                if (slotSeriesOptions !== SlotSeriesActionOptions.THIS_SLOT) {
                  await handleSlotSeriesUpdate();
                  setIsEditScheduledSlot(true);
                } else {
                  setIsEditScheduledSlot(true);
                }
              } else {
                setIsEditScheduledSlot(false);
                handleSlotSeriesUpdate();
              }
            }}
          />
        </>
      )}
      <Drawer
        open={open}
        transitionDuration={400}
        anchor="right"
        classes={{
          paper: classes.drawer,
        }}
        role="dialog"
        aria-labelledby="drawer-title"
        onClose={handleClose}
        data-testid="slot-action-drawer"
      >
        <form onSubmit={handleFormSubmit}>
          <div className={classes.topContainer}>
            <div className={classes.titleContainer}>
              <Heading.H appearance="h5" id="drawer-title">
                {getDrawerTitle(isSlotCreation)}
              </Heading.H>
              <div className={classes.iconWrapper}>
                {shouldRenderDeleteIcon && (
                  <ButtonBase
                    data-testid="delete-slot-button"
                    role="button"
                    onClick={handleClickDeleteButton}
                    aria-label="Delete slot"
                    disabled={!hasFullXoCalPermissions || isSlotDatePast}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </ButtonBase>
                )}
                <ButtonBase
                  role="button"
                  aria-label={`Close ${getDrawerTitle(
                    isSlotCreation
                  ).toLowerCase()} dialog`}
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </ButtonBase>
              </div>
            </div>
            <Divider />
            <ProviderDisplayAndTabs
              isSlotCreation={isSlotCreation}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              slotDrawerProvider={slotDrawerProvider}
            />
          </div>
          <Divider />

          {activeTab === 0 && (
            <SettingsTab
              site={site}
              timezone={timezone!}
              isSlotCreation={isSlotCreation}
              values={values}
              setField={setField}
              handleChange={handleChange}
              oooDays={oooDays}
              slot={slot}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              operatingDaysNumerical={operatingDaysNumerical}
              hasFullXoCalPermissions={hasFullXoCalPermissions}
              isReadOnly={Boolean(isReadOnly)}
              isKeepOrCancelFlow={isKeepOrCancelFlow}
              isChangingVisitType={isChangingVisitType}
              setIsChangingVisitType={setIsChangingVisitType}
              setIsKeepOrCancelFlow={setIsKeepOrCancelFlow}
              setIsCreatingSeriesOutOfBookedSlot={
                setIsCreatingSeriesOutOfBookedSlot
              }
            />
          )}
          {/* isReadOnly was the render guard for this tab, but that's wrong. We want people to be able to
          SEE this tab even when the slot is in the past, but the inputs should be disabled in that case
          isReadyOnly is currently tied to admin vs. staff permissions, but this is imprecise
          we will need a new boolean or change isReadOnly. my gut says isReadOny shouldn't be so tightly
          coupled to admin vs. staff permissions. */}
          {activeTab === 1 && (
            <ScheduleVisitTab
              timezone={timezone!}
              site={site}
              values={values}
              setField={setField}
              handleChange={handleChange}
              provider={slotDrawerProvider!}
              slotId={slotId!}
              handleClose={handleClose}
              slot={slot}
              isSlotDatePast={isSlotDatePast}
            />
          )}

          <div className={classes.buttonContainer}>
            {activeTab === 0 && isSlotCreation && (
              <Button
                type="submit"
                className={classes.createUpdateButton}
                variant="contained"
                disabled={
                  !hasFullXoCalPermissions ||
                  isCreateSlotLoading ||
                  Object.keys(errors).length > 0 ||
                  selectedAndOOO ||
                  (view === WEEK_VIEW && !selectedDate)
                }
                isLoading={isCreateSlotLoading || isCreateSlotSeriesLoading}
              >
                Create
              </Button>
            )}
            {activeTab === 0 && !isSlotCreation && (
              <Button
                type="submit"
                className={classes.createUpdateButton}
                variant="contained"
                data-testid="slot-action-drawer-update-slot-button"
                disabled={
                  !hasFullXoCalPermissions ||
                  isCreateSlotLoading ||
                  Object.keys(errors).length > 0 ||
                  isUpdateSlotSeriesLoading ||
                  isSlotDatePast
                }
                isLoading={
                  isCreateSlotLoading ||
                  isUpdateSlotSeriesLoading ||
                  isUpdateSlotLoading ||
                  isReplicateSingleSlotIntoSeriesLoading
                }
              >
                Update
              </Button>
            )}
            {activeTab === 1 && (
              <Button
                type="submit"
                className={classes.createUpdateButton}
                variant="contained"
                disabled={
                  !selectedMember || values?.appointmentTypes?.length === 0
                }
                isLoading={isUpdateSlotLoading || isScheduleAppointmentLoading}
              >
                Schedule visit
              </Button>
            )}
            {activeTab === 0 && (
              <Button className={classes.cancelButton} onClick={handleClose}>
                Cancel
              </Button>
            )}
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default SlotActionDrawer;
