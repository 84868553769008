import React, { FunctionComponent, useEffect, useRef } from "react";
import DialogTitleComponent, {
  DialogTitleProps,
} from "@mui/material/DialogTitle";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface IProps extends Omit<DialogTitleProps, "disableTypography"> {
  onClose?: () => void;
  id?: string; // For associating with aria-labelledby
}

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  close: {
    width: "15px",
  },
  dialogTitleTypography: {
    fontSize: "19px",
    fontWeight: "bold",
  },
}));

const DialogTitle: FunctionComponent<IProps> = (props) => {
  const { onClose, children, className, ...other } = props;
  const classes = useStyles(props);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  return (
    <DialogTitleComponent {...other} className={clsx(className, classes.root)}>
      <Typography
        ref={titleRef}
        tabIndex={-1}
        aria-level={2}
        className={classes.dialogTitleTypography}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          onClick={onClose}
          aria-label="Close dialog"
          edge="end"
          data-testid="dialog-close-button"
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitleComponent>
  );
};

export default DialogTitle;
