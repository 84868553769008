// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientsSearchRequest
 */
export interface PatientsSearchRequest  {
    /**
     * 
     * @type {string}
     * @memberof PatientsSearchRequest
     */
    q: string;
    /**
     * 
     * @type {string}
     * @memberof PatientsSearchRequest
     */
    dpid?: string;
}

export function PatientsSearchRequestFromJSON(json: any): PatientsSearchRequest {
    return {
        'q': json['q'],
        'dpid': !exists(json, 'dpid') ? undefined : json['dpid'],
    };
}

export function PatientsSearchRequestToJSON(value?: PatientsSearchRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'q': value.q,
        'dpid': value.dpid,
    };
}


