import React from "react";
import { Tooltip } from "@mui/material";
import RepeatIcon from "@mui/icons-material/Repeat";
import styles from "components/XOCal/EventContent.module.css";
import { composeTooltipSlotProps } from "../utils";

export default function SlotSeriesIcon({
  isSlotSeries,
}: {
  isSlotSeries: boolean;
}) {
  if (!isSlotSeries) {
    return null;
  }
  return (
    <Tooltip
      title={"Repeated slot"}
      slotProps={composeTooltipSlotProps({ offset: [50, -10] })}
    >
      <div>
        <RepeatIcon className={styles.visibilityIcon} />
      </div>
    </Tooltip>
  );
}
