import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useSearchParams } from "react-router-dom";
import { CalendarStaticDataResponseClinics } from "shared/fetch/src/models/CalendarStaticDataResponseClinics";
import { DAY_VIEW } from "../utils";
import { useDispatch } from "react-redux";
import { setClinic } from "shared/state/ui/xoCal";

interface IProps {
  centers: CalendarStaticDataResponseClinics[];
}

const SelectCenter = ({ centers }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setClinic(event.target.value));

    const currentParams = Object.fromEntries(searchParams.entries());

    setSearchParams({
      ...currentParams,
      clinicId: event.target.value,
      view: DAY_VIEW,
    });
  };

  const currentClinicId = searchParams.get("clinicId");

  useEffect(() => {
    if (!currentClinicId && centers.length) {
      const currentParams = Object.fromEntries(searchParams.entries());
      const newParams = new URLSearchParams({
        ...currentParams,
        clinicId: centers[0].id?.toString() || "",
      });
      setSearchParams(newParams);
    }
  }, [currentClinicId, centers, setSearchParams]);
  // TODO getting this console warning: You have provided an out-of-range value `2698` for the select component.
  // Consider providing a value that matches one of the available options or ''.

  return (
    <FormControl fullWidth>
      <TextField
        select
        defaultValue={currentClinicId}
        onChange={handleChange}
        value={currentClinicId}
        inputProps={{ "aria-label": "Select clinic" }}
      >
        {centers?.map((option, i) => (
          <MenuItem key={i + 100} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectCenter;
