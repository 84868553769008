const SET_CLINIC = "SET_CLINIC";

export const defaultStore = {
  clinic: null,
};

export const generateDefaultXoCal = () => {
  return defaultStore;
};

export const getInitialXoCalState = (): IState => {
  return {
    clinic: null,
  };
};

export interface IState {
  clinic: string | null;
}

export const xoCal = (
  state: IState = generateDefaultXoCal(),
  action: any
): IState => {
  switch (action.type) {
    case SET_CLINIC:
      return {
        ...state,
        clinic: action.payload,
      };
    default:
      return state;
  }
};

export const setClinic = (clinicId: string) => {
  return { type: SET_CLINIC, payload: clinicId };
};
