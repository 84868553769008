// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteSlotSeriesRequestBody
 */
export interface DeleteSlotSeriesRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof DeleteSlotSeriesRequestBody
     */
    providerId: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSlotSeriesRequestBody
     */
    clinicId: string;
    /**
     * The start of the date range of slots we are intending to delete.
     * @type {string}
     * @memberof DeleteSlotSeriesRequestBody
     */
    startDate: string;
    /**
     * The end of the date range of slots we are intending to delete
     * @type {string}
     * @memberof DeleteSlotSeriesRequestBody
     */
    endDate: string;
}

export function DeleteSlotSeriesRequestBodyFromJSON(json: any): DeleteSlotSeriesRequestBody {
    return {
        'providerId': json['provider_id'],
        'clinicId': json['clinic_id'],
        'startDate': json['start_date'],
        'endDate': json['end_date'],
    };
}

export function DeleteSlotSeriesRequestBodyToJSON(value?: DeleteSlotSeriesRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'provider_id': value.providerId,
        'clinic_id': value.clinicId,
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}


