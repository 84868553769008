import { useGetPaymentMethods } from "shared/features/payments/paymentMethods";

const useGetAllAPI = (userId: string) => {
  const { paymentMethods, isPending } = useGetPaymentMethods({
    id: userId,
  });

  return {
    methods: paymentMethods,
    isLoading: isPending,
  };
};

export default useGetAllAPI;
