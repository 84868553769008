/* eslint no-useless-computed-key: 0 */
import React, { ReactElement } from "react";
import clsx from "clsx";

import { Assets } from "../../@types";
// SVG icon map - soon, this will be the source of truth for ALL svgs
import SVGIcons from "../../styles/icons/icons.svg";
// Images / raw assets
import AvatarCooper from "../../styles/images/avatars/cooper.jpg";
import AvatarJay from "../../styles/images/avatars/jay.jpg";

import AvatarPingu from "../../styles/images/avatars/pingu.jpg";
import AttachedImage from "../../styles/images/episode/episode_image.jpg";
import Logo from "../../styles/images/logo/logo.png";
import NoResults from "../../styles/images/no-results.png";
import NoPaymentHistory from "../../styles/images/payment/no-payment-history.png";
import NoPaymentMethod from "../../styles/images/payment/no-payment-method.png";
import Logomark from "../../styles/images/logo/logomark.png";
import AvatarDefault from "../../styles/images/avatars/photo-default.jpg";
import Rocket from "../../styles/images/episode/rocket.jpg";
import MessageSent from "../../styles/images/episode/new-message.jpeg";
import MentalHealth from "../../styles/images/meet-your-team/mental-health-image.png";
import TeamClinic from "../../styles/images/meet-your-team/default-clinic.png";
import VirtualTeamClinic from "../../styles/images/meet-your-team/virtual-clinic.png";
import Host from "../../styles/images/meet-your-team/host.png";
import PrimaryCare from "../../styles/images/meet-your-team/primary-care-image.png";
import PhysicalMedicine from "../../styles/images/meet-your-team/physical-medicine-image.png";
import CareNavigator from "../../styles/images/meet-your-team/care-navigator-service-line.jpg";
import HealthCoach from "../../styles/images/meet-your-team/health-coach-service-line.jpg";
import NurseTriage from "../../styles/images/meet-your-team/nurse-triage.jpg";
import TravelMedicine from "../../styles/images/meet-your-team/travel-med.jpg";
import VirtualInPerson from "../../styles/images/marketing/virtual-in-person.png";
import EAPrimaryCare from "../../styles/images/engagement-activation/ea-primary-care.png";
import EAPhysicalTherapy from "../../styles/images/engagement-activation/ea-physical-med.png";
import EAMentalHealth from "../../styles/images/engagement-activation/ea-mental-health.png";
import EAHealthCoaching from "../../styles/images/engagement-activation/ea-health-coach.png";
import TravelMed from "styles/images/meet-your-team/icon-travel-med.png";

import Pharmacy from "../../styles/images/meet-your-team/pharmacy.jpg";
import Fitness from "../../styles/images/meet-your-team/Fitness.jpg";
import Massage from "../../styles/images/meet-your-team/Massage.jpg";
import Acupuncture from "../../styles/images/meet-your-team/Acupuncture.jpg";
import Optometry from "../../styles/images/meet-your-team/Optometry.jpg";
import VisionCare from "../../styles/images/meet-your-team/VisionCare.jpg";
import Chiropractic from "../../styles/images/meet-your-team/Chiropractic.jpg";
import Dermatology from "../../styles/images/meet-your-team/Dermatology.jpg";
import Pediatrics from "../../styles/images/meet-your-team/Pediatrics.jpg";
import Psychiatry from "../../styles/images/meet-your-team/Psychiatry.jpg";
import Dental from "../../styles/images/meet-your-team/Dental.jpg";
import SportsMedicine from "../../styles/images/meet-your-team/sportsMedicine.jpg";
import Occupational from "../../styles/images/meet-your-team/Occupational.jpg";
import FirstAid from "../../styles/images/meet-your-team/firstAid.jpg";
import FluAndCovid from "../../styles/images/meet-your-team/flu-and-covid.png";

import Communicate from "../../styles/images/marketing/communicate.png";
import StartOnline from "../../styles/images/marketing/start-online.png";
import Share from "../../styles/images/marketing/share.png";
import PreventiveHero from "../../styles/images/marketing-messages/Preventive_Hero.png";
import PreventiveSpot from "../../styles/images/marketing-messages/Preventive_Spot.png";
import PreventiveHow from "../../styles/images/marketing-messages/Preventive_how.png";
import PreventiveWhat from "../../styles/images/marketing-messages/Preventive_what.png";
import PreventiveWhy from "../../styles/images/marketing-messages/Preventive_why.png";
import Appointment from "styles/images/episode/appointment.svg";

import PlaceholderImage from "../../styles/images/avatars/placeholder-image.jpg";
import FelamePlaceHolder from "../../styles/images/avatars/avatar-placeholder-female.jpg";
import MalePlaceHolder from "../../styles/images/avatars/avatar-placeholder-male.jpg";
import GenericPlaceholder from "../../styles/images/avatars/avatar-generic.jpg";
import Clinic from "../../styles/images/home/clinic.png";
import CtaClinic from "../../styles/images/home/cta-clinic.png";

import ErrorResults from "../../styles/images/error-results.png";

// Standalone svgs
import NavChevron from "../../styles/icons/svg/icon-chevron-white.svg";
import Alert from "../../styles/icons/svg/icon-alert.svg";
import Wellness from "../../styles/icons/svg/icon-health-wellness-dark-gray.svg";
import Fasttrack from "../../styles/icons/svg/icon-fast-track-dark-gray.svg";
import Search from "../../styles/icons/svg/icon-search.svg";
import SearchWhite from "../../styles/icons/svg/icon-search-white.svg";
import FilesSVG from "../../styles/icons/svg/icon-files.svg";
import CTARed from "../../styles/icons/svg/icon-cta-red.svg";
import CTAOrange from "../../styles/icons/svg/icon-cta-orange.svg";
import CTAGray from "../../styles/icons/svg/icon-cta-gray.svg";
import Camera from "../../styles/icons/svg/icon-camera.svg";
import CreateEpisodeSVG from "../../styles/images/episode/create-episode-of-care.svg";
import Edit from "../../styles/icons/svg/icon-edit.svg";
import Phone from "styles/icons/svg/icon-phone.svg";
import CareAnyTime from "styles/icons/svg/care_anytime.svg";
import VideoCamera from "styles/icons/svg/icon-video-camera.svg";
import GetCareNowSVG from "styles/icons/svg/get-care-now.svg";
import ScheduleVisitSVG from "styles/icons/svg/icon-schedule-a-visit.svg";
import CalendarSVG from "styles/icons/svg/calendar.svg";
import DocsMed from "styles/icons/svg/labs-docs-meds.svg";
import PhoneTriage from "styles/icons/svg/icon-phone-triage.svg";
import PreventiveBadge from "styles/icons/svg/icon-spa.svg";
import ActiveAccountBadge from "styles/icons/svg/icon-active-account.svg";
import AccountBadge from "styles/icons/svg/icon-account.svg";
import ActiveBenefitsBadge from "styles/icons/svg/icon-active-benefits.svg";
import BenefitsBadge from "styles/icons/svg/icon-benefits.svg";
import ActivePaymentsBadge from "styles/icons/svg/icon-active-payments.svg";
import PaymentsBadge from "styles/icons/svg/icon-payments.svg";
import ActiveMethodsBadge from "styles/icons/svg/icon-active-methods.svg";
import MethodsBadge from "styles/icons/svg/icon-methods.svg";
import CloudIcon from "styles/icons/svg/cloud.svg";
import DependentsIcon from "styles/icons/svg/icon-dependents.svg";
import ManageDependents from "styles/icons/svg/manage-dependents.svg";
import Download from "styles/icons/svg/download.svg";
import ErrorAccount from "styles/icons/error-account.png";
import NoConvosOrVisits from "../../styles/images/no-convos-visits.svg";
import MinorProxy from "../../styles/images/minor-proxy.svg";
import Acknowledge from "../../styles/icons/svg/acknowledge.svg";
import Calculator from "../../styles/icons/svg/calculator.svg";
import Refer from "../../styles/icons/svg/refer.svg";
import QuestionSet from "../../styles/icons/svg/question_set.svg";
import RevokeProxy from "../../styles/icons/svg/revoke-proxy.svg";
import HistoryLog from "../../styles/icons/svg/history-log.svg";
import DockingIcon from "../../styles/icons/svg/dockingIcon.svg";
import ctmMessage from "../../styles/icons/svg/ctm-message.svg";
import Review from "../../styles/icons/svg/acknowledgeIcon.svg";
import AwaitReply from "../../styles/icons/svg/await-reply.svg";
import DockRight from "../../styles/icons/svg/dock-right.svg";
import AfterHoursMoonStars from "styles/images/after-hours-widget/moon-stars.svg";
import Hold from "../../styles/icons/svg/hold.svg";
import NoAppointmentsWithBackground from "styles/images/no-appointments-with-background.png";

// Standalone assets - cta's
import Conditions from "../../styles/icons/cta/health-profile/icon-conditions-dark-gray-bg_v2.png";
import Meds from "../../styles/icons/cta/health-profile/icon-meds-dark-gray-bg_v2.png";
import Labs from "../../styles/icons/cta/health-profile/icon-labs-dark-gray-bg_v2.png";
import Referrals from "../../styles/icons/cta/health-profile/icon-referrals-dark-gray-bg_v2.png";
import Imaging from "../../styles/icons/cta/health-profile/icon-imaging-dark-gray-bg_v2.png";
import Immunizations from "../../styles/icons/cta/health-profile/icon-immunizations-dark-gray-bg_v2.png";
import FamilyHistory from "../../styles/icons/cta/health-profile/icon-family-history-dark-gray-bg_v2.png";
import Photos from "../../styles/icons/cta/health-profile/icon-photos-dark-gray-bg_v2.png";
import FilesPNG from "../../styles/icons/cta/health-profile/icon-files-dark-gray-bg_v2.png";
import CreateEpisode from "../../styles/icons/cta/icon-create-episode-dark-gray-bg.png";
import RiskAssessment from "../../styles/icons/cta/icon-risk-assessment-light-gray-bg.png";
import Doctor from "../../styles/icons/cta/episode/icon-doctor-dark-gray-bg.png";
import PhysicalTherapist from "../../styles/icons/cta/episode/icon-physical-therapist-dark-gray.png";
import WomensHealth from "../../styles/icons/cta/episode/icon-womens-health-dark-gray.png";
import HeroOrange from "../../styles/images/hero/bg-orange.jpg";
import CareStartsOnline from "../../styles/images/episode/care-starts-online.png";
import NoEpisodeResults from "../../styles/images/episode/no_episode.png";

// member health profile
import NoLabResults from "../../styles/images/no-lab-results.png";
import NoMedsOrDocs from "../../styles/images/no-meds-docs.png";

import useIconStyles from "utils/useIconStyles";

// Accounts Page
import AccountsMobile from "../../styles/images/account/icon-account-mobile.png";
import AccountBiometric from "../../styles/images/account/icon-account-biometric.png";
import AccountsBenefits from "../../styles/images/account/icon-benefits-mobile.png";
import AccountsMembership from "../../styles/images/account/icon-membership-mobile.png";
import AccountsNotifications from "../../styles/images/account/icon-notifications-mobile.png";
import AccountsPaymentMethods from "../../styles/images/account/icon-payment-mobile.png";
import AccountsPayments from "../../styles/images/account/icon-payments-main.png";
import AccountChevron from "../../styles/images/account/account-chevron.png";
import AccountBackIcon from "../../styles/images/account/account-back-icon.png";
import AccountLogoutIcon from "../../styles/images/account/logout-icon.png";
import AccountDependentsIcon from "styles/images/account/icon-dependents-mobile.png";

// Care Access Page
import CareSyringe from "styles/icons/care-access/syringe.svg";
import CareVirus from "styles/icons/care-access/virus.svg";
import CareBaggagePlane from "styles/icons/care-access/baggage-plane.svg";
import CareLabBottle from "styles/icons/care-access/lab-bottle.svg";
import CareWoman from "styles/icons/care-access/woman.svg";
import CareCalendarCheck from "styles/icons/care-access/calendar-check.svg";
import CalendarWarning from "styles/icons/care-access/calendar-warning.svg";
import CareStethoscope from "styles/icons/care-access/stethoscope.svg";
import CareNurse from "styles/icons/care-access/nurse.svg";
import CareBloodTest from "styles/icons/care-access/blood-test.svg";
import CarePreventive from "styles/icons/care-access/preventive.svg";
import CareBiometric from "styles/icons/care-access/biometric.svg";
import CarePassportGlobe from "styles/icons/care-access/passport-globe.svg";
import CareRehabilitation from "styles/icons/care-access/rehabilitation.svg";
import CareFirstAid from "styles/icons/care-access/first-aid.svg";
import CareWorker from "styles/icons/care-access/worker.svg";
import CareHeartWatch from "styles/icons/care-access/heart-watch.svg";
import CareSalad from "styles/icons/care-access/salad.svg";
import CareAcupuncturist from "styles/icons/care-access/acupuncturist.svg";
import CareKnee from "styles/icons/care-access/knee.svg";
import CareTherapy from "styles/icons/care-access/therapy.svg";
import CareLoveHeartHands from "styles/icons/care-access/love-heart-hands.svg";
import CarePeopleMeeting from "styles/icons/care-access/people-meeting.svg";
import CareSpinner from "styles/icons/care-access/spinner.svg";
import CareEyeCrying from "styles/icons/care-access/breakup-cry.svg";
import CareFitness from "styles/icons/care-access/fitness.svg";
import CareBrainHead from "styles/icons/care-access/brain-head.svg";
import CareRadiologyHand from "styles/icons/care-access/radiology-hand.svg";
import CareSkin from "styles/icons/care-access/skin.svg";
import CareDoctor from "styles/icons/care-access/doctor.svg";
import CareOptometrist from "styles/icons/care-access/services-optometrist.png";
import CareEyeChart from "styles/icons/care-access/optometrist.svg";
import CareEyeMedical from "styles/icons/care-access/eye-medical.svg";
import CareRunner from "styles/icons/care-access/runner.svg";
import CareCalendarGeneric from "styles/icons/care-access/calendar-generic.svg";
import CareFootstep from "styles/icons/care-access/footstep.svg";
import BubbleQuestion from "styles/icons/care-access/bubble-question.svg";
import CareConversationChat from "styles/icons/care-access/conversation-chat.svg";
import CareArrowForward from "styles/icons/care-access/arrow-forward.svg";
import CareInPersonCenter from "styles/icons/care-access/in-person-center.svg";
import CarePrimaryCare from "styles/icons/care-access/primary-care.png";
import CareMentalHealth from "styles/icons/care-access/mental-health.png";
import CarePhysicalTherapy from "styles/icons/care-access/physical-therapy.png";
import CareHealthCoaching from "styles/icons/care-access/health-coaching.png";
import CareAcupuncture from "styles/icons/care-access/acupuncture.png";
import CareFitnessCoach from "styles/icons/care-access/services-fitness.png";
import CareServicesFirstAid from "styles/icons/care-access/services-first-aid.png";
import CareVisitQuestion from "styles/icons/care-access/visit-question.svg";
import CarePregnancy from "styles/icons/care-access/pregnancy.svg";
import CareFamilyHeart from "styles/icons/care-access/family-heart.svg";
import CareNetwork from "styles/icons/care-access/network.svg";
import CareLungs from "styles/icons/care-access/lungs.svg";
import CareFertility from "styles/icons/care-access/fertility.svg";
import CareAllergy from "styles/icons/care-access/allergy.svg";
import CareWalk from "styles/icons/care-access/walk.svg";
import CarePosture from "styles/icons/care-access/posture.svg";
import CareEmptyState from "styles/icons/care-access/empty-state.svg";
import CareBriefCase from "styles/icons/care-access/get-care-briefcase.svg";
import CareScheduleCalendar from "styles/icons/care-access/schedule-calendar.svg";
import CareCurrentLocationModal from "styles/icons/care-access/current-location.svg";
import CareChiropractic from "styles/icons/care-access/chiropractor.png";
import CareDermatology from "styles/icons/care-access/dermatology.png";
import CareMassage from "styles/icons/care-access/massage.png";
import CareOccMed from "styles/icons/care-access/occupational-medicine.png";
import CarePsychiatry from "styles/icons/care-access/psychiatry.png";
import CareSportsMed from "styles/icons/care-access/sports-medicine.png";
import CareTravelMed from "styles/icons/care-access/travel-medicine.png";
import CareFluVaccines from "styles/icons/care-access/flu-vaccines.png";
import CarePharmacy from "styles/icons/care-access/pharmacy.png";
import CareVision from "styles/icons/care-access/vision-care.png";
import CareNVTCenter from "styles/icons/care-access/national-virtual-team.svg";
import CareDental from "styles/icons/care-access/dental.png";
import CarePediatrics from "styles/icons/care-access/pediatrics.png";
import CareAccessNavigator from "styles/icons/care-access/care-navigator.png";
import MissingPhoneModal from "styles/icons/svg/missing-number.png";

// Quick links
import QuickLinkLabs from "styles/icons/quick-links/lab-bottle.svg";
import QuickLinkConversations from "styles/icons/quick-links/conversations.svg";
import QuickLinkMedication from "styles/icons/quick-links/medication.svg";
import QuickLinkVisits from "styles/icons/quick-links/visits.svg";
import QuickLinkDirectMessage from "styles/icons/quick-links/direct-message.svg";

// TODO: Jesse will fix broken SVGs and incorporate them in ~styles/icons/icons.svg

/**
 * Mapping of names to their source files
 * NOTE: these don't necessarily map to the file names _verbatim_
 */
// ts] Type 'string' is not assignable to type 'Image'.
// web.tsx(45, 27): The expected type comes from this index signature.
// const mapNamesToImages: { [name: string]: Assets.Image } = {
const mapNamesToImages: { [name: string]: any } = {
  share: Share,
  communicate: Communicate,
  ["start-online"]: StartOnline,
  ["virtual-in-person"]: VirtualInPerson,
  ["no-results"]: NoResults,
  ["no-payment-history"]: NoPaymentHistory,
  ["no-payment-method"]: NoPaymentMethod,
  ["error-results"]: ErrorResults,
  rocket: Rocket,
  logo: Logo,
  logomark: Logomark,
  cooper: AvatarCooper,
  jay: AvatarJay,
  pingu: AvatarPingu,
  doctor: Doctor,
  conditions: Conditions,
  meds: Meds,
  labs: Labs,
  files: FilesPNG,
  photos: Photos,
  referrals: Referrals,
  imaging: Imaging,
  immunizations: Immunizations,
  ["family-history"]: FamilyHistory,
  ["create-episode"]: CreateEpisode,
  ["create-episode-cta"]: CreateEpisode,
  ["risk-assessment"]: RiskAssessment,
  ["risk-assessment-cta"]: RiskAssessment,
  ["attached-image"]: AttachedImage,
  ["physical-therapist"]: PhysicalTherapist,
  ["womens-health"]: WomensHealth,
  ["female-avatar"]: FelamePlaceHolder,
  ["male-avatar"]: MalePlaceHolder,
  ["placeholder"]: PlaceholderImage,
  ["generic-placeholder"]: GenericPlaceholder,
  ["team-clinic"]: TeamClinic,
  ["virtual-team-clinic"]: VirtualTeamClinic,
  defaultAvatar: AvatarDefault,
  medical: PrimaryCare,
  ["primary_care_center"]: PrimaryCare,
  ["mental_health"]: MentalHealth,
  ["mental_health_center"]: MentalHealth,
  ["eap"]: MentalHealth,
  ["physical_medicine"]: PhysicalMedicine,
  ["physical_medicine_center"]: PhysicalMedicine,
  ["sports_medicine"]: SportsMedicine,
  ["occupational_health"]: Occupational,
  ["first_aid"]: FirstAid,
  acupuncture: Acupuncture,
  chiropractic: Chiropractic,
  dental: Dental,
  dermatology: Dermatology,
  fitness: Fitness,
  ["fitness_apple"]: Fitness,
  pharmacy: Pharmacy,
  massage: Massage,
  ["massage_apple"]: Massage,
  optometry: Optometry,
  pediatrics: Pediatrics,
  psychiatry: Psychiatry,
  vsp: VisionCare,
  eaPrimaryCare: EAPrimaryCare,
  eaMentalHealth: EAMentalHealth,
  eaPhysicalTherapy: EAPhysicalTherapy,
  eaHealthCoaching: EAHealthCoaching,
  host: Host,
  ["hero-orange"]: HeroOrange,
  careStartsOnline: CareStartsOnline,
  ["care_navigation_stub"]: CareNavigator,
  ["health_coaching"]: HealthCoach,
  ["health_coaching_center"]: HealthCoach,
  ["nutrition_apple"]: HealthCoach,
  ["nurse_triage"]: NurseTriage,
  ["travel_medicine"]: TravelMedicine,
  ["flu_covid_only_cn"]: FluAndCovid,
  ["flu_covid_only_med"]: FluAndCovid,
  ["Preventive_Hero.png"]: PreventiveHero,
  ["Preventive_Spot.png"]: PreventiveSpot,
  ["Preventive_what.png"]: PreventiveWhat,
  ["Preventive_why.png"]: PreventiveWhy,
  ["Preventive_how.png"]: PreventiveHow,
  clinic: Clinic,
  ctaClinic: CtaClinic,
  noEpisodeResults: NoEpisodeResults,
  noLabResults: NoLabResults,
  noMedsOrDocs: NoMedsOrDocs,
  ["account-mobile"]: AccountsMobile,
  ["account-biometric-mobile"]: AccountBiometric,
  ["account-benefits-mobile"]: AccountsBenefits,
  ["account-membership-mobile"]: AccountsMembership,
  ["account-notifications-mobile"]: AccountsNotifications,
  ["account-payment-methods-mobile"]: AccountsPaymentMethods,
  ["account-payments-mobile"]: AccountsPayments,
  ["account-chevron-mobile"]: AccountChevron,
  ["account-back-icon"]: AccountBackIcon,
  ["logout-icon"]: AccountLogoutIcon,
  ["account-dependents-mobile"]: AccountDependentsIcon,
  MessageSent,
  QuestionSet,
  Calculator,
  Acknowledge,
  Refer,
  AwaitReply,
  DockRight,
  ["error-account"]: ErrorAccount,
  ["care-primary-care"]: CarePrimaryCare,
  ["care-vision"]: CareVision,
  ["care-mental-health"]: CareMentalHealth,
  ["care-dermatology"]: CareDermatology,
  ["care-physical-therapy"]: CarePhysicalTherapy,
  ["care-services-first-aid"]: CareServicesFirstAid,
  ["care-health-coaching"]: CareHealthCoaching,
  ["care-occupational"]: CareOccMed,
  ["care-acupuncture"]: CareAcupuncture,
  ["care-psychiatry"]: CarePsychiatry,
  ["care-fitness-coach"]: CareFitnessCoach,
  ["care-travel-med"]: CareTravelMed,
  ["care-chiropractic"]: CareChiropractic,
  ["care-dental"]: CareDental,
  ["care-massage"]: CareMassage,
  ["care-pediatrics"]: CarePediatrics,
  ["care-sports-med"]: CareSportsMed,
  ["care-optometrist"]: CareOptometrist,
  ["care-access-navigator"]: CareAccessNavigator,
  ["care-flu-vaccines"]: CareFluVaccines,
  ["care-pharmacy"]: CarePharmacy,
  ["primary_care_kaiser"]: PrimaryCare,
};

/**
 * Standalone SVGS: Can be used directly
 */
// const mapNamesToStandaloneSVGS: { [name: string]: Assets.SVG } = {
const mapNamesToStandaloneSVGS: { [name: string]: any } = {
  ["nav-chevron"]: NavChevron,
  ["nav-chevron-white"]: NavChevron,
  alert: Alert,
  wellness: Wellness,
  fasttrack: Fasttrack,
  ["fast-track"]: Fasttrack,
  search: Search,
  ["search-white"]: SearchWhite,
  ["files-svg"]: FilesSVG,
  ["cta-red"]: CTARed,
  ["cta-orange"]: CTAOrange,
  ["cta-gray"]: CTAGray,
  ["create-episode-of-care"]: CreateEpisodeSVG,
  ["edit"]: Edit,
  camera: Camera,
  phone: Phone,
  getCareNow: GetCareNowSVG,
  calendar: CalendarSVG,
  scheduleVisit: ScheduleVisitSVG,
  ["video-camera"]: VideoCamera,
  CareAnyTime,
  NoConvosOrVisits,
  Appointment,
  ManageDependents,
  ["labs-docs-meds"]: DocsMed,
  ["travel-med"]: TravelMed,
  ["phone-triage"]: PhoneTriage,
  ["preventive-badge"]: PreventiveBadge,
  ["active-account-badge"]: ActiveAccountBadge,
  ["account-badge"]: AccountBadge,
  ["active-benefits-badge"]: ActiveBenefitsBadge,
  ["benefits-badge"]: BenefitsBadge,
  ["active-payments-badge"]: ActivePaymentsBadge,
  ["payments-badge"]: PaymentsBadge,
  ["active-methods-badge"]: ActiveMethodsBadge,
  ["methods-badge"]: MethodsBadge,
  ["cloud-icon"]: CloudIcon,
  ["dependents-badge"]: DependentsIcon,
  ["quick-links-labs"]: QuickLinkLabs,
  ["quick-links-conversations"]: QuickLinkConversations,
  ["quick-links-medication"]: QuickLinkMedication,
  ["quick-links-visits"]: QuickLinkVisits,
  ["quick-links-direct-message"]: QuickLinkDirectMessage,
  Download,
  minorProxy: MinorProxy,
  RevokeProxy,
  HistoryLog,
  DockingIcon,
  ctmMessage,
  Review,
  ["care-syringe"]: CareSyringe,
  ["care-virus"]: CareVirus,
  ["care-baggage-plane"]: CareBaggagePlane,
  ["care-lab-tube-bottle"]: CareLabBottle,
  ["care-woman"]: CareWoman,
  ["care-calendar-check"]: CareCalendarCheck,
  ["care-stethoscope"]: CareStethoscope,
  ["care-nurse"]: CareNurse,
  ["care-blood-test"]: CareBloodTest,
  ["care-preventive"]: CarePreventive,
  ["care-biometric"]: CareBiometric,
  ["care-passport-globe"]: CarePassportGlobe,
  ["care-rehabilitation"]: CareRehabilitation,
  ["care-first-aid"]: CareFirstAid,
  ["care-worker"]: CareWorker,
  ["care-heart-watch"]: CareHeartWatch,
  ["care-salad"]: CareSalad,
  ["care-acupuncturist"]: CareAcupuncturist,
  ["care-knee"]: CareKnee,
  ["care-therapy"]: CareTherapy,
  ["care-love-heart-hands"]: CareLoveHeartHands,
  ["care-people-meeting"]: CarePeopleMeeting,
  ["care-spinner"]: CareSpinner,
  ["care-breakup-cry"]: CareEyeCrying,
  ["care-fitness"]: CareFitness,
  ["care-brain-head"]: CareBrainHead,
  ["care-radiology-hand"]: CareRadiologyHand,
  ["care-footstep"]: CareFootstep,
  ["care-skin"]: CareSkin,
  ["care-doctor"]: CareDoctor,
  ["care-eyechart"]: CareEyeChart,
  ["care-eye-medical"]: CareEyeMedical,
  ["care-runner"]: CareRunner,
  ["care-family-heart"]: CareFamilyHeart,
  ["care-network"]: CareNetwork,
  ["care-lungs"]: CareLungs,
  ["care-fertility"]: CareFertility,
  ["care-allergy"]: CareAllergy,
  ["care-walk"]: CareWalk,
  ["care-pregnancy"]: CarePregnancy,
  ["care-posture"]: CarePosture,
  ["care-calendar-generic"]: CareCalendarGeneric,
  ["moon-stars"]: AfterHoursMoonStars,
  ["no-appointments-with-background"]: NoAppointmentsWithBackground,
  ["bubble-question"]: BubbleQuestion,
  ["calendar-warning"]: CalendarWarning,
  ["care-conversation-chat"]: CareConversationChat,
  ["care-arrow-forward"]: CareArrowForward,
  ["care-in-person-center"]: CareInPersonCenter,
  ["care-visit-question"]: CareVisitQuestion,
  ["care-empty-state"]: CareEmptyState,
  ["care-brief-case"]: CareBriefCase,
  ["care-schedule-calendar"]: CareScheduleCalendar,
  ["current-location"]: CareCurrentLocationModal,
  ["care-nvt-center"]: CareNVTCenter,
  ["missing-number"]: MissingPhoneModal,
  Hold,
};

export interface IconProps {
  /** Name of an icon OR a font-awesome classname */
  name: Assets.Image | Assets.SVG | Assets.Icon | string;
  className?: string;
  /** Wrapper styles */
  style?: React.CSSProperties;
  // For accessibility
  title?: string;
  alt?: string;
  id?: string;
  hiddenFromScreenReader?: boolean;
}

/**
 * Icon and Image HoC - maps names to assets,
 * renders them as accessible img/svg tags
 */

// class Asset extends Component<IconProps> {
const Asset: React.FunctionComponent<IconProps> = ({
  name,
  className,
  style,
  title,
  alt,
  id,
  hiddenFromScreenReader = false,
}): ReactElement => {
  const iconStyles = useIconStyles();

  const updatedClassname = className?.includes("icon-")
    ? clsx(iconStyles.icon, className)
    : className;

  // Font Awesome - return <i> tag
  if (name?.startsWith("fa-") || name?.includes(" fa-")) {
    return <i className={`${name} ${updatedClassname || ""}`} title={title} />;
  }

  // Images - use img tag
  if (!!mapNamesToImages[name]) {
    return (
      <img
        src={mapNamesToImages[name]}
        className={updatedClassname}
        alt={alt}
        title={title}
        style={style}
        id={id}
        aria-hidden={hiddenFromScreenReader}
      />
    );
  }
  // Imported, standalone SVGs - can be used as img tags
  // (ugly, but the most stable solution w/o webpack magic)
  if (!!mapNamesToStandaloneSVGS[name]) {
    return (
      <img
        src={mapNamesToStandaloneSVGS[name]}
        className={updatedClassname}
        style={style}
        aria-label={title}
        alt={alt}
        id={id}
        aria-hidden={hiddenFromScreenReader}
      />
    );
  }
  // Default: Use an SVG "fragment" in ~styles/icons/icons.svg
  // this is our best long-term solution, takes advantage of b. caching
  return (
    <>
      <svg
        id={id}
        className={updatedClassname}
        style={style}
        aria-labelledby={alt}
        aria-hidden={hiddenFromScreenReader}
      >
        <use xlinkHref={`${SVGIcons}#${name}`} />
      </svg>
    </>
  );
};

export default Asset;

export const rocketImageSrc = Rocket;
export const messageSentSrc = MessageSent;
