import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useSearchParams } from "react-router-dom";
import { ACTIVE_PROVIDERS, ALL_PROVIDERS, ALL_TYPES } from "../utils";

interface IProps {
  serviceTypes: string[];
}

const SelectServiceType = ({ serviceTypes }: IProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const selectServiceTypeLabel = "View by service type";
  const providerId = searchParams.get("providerId");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams((params) => {
      const newValue = event.target.value;
      if (newValue === ALL_TYPES) {
        params.delete("serviceType");
      } else {
        params.set("serviceType", newValue);
      }
      return params;
    });
  };

  useEffect(() => {
    const allOrActive =
      providerId === ALL_PROVIDERS || providerId === ACTIVE_PROVIDERS
        ? true
        : false;
    const shouldDisable = Boolean(providerId) && !allOrActive;
    setIsDisabled(shouldDisable);
  }, [providerId]);

  useEffect(() => {
    setSearchParams((params) => {
      const isSingleProviderWeekView = params.get("view") === "timeGridWeek";
      params.delete("serviceType");
      !isSingleProviderWeekView && params.delete("providerId");
      return params;
    });
  }, []);

  return (
    <FormControl fullWidth>
      <TextField
        disabled={isDisabled}
        select
        label={selectServiceTypeLabel}
        defaultValue={ALL_TYPES}
        onChange={handleChange}
        value={searchParams.get("serviceType") || ALL_TYPES}
      >
        <MenuItem key={0} value={ALL_TYPES}>
          All service types
        </MenuItem>
        {serviceTypes?.map((option, i) => (
          <MenuItem key={i + 100} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectServiceType;
