const DATE_SIZE = 25;

export const datePickerStyles = {
  "& .MuiDateCalendar-root": {
    paddingLeft: "5px",
    height: 260,
  },
  "& .MuiDayCalendar-header": {
    width: "90%",
  },
  "& .MuiDayCalendar-weekContainer": {
    width: "90%",
  },
  "& .this-week": {
    border: "1px solid #606A76",
    borderRadius: "4px",
  },
  "& > div": {
    minWidth: 220,
  },
  "& > div > div, & > div > div > div, &.MuiPickersLayout-root": {
    width: 215,
    minWidth: 200,
  },
  "& > div > div, & > div > div > div, & .MuiCalendarPicker-root": {
    width: 215,
    height: 240,
  },
  "& .MuiTypography-caption": {
    width: DATE_SIZE,
    margin: 0,
  },
  "& .PrivatePickersSlideTransition-root": {
    minHeight: DATE_SIZE * 6,
  },
  '& .PrivatePickersSlideTransition-root [role="row"]': {
    margin: 0,
  },
  "& .MuiPickersDay-dayWithMargin": {
    margin: 0,
  },
  "& .MuiPickersDay-root": {
    width: DATE_SIZE,
    height: DATE_SIZE,
  },
  "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#1C74AB",
    color: "white",
    borderRadius: "4px",
  },
  ".MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin": {
    borderRadius: "4px",
  },
};
