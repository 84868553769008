import React, { FunctionComponent } from "react";
import classes from "./index.module.css";
import Typography from "components/Typography";
import Checkbox from "components/Checkbox";
import { RestrictedToEnum } from "shared/fetch/src/models/RestrictedToEnum";

interface IProps {
  value?: RestrictedToEnum;
  handleChange?: (event: any) => void;
  disabled?: boolean;
}

const RestrictToXOP: FunctionComponent<IProps> = ({
  value,
  handleChange,
  disabled,
}) => {
  const handleCheckboxChange = () => {
    const changeEvent = {
      target: {
        name: "restrictedTo",
        value:
          value === "virtual_visit_referral"
            ? "not_restricted"
            : "virtual_visit_referral",
      },
    };
    handleChange?.(changeEvent);
  };

  return (
    <>
      <Checkbox
        name="restrictedTo"
        color="primary"
        className={classes.checkbox}
        value={value}
        checked={value === "virtual_visit_referral"}
        aria-label="Restrict to XOP referrals only, hide for self schedule"
        onChange={handleCheckboxChange}
        disabled={disabled}
      />
      <Typography className={classes.restrictText}>
        {" "}
        Restrict to XOP referrals only (hide for Self Schedule)
      </Typography>
    </>
  );
};

export default RestrictToXOP;
