import React from "react";
import classes from "./index.module.css";
import { Button, Link } from "@mui/material";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { prepareAge } from "utils/calculateAge";
import useFormatDate from "shared/utils/useFormatDate";
import { getPronouns } from "components/Sogi/utils";
import { useSearchParams } from "react-router-dom";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import VisitNotesModal from "../../VisitNotes/VisitNotesModal";

interface IProps {
  appointment: AppointmentOutput;
  appointmentType?: string;
  key: number;
  disabled?: boolean;
  setEditAMember: React.Dispatch<React.SetStateAction<boolean>>;
  setVisitReason?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCancelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduledMember = ({
  appointment,
  appointmentType,
  key,
  setEditAMember,
  setVisitReason,
  setCancelModalOpen,
  disabled = false,
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const memberInfo = useGetAccountInfo({
    id: appointment.patientId.toString() ?? "",
  });
  const accountInfo = memberInfo.accountInfo;
  const [open, setOpen] = React.useState(false);

  const age = accountInfo.age ? prepareAge(accountInfo.age) : "";
  const sexAtBirth = accountInfo.sexAtBirth;
  const cellPhone = accountInfo.cellPhone;
  const formatDate = useFormatDate();

  const memberBirthdate = formatDate(accountInfo.dob, "MM/dd/yyyy", {
    fallbackDate: "",
    showAsTodayIfSameDay: false,
    isDoB: true,
  });

  const memberPronouns = accountInfo.pronouns
    ? getPronouns(accountInfo.pronouns, true)
    : undefined;

  const caption = `${
    memberPronouns ? `${memberPronouns} |` : ""
  } ${memberBirthdate} | ${age} | ${sexAtBirth}`;

  const handleRemoveMember = () => {
    // the cancel modal can be opened for a visit that has multiple
    // members scheduled, so we have to write the member id to params so that
    // the cancel modal know which appointment on the slot it should cancel
    setCancelModalOpen(true);
    accountInfo?.id &&
      searchParams.append("member", accountInfo?.id.toString());
    searchParams.append("appointmentId", appointment.id.toString());
    setSearchParams(searchParams);
  };

  const handleViewVistNote = () => {
    setOpen(true);
  };

  const handleEditMember = () => {
    setEditAMember(true);
    accountInfo?.id &&
      searchParams.append("member", accountInfo?.id.toString());
    searchParams.append("appointmentId", appointment.id.toString());
    setSearchParams(searchParams);
    setVisitReason && setVisitReason(appointment.reason);
  };

  return (
    <>
      <VisitNotesModal
        open={open}
        onClose={() => setOpen(false)}
        appointment={appointment}
        appointmentType={appointmentType}
      />
      <div className={classes.memberInformationContainer}>
        <div
          className={classes.memberInformation}
          key={key}
          data-testid="scheduled-member"
        >
          <div className={classes.memberInfoTitle}>
            {" "}
            <Link
              href={
                appointment.patientId
                  ? `/members/${encodeURIComponent(
                      appointment.patientId?.toString()
                    )}/demographics`
                  : ""
              }
            >
              {appointment.patientInfo?.name}
            </Link>{" "}
            <div style={{ paddingRight: "12px" }}>
              <Button
                color="inherit"
                size="small"
                sx={{ minWidth: "0px", padding: "0px" }}
                onClick={handleViewVistNote}
                data-testid="visit-note-icon"
                role="button"
                aria-label="View visit note"
              >
                {" "}
                <StickyNote2Icon />{" "}
              </Button>{" "}
              <Button
                color="inherit"
                size="small"
                sx={{ minWidth: "0px", padding: "0px" }}
                onClick={handleRemoveMember}
                aria-label="Remove member"
                disabled={disabled}
              >
                {" "}
                <EventBusyIcon />{" "}
              </Button>{" "}
              <Button
                color="inherit"
                size="small"
                sx={{ minWidth: "0px", padding: "0px" }}
                onClick={handleEditMember}
                aria-label="Edit member"
                disabled={disabled}
              >
                {" "}
                <ModeEditIcon />{" "}
              </Button>{" "}
            </div>
          </div>
          <p>{caption}</p>
          <p>{cellPhone}</p>

          <>
            {" "}
            <div
              className={classes.visitTypeTitle}
              data-testid={"visit-type-title"}
            >
              {appointmentType}
            </div>
            <div>Reason for visit: {appointment.reason || "Not provided"}</div>{" "}
          </>
        </div>
      </div>
    </>
  );
};

export default ScheduledMember;
