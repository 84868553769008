import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import {
  DeleteSlotRequest,
  fetchSlotsApi,
} from "shared/fetch/src/apis/SlotsApi";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

type DeleteSlotMutationFn = (
  requestParameters: DeleteSlotRequest
) => Promise<void>;

const useDeleteSlot = (
  config?: UseMutationOptions<void, Error, DeleteSlotRequest>
): UseMutationResult<void, Error, DeleteSlotRequest> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const mutationFn: DeleteSlotMutationFn = (
    requestParameters: DeleteSlotRequest
  ) => {
    return fetchSlotsApi.deleteSlot(requestParameters) as Promise<void>;
  };

  const user = useSelector(getUser) as JsonUser;
  if (!user?.hasFullXoCalPermissions) {
    return useMutation<void, Error, DeleteSlotRequest>(
      () => {
        throw new Error("You do not have permission to delete slots");
      },
      {
        onError: () => {
          showErrorStatusSnackbar("You do not have permission to delete slots");
        },
      }
    );
  }

  const defaultConfig: UseMutationOptions<void, Error, DeleteSlotRequest> = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Slot has been successfully deleted.");
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to delete the slot.");
    },
    ...config,
  };

  return useMutation<void, Error, DeleteSlotRequest>(mutationFn, defaultConfig);
};

export default useDeleteSlot;
