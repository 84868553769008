import makeStyles from "@mui/styles/makeStyles";
import Typography from "components/Typography";
import React, { useState } from "react";
import useFormatDate from "shared/utils/useFormatDate";
import StripeProvider from "components/StripeProvider";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DailyScheduleResponseAppointments } from "shared/fetch/src/models/DailyScheduleResponseAppointments";
import clsx from "clsx";
import { getPronouns } from "components/Sogi/utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandedMemberDetails from "../ExpandedMemberDetails";
import ButtonBase, { Link } from "components/Button";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import useGetAllAPI from "features/payments/utils/useGetAllAPI";
import useGetPaymentsHistory from "shared/features/payments/paymentsHistory/useGetPaymentsHistory";
import { GetPaymentHistoryStatusEnum } from "shared/fetch/src/apis/MemberPaymentsApi";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import ProcessPaymentModal from "../ProcessPaymentModal";
import PaymentsOwedModal from "../PaymentsOwedModal";
import AppointmentActionsButtonGroup from "../AppointmentActionsButtonGroup";
export interface IAppointmentRow {
  appointment: DailyScheduleResponseAppointments;
  isLastRow?: boolean;
  setOpenPaymentsOwed: any;
  openPaymentsOwed: any;
  providerName?: string;
  site: string;
  date: string;
}

export const useAppointmentRowGridStyles = makeStyles(({ breakpoints }) => ({
  appointmentGrid: {
    display: "grid",
    gridTemplateColumns: "1.2fr 2.4fr 1.9fr 1.5fr 0.3fr 1.1fr",
    gap: "16px",
    [breakpoints.down("lg")]: {
      gap: "12px",
    },
  },
}));

const useStyles = makeStyles(({ palette, spacing }) => ({
  arrow: {
    fill: palette?.text?.secondary,
    height: spacing(3),
    width: spacing(3),
  },
  arrowSmall: {
    fill: palette?.text?.secondary,
    height: spacing(2.5),
    width: spacing(2.5),
  },
  appointmentColumn: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(1),
  },
  appointmentColumnBorder: {
    borderBottom: `1px solid ${palette?.text?.strokes}`,
  },
  timeColumn: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: spacing(0.5),
  },
  visitTypeColumn: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: spacing(2),
  },
  virtualVisitIcon: {
    width: "16px",
  },
  linkToProfile: {
    textDecoration: "none !important",
  },
  linkText: {
    color: palette?.text?.link,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  linkToPayments: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
  },
  moneyIcon: {
    fill: palette?.text?.secondary,
    padding: spacing(0.25),
  },
  noUnderline: {
    "& .MuiTypography-root": {
      textDecoration: "none !important",
    },
    "& .MuiTypography-root": {
      textDecoration: "underline !important",
      textUnderlineOffset: "2.5px",
    },
  },
}));

const truncatePronouns = (pronouns: string) => {
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const [subjectPronoun, objectPronoun, _possessivePronoun] =
    pronouns.split(" / ");
  return `${subjectPronoun} / ${objectPronoun}`;
};

const getAppointmentSummary = (
  appointment: DailyScheduleResponseAppointments
) => {
  if (appointment.summarySub) {
    return appointment.summarySub.replace(/\(.*\)/g, "");
  } else {
    return appointment.summary;
  }
};

const AppointmentRow: React.FC<IAppointmentRow> = ({
  appointment,
  isLastRow,
  providerName,
  site,
  date,
}) => {
  const gridClasses = useAppointmentRowGridStyles();
  const classes = useStyles();
  const [isProcessPaymentOpen, setIsProcessPaymentOpen] = useState(false);
  const [openPaymentsOwed, setOpenPaymentsOwed] = useState(false);
  const { methods } = useGetAllAPI(appointment.patientId?.toString() || "");
  const formatDate = useFormatDate();

  const memberPronouns = appointment?.pronouns
    ? getPronouns(appointment?.pronouns, true)
    : undefined;
  const truncatedPronouns = memberPronouns
    ? truncatePronouns(memberPronouns)
    : null;
  const [memberAge, memberSex] = appointment.sexage?.split(" ") || [];
  const hasMemberAge = !!memberAge;
  const hasMemberSex = !!memberSex;
  const isCopayPaid = appointment.copayCharge?.status === "paid";
  //object to pass as prop to child components
  const memberSummary = {
    memberName: appointment?.legalAndPreferredName || appointment?.name || "",
    memberPronouns: memberPronouns ? `${truncatedPronouns} | ` : "",
    memberDob: appointment?.dob ? `${appointment?.dob} | ` : "",
    memberAge: hasMemberAge ? `${memberAge} | ` : "",
    memberSex: hasMemberSex ? `${memberSex}` : "",
    memberPhone: appointment?.cellPhone || "",
    summary: `${appointment?.summarySub || appointment?.summary}`,
    dateTime: formatDate(
      appointment.appointmentAt,
      "MMMM d, yyyy @ h:mmaaa zzz"
    ),
  };
  const apptTime = formatDate(appointment.appointmentAt, "h:mmaaa zzz");
  // temporary const value until expand/collapse is implemented
  const isExpanded = true;
  const isDesktopView = useMediaQuery("@media (min-width: 992px)");
  const isCopaySet =
    appointment?.copayCharge?.status === "set" ||
    appointment?.copayCharge?.status === "set_by_ctm";
  const showModified =
    isCopaySet &&
    appointment?.copayCharge?.createdAt !== appointment?.copayCharge?.updatedAt;
  const copayAmount = appointment?.copayCharge?.amount;
  const copayInDollars = copayAmount ? copayAmount / 100 : 0;

  const amountLabel = () => {
    if (isCopaySet) {
      if (showModified) {
        return "$" + copayInDollars + " (updated)";
      }
      return appointment?.owed;
    } else if (appointment?.copayCharge?.status?.includes("unpaid")) {
      return "$" + copayInDollars + " (unpaid)";
    }
  };

  const { unpaidItemsCount } = useGetPaymentsHistory({
    id: appointment?.patientId?.toString(),
    page: "1",
    status: GetPaymentHistoryStatusEnum.Unpaid,
  });

  return (
    <>
      <div
        data-testid="ds-appointment-row"
        className={clsx(
          gridClasses.appointmentGrid,
          classes.appointmentColumn,
          !isExpanded && !isLastRow && classes.appointmentColumnBorder
        )}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <KeyboardArrowUpIcon className={classes.arrowSmall} />
          </div>
          <Typography
            appearance="smallBody"
            color="textSecondary"
            className={classes.timeColumn}
          >
            <div>{apptTime}</div>
            <div>{appointment?.duration} min</div>
          </Typography>
        </div>
        <div>
          <Link
            color="primary"
            inline
            className={classes.linkToProfile}
            to={`/members/${appointment?.userId}?b=Schedule`}
          >
            <Typography
              appearance="smallBody"
              fontWeightBold
              className={classes.linkText}
            >
              {appointment?.legalAndPreferredName}
            </Typography>
          </Link>
          {!isDesktopView && memberPronouns && (
            <Typography appearance="smallBody" color="textSecondary">
              {truncatedPronouns}
            </Typography>
          )}
          <Typography appearance="smallBody" color="textSecondary">
            {isDesktopView && (
              <>{memberPronouns ? `${truncatedPronouns} | ` : ""}</>
            )}
            {appointment?.dob && `${appointment?.dob} | `}
            {hasMemberAge && `${memberAge} | `}
            {hasMemberSex && `${memberSex}`}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {appointment?.cellPhone}
          </Typography>
        </div>
        <div className={classes.visitTypeColumn}>
          <Typography
            appearance="smallBody"
            color="textSecondary"
            fontWeightBold
            textDecorationUnderline
          >
            {getAppointmentSummary(appointment)}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <ButtonBase
            style={{
              minWidth: 0,
              alignItems: "flex-start",
              paddingRight: "4px",
              paddingTop: 0,
            }}
            onClick={() => setOpenPaymentsOwed(true)}
          >
            {unpaidItemsCount > 0 && (
              <MonetizationOnIcon
                className={classes.moneyIcon}
                fontSize="small"
                data-e2e="payments-owed-btn"
                aria-label="Show all payments owed"
                aria-hidden="false"
              />
            )}
          </ButtonBase>
          <div>
            {isCopayPaid ? (
              <Link
                className={classes.linkToPayments}
                to={`/members/${appointment?.userId}/payments`}
              >
                <Typography
                  appearance="smallBody"
                  color="textSecondary"
                  fontWeightBold
                  textDecorationUnderline
                >
                  Paid
                </Typography>
              </Link>
            ) : (
              <ButtonStyledAsLink
                onClick={() => setIsProcessPaymentOpen(true)}
                className={classes.noUnderline}
              >
                <Typography
                  appearance="smallBody"
                  color="textSecondary"
                  textDecorationUnderline
                >
                  {amountLabel()}
                </Typography>
              </ButtonStyledAsLink>
            )}
            <Typography appearance="smallBody" color="textSecondary">
              {appointment?.insurancePlan}
            </Typography>
            <Typography appearance="smallBody" color="textSecondary">
              HRA Balance: {appointment?.hraBalance}
            </Typography>
          </div>
        </div>
        <div>{/* placeholder for notes icon column */}</div>
        <div style={{ maxWidth: "100%" }}>
          <AppointmentActionsButtonGroup
            appointment={appointment}
            site={site}
            date={date}
            providerNameLabel={providerName}
            memberSummary={memberSummary}
          />
        </div>
      </div>
      {isExpanded && (
        <ExpandedMemberDetails
          appointment={appointment}
          isLastRow={isLastRow}
        />
      )}
      {isProcessPaymentOpen && (
        <StripeProvider>
          <ProcessPaymentModal
            memberId={appointment.patientId?.toString()}
            charge={appointment.copayCharge}
            amount={appointment?.copayCharge?.amount}
            onClose={() => setIsProcessPaymentOpen(false)}
            memberSummary={memberSummary}
            methods={methods}
            providerName={providerName}
            appointmentId={appointment.id}
            site={site}
            date={date}
          />
        </StripeProvider>
      )}

      {openPaymentsOwed && (
        <PaymentsOwedModal
          open={openPaymentsOwed}
          onClose={() => setOpenPaymentsOwed(false)}
          memberId={appointment?.patientId}
          appointment={appointment}
        />
      )}
    </>
  );
};

export default AppointmentRow;
