// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    XOCalProvider,
    XOCalProviderFromJSON,
    XOCalProviderToJSON,
} from './XOCalProvider';


/**
 * 
 * @export
 * @interface CalendarStaticDataResponseClinicDetailsProvidersByServiceType
 */
export interface CalendarStaticDataResponseClinicDetailsProvidersByServiceType  {
    /**
     * 
     * @type {string}
     * @memberof CalendarStaticDataResponseClinicDetailsProvidersByServiceType
     */
    serviceType?: string;
    /**
     * 
     * @type {Array<XOCalProvider>}
     * @memberof CalendarStaticDataResponseClinicDetailsProvidersByServiceType
     */
    providers?: Array<XOCalProvider>;
}

export function CalendarStaticDataResponseClinicDetailsProvidersByServiceTypeFromJSON(json: any): CalendarStaticDataResponseClinicDetailsProvidersByServiceType {
    return {
        'serviceType': !exists(json, 'service_type') ? undefined : json['service_type'],
        'providers': !exists(json, 'providers') ? undefined : (json['providers'] as Array<any>).map(XOCalProviderFromJSON),
    };
}

export function CalendarStaticDataResponseClinicDetailsProvidersByServiceTypeToJSON(value?: CalendarStaticDataResponseClinicDetailsProvidersByServiceType): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'service_type': value.serviceType,
        'providers': value.providers === undefined ? undefined : (value.providers as Array<any>).map(XOCalProviderToJSON),
    };
}


