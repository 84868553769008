import { useQuery } from "react-query";
import {
  fetchProviderOooNotificationApi,
  GetOutOfOfficeNotificationRequest,
} from "shared/fetch/src/apis";
import { OOONotificationsArray } from "shared/fetch/src/models/OOONotificationsArray";

export const getFetchOOOQueryKey = (
  requestParameters: GetOutOfOfficeNotificationRequest
) => {
  return ["oooNotifications", ...Object.entries(requestParameters)];
};

const useGetOOO = (requestParameters: GetOutOfOfficeNotificationRequest) => {
  return useQuery(
    getFetchOOOQueryKey(requestParameters),
    () =>
      fetchProviderOooNotificationApi.getOutOfOfficeNotification(
        requestParameters
      ) as Promise<OOONotificationsArray>
  );
};

export default useGetOOO;
