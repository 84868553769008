// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientSearchResultDetails
 */
export interface PatientSearchResultDetails  {
    /**
     * 
     * @type {number}
     * @memberof PatientSearchResultDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    age?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    cellPhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    registered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    isVip?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    minor?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatientSearchResultDetails
     */
    dob?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatientSearchResultDetails
     */
    bornAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    outsideId?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientSearchResultDetails
     */
    patientId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    asleep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    inactive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    ineligible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    traveler?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    client?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    accountLocked?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientSearchResultDetails
     */
    xoid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientSearchResultDetails
     */
    migratedToXop?: boolean;
}

export function PatientSearchResultDetailsFromJSON(json: any): PatientSearchResultDetails {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'isVip': !exists(json, 'is_vip') ? undefined : json['is_vip'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'dob': !exists(json, 'dob') ? undefined : new Date(json['dob']),
        'bornAt': !exists(json, 'born_at') ? undefined : new Date(json['born_at']),
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'outsideId': !exists(json, 'outside_id') ? undefined : json['outside_id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'asleep': !exists(json, 'asleep') ? undefined : json['asleep'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
        'ineligible': !exists(json, 'ineligible') ? undefined : json['ineligible'],
        'traveler': !exists(json, 'traveler') ? undefined : json['traveler'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'accountLocked': !exists(json, 'account_locked') ? undefined : json['account_locked'],
        'xoid': !exists(json, 'xoid') ? undefined : json['xoid'],
        'migratedToXop': !exists(json, 'migrated_to_xop') ? undefined : json['migrated_to_xop'],
    };
}

export function PatientSearchResultDetailsToJSON(value?: PatientSearchResultDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'first_name': value.firstName,
        'preferred_name': value.preferredName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'last_name': value.lastName,
        'age': value.age,
        'gender': value.gender,
        'sex_at_birth': value.sexAtBirth,
        'cell_phone': value.cellPhone,
        'registered': value.registered,
        'is_vip': value.isVip,
        'minor': value.minor,
        'dob': value.dob === undefined ? undefined : value.dob.toISOString().substr(0,10),
        'born_at': value.bornAt === undefined ? undefined : value.bornAt.toISOString().substr(0,10),
        'employee_id': value.employeeId,
        'outside_id': value.outsideId,
        'patient_id': value.patientId,
        'asleep': value.asleep,
        'inactive': value.inactive,
        'ineligible': value.ineligible,
        'traveler': value.traveler,
        'client': value.client,
        'account_locked': value.accountLocked,
        'xoid': value.xoid,
        'migrated_to_xop': value.migratedToXop,
    };
}


