import React, { useCallback, useState } from "react";
import DeleteOOOConfirmationDialog from "./DeleteOOOConfirmationDialog";
import useDeleteOOO from "shared/features/xocal/useDeleteOOO";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";

interface OOOInfo {
  id: string;
  start: string;
  end: string;
  providerId: string;
}

export interface OOOBadgeProps {
  width: string;
  providerId: string;
  date: Date;
  el: HTMLElement;
  oooInfo: OOOInfo;
  allProviderResources: XOCalProvider[];
}

export const OOOBadge: React.FC<OOOBadgeProps> = ({
  width,
  providerId,
  oooInfo,
  allProviderResources,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { mutateAsync: deleteOOO } = useDeleteOOO();

  const allProviderInformation = allProviderResources.find((prov) => {
    return prov.providerId === oooInfo.providerId;
  });

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    await deleteOOO({ id: oooInfo.id });
    handleCloseDialog();
  }, [deleteOOO, oooInfo.id, handleCloseDialog]);

  return (
    <>
      <button
        className="ooo-badge-container"
        style={{
          position: "absolute",
          top: 0,
          width,
          cursor: "pointer",
          background: "none",
          border: "none",
          padding: 0,
          textAlign: "left",
          zIndex: 100,
        }}
        onClick={handleOpenDialog}
      >
        <div className="ooo-badge">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M6.70667 12.0007L8.33333 10.374L9.96 12.0007L10.6667 11.294L9.04 9.66732L10.6667 8.04065L9.96 7.33398L8.33333 8.96065L6.70667 7.33398L6 8.04065L7.62667 9.66732L6 11.294L6.70667 12.0007ZM13.1667 2.66732H12.5V1.33398H11.1667V2.66732H5.83333V1.33398H4.5V2.66732H3.83333C3.09333 2.66732 2.50667 3.26732 2.50667 4.00065L2.5 13.334C2.5 14.0673 3.09333 14.6673 3.83333 14.6673H13.1667C13.9 14.6673 14.5 14.0673 14.5 13.334V4.00065C14.5 3.26732 13.9 2.66732 13.1667 2.66732ZM13.1667 13.334H3.83333V6.00065H13.1667V13.334Z"
              fill="#2B3949"
            />
          </svg>
          <div className="ooo-badge-words">
            <p className="ooo-words-dark">Out of Office</p>
            <p className="ooo-words-light">All day</p>
          </div>
        </div>
      </button>
      <DeleteOOOConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        provider={providerId}
        startDate={oooInfo.start}
        endDate={oooInfo.end}
        allProviderInformation={allProviderInformation}
      />
    </>
  );
};
