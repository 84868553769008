// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from './SlotOutput';


/**
 * 
 * @export
 * @interface GetSlotsForDateOutputWithArray
 */
export interface GetSlotsForDateOutputWithArray  {
    /**
     * 
     * @type {Array<SlotOutput>}
     * @memberof GetSlotsForDateOutputWithArray
     */
    results?: Array<SlotOutput>;
    /**
     * 
     * @type {number}
     * @memberof GetSlotsForDateOutputWithArray
     */
    conflicts?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSlotsForDateOutputWithArray
     */
    shape?: string;
}

export function GetSlotsForDateOutputWithArrayFromJSON(json: any): GetSlotsForDateOutputWithArray {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(SlotOutputFromJSON),
        'conflicts': !exists(json, 'conflicts') ? undefined : json['conflicts'],
        'shape': !exists(json, 'shape') ? undefined : json['shape'],
    };
}

export function GetSlotsForDateOutputWithArrayToJSON(value?: GetSlotsForDateOutputWithArray): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(SlotOutputToJSON),
        'conflicts': value.conflicts,
        'shape': value.shape,
    };
}


