import React from "react";
import { ListItemButton, TextField } from "@mui/material";
import MenuList from "components/Menu/MenuList";
import { ProviderMenuItem } from "./SlotActionDrawer/ProviderMenuItem";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";
import { addDays, isWithinInterval } from "date-fns";
import { useGetProviders } from "shared/features/xocal/useGetProviders";

interface IProps {
  handleClose: () => void;
  openDrawer: (arg0: boolean) => void;
  menuRef: React.RefObject<HTMLUListElement>;
  oooDays: Set<{
    start: "";
    end: "";
    provider: "";
  }>;
  isClinicOpen: boolean;
}

const ProviderSearch = (props: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const clinicId = searchParams.get("clinicId") as string;
  const date = searchParams.get("date");
  const providers = useGetProviders(clinicId);

  providers.sort((a, b) => {
    if (a.flat && b.flat) {
      if (a.flat < b.flat) {
        return -1;
      }
      if (a.flat > b.flat) {
        return 1;
      }
    }
    return 0;
  });

  const [searchedProviders, setSearchedProviders] =
    React.useState<XOCalProvider[]>(providers);

  const handleSelectProvider = (providerId: string) => {
    searchParams.delete("slotActionDrawerProvider");
    searchParams.append("slotActionDrawerProvider", providerId);
    setSearchParams(searchParams);
    props.openDrawer(true);
    props.handleClose();
  };

  const handleSearch = (input: string) => {
    setSearchedProviders(
      providers?.filter((provider) =>
        provider?.name?.toLowerCase().includes(input.toLowerCase())
      )
    );
  };

  const disableProvider = (providerItem: XOCalProvider) => {
    const oooArray = Array.from(props.oooDays);

    return oooArray.some((day) => {
      return (
        date &&
        isWithinInterval(new Date(date), {
          start: new Date(day.start),
          end: addDays(new Date(day.end), 1),
        }) &&
        day.provider === providerItem.providerId
      );
    });
  };

  return (
    <div>
      <TextField
        size={"small"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{
          backgroundColor: "#F3F6F8",
          borderRadius: "8px",
          margin: "12px 24px",
          width: "85%",
        }}
        aria-label={"Search provider"}
        placeholder={"Search provider"}
        name={"Search provider"}
        onChange={(event) => handleSearch(event.target.value)}
      />
      <MenuList
        tabIndex={-1}
        ref={props.menuRef}
        data-testid="select-provider-menu"
        style={{ overflow: "scroll", height: "13rem", paddingTop: "0px" }}
      >
        {searchedProviders?.map((provider: XOCalProvider, index: number) => {
          return (
            <ListItemButton
              key={index}
              onClick={() => handleSelectProvider(provider?.providerId!)}
              data-testid={`provider-button-${index}`}
              disabled={disableProvider(provider) || !props.isClinicOpen}
            >
              <ProviderMenuItem
                title={provider?.name!}
                avatarUrl={provider?.avatarUrl!}
                type={provider?.serviceType!}
              />
            </ListItemButton>
          );
        })}
      </MenuList>
    </div>
  );
};

export default ProviderSearch;
