import classes from "./index.module.css";
import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { format } from "date-fns";
import { timezoneOptions } from "shared/utils/timeZone";
import { inputSxProps, ValuesType } from "./utils";
import { useGetActiveEpisodes } from "shared/state/activeEpisodes/useGetActiveEpisodes";
import { useSearchParams } from "react-router-dom";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";

const LinkAConversation = ({
  timezone,
  setField,
  values,
}: {
  timezone: string;
  setField: (field: string, value: any) => void;
  values: ValuesType;
}) => {
  const DEFAULT_VALUE = "Start a new conversation";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField("episodeId", event.target.value);
  };

  const [searchParams] = useSearchParams();

  const [orderedActiveEpisodes, setOrderedActiveEpisodes] = React.useState<
    EpisodeSummary[] | undefined
  >();

  const { activeEpisodes } = useGetActiveEpisodes(searchParams.get("member")!);

  React.useEffect(() => {
    if (activeEpisodes?.length) {
      setOrderedActiveEpisodes(activeEpisodes.reverse());
    }
  }, [activeEpisodes]);

  return (
    <div>
      <p
        className={classes.linkAConvoText}
        id="link-a-conversation-description"
      >
        Link it to an existing active conversation below. If nothing is selected
        or the Member has none, it will start a new one.
      </p>
      <FormControl fullWidth className={classes.linkAConvoDropdown}>
        <TextField
          sx={inputSxProps}
          disabled={activeEpisodes?.length === 0 || !activeEpisodes}
          select
          inputProps={{
            "aria-label": "Link visit to conversation",
            "aria-describedby": "link-a-conversation-description",
          }}
          onChange={handleChange}
          defaultValue={DEFAULT_VALUE}
          value={values.episodeId}
          size="small"
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 137,
                  overflowY: "auto",
                },
              },
            },
          }}
        >
          <MenuItem key={0} value={DEFAULT_VALUE}>
            {DEFAULT_VALUE}
          </MenuItem>

          {orderedActiveEpisodes &&
            orderedActiveEpisodes.map((option, i) => (
              <MenuItem
                key={i + 100}
                value={option.id}
                data-testid={`option-${option.id}`}
              >
                {`${option.serviceSubtype} (${
                  option.addedAt &&
                  format(new Date(option.addedAt), "PP @ h:mm a")
                } ${
                  timezoneOptions.find((option) => option.timezone === timezone)
                    ?.name ?? timezone
                })`}
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default LinkAConversation;
