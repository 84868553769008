// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CtmPodAssignment
 */
export interface CtmPodAssignment  {
    /**
     * 
     * @type {number}
     * @memberof CtmPodAssignment
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CtmPodAssignment
     */
    ctmId?: number;
    /**
     * 
     * @type {number}
     * @memberof CtmPodAssignment
     */
    xopPodId?: number;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    podName?: string;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    clinicId?: string;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    ctmName?: string;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    isPublic?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmPodAssignment
     */
    clinicalRoles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    staffGlobalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignment
     */
    gone?: string;
}

export function CtmPodAssignmentFromJSON(json: any): CtmPodAssignment {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ctmId': !exists(json, 'ctm_id') ? undefined : json['ctm_id'],
        'xopPodId': !exists(json, 'xop_pod_id') ? undefined : json['xop_pod_id'],
        'podName': !exists(json, 'pod_name') ? undefined : json['pod_name'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'ctmName': !exists(json, 'ctm_name') ? undefined : json['ctm_name'],
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'clinicalRoles': !exists(json, 'clinical_roles') ? undefined : json['clinical_roles'],
        'staffGlobalId': !exists(json, 'staff_global_id') ? undefined : json['staff_global_id'],
        'gone': !exists(json, 'gone') ? undefined : json['gone'],
    };
}

export function CtmPodAssignmentToJSON(value?: CtmPodAssignment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'ctm_id': value.ctmId,
        'xop_pod_id': value.xopPodId,
        'pod_name': value.podName,
        'clinic_id': value.clinicId,
        'email': value.email,
        'ctm_name': value.ctmName,
        'is_public': value.isPublic,
        'clinical_roles': value.clinicalRoles,
        'staff_global_id': value.staffGlobalId,
        'gone': value.gone,
    };
}


