/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConflictResponse,
    ConflictResponseFromJSON,
    ConflictResponseToJSON,
} from '../models/ConflictResponse'
import {
    CreateSlotSeries,
    CreateSlotSeriesFromJSON,
    CreateSlotSeriesToJSON,
} from '../models/CreateSlotSeries'
import {
    CreateSlotSeriesRequestBody,
    CreateSlotSeriesRequestBodyFromJSON,
    CreateSlotSeriesRequestBodyToJSON,
} from '../models/CreateSlotSeriesRequestBody'
import {
    DeleteSlotSeries,
    DeleteSlotSeriesFromJSON,
    DeleteSlotSeriesToJSON,
} from '../models/DeleteSlotSeries'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    SlotSeriesDeleteResponse,
    SlotSeriesDeleteResponseFromJSON,
    SlotSeriesDeleteResponseToJSON,
} from '../models/SlotSeriesDeleteResponse'
import {
    SlotSeriesOutput,
    SlotSeriesOutputFromJSON,
    SlotSeriesOutputToJSON,
} from '../models/SlotSeriesOutput'
import {
    SlotSeriesUpdateResponseUnion,
    SlotSeriesUpdateResponseUnionFromJSON,
    SlotSeriesUpdateResponseUnionToJSON,
} from '../models/SlotSeriesUpdateResponseUnion'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateSlotSeries,
    UpdateSlotSeriesFromJSON,
    UpdateSlotSeriesToJSON,
} from '../models/UpdateSlotSeries'


export interface CreateSlotSeriesRequest {
    createSlotSeriesRequestBody?: CreateSlotSeriesRequestBody;
}
export interface DeleteSlotSeriesRequest {
    id: string;
    dryRun?: DeleteSlotSeriesDryRunEnum;
    deleteSlotSeries?: DeleteSlotSeries;
}
export interface GetSlotSeriesByIDRequest {
    id: string;
}
export interface ReplicateSingleSlotIntoSeriesRequest {
    id: string;
    createSlotSeries?: CreateSlotSeries;
}
export interface UpdateSlotSeriesRequest {
    id: string;
    dryRun?: UpdateSlotSeriesDryRunEnum;
    updateSlotSeries?: UpdateSlotSeries;
}


export interface ISlotSeriesApi {
      createSlotSeries: (requestParameters: CreateSlotSeriesRequest) => Promise<SlotSeriesOutput>
      deleteSlotSeries: (requestParameters: DeleteSlotSeriesRequest) => Promise<SlotSeriesDeleteResponse>
      getSlotSeriesByID: (requestParameters: GetSlotSeriesByIDRequest) => Promise<SlotSeriesOutput>
      replicateSingleSlotIntoSeries: (requestParameters: ReplicateSingleSlotIntoSeriesRequest) => Promise<SlotSeriesOutput>
      updateSlotSeries: (requestParameters: UpdateSlotSeriesRequest) => Promise<SlotSeriesUpdateResponseUnion>
      createSlotSeriesWithResponse: (requestParameters: CreateSlotSeriesRequest) => Promise<{ value: SlotSeriesOutput, response: any}>
      deleteSlotSeriesWithResponse: (requestParameters: DeleteSlotSeriesRequest) => Promise<{ value: SlotSeriesDeleteResponse, response: any}>
      getSlotSeriesByIDWithResponse: (requestParameters: GetSlotSeriesByIDRequest) => Promise<{ value: SlotSeriesOutput, response: any}>
      replicateSingleSlotIntoSeriesWithResponse: (requestParameters: ReplicateSingleSlotIntoSeriesRequest) => Promise<{ value: SlotSeriesOutput, response: any}>
      updateSlotSeriesWithResponse: (requestParameters: UpdateSlotSeriesRequest) => Promise<{ value: SlotSeriesUpdateResponseUnion, response: any}>
}

/**
 * no description
 */
export class SlotSeriesApi extends runtime.BaseAPI {

    /**
     * Create a slot series.
     * Create a slot series.
     */
    createSlotSeriesRaw = async (requestParameters: CreateSlotSeriesRequest): Promise<{ runtime: runtime.ApiResponse<SlotSeriesOutput>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slot-series`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSlotSeriesRequestBodyToJSON(requestParameters.createSlotSeriesRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlotSeriesOutputFromJSON(jsonValue)), response };
    }

    /**
     * Create a slot series.
     * Create a slot series.
     */
    createSlotSeries = async (requestParameters: CreateSlotSeriesRequest): Promise<SlotSeriesOutput> => {
        const { runtime } = await this.createSlotSeriesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create a slot series.
     * Create a slot series.
     */
    createSlotSeriesWithResponse = async (requestParameters: CreateSlotSeriesRequest): Promise<{ value: SlotSeriesOutput, response: any}> => {
        const { runtime, response } = await this.createSlotSeriesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * deletes a slot series
     * deletes a slot series
     */
    deleteSlotSeriesRaw = async (requestParameters: DeleteSlotSeriesRequest): Promise<{ runtime: runtime.ApiResponse<SlotSeriesDeleteResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSlotSeries.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dryRun !== undefined) {
            queryParameters['dry_run'] = requestParameters.dryRun;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slot-series/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSlotSeriesToJSON(requestParameters.deleteSlotSeries),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlotSeriesDeleteResponseFromJSON(jsonValue)), response };
    }

    /**
     * deletes a slot series
     * deletes a slot series
     */
    deleteSlotSeries = async (requestParameters: DeleteSlotSeriesRequest): Promise<SlotSeriesDeleteResponse> => {
        const { runtime } = await this.deleteSlotSeriesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * deletes a slot series
     * deletes a slot series
     */
    deleteSlotSeriesWithResponse = async (requestParameters: DeleteSlotSeriesRequest): Promise<{ value: SlotSeriesDeleteResponse, response: any}> => {
        const { runtime, response } = await this.deleteSlotSeriesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get slot series by ID
     * Get slot series by ID
     */
    getSlotSeriesByIDRaw = async (requestParameters: GetSlotSeriesByIDRequest): Promise<{ runtime: runtime.ApiResponse<SlotSeriesOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSlotSeriesByID.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slot-series/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlotSeriesOutputFromJSON(jsonValue)), response };
    }

    /**
     * Get slot series by ID
     * Get slot series by ID
     */
    getSlotSeriesByID = async (requestParameters: GetSlotSeriesByIDRequest): Promise<SlotSeriesOutput> => {
        const { runtime } = await this.getSlotSeriesByIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get slot series by ID
     * Get slot series by ID
     */
    getSlotSeriesByIDWithResponse = async (requestParameters: GetSlotSeriesByIDRequest): Promise<{ value: SlotSeriesOutput, response: any}> => {
        const { runtime, response } = await this.getSlotSeriesByIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create a slot series from slot
     * Create a slot series from slot
     */
    replicateSingleSlotIntoSeriesRaw = async (requestParameters: ReplicateSingleSlotIntoSeriesRequest): Promise<{ runtime: runtime.ApiResponse<SlotSeriesOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling replicateSingleSlotIntoSeries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slots/{id}/series`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSlotSeriesToJSON(requestParameters.createSlotSeries),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlotSeriesOutputFromJSON(jsonValue)), response };
    }

    /**
     * Create a slot series from slot
     * Create a slot series from slot
     */
    replicateSingleSlotIntoSeries = async (requestParameters: ReplicateSingleSlotIntoSeriesRequest): Promise<SlotSeriesOutput> => {
        const { runtime } = await this.replicateSingleSlotIntoSeriesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create a slot series from slot
     * Create a slot series from slot
     */
    replicateSingleSlotIntoSeriesWithResponse = async (requestParameters: ReplicateSingleSlotIntoSeriesRequest): Promise<{ value: SlotSeriesOutput, response: any}> => {
        const { runtime, response } = await this.replicateSingleSlotIntoSeriesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update slot series
     * Update slot series
     */
    updateSlotSeriesRaw = async (requestParameters: UpdateSlotSeriesRequest): Promise<{ runtime: runtime.ApiResponse<SlotSeriesUpdateResponseUnion>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSlotSeries.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dryRun !== undefined) {
            queryParameters['dry_run'] = requestParameters.dryRun;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slot-series/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSlotSeriesToJSON(requestParameters.updateSlotSeries),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlotSeriesUpdateResponseUnionFromJSON(jsonValue)), response };
    }

    /**
     * Update slot series
     * Update slot series
     */
    updateSlotSeries = async (requestParameters: UpdateSlotSeriesRequest): Promise<SlotSeriesUpdateResponseUnion> => {
        const { runtime } = await this.updateSlotSeriesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update slot series
     * Update slot series
     */
    updateSlotSeriesWithResponse = async (requestParameters: UpdateSlotSeriesRequest): Promise<{ value: SlotSeriesUpdateResponseUnion, response: any}> => {
        const { runtime, response } = await this.updateSlotSeriesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum DeleteSlotSeriesDryRunEnum {
    True = 'true',
    False = 'false'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateSlotSeriesDryRunEnum {
    True = 'true',
    False = 'false'
}

export const fetchSlotSeriesApi: ISlotSeriesApi  = new SlotSeriesApi();
