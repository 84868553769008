import { useQuery } from "react-query";
import { fetchConfigApi } from "shared/fetch/src/apis/ConfigApi";

export function getSynopsisQueryKey() {
  return ["synopsis"];
}

export default function useGetSynopsis() {
  return useQuery(getSynopsisQueryKey(), () => fetchConfigApi.getSynopsis(), {
    staleTime: Infinity,
  });
}

export function useGetSiteProviders(siteId: string) {
  const synopsis = useGetSynopsis();
  const site = synopsis?.data?.sites?.find(
    (_site) => _site.id?.toString() === siteId
  );
  return site?.providers || [];
}
