import React, { useState } from "react";
import { Autocomplete } from "@mui/material";
import TextField from "../TextField";
import makeStyles from "@mui/styles/makeStyles";

interface IProps {
  setInsuranceType: any;
}
export const INSURANCE_TYPES = {
  INSURED_BY_EMPLOYER: "Insured through employer",
  COVERED_DEPENDENT: "Covered dependent",
  SELF_PAY: "Self pay",
};

const useStyles = makeStyles(({ palette }) => ({
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "&[aria-selected='true']": {
          backgroundColor: palette?.appBackground?.blue,
          color: palette.white,
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
      },
    },
  },
}));

export default function InsuranceTypeAutoComplete({
  setInsuranceType,
}: IProps) {
  const [selectedType, setSelectedType] = useState("");
  const classes = useStyles();
  return (
    <Autocomplete
      options={[
        INSURANCE_TYPES.INSURED_BY_EMPLOYER,
        INSURANCE_TYPES.COVERED_DEPENDENT,
        INSURANCE_TYPES.SELF_PAY,
      ]}
      style={{
        marginTop: "8px",
        marginBottom: "16px",
      }}
      ListboxProps={{
        className: classes.listboxItem,
      }}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }
        return option;
      }}
      onChange={(e, value) => {
        if (value) {
          setSelectedType(value);
          setInsuranceType(value);
        } else {
          setSelectedType("");
        }
      }}
      inputValue={selectedType}
      onInputChange={(e, value, reason) => {
        if (reason === "clear") {
          setSelectedType("");
        } else if (reason === "input") {
          setSelectedType(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          role="combobox"
          value={selectedType}
          label="Select insurance type"
          variant="filled"
          fullWidth
        />
      )}
      data-e2e="insurance-select"
    />
  );
}
