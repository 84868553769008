import isValid from "date-fns/isValid";

import React, { useState, useRef } from "react";
import { InputAdornment, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import clsx from "clsx";
import classes from "./DatePicker.module.css";
import { CustomCalendarHeader } from "../DatePicker/XOCalDatePicker";
import {
  DateValidationError,
  PickerChangeHandlerContext,
  PickersDay,
} from "@mui/x-date-pickers";
import { addDays, getDay, isWithinInterval } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import { findDisabledDates } from "../utils";

export interface IMaterialUIPickersProps {
  isLastElementInRow: boolean;
  minDate: Date;
  onChangeDate: Function;
  classNames: string;
  classNamesInputIconWrap?: string;
  classNamesInputIcon?: string;
  InputProps?: any;
  oooDays?: Set<{
    start: string;
    end: string;
    provider: string;
  }>;
  selectedDate?: Date | null;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  operatingDaysNumerical?: Number[];
  ariaLabel?: string;
  disabled?: boolean;
}

const PickersPaper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: "10px",
}));

export default function XODatePicker({
  minDate,
  onChangeDate,
  classNames,
  classNamesInputIconWrap = "",
  classNamesInputIcon = "",
  oooDays,
  selectedDate,
  operatingDaysNumerical,
  setSelectedDate,
  ariaLabel,
  disabled,
}: IMaterialUIPickersProps) {
  const [isOpen, setIsOpen] = useState(false);

  const refDatePicker = useRef(null);

  const isTodayOOO = () => {
    let todayIsOOO = false;
    if (oooDays) {
      const oooArray = Array.from(oooDays);
      oooArray.forEach((day) => {
        if (
          isWithinInterval(new Date(), {
            start: new Date(day.start),
            end: addDays(new Date(day.end), 1),
          })
        ) {
          todayIsOOO = true;
        }
      });
    }
    if (!operatingDaysNumerical?.includes(getDay(new Date()))) {
      todayIsOOO = true;
    }
    return todayIsOOO;
  };

  const handleDateChange = (
    date: dateFns | null,
    _context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    if (isValid(date)) {
      if (setSelectedDate) {
        setSelectedDate(date as unknown as Date);
      }
      onChangeDate(date);
    } else {
      setSelectedDate && setSelectedDate(null);
    }
  };

  const InputAdornmentComponent = (props: any) => {
    return (
      <InputAdornment {...props}>
        <div className={`${classNamesInputIconWrap}`}>
          <ArrowDropDown className={classNamesInputIcon} />
        </div>
      </InputAdornment>
    );
  };

  const CustomDay = (props: any) => {
    return (
      <PickersDay
        sx={{
          "&.MuiPickersDay-root": {
            borderRadius: "10%",
            "&:focus": {
              backgroundColor: "rgba(76, 90, 115, 0.12)",
            },
            "&:hover": {
              backgroundColor: "rgba(76, 90, 115, 0.12)",
              color: "var(--palette-text-primary)",
            },
          },
          "&:hover": {
            backgroundColor: "inherit",
            color: "inherit",
          },
          "&.Mui-selected": {
            backgroundColor: "#364257",
            color: "#FFFFFF",
            "&:focus": {
              backgroundColor: "#364257",
              color: "#FFFFFF",
            },
          },
        }}
        {...props}
      />
    );
  };

  const selectedAndOOO =
    selectedDate &&
    oooDays &&
    findDisabledDates(selectedDate, oooDays, operatingDaysNumerical);

  return (
    <div className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          shouldDisableDate={(e) =>
            findDisabledDates(e, oooDays, operatingDaysNumerical)
          }
          label={ariaLabel}
          className={classNames}
          // @ts-ignore
          value={selectedDate}
          onChange={handleDateChange}
          // @ts-ignore // TODO: look into types
          minDate={minDate}
          ref={refDatePicker}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          disabled={disabled}
          slots={{
            inputAdornment: InputAdornmentComponent,
            /* @ts-ignore */
            calendarHeader: CustomCalendarHeader,
            // layout: StyledPickersLayout
            desktopPaper: PickersPaper,
            day: CustomDay,
          }}
          slotProps={{
            inputAdornment: {
              position: "end",
            },
            actionBar: {
              actions: ["clear", "today"],
              sx: {
                justifyContent: "space-between",
                "& .MuiButton-root": {
                  color: "var(--palette-secondary-main)",
                },
                "& .MuiButton-root:first-of-type": {
                  color: "var(--palette-secondary-main)",
                },
                "& .MuiButton-root:last-of-type": {
                  color: isTodayOOO()
                    ? "grey"
                    : "var(--palette-secondary-main)",
                  pointerEvents: isTodayOOO() ? "none" : "all",
                },
              },
            },
            layout: {
              classes: {
                root: clsx(classes.pickersLayout),
              },
            },
            textField: {
              id: "date-input",
              classes: {
                root: clsx(classes.dateField),
              },
              InputProps: {
                className: selectedAndOOO
                  ? classes.dateFieldInputError
                  : classes.dateFieldInput,
              },
              onClick: (e) => {
                e.stopPropagation();
                setIsOpen(true);
              },
            },
          }}
        />
      </LocalizationProvider>
      {selectedAndOOO && (
        <div className={classes.errorMessageContainer}>
          {" "}
          <InfoIcon />
          <p className={classes.errorText}>
            CTM is Out of Office or Clinic is Closed
          </p>
        </div>
      )}
    </div>
  );
}
