// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from './SlotOutput';


/**
 * 
 * @export
 * @interface GetSlotsForDateOutputWithObject
 */
export interface GetSlotsForDateOutputWithObject  {
    /**
     * 
     * @type {{ [key: string]: Array<SlotOutput>; }}
     * @memberof GetSlotsForDateOutputWithObject
     */
    results?: { [key: string]: Array<SlotOutput>; };
    /**
     * 
     * @type {number}
     * @memberof GetSlotsForDateOutputWithObject
     */
    conflicts?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSlotsForDateOutputWithObject
     */
    shape?: string;
}

export function GetSlotsForDateOutputWithObjectFromJSON(json: any): GetSlotsForDateOutputWithObject {
    return {
        'results': !exists(json, 'results') ? undefined : json['results'],
        'conflicts': !exists(json, 'conflicts') ? undefined : json['conflicts'],
        'shape': !exists(json, 'shape') ? undefined : json['shape'],
    };
}

export function GetSlotsForDateOutputWithObjectToJSON(value?: GetSlotsForDateOutputWithObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results,
        'conflicts': value.conflicts,
        'shape': value.shape,
    };
}


