import Default from "pages/Default";
import * as React from "react";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
// import Layout from "../Layout";
import MembersFooter from "components/Members/MembersFooter";
import { getUser } from "shared/features/user";
import RouteChangeHandler from "./RouteChangeHandler";
// import CTMMemberHealthProfile from "pages/CTMMemberHealthProfile";
import RouteAccess from "components/Access/RouteAccess";
import authRoutes from "features/auth/routes";
import cmsRoutes from "features/cms/routes";
import eCheckinRoutes from "features/eCheckin/routes";
import episodesRoutes from "features/episodes/routes";
import healthRecordsRoutes from "features/healthRecords/routes";
import memberSearchRoutes from "features/memberSearch/routes";
// import CreateTimelineMessage from "components/CreateTimelineMessage";
import ConfirmationsDialog from "components/Dialog/ConfirmationsDialog";
import NotFoundPage from "components/NotFoundPage";
import Toast from "components/Toast";
import useIsAuthenticated from "features/auth/useIsAuthenticated";
// import MeetYourTeam from "features/meetYourTeam";
// import WithWorkspace from "components/Workspace/Common/WithWorkspace";
import BiometricEnrollment from "components/BiometricEnrollment";
import NativeAppState from "components/NativeAppState";
import Skeleton from "components/Skeleton";
// import DirectMessaging from "components/DirectMessaging";
// import EngagementActivation from "features/engagementActivation";
import MobileComponentModal from "components/MobileComponentModal";
import NativeAppError from "components/NativeAppError";
import NativeAppNavigation from "components/NativeAppNavigation";
import PushNotificationEnrollment from "components/PushNotificationEnrollment";
import { Subject } from "rxjs";
// import NotificationCenter from "components/NotificationCenter";
import { useFeatureFlags } from "shared/features/featureFlags/useFeatureFlagsForUser";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { loadLazy } from "utils/loadLazy";
// import WithCTMSidebar from "components/EOCTimeline/WithCTMSidebar";
import { clearStepsData } from "shared/state/ui/selfSchedule";
// import SelfScheduling from "features/selfSchedule/pages";
// import NewMember from "features/memberSearch/pages/NewMember";
// import CareAccess from "features/careAccess";
import XOCalContainer from "components/XOCal/XOCalContainer";
import ConflictsQueueContainer from "components/ConflictsQueue/ConflictsQueueContainer";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import { getFeatures } from "shared/features/featureFlags/selectors";
import Layout from "../Layout";
import CentersPage from "features/careAccess/pages/Centers";
import CareTypePage from "features/careAccess/pages/CareType";
import ServicesPage from "features/careAccess/pages/Services";
import VisitsPage from "features/careAccess/pages/Visits";
import CareAccessPicker from "features/careAccess/pages/Picker";
import FinalizePage from "features/careAccess/pages/Finalize";
import MessagesPage from "features/careAccess/pages/Messages";
import DailyScheduleContainer from "features/dailySchedule/pages";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const LazyEpisodes = loadLazy(() => import("pages/Episodes"));
const LazyTimeline = loadLazy(() => import("components/EOCTimeline"));
const LazyComponentDetail = loadLazy(
  () => import("components/ComponentDetail")
);
const LazyMessageAndMore = loadLazy(() => import("components/MessageAndMore"));
const LazyAccountAndSettings = loadLazy(
  () => import("components/AccountAndSettings")
);
const LazyCompleteCtmCalculator = loadLazy(
  () => import("components/ComponentDetail/CompleteCtmCalculator")
);
const LazyCreateTimelineMessage = loadLazy(
  () => import("components/CreateTimelineMessage")
);
const LazyMeetYourTeam = loadLazy(() => import("features/meetYourTeam"));
const LazyCTMMemberHealthProfile = loadLazy(
  () => import("pages/CTMMemberHealthProfile")
);
const LazyNewMember = loadLazy(
  () => import("features/memberSearch/pages/NewMember")
);
const LazyCareAccess = loadLazy(() => import("features/careAccess"));
const LazyEngagementActivation = loadLazy(
  () => import("features/engagementActivation")
);

const LazyCareHistoryPage = loadLazy(() => import("pages/CareHistoryPage"));
// const LazyDefault = loadLazy(() => import("pages/Default"));
const LazyCreateEOC = loadLazy(() => import("pages/CreateEOC"));
const LazySelfScheduling = loadLazy(
  () => import("features/selfSchedule/pages")
);
const LazyDirectMessaging = loadLazy(
  () => import("components/DirectMessaging")
);
const LazyNotificationCenter = loadLazy(
  () => import("components/NotificationCenter")
);
// const LazyLayout = loadLazy(() => import("../Layout"));
const LazyWithCTMSidebar = loadLazy(
  () => import("components/EOCTimeline/WithCTMSidebar")
);
const LazyWithWorkspace = loadLazy(
  () => import("components/Workspace/Common/WithWorkspace")
);

const LazyFeatureFlags = loadLazy(() => import("pages/FeatureFlags"));
const LazySlataImport = loadLazy(() => import("pages/SlataImport"));
const LazyCtmPodAssignment = loadLazy(() => import("pages/CTMPodAssignments"));
const LazyAdminTools = loadLazy(() => import("pages/AdminTools"));
const LazyAccessPlanPodAssignments = loadLazy(
  () => import("pages/AccessPlanPodAssignments")
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      backgroundColor: theme.palette?.appBackground?.main,
      height: "100%",
      [theme.breakpoints.down("md")]: {
        overflow: "visible",
      },
    },
    pageLayout: {
      width: "100%",
      minWidth: 0,
      padding: 0,
      overflowY: "scroll",
      zIndex: 1,
      transition: "all ease 0.6s",
    },
    hasFooter: {
      paddingBottom: 0,
      [theme.breakpoints.up("md")]: {
        paddingBottom: 56,
      },
    },
  })
);

export const scrollPosition$ = new Subject();

const AuthedRoutes = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const features = useSelector(getFeatures);

  React.useEffect(() => {
    setTimeout(() => {
      const wrapper = document.getElementById("layout-wrapper-div");
      wrapper?.focus();
    }, 100);
  }, []);

  const RedirectToConversations = ({ to }: { to: string }) => {
    const conversationsPath = to.replace("episodes", "conversations");
    React.useEffect(() => {
      navigate(conversationsPath);
    });
    return null;
  };

  return (
    <React.Fragment>
      <LazyWithCTMSidebar />
      <div
        className={classes.pageLayout}
        data-e2e="router-div"
        id="main-container-div"
        onScroll={(e) => {
          const target = e.target;

          if (target instanceof Element) {
            if (target.scrollTop && target.clientHeight) {
              scrollPosition$.next(target.scrollTop + target.clientHeight);
            }
          }
        }}
      >
        <Routes>
          <Route index element={<Default />} />
          <Route path="/index.html" element={<Navigate replace to="/" />} />
          <Route path="/signin" element={<Navigate replace to="/" />} />
          <Route
            path="/episodes"
            element={<Navigate replace to="/conversations" />}
          />
          <Route
            path="/members/:memberId/episodes/:id"
            element={<RedirectToConversations to={location?.pathname} />}
          />
          <Route
            path="/members/:memberId/episodes/:id/component/:componentId"
            element={<RedirectToConversations to={location?.pathname} />}
          />
          <Route
            path="/members/:memberId/episodes/:id/bundle/:timelineEventId"
            element={<RedirectToConversations to={location?.pathname} />}
          />
          <Route
            path="/members/:memberId/episodes/:id/new-message"
            element={<RedirectToConversations to={location?.pathname} />}
          />
          <Route
            path="/members/:memberId/episodes/:id/component/:componentId/respond"
            element={<RedirectToConversations to={location?.pathname} />}
          />

          {cmsRoutes()}
          {memberSearchRoutes()}
          {episodesRoutes()}
          {eCheckinRoutes()}
          {healthRecordsRoutes()}

          <Route
            path="/members/:memberId/self-schedule"
            element={
              <RouteAccess
                to={PermissionsEnum.SelfScheduleCreate}
                mobileOverlay={{
                  title: "Schedule a Visit",
                  onClose: () => dispatch(clearStepsData()),
                }}
              />
            }
          >
            <Route index element={<LazySelfScheduling />} />
          </Route>
          <Route
            path="/direct-messaging"
            element={
              <RouteAccess
                to={PermissionsEnum.EpisodesCreate}
                ctmOnly={features.hasCareAccessMenus()}
              />
            }
          >
            <Route index element={<LazyDirectMessaging />} />
          </Route>
          <Route
            path={`${eocRootPath}/*`}
            element={
              <RouteAccess
                to={PermissionsEnum.EpisodesCreate}
                mobileOverlay={{ title: "Get Care" }}
              />
            }
          >
            <Route path="*" element={<LazyCreateEOC />} />
          </Route>
          <Route
            path="/notifications/*"
            element={<RouteAccess to={PermissionsEnum.EpisodesCreate} />}
          >
            <Route index element={<LazyNotificationCenter />} />
          </Route>
          <Route
            path="/conversations"
            element={<RouteAccess to={PermissionsEnum.CtmEpisodesRead} />}
          >
            <Route index element={<LazyEpisodes />} />
          </Route>
          <Route
            path="/members/:memberId/conversations/:id"
            element={<RouteAccess to={PermissionsEnum.EpisodesRead} />}
          >
            <Route index element={<LazyTimeline />} />
          </Route>
          <Route
            path="/members/:memberId/conversations/:id/component/:componentId"
            element={<RouteAccess to={PermissionsEnum.EpisodesRead} />}
          >
            <Route index element={<LazyComponentDetail />} />
          </Route>
          <Route
            path="/members/:memberId/conversations/:id/bundle/:timelineEventId"
            element={<RouteAccess to={PermissionsEnum.EpisodesRead} />}
          >
            <Route index element={<LazyMessageAndMore />} />
          </Route>
          <Route
            path="/members/:memberId/conversations/:id/new-message"
            element={
              <RouteAccess to={PermissionsEnum.EpisodesUpdate} memberOnly />
            }
          >
            <Route index element={<LazyCreateTimelineMessage />} />
          </Route>
          <Route
            path="/members/:memberId/conversations/:id/component/:componentId/respond"
            element={
              <RouteAccess to={PermissionsEnum.EpisodesUpdate} memberOnly />
            }
          >
            <Route index element={<LazyCreateTimelineMessage />} />
          </Route>
          <Route
            path="/providers"
            element={<RouteAccess to={PermissionsEnum.MeetYourTeamRead} />}
          >
            <Route index element={<LazyMeetYourTeam />} />
          </Route>
          <Route
            path="/members/:id/*"
            element={<RouteAccess to={PermissionsEnum.EpisodesRead} ctmOnly />}
          >
            <Route
              index
              /* @ts-ignore */
              element={<LazyCTMMemberHealthProfile />}
            />
            <Route
              path="*"
              /* @ts-ignore */
              element={<LazyCTMMemberHealthProfile />}
            />
          </Route>
          <Route
            path="/members/new"
            element={<RouteAccess to={PermissionsEnum.EpisodesRead} />}
          >
            <Route index element={<LazyNewMember />} />
          </Route>
          <Route
            path="/members/:memberId/care-access"
            element={
              <RouteAccess to={PermissionsEnum.SelfScheduleCreate} memberOnly />
            }
          >
            <Route element={<LazyCareAccess />}>
              <Route path="services" element={<ServicesPage />} />
              <Route path="decisions" element={<CareTypePage />} />
              <Route path="centers" element={<CentersPage />} />
              <Route path="messages" element={<MessagesPage />} />
              <Route path="visits" element={<VisitsPage />} />
              <Route path="date-picker" element={<CareAccessPicker />} />
              <Route path="finalize" element={<FinalizePage />} />
            </Route>
            <Route index element={<NotFoundPage />} />
          </Route>
          <Route
            path="/engagement-activation"
            element={
              <RouteAccess to={PermissionsEnum.EpisodesCreate} memberOnly />
            }
          >
            <Route index element={<LazyEngagementActivation />} />
          </Route>
          <Route
            path="/members/:memberId/conversations/:id/calculator/:calculatorId"
            element={<RouteAccess to={PermissionsEnum.EpisodesRead} />}
          >
            <Route index element={<LazyCompleteCtmCalculator />} />
          </Route>
          <Route path="/account/*" element={<LazyAccountAndSettings />} />
          <Route path="/care-history/*" element={<LazyCareHistoryPage />} />
          <Route path="/feature-flags" element={<LazyFeatureFlags />} />
          <Route
            path="/calendar"
            element={<RouteAccess to={PermissionsEnum.CtmEpisodesRead} />}
          >
            <Route index element={<XOCalContainer />} />
          </Route>
          <Route
            path="/calendar/conflicts"
            element={<RouteAccess to={PermissionsEnum.CtmEpisodesRead} />}
          >
            <Route index element={<ConflictsQueueContainer />} />
          </Route>
          <Route
            path="/schedule"
            element={<RouteAccess to={PermissionsEnum.CtmEpisodesRead} />}
          >
            <Route index element={<DailyScheduleContainer />} />
          </Route>
          <Route path="/slata" element={<LazySlataImport />} />
          <Route
            path="/ctms-and-their-pods"
            element={<LazyCtmPodAssignment />}
          />
          <Route
            path="/access-plans-and-their-pods"
            element={<LazyAccessPlanPodAssignments />}
          />
          <Route
            path="/admin-tools"
            element={<RouteAccess to={PermissionsEnum.CtmEpisodesRead} />}
          >
            <Route index element={<LazyAdminTools />} />
          </Route>

          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <LazyWithWorkspace />
    </React.Fragment>
  );
};

const RoutesWithLayout = () => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const location = useLocation();
  const features = useSelector(getFeatures);

  const isAuthenticated = useIsAuthenticated();

  // @ts-ignore: Object is possibly 'null'.
  const isFooterVisible = canShowFooter(user?.ctm, location.pathname);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Layout>
      <RouteChangeHandler
        routeAction={() => {
          const scrollElements = document.getElementsByClassName("router page");
          if (scrollElements && scrollElements.length) {
            scrollElements[0].scrollTo(0, 0);
          }
        }}
      />
      <main
        className={`${classes.root} ${
          isFooterVisible ? classes.hasFooter : ""
        }`}
      >
        <React.Suspense fallback={<Skeleton appearance="page" />}>
          <Toast />
          <ConfirmationsDialog />
          <MobileComponentModal />
          <AuthedRoutes />
          {!features?.hasDisableBiometricLogin() && <BiometricEnrollment />}
          <PushNotificationEnrollment />
          <NativeAppError />
          <NativeAppNavigation />
          <NativeAppState />
        </React.Suspense>
      </main>
      {isFooterVisible && <MembersFooter clinicContact={user?.clinicContact} />}
    </Layout>
  );
};

// force a rebuild
const Routing = () => {
  useFeatureFlags();

  return (
    <Routes>
      {authRoutes()}
      <Route path="/*" element={<RoutesWithLayout />} />
    </Routes>
  );
};

const canShowFooter = (isCTM: boolean, currentPath: string) => {
  const features = useSelector(getFeatures);
  const selfScheduleRoute = /members\/\d+\/self-schedule/;
  const careAccessRoute = /members\/\d+\/care-access/;
  const timelinePage = features?.hasMemberInlineMessaging()
    ? /members\/\d+\/conversations\/\d+/
    : null;
  const checkinRoute =
    /members\/\d+\/conversations\/\d+\/component\/\d+\/check-in/;
  const activationRoute = /engagement-activation/;
  const footerLessRoutes = [
    selfScheduleRoute,
    checkinRoute,
    activationRoute,
    timelinePage,
    careAccessRoute,
  ];

  const matchesFooterLessRoutes =
    footerLessRoutes.find((route) => {
      if (route instanceof RegExp) {
        return route.test(currentPath);
      }

      return route === currentPath;
    }) !== undefined;

  return isCTM === false && !matchesFooterLessRoutes;
};

export const eocRootPath = "/get-care";

export default Routing;
