import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Button from "components/Button";
import Typography from "components/Typography";
import { visuallyHidden } from "@mui/utils";
import clsx from "clsx";

interface IProps {
  children: React.ReactNode;
  color?: string;
  hoverColor?: string;
  onClick: any;
  srSupplementText?: string;
  webFontSize?: number | string;
  mobileFontSize?: number | string;
  noPadding?: boolean;
  ariaLabel?: string;
  className?: string;
}

interface CProps {
  color?: string;
  hoverColor?: string;
  webFontSize?: number | string;
  mobileFontSize?: number | string;
  noPadding?: boolean;
}

export const useButtonStyledAsLinkStyles = makeStyles(
  ({ palette, breakpoints }: Theme) =>
    createStyles({
      root: (props: CProps) => ({
        padding: props.noPadding ? "0px 0px 0px 0px" : "inherit",
        "&:hover": {
          background: "none",
        },
      }),
      text: (props: CProps) => ({
        color: props.color || palette?.links?.primary,
        textDecoration: "underline",
        "&:hover": {
          color: props.hoverColor || palette?.links?.primaryHover,
        },
        fontSize: props.webFontSize || "inherit",
        [breakpoints.down("md")]: {
          fontSize: props.mobileFontSize || "inherit",
        },
      }),
    })
);

const ButtonStyledAsLink: React.FunctionComponent<IProps> = ({
  children,
  color,
  hoverColor,
  onClick,
  webFontSize,
  mobileFontSize,
  ariaLabel,
  srSupplementText,
  className,
  noPadding,
}) => {
  const classes = useButtonStyledAsLinkStyles({
    color,
    hoverColor,
    webFontSize,
    mobileFontSize,
    noPadding,
  });

  return (
    <Button
      onClick={onClick}
      className={className ? clsx(classes.root, className) : classes.root}
      aria-label={ariaLabel}
    >
      <Typography component="span" className={classes.text}>
        {children}
      </Typography>
      {srSupplementText && (
        <Typography style={visuallyHidden}>{srSupplementText}</Typography>
      )}
    </Button>
  );
};

export default ButtonStyledAsLink;
