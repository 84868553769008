import React from "react";
import Button from "components/Button";
import { format } from "date-fns";
import Dialog from "components/Dialog";
import DialogTitle from "components/Dialog/DialogTitle";
import DialogContent from "components/Dialog/DialogContent";
import DialogContentText from "components/Dialog/DialogContentText";
import DialogActions from "components/Dialog/DialogActions";
import classes from "./delete-OOO-confirmation.module.css";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  provider: string;
  startDate: string;
  endDate: string;
  allProviderInformation: XOCalProvider | undefined;
}

const DeleteOOOConfirmationDialog: React.FC<IProps> = ({
  open,
  onClose,
  onConfirm,
  startDate,
  endDate,
  allProviderInformation,
}) => {
  const formatDate = (date: string) => {
    return format(new Date(date), "MMM d, yyyy");
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      ariaLabelledBy="delete-ooo-dialog-title"
    >
      <DialogTitle id="delete-ooo-dialog-title">
        Delete OOO day(s) for {allProviderInformation?.name}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete Out of Office days for{" "}
          {allProviderInformation?.name}?
        </DialogContentText>
        <DialogContentText
          sx={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {formatDate(startDate)} - {formatDate(endDate)}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.deleteOOOModalActions}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOOOConfirmationDialog;
