// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessPlanPodAssignmentFiltersResponseResultsAccessPlans,
    AccessPlanPodAssignmentFiltersResponseResultsAccessPlansFromJSON,
    AccessPlanPodAssignmentFiltersResponseResultsAccessPlansToJSON,
} from './AccessPlanPodAssignmentFiltersResponseResultsAccessPlans';
import {
    CtmPodAssignmentFiltersResponseResultsPods,
    CtmPodAssignmentFiltersResponseResultsPodsFromJSON,
    CtmPodAssignmentFiltersResponseResultsPodsToJSON,
} from './CtmPodAssignmentFiltersResponseResultsPods';


/**
 * 
 * @export
 * @interface AccessPlanPodAssignmentFiltersResponseResults
 */
export interface AccessPlanPodAssignmentFiltersResponseResults  {
    /**
     * 
     * @type {Array<CtmPodAssignmentFiltersResponseResultsPods>}
     * @memberof AccessPlanPodAssignmentFiltersResponseResults
     */
    pods: Array<CtmPodAssignmentFiltersResponseResultsPods>;
    /**
     * 
     * @type {Array<AccessPlanPodAssignmentFiltersResponseResultsAccessPlans>}
     * @memberof AccessPlanPodAssignmentFiltersResponseResults
     */
    accessPlans: Array<AccessPlanPodAssignmentFiltersResponseResultsAccessPlans>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessPlanPodAssignmentFiltersResponseResults
     */
    efIds: Array<string>;
}

export function AccessPlanPodAssignmentFiltersResponseResultsFromJSON(json: any): AccessPlanPodAssignmentFiltersResponseResults {
    return {
        'pods': (json['pods'] as Array<any>).map(CtmPodAssignmentFiltersResponseResultsPodsFromJSON),
        'accessPlans': (json['access_plans'] as Array<any>).map(AccessPlanPodAssignmentFiltersResponseResultsAccessPlansFromJSON),
        'efIds': json['ef_ids'],
    };
}

export function AccessPlanPodAssignmentFiltersResponseResultsToJSON(value?: AccessPlanPodAssignmentFiltersResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pods': (value.pods as Array<any>).map(CtmPodAssignmentFiltersResponseResultsPodsToJSON),
        'access_plans': (value.accessPlans as Array<any>).map(AccessPlanPodAssignmentFiltersResponseResultsAccessPlansToJSON),
        'ef_ids': value.efIds,
    };
}


