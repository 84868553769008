import ToggleButton from "@mui/material/ToggleButton";
import Box from "components/Box";
import FormControlLabel from "components/Form/FormControlLabel";
import Radio from "components/Radio";
import RadioGroup from "components/Radio/RadioGroup";
import Typography from "components/Typography";
import React, { useState, useEffect } from "react";
import classes from "./index.module.css";
import XODatePicker from "./DatePicker";
import { addDays, format } from "date-fns";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { ValuesType } from "./utils";

interface Props {
  repeats: boolean;
  setField: (field: string, value: any) => void;
  values: ValuesType;
  setRepeats: (r: boolean) => void;
  daysActive?: string[];
  setDaysActive: (daysActive: string[]) => void;
  seriesStartDate: string | null; // format example "2025/01/14" TODO: tighten the string type
  operatingDaysNumerical: Number[];
  disabled?: boolean;
  setIsKeepOrCancelFlow: React.Dispatch<React.SetStateAction<boolean>>;
  slot: SlotOutput | undefined;
  setIsCreatingSeriesOutOfBookedSlot: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  slotDrawerDate: string;
  isSlotCreation: boolean;
}

interface Day {
  displayLabel: string;
  ariaLabel: string;
  numerical: Number;
}

export default function SelectFrequency({
  repeats,
  values,
  setField,
  setRepeats,
  daysActive,
  setDaysActive,
  seriesStartDate,
  operatingDaysNumerical,
  disabled,
  setIsKeepOrCancelFlow,
  slot,
  setIsCreatingSeriesOutOfBookedSlot,
  slotDrawerDate,
  isSlotCreation,
}: Props) {
  const isUpdatingSlot = !isSlotCreation;
  // When the Create slot button is clicked from  a week view, the user must select the date on which they
  // want to create a slot, but this variable is badly named on the values object, so let's make it clear
  const selectedStartDateFromSettingsTabStartDatePicker = values.selectedDate; // TODO: make values property name more descriptive
  const hasStartDate = Boolean(selectedStartDateFromSettingsTabStartDatePicker);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeats(event.target.value === "true");
    // if there is a booked slot and we are changing the slot to a slot series, we need to trigger the keep or cancel flow
    slot?.appointments?.length &&
      setIsKeepOrCancelFlow(event.target.value === "true");
    // also set isCreatingSeriesOutOfBookedSlot true so we can tell the keepOrCancelFlow modal what to do (create slot series)
    slot?.appointments?.length &&
      setIsCreatingSeriesOutOfBookedSlot(event.target.value === "true");
  };

  const dayz: Day[] = [
    { displayLabel: "Su", ariaLabel: "Sunday", numerical: 0 },
    { displayLabel: "M", ariaLabel: "Monday", numerical: 1 },
    { displayLabel: "T", ariaLabel: "Tuesday", numerical: 2 },
    { displayLabel: "W", ariaLabel: "Wednesday", numerical: 3 },
    { displayLabel: "Th", ariaLabel: "Thursday", numerical: 4 },
    { displayLabel: "F", ariaLabel: "Friday", numerical: 5 },
    { displayLabel: "S", ariaLabel: "Saturday", numerical: 6 },
  ];
  const dayAbbreviations = dayz.map((day) => day.displayLabel);

  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const toggleDay = (index: number) => {
    setSelectedDays((prevSelected) => {
      const newSelected = [...prevSelected];
      const dayIndex = newSelected.indexOf(index);
      if (dayIndex === -1) {
        newSelected.push(index);
      } else {
        newSelected.splice(dayIndex, 1);
      }
      setDaysActive(newSelected.map((i) => dayAbbreviations[i]));
      return newSelected;
    });
  };

  // move to utils?
  const setSeriesEndTwoDaysAheadOfDate = (
    dateAsStringOrDate: Date | string | undefined | null
  ) => {
    if (!Boolean(dateAsStringOrDate)) {
      return;
    }
    const dateAsDate =
      typeof dateAsStringOrDate === "string"
        ? new Date(dateAsStringOrDate)
        : dateAsStringOrDate;
    const seriesEndAsDate = addDays(dateAsDate!, 2); // can we trust this?
    const seriesEndAsString = format(seriesEndAsDate, "yyyy/MM/dd");
    setField("seriesEnd", seriesEndAsString); // sets values.seriesEnd in yyyy/MM/dd format
  };

  useEffect(() => {
    if (daysActive) {
      setSelectedDays(daysActive.map((day) => dayAbbreviations.indexOf(day)));
    } else {
      setSelectedDays([]);
    }
  }, [daysActive]);

  useEffect(() => {
    // if user is updating an existing slot that is not part of a series
    if (
      isUpdatingSlot &&
      !Boolean(values?.seriesEnd) &&
      !Boolean(slot?.slotSeries)
    ) {
      setSeriesEndTwoDaysAheadOfDate(slot?.startAt!);
    }
    // if user is creating slot and the start date has NOT been modified in the slot start DatePicker
    if (isSlotCreation && !hasStartDate) {
      setSeriesEndTwoDaysAheadOfDate(slotDrawerDate);
    }
  }, []);

  useEffect(() => {
    // if user is creating slot and the start date HAS been modified in the slot start DatePicker
    if (isSlotCreation && hasStartDate) {
      setSeriesEndTwoDaysAheadOfDate(
        selectedStartDateFromSettingsTabStartDatePicker
      );
    }
  }, [selectedStartDateFromSettingsTabStartDatePicker]);

  return (
    <>
      <RadioGroup
        name="repeats"
        value={repeats ? "true" : "false"}
        onChange={handleChange}
        className={classes.radioGroup}
        aria-labelledby="repeats-radio-group-label"
      >
        <FormControlLabel
          checked={!repeats}
          control={<Radio />}
          disabled={disabled}
          label={
            <Typography className={classes.text}>Does not repeat</Typography>
          }
          value="false"
        />
        <FormControlLabel
          checked={repeats}
          value="true"
          disabled={disabled}
          control={<Radio />}
          label={
            <Typography className={classes.text}>
              Repeats
              {repeats && <span> on:</span>}
            </Typography>
          }
        />
      </RadioGroup>
      {repeats && (
        <>
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: 2,
              paddingLeft: "28px",
            }}
          >
            {dayz.map(({ displayLabel, ariaLabel, numerical }, index) => (
              <ToggleButton
                disabled={
                  !operatingDaysNumerical.includes(numerical) || disabled
                }
                value={displayLabel}
                key={index}
                aria-label={ariaLabel}
                selected={selectedDays.includes(index)}
                className={classes.toggleButton}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "var(--palette-secondary-main)",
                    color: "var(--palette-common-white)",
                    "&:hover": {
                      backgroundColor: "var(--palette-secondary-main)",
                    },
                  },
                }}
                onChange={() => toggleDay(index)}
              >
                {displayLabel}
              </ToggleButton>
            ))}
          </Box>
          {selectedDays.length > 0 && (
            <Box
              sx={{
                paddingLeft: "28px",
                paddingTop: "16px",
                paddingBottom: "8px",
              }}
            >
              <XODatePicker
                isLastElementInRow={false}
                minDate={new Date(seriesStartDate || new Date().toISOString())}
                selectedDate={new Date(values.seriesEnd!)} // will be passed as value to the MUI datepicker
                onChangeDate={(newDate: Date) => {
                  const seriesEndDateAsString = format(newDate, "yyyy/MM/dd");
                  setField("seriesEnd", seriesEndDateAsString);
                }}
                classNames={classes.endDatePicker}
                ariaLabel="End date"
                disabled={disabled}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
