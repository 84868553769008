import React, { useState } from "react";
import { PaperProps } from "@mui/material";
import Paper from "components/Paper";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import Heading from "components/DynamicHeadings";
import classes from "./index.module.css";

interface IProps {
  open: boolean;
  toggle: () => void;
  emergencyPhoneContact?: string;
}

function PaperComponent(props: PaperProps) {
  return <Paper {...props} style={{ padding: "0px", top: "-50px" }} />;
}

const UrgentModal: React.FC<IProps> = ({
  open,
  toggle,
  emergencyPhoneContact,
}) => {
  const [showNumber, setShowNumber] = useState(false);
  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      onClose={toggle}
      ariaLabelledBy="alert-dialog-title"
    >
      <DialogTitle
        data-e2e="urgent-modal"
        className={classes.titleContainer}
        onClose={toggle}
      >
        <Heading.H
          id="alert-dialog-title"
          className={classes.titleText}
          appearance="h4"
          gutterBottom
        >
          Is this an urgent issue?
        </Heading.H>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        You will have the chance to reach out to your Team shortly. First, let’s
        confirm a few things:
        <ul className={classes.listContainer}>
          <li className={classes.list}>
            This is an urgent health concern and you need to speak with your
            Team now
          </li>

          <li className={classes.list}>
            You are a registered Crossover Health user with your own account
          </li>

          <li>If this is an emergency, call 911</li>
        </ul>
      </DialogContent>

      <DialogActions className={classes.dialogActions} horizontal>
        <Button
          color="primary"
          onClick={() => {
            setShowNumber(true);
            window.location.assign(`tel:${emergencyPhoneContact}`);
          }}
        >
          {showNumber ? emergencyPhoneContact : "Confirm"}
        </Button>
        <Button
          color="link-secondary"
          data-e2e="no-consent-button"
          onClick={() => {
            toggle();
            setShowNumber(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UrgentModal;
