import React from "react";
// TODO: we should ideally import our own version of these so we can control the typings
import { Button, Typography } from "@mui/material";
import classes from "./index.module.css";
import TodayIcon from "@mui/icons-material/Today";
import { format } from "date-fns";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import ScheduledMember from "./ScheduledMember";
import AddAdditionalMemberDrawer from "./AddAdditionalMemberDrawer";
import SearchAndScheduleMember from "./SearchAndScheduleMember";
import { ValuesType } from "./utils";
import { CalendarStaticDataResponseClinicDetails } from "shared/api/src/models/CalendarStaticDataResponseClinicDetails";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import { getAppointmentTypeName } from "components/XOCal/Slot/utils";
import EditMemberDrawer from "./EditMemberDrawer";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import theme from "styles/mui";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { useSearchParams } from "react-router-dom";

interface IProps {
  values: ValuesType;
  setField: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<any>) => void;
  site: CalendarStaticDataResponseClinicDetails | undefined;
  timezone: string;
  provider: XOCalProvider;
  slotId: string;
  handleClose: () => void;
  slot: SlotOutput | undefined;
  isSlotDatePast?: boolean;
}

const ScheduleVisitTab = ({
  values,
  setField,
  handleChange,
  timezone,
  provider,
  slotId,
  slot,
  isSlotDatePast,
}: IProps) => {
  const [searchParams] = useSearchParams();
  // we could get the clinicId from the slot, but sometimes the slot is undefined,
  // while the clinicId value in the params is always defined
  const clinicIdFromParams = searchParams.get("clinicId") as string;
  const { data: calendarData } = useGetCalendarStaticData({
    clinicId: clinicIdFromParams,
  });

  const [addingAMember, setAddingAMember] = React.useState<boolean>(false);
  const [editAMember, setEditAMember] = React.useState<boolean>(false);
  const [visitReason, setVisitReason] = React.useState<string | undefined>("");

  const scheduledAppointments = slot && slot.appointments;
  const hasScheduledMember = slot && !!slot.appointments?.length;
  const totalNumberPatients = slot?.maxPatients! + slot?.maxOverbook!;
  const hasScheduleAdditionalMemberButton =
    hasScheduledMember &&
    totalNumberPatients &&
    totalNumberPatients >= 2 &&
    scheduledAppointments?.length !== totalNumberPatients;

  const handleAddAnotherMember = () => {
    setAddingAMember(true);
  };

  const [cancelModalOpen, setCancelModalOpen] = React.useState<boolean>(false);

  return (
    <>
      <div className={classes.scheduleVisitTab}>
        <div
          className={classes.scheduleVisitTabIconDateTimeContainer}
          data-testid="schedule-visit-tab-slot-start-and-end-time"
        >
          <TodayIcon className={classes.scheduleVisitTabIcon} />{" "}
          {values?.startAt &&
            values?.endAt &&
            `${format(new Date(values?.startAt), "eee, MMMM d, yyyy")} - ${
              values.startTime
            } to ${values.endTime}`}
        </div>
        <div className={classes.scheduleVisitTitleContainer}>
          <div className={classes.scheduleVisitTitlePadding}>
            <Typography className={classes.scheduleVisitTitle}>
              {hasScheduledMember ? "Scheduled Visits" : "Schedule Member"}
            </Typography>
            {totalNumberPatients && totalNumberPatients >= 2 && (
              <div
                className={
                  scheduledAppointments?.length === totalNumberPatients
                    ? classes.groupIconTextContainerFullGroup
                    : classes.groupIconTextContainer
                }
              >
                <div className={classes.groupIconContainer}>
                  <GroupsIcon className={classes.groupIcon} />
                  <span data-testid="scheduled-visits-to-capacity-ratio">{`${
                    slot && slot.appointments?.length
                  }/${totalNumberPatients}`}</span>
                </div>
              </div>
            )}
          </div>
          {hasScheduleAdditionalMemberButton && (
            <div className={classes.scheduleAnotherMember}>
              <Button
                sx={{ minWidth: "0px", padding: "0px", paddingRight: "10px" }}
                onClick={handleAddAnotherMember}
                aria-label="Add another member" // make it match the copy
                disabled={isSlotDatePast}
                aria-disabled={isSlotDatePast}
              >
                <PersonAddIcon color="secondary" />
                <Typography paddingLeft={theme.spacing(1)} color="secondary">
                  Schedule Member
                </Typography>
              </Button>
            </div>
          )}
        </div>
        {!isSlotDatePast && (
          <SearchAndScheduleMember
            slot={slot}
            values={values}
            setField={setField}
            handleChange={handleChange}
            additionalMember={false}
            editMember={false}
            timezone={timezone!}
          />
        )}
        {hasScheduledMember &&
          scheduledAppointments?.map((appointment, _key) => {
            return (
              <ScheduledMember
                key={appointment.id}
                appointment={appointment}
                appointmentType={getAppointmentTypeName(
                  appointment.appointmentType,
                  calendarData!
                )}
                setEditAMember={setEditAMember}
                setVisitReason={setVisitReason}
                setCancelModalOpen={setCancelModalOpen}
                disabled={isSlotDatePast}
              />
            );
          })}
      </div>
      <AddAdditionalMemberDrawer
        open={addingAMember}
        setOpen={setAddingAMember}
        slot={slot}
        values={values}
        timezone={timezone!}
        provider={provider}
        setField={setField}
        handleChange={handleChange}
        slotId={slotId}
      />
      <EditMemberDrawer
        open={editAMember}
        setOpen={setEditAMember}
        slot={slot}
        values={values}
        timezone={timezone!}
        setField={setField}
        handleChange={handleChange}
        visitReason={visitReason}
        setVisitReason={setVisitReason}
        cancelModalOpen={cancelModalOpen}
        setCancelModalOpen={setCancelModalOpen}
        appointmentType={
          values?.appointmentType &&
          getAppointmentTypeName(values?.appointmentType, calendarData!)
        }
      />
    </>
  );
};

export default ScheduleVisitTab;
