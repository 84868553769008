/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppointmentTypeConfigRequestBody,
    AppointmentTypeConfigRequestBodyFromJSON,
    AppointmentTypeConfigRequestBodyToJSON,
} from '../models/AppointmentTypeConfigRequestBody'
import {
    AppointmentTypeConfigSchema,
    AppointmentTypeConfigSchemaFromJSON,
    AppointmentTypeConfigSchemaToJSON,
} from '../models/AppointmentTypeConfigSchema'
import {
    AppointmentTypeConfigsResponse,
    AppointmentTypeConfigsResponseFromJSON,
    AppointmentTypeConfigsResponseToJSON,
} from '../models/AppointmentTypeConfigsResponse'
import {
    ClinicConfigFetchResponse,
    ClinicConfigFetchResponseFromJSON,
    ClinicConfigFetchResponseToJSON,
} from '../models/ClinicConfigFetchResponse'
import {
    ClinicConfigSchema,
    ClinicConfigSchemaFromJSON,
    ClinicConfigSchemaToJSON,
} from '../models/ClinicConfigSchema'
import {
    ClinicProviderConfigFetchResponse,
    ClinicProviderConfigFetchResponseFromJSON,
    ClinicProviderConfigFetchResponseToJSON,
} from '../models/ClinicProviderConfigFetchResponse'
import {
    ClinicProviderConfigSchema,
    ClinicProviderConfigSchemaFromJSON,
    ClinicProviderConfigSchemaToJSON,
} from '../models/ClinicProviderConfigSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InternalServerErrorResponse,
    InternalServerErrorResponseFromJSON,
    InternalServerErrorResponseToJSON,
} from '../models/InternalServerErrorResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    InvalidParametersResponse1,
    InvalidParametersResponse1FromJSON,
    InvalidParametersResponse1ToJSON,
} from '../models/InvalidParametersResponse1'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface CreateAppointmentTypeConfigRequest {
    appointmentTypeConfigRequestBody?: AppointmentTypeConfigRequestBody;
}
export interface CreateClinicConfigRequest {
    clinicConfigSchema?: ClinicConfigSchema;
}
export interface CreateClinicProviderConfigRequest {
    clinicProviderConfigSchema?: ClinicProviderConfigSchema;
}
export interface DeleteAppointmentTypeRequest {
    id: string;
}
export interface DeleteClinicConfigRequest {
    id: string;
}
export interface DeleteClinicProviderConfigRequest {
    id: string;
}
export interface GetAppointmentTypeConfigsRequest {
    page?: string;
    q?: string;
}
export interface GetClinicConfigByIdRequest {
    id: string;
}
export interface GetClinicConfigsRequest {
    page?: string;
    q?: string;
}
export interface GetClinicProviderConfigByIdRequest {
    id: string;
}
export interface GetClinicProviderConfigsRequest {
    clinicId?: string;
    page?: string;
    q?: string;
}
export interface UpdateClinicConfigRequest {
    id: string;
    clinicConfigSchema?: ClinicConfigSchema;
}
export interface UpdateClinicProviderConfigRequest {
    id: string;
    clinicProviderConfigSchema?: ClinicProviderConfigSchema;
}
export interface V1DbcmsAppointmentTypeConfigsIdGetRequest {
    id: string;
}
export interface V1DbcmsAppointmentTypeConfigsIdPatchRequest {
    id: string;
    appointmentTypeConfigRequestBody: AppointmentTypeConfigRequestBody;
}


export interface ICmsApi {
      createAppointmentTypeConfig: (requestParameters: CreateAppointmentTypeConfigRequest) => Promise<AppointmentTypeConfigSchema>
      createClinicConfig: (requestParameters: CreateClinicConfigRequest) => Promise<ClinicConfigSchema>
      createClinicProviderConfig: (requestParameters: CreateClinicProviderConfigRequest) => Promise<ClinicProviderConfigSchema>
      deleteAppointmentType: (requestParameters: DeleteAppointmentTypeRequest) => Promise<void>
      deleteClinicConfig: (requestParameters: DeleteClinicConfigRequest) => Promise<void>
      deleteClinicProviderConfig: (requestParameters: DeleteClinicProviderConfigRequest) => Promise<void>
      getAppointmentTypeConfigs: (requestParameters: GetAppointmentTypeConfigsRequest) => Promise<AppointmentTypeConfigsResponse>
      getClinicConfigById: (requestParameters: GetClinicConfigByIdRequest) => Promise<ClinicConfigSchema>
      getClinicConfigs: (requestParameters: GetClinicConfigsRequest) => Promise<ClinicConfigFetchResponse>
      getClinicProviderConfigById: (requestParameters: GetClinicProviderConfigByIdRequest) => Promise<ClinicProviderConfigSchema>
      getClinicProviderConfigs: (requestParameters: GetClinicProviderConfigsRequest) => Promise<ClinicProviderConfigFetchResponse>
      getCostCenters: () => Promise<Array<string>>
      updateClinicConfig: (requestParameters: UpdateClinicConfigRequest) => Promise<ClinicConfigSchema>
      updateClinicProviderConfig: (requestParameters: UpdateClinicProviderConfigRequest) => Promise<ClinicProviderConfigSchema>
      v1DbcmsAppointmentTypeConfigsIdGet: (requestParameters: V1DbcmsAppointmentTypeConfigsIdGetRequest) => Promise<AppointmentTypeConfigSchema>
      v1DbcmsAppointmentTypeConfigsIdPatch: (requestParameters: V1DbcmsAppointmentTypeConfigsIdPatchRequest) => Promise<AppointmentTypeConfigSchema>
      createAppointmentTypeConfigWithResponse: (requestParameters: CreateAppointmentTypeConfigRequest) => Promise<{ value: AppointmentTypeConfigSchema, response: any}>
      createClinicConfigWithResponse: (requestParameters: CreateClinicConfigRequest) => Promise<{ value: ClinicConfigSchema, response: any}>
      createClinicProviderConfigWithResponse: (requestParameters: CreateClinicProviderConfigRequest) => Promise<{ value: ClinicProviderConfigSchema, response: any}>
      deleteAppointmentTypeWithResponse: (requestParameters: DeleteAppointmentTypeRequest) => Promise<{ value: void, response: any}>
      deleteClinicConfigWithResponse: (requestParameters: DeleteClinicConfigRequest) => Promise<{ value: void, response: any}>
      deleteClinicProviderConfigWithResponse: (requestParameters: DeleteClinicProviderConfigRequest) => Promise<{ value: void, response: any}>
      getAppointmentTypeConfigsWithResponse: (requestParameters: GetAppointmentTypeConfigsRequest) => Promise<{ value: AppointmentTypeConfigsResponse, response: any}>
      getClinicConfigByIdWithResponse: (requestParameters: GetClinicConfigByIdRequest) => Promise<{ value: ClinicConfigSchema, response: any}>
      getClinicConfigsWithResponse: (requestParameters: GetClinicConfigsRequest) => Promise<{ value: ClinicConfigFetchResponse, response: any}>
      getClinicProviderConfigByIdWithResponse: (requestParameters: GetClinicProviderConfigByIdRequest) => Promise<{ value: ClinicProviderConfigSchema, response: any}>
      getClinicProviderConfigsWithResponse: (requestParameters: GetClinicProviderConfigsRequest) => Promise<{ value: ClinicProviderConfigFetchResponse, response: any}>
      getCostCentersWithResponse: () => Promise<{ value: Array<string>, response: any}>
      updateClinicConfigWithResponse: (requestParameters: UpdateClinicConfigRequest) => Promise<{ value: ClinicConfigSchema, response: any}>
      updateClinicProviderConfigWithResponse: (requestParameters: UpdateClinicProviderConfigRequest) => Promise<{ value: ClinicProviderConfigSchema, response: any}>
      v1DbcmsAppointmentTypeConfigsIdGetWithResponse: (requestParameters: V1DbcmsAppointmentTypeConfigsIdGetRequest) => Promise<{ value: AppointmentTypeConfigSchema, response: any}>
      v1DbcmsAppointmentTypeConfigsIdPatchWithResponse: (requestParameters: V1DbcmsAppointmentTypeConfigsIdPatchRequest) => Promise<{ value: AppointmentTypeConfigSchema, response: any}>
}

/**
 * no description
 */
export class CmsApi extends runtime.BaseAPI {

    /**
     * Create an appointment type config
     * Create an appointment type config
     */
    createAppointmentTypeConfigRaw = async (requestParameters: CreateAppointmentTypeConfigRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentTypeConfigSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/appointment_type_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentTypeConfigRequestBodyToJSON(requestParameters.appointmentTypeConfigRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentTypeConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create an appointment type config
     * Create an appointment type config
     */
    createAppointmentTypeConfig = async (requestParameters: CreateAppointmentTypeConfigRequest): Promise<AppointmentTypeConfigSchema> => {
        const { runtime } = await this.createAppointmentTypeConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create an appointment type config
     * Create an appointment type config
     */
    createAppointmentTypeConfigWithResponse = async (requestParameters: CreateAppointmentTypeConfigRequest): Promise<{ value: AppointmentTypeConfigSchema, response: any}> => {
        const { runtime, response } = await this.createAppointmentTypeConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create clinic config
     * Create clinic config
     */
    createClinicConfigRaw = async (requestParameters: CreateClinicConfigRequest): Promise<{ runtime: runtime.ApiResponse<ClinicConfigSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicConfigSchemaToJSON(requestParameters.clinicConfigSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create clinic config
     * Create clinic config
     */
    createClinicConfig = async (requestParameters: CreateClinicConfigRequest): Promise<ClinicConfigSchema> => {
        const { runtime } = await this.createClinicConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create clinic config
     * Create clinic config
     */
    createClinicConfigWithResponse = async (requestParameters: CreateClinicConfigRequest): Promise<{ value: ClinicConfigSchema, response: any}> => {
        const { runtime, response } = await this.createClinicConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create clinic provider config
     * Create clinic provider config
     */
    createClinicProviderConfigRaw = async (requestParameters: CreateClinicProviderConfigRequest): Promise<{ runtime: runtime.ApiResponse<ClinicProviderConfigSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_provider_configs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicProviderConfigSchemaToJSON(requestParameters.clinicProviderConfigSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicProviderConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create clinic provider config
     * Create clinic provider config
     */
    createClinicProviderConfig = async (requestParameters: CreateClinicProviderConfigRequest): Promise<ClinicProviderConfigSchema> => {
        const { runtime } = await this.createClinicProviderConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create clinic provider config
     * Create clinic provider config
     */
    createClinicProviderConfigWithResponse = async (requestParameters: CreateClinicProviderConfigRequest): Promise<{ value: ClinicProviderConfigSchema, response: any}> => {
        const { runtime, response } = await this.createClinicProviderConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Delete appointment type
     */
    deleteAppointmentTypeRaw = async (requestParameters: DeleteAppointmentTypeRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAppointmentType.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/appointment_type_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete appointment type
     */
    deleteAppointmentType = async (requestParameters: DeleteAppointmentTypeRequest): Promise<void> => {
        const { runtime } = await this.deleteAppointmentTypeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete appointment type
     */
    deleteAppointmentTypeWithResponse = async (requestParameters: DeleteAppointmentTypeRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteAppointmentTypeRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Delete a clinic config
     * Delete a clinic config
     */
    deleteClinicConfigRaw = async (requestParameters: DeleteClinicConfigRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClinicConfig.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete a clinic config
     * Delete a clinic config
     */
    deleteClinicConfig = async (requestParameters: DeleteClinicConfigRequest): Promise<void> => {
        const { runtime } = await this.deleteClinicConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a clinic config
     * Delete a clinic config
     */
    deleteClinicConfigWithResponse = async (requestParameters: DeleteClinicConfigRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteClinicConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Delete clinic provider config
     */
    deleteClinicProviderConfigRaw = async (requestParameters: DeleteClinicProviderConfigRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClinicProviderConfig.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_provider_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete clinic provider config
     */
    deleteClinicProviderConfig = async (requestParameters: DeleteClinicProviderConfigRequest): Promise<void> => {
        const { runtime } = await this.deleteClinicProviderConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete clinic provider config
     */
    deleteClinicProviderConfigWithResponse = async (requestParameters: DeleteClinicProviderConfigRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteClinicProviderConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Get all appointment type configs
     * Get all appointment type configs
     */
    getAppointmentTypeConfigsRaw = async (requestParameters: GetAppointmentTypeConfigsRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentTypeConfigsResponse>, response: any}> => {
                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/appointment_type_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentTypeConfigsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get all appointment type configs
     * Get all appointment type configs
     */
    getAppointmentTypeConfigs = async (requestParameters: GetAppointmentTypeConfigsRequest): Promise<AppointmentTypeConfigsResponse> => {
        const { runtime } = await this.getAppointmentTypeConfigsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get all appointment type configs
     * Get all appointment type configs
     */
    getAppointmentTypeConfigsWithResponse = async (requestParameters: GetAppointmentTypeConfigsRequest): Promise<{ value: AppointmentTypeConfigsResponse, response: any}> => {
        const { runtime, response } = await this.getAppointmentTypeConfigsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get clinic config by id
     * Get clinic config by id
     */
    getClinicConfigByIdRaw = async (requestParameters: GetClinicConfigByIdRequest): Promise<{ runtime: runtime.ApiResponse<ClinicConfigSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClinicConfigById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Get clinic config by id
     * Get clinic config by id
     */
    getClinicConfigById = async (requestParameters: GetClinicConfigByIdRequest): Promise<ClinicConfigSchema> => {
        const { runtime } = await this.getClinicConfigByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get clinic config by id
     * Get clinic config by id
     */
    getClinicConfigByIdWithResponse = async (requestParameters: GetClinicConfigByIdRequest): Promise<{ value: ClinicConfigSchema, response: any}> => {
        const { runtime, response } = await this.getClinicConfigByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get all clinic configs
     * Get all clinic configs
     */
    getClinicConfigsRaw = async (requestParameters: GetClinicConfigsRequest): Promise<{ runtime: runtime.ApiResponse<ClinicConfigFetchResponse>, response: any}> => {
                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicConfigFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get all clinic configs
     * Get all clinic configs
     */
    getClinicConfigs = async (requestParameters: GetClinicConfigsRequest): Promise<ClinicConfigFetchResponse> => {
        const { runtime } = await this.getClinicConfigsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get all clinic configs
     * Get all clinic configs
     */
    getClinicConfigsWithResponse = async (requestParameters: GetClinicConfigsRequest): Promise<{ value: ClinicConfigFetchResponse, response: any}> => {
        const { runtime, response } = await this.getClinicConfigsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get clinic provider config by id
     * Get clinic provider config by id
     */
    getClinicProviderConfigByIdRaw = async (requestParameters: GetClinicProviderConfigByIdRequest): Promise<{ runtime: runtime.ApiResponse<ClinicProviderConfigSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClinicProviderConfigById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_provider_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicProviderConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Get clinic provider config by id
     * Get clinic provider config by id
     */
    getClinicProviderConfigById = async (requestParameters: GetClinicProviderConfigByIdRequest): Promise<ClinicProviderConfigSchema> => {
        const { runtime } = await this.getClinicProviderConfigByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get clinic provider config by id
     * Get clinic provider config by id
     */
    getClinicProviderConfigByIdWithResponse = async (requestParameters: GetClinicProviderConfigByIdRequest): Promise<{ value: ClinicProviderConfigSchema, response: any}> => {
        const { runtime, response } = await this.getClinicProviderConfigByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigsRaw = async (requestParameters: GetClinicProviderConfigsRequest): Promise<{ runtime: runtime.ApiResponse<ClinicProviderConfigFetchResponse>, response: any}> => {
                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_provider_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicProviderConfigFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigs = async (requestParameters: GetClinicProviderConfigsRequest): Promise<ClinicProviderConfigFetchResponse> => {
        const { runtime } = await this.getClinicProviderConfigsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigsWithResponse = async (requestParameters: GetClinicProviderConfigsRequest): Promise<{ value: ClinicProviderConfigFetchResponse, response: any}> => {
        const { runtime, response } = await this.getClinicProviderConfigsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get all cost centers
     * Get all cost centers
     */
    getCostCentersRaw = async (): Promise<{ runtime: runtime.ApiResponse<Array<string>>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/cost_centers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse<any>(response), response };
    }

    /**
     * Get all cost centers
     * Get all cost centers
     */
    getCostCenters = async (): Promise<Array<string>> => {
        const { runtime } = await this.getCostCentersRaw();
        return await runtime.value();
    }

    /**
     * Get all cost centers
     * Get all cost centers
     */
    getCostCentersWithResponse = async (): Promise<{ value: Array<string>, response: any}> => {
        const { runtime, response } = await this.getCostCentersRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update a clinic config
     * Update a clinic config
     */
    updateClinicConfigRaw = async (requestParameters: UpdateClinicConfigRequest): Promise<{ runtime: runtime.ApiResponse<ClinicConfigSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClinicConfig.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicConfigSchemaToJSON(requestParameters.clinicConfigSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update a clinic config
     * Update a clinic config
     */
    updateClinicConfig = async (requestParameters: UpdateClinicConfigRequest): Promise<ClinicConfigSchema> => {
        const { runtime } = await this.updateClinicConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update a clinic config
     * Update a clinic config
     */
    updateClinicConfigWithResponse = async (requestParameters: UpdateClinicConfigRequest): Promise<{ value: ClinicConfigSchema, response: any}> => {
        const { runtime, response } = await this.updateClinicConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update clinic provider config
     */
    updateClinicProviderConfigRaw = async (requestParameters: UpdateClinicProviderConfigRequest): Promise<{ runtime: runtime.ApiResponse<ClinicProviderConfigSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClinicProviderConfig.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/clinic_provider_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicProviderConfigSchemaToJSON(requestParameters.clinicProviderConfigSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicProviderConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update clinic provider config
     */
    updateClinicProviderConfig = async (requestParameters: UpdateClinicProviderConfigRequest): Promise<ClinicProviderConfigSchema> => {
        const { runtime } = await this.updateClinicProviderConfigRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update clinic provider config
     */
    updateClinicProviderConfigWithResponse = async (requestParameters: UpdateClinicProviderConfigRequest): Promise<{ value: ClinicProviderConfigSchema, response: any}> => {
        const { runtime, response } = await this.updateClinicProviderConfigRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get appointment type by ID
     */
    v1DbcmsAppointmentTypeConfigsIdGetRaw = async (requestParameters: V1DbcmsAppointmentTypeConfigsIdGetRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentTypeConfigSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1DbcmsAppointmentTypeConfigsIdGet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/appointment_type_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentTypeConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Get appointment type by ID
     */
    v1DbcmsAppointmentTypeConfigsIdGet = async (requestParameters: V1DbcmsAppointmentTypeConfigsIdGetRequest): Promise<AppointmentTypeConfigSchema> => {
        const { runtime } = await this.v1DbcmsAppointmentTypeConfigsIdGetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get appointment type by ID
     */
    v1DbcmsAppointmentTypeConfigsIdGetWithResponse = async (requestParameters: V1DbcmsAppointmentTypeConfigsIdGetRequest): Promise<{ value: AppointmentTypeConfigSchema, response: any}> => {
        const { runtime, response } = await this.v1DbcmsAppointmentTypeConfigsIdGetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update appointment type by ID
     */
    v1DbcmsAppointmentTypeConfigsIdPatchRaw = async (requestParameters: V1DbcmsAppointmentTypeConfigsIdPatchRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentTypeConfigSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1DbcmsAppointmentTypeConfigsIdPatch.');
        }

                        if (requestParameters.appointmentTypeConfigRequestBody === null || requestParameters.appointmentTypeConfigRequestBody === undefined) {
            throw new runtime.RequiredError('appointmentTypeConfigRequestBody','Required parameter requestParameters.appointmentTypeConfigRequestBody was null or undefined when calling v1DbcmsAppointmentTypeConfigsIdPatch.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/dbcms/appointment_type_configs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentTypeConfigRequestBodyToJSON(requestParameters.appointmentTypeConfigRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentTypeConfigSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update appointment type by ID
     */
    v1DbcmsAppointmentTypeConfigsIdPatch = async (requestParameters: V1DbcmsAppointmentTypeConfigsIdPatchRequest): Promise<AppointmentTypeConfigSchema> => {
        const { runtime } = await this.v1DbcmsAppointmentTypeConfigsIdPatchRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update appointment type by ID
     */
    v1DbcmsAppointmentTypeConfigsIdPatchWithResponse = async (requestParameters: V1DbcmsAppointmentTypeConfigsIdPatchRequest): Promise<{ value: AppointmentTypeConfigSchema, response: any}> => {
        const { runtime, response } = await this.v1DbcmsAppointmentTypeConfigsIdPatchRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCmsApi: ICmsApi  = new CmsApi();
