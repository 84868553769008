import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { State } from "@types";
import { setMessageSubject } from "shared/state/ui/careAccess/actions";
import Autocomplete from "components/Autocomplete";
import TextField from "components/TextField";
import Typography from "components/Typography";
import { InputAdornment } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

interface ISubjectComboBox {
  options: string[];
  characterLimit?: number;
  label: string;
}

const useStyles = makeStyles(({ palette }) => ({
  label: {
    color: palette?.links?.secondaryHover,
    marginBottom: 5,
  },
  limit: {
    color: palette?.appBackground?.darkGrey,
    marginTop: "8px",
    textAlign: "end",
  },
  limitReached: {
    color: `${palette.redHue} !important`,
  },
  autocomplete: {
    borderRadius: "8px",
    border: `1px solid ${palette?.text?.tertiary}`,
    "&.Mui-focused": {
      border: `1px solid ${palette?.appBackground?.blue}`,
    },
    "@media (min-width: 576px)": {
      width: "277px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: 8,
      paddingTop: 4.5,
      paddingBottom: 4.5,
    },
    "& .MuiAutocomplete-input": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
  comboContainer: {
    "@media (min-width: 576px)": {
      width: "fit-content",
    },
    marginBottom: 15,
  },
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "&[aria-selected='true']": {
          backgroundColor: palette?.appBackground?.blue,
          color: palette.white,
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
      },
    },
  },
  autocompleteLimitReached: {
    border: `1px solid ${palette.redHue} !important`,
    "& .MuiInputBase-root": {
      backgroundColor: palette.pinkPastel,
    },
  },
}));

const SubjectComboBox: React.FC<ISubjectComboBox> = ({
  options,
  characterLimit,
  label,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    message: { messageSubject, isPaymentsRedirect },
  } = useSelector((state: State) => state.ui.careAccess);
  const [subject, setSubject] = useState(messageSubject);

  useEffect(() => {
    if (subject !== messageSubject) {
      setSubject(messageSubject);
    }
  }, [messageSubject]);

  useEffect(() => {
    if (isPaymentsRedirect) {
      dispatch(setMessageSubject("Payment questions"));
    }
  }, [isPaymentsRedirect]);

  const limitReached = useMemo(
    () => subject?.length === characterLimit,
    [subject]
  );

  const handleChange = (newValue: any) => {
    setSubject(newValue);
    dispatch(setMessageSubject(newValue));
  };

  return (
    <div className={classes.comboContainer}>
      <Typography className={classes.label} appearance="smallBody">
        {label}
      </Typography>
      <Autocomplete
        id="care-access-subject-dropdown"
        className={clsx(
          classes.autocomplete,
          limitReached && classes.autocompleteLimitReached
        )}
        freeSolo
        forcePopupIcon
        disableClearable={!subject}
        options={options}
        clearOnBlur={false}
        ListboxProps={{
          className: classes.listboxItem,
        }}
        renderInput={(params: any) => (
          <TextField
            value={subject}
            fullWidth
            placeholder={"Select or type"}
            maxChars={characterLimit}
            displayMaxLengthTypography={false}
            onChange={(event) => {
              handleChange(event.target.value);
            }}
            InputProps={{
              startAdorment: (
                <InputAdornment position="start">
                  <ErrorIcon color="error" />
                </InputAdornment>
              ),
            }}
            {...params}
            inputProps={{
              ...params.inputProps,
              "aria-required": "true",
              "aria-label": `Select or type, maximum ${characterLimit} characters`,
            }}
          />
        )}
        value={subject}
        onChange={(_, selectedValue) => {
          const newValue = selectedValue?.slice(0, 65) || "";
          handleChange(newValue);
        }}
      />
      {!!characterLimit && (
        <Typography
          className={clsx(limitReached && classes.limitReached, classes.limit)}
          appearance="caption"
        >
          Character limit {subject?.length || 0}/{characterLimit}
        </Typography>
      )}
    </div>
  );
};

export default SubjectComboBox;
