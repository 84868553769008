import React from "react";
import TextField from "components/TextField";
import { styled } from "@mui/material";

import Box from "components/Box";
import Typography from "components/Typography";
import RadioGroup from "components/Radio/RadioGroup";
import FormControlLabel from "components/Form/FormControlLabel";
import Radio from "components/Radio";

export const StyledTextField = styled(TextField)(() => ({
  "& .MuiFilledInput-input": {
    borderRadius: "8px",
    paddingBottom: "4px",
  },

  "& .MuiFormHelperText-root": {
    minHeight: "20px",
    marginTop: "4px",
  },

  "& .MuiInputLabel-root": {
    fontSize: "13.3px",
    transform: "translate(12px, 6px) scale(1)",
  },
}));

interface PhoneSectionProps {
  phoneType: string | undefined;
  setPhoneType: (type: string | undefined) => void;
  displayedPhoneNumber: string | undefined;
  setDisplayedPhoneNumber: (phone: string | undefined) => void;
  phoneValidationError: string;
  setPhoneValidationError: (error: string) => void;
  phoneTypeError: boolean;
  setPhoneTypeError: (error: boolean) => void;
  handlePhoneBlur: () => void;
  handlePhoneInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  member: {
    cellPhone?: string;
    homePhone?: string;
  };
}

export const PhoneSection: React.FC<PhoneSectionProps> = ({
  phoneType,
  displayedPhoneNumber,
  phoneValidationError,
  phoneTypeError,
  handlePhoneBlur,
  handlePhoneInputChange,
  handlePhoneTypeChange,
}) => (
  <>
    <StyledTextField
      variant="filled"
      label="Phone number"
      id="activate-account-phone"
      fullWidth
      margin="dense"
      onChange={handlePhoneInputChange}
      onBlur={handlePhoneBlur}
      value={displayedPhoneNumber || ""}
      error={!!phoneValidationError}
      helperText={phoneValidationError || ""}
      inputProps={{ "aria-label": "Phone number input" }}
    />
    <Box>
      <Typography color={phoneTypeError ? "error" : "textPrimary"}>
        Select phone number type
      </Typography>
    </Box>
    <RadioGroup
      aria-label="Select phone number type"
      name="phoneType"
      value={phoneType}
      onChange={handlePhoneTypeChange}
      row
    >
      <FormControlLabel
        checked={phoneType === "Mobile"}
        value="Mobile"
        control={<Radio />}
        label="Mobile"
        aria-label="This is my mobile phone number"
        id="radio-btn-mobile-phone"
      />
      <FormControlLabel
        checked={phoneType === "Home"}
        value="Home"
        control={<Radio />}
        label="Home"
        aria-label="This is my home phone number"
        id="radio-btn-home-phone"
      />
    </RadioGroup>
  </>
);
