import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { EventContentArg } from "@fullcalendar/core";
import { CalendarStaticDataResponse } from "shared/api/src/models/CalendarStaticDataResponse";
import { CalendarStaticDataResponseClinicDetailsProvidersByServiceType } from "shared/api/src/models/CalendarStaticDataResponseClinicDetailsProvidersByServiceType";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import { XOCalProviderAppointment } from "shared/fetch/src/models/XOCalProviderAppointment";
import { addDays, getDay, isWithinInterval } from "date-fns";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";

export const ALL_PROVIDERS = "all-providers";
export const ACTIVE_PROVIDERS = "all-active-providers";
export const DAY_VIEW = "resourceTimeGridDay";
export const WEEK_VIEW = "timeGridWeek";
export const ALL_CLINICS = "all-clinics";
export const ALL_TYPES = "all-types";

export function isGroupSlot(slot: SlotOutput) {
  if (slot?.maxPatients === undefined || slot?.maxOverbook === undefined) {
    return false;
  }
  const totalNumberPatients = slot?.maxPatients! + slot?.maxOverbook!;
  return totalNumberPatients && totalNumberPatients > 1;
}

//  lowercase abbreviations's for Sunday and Thursday needed to match information coming in from Calendar Static Data
export const dayOfWeekAbbreviations: string[] = [
  "su",
  "M",
  "T",
  "W",
  "th",
  "F",
  "S",
];

export const findDisabledDates = (
  e: Date | dateFns,
  oooDays?: Set<{
    start: string;
    end: string;
    provider: string;
  }>,
  operatingDaysNumerical?: Number[]
) => {
  let shouldDisableDate = false;
  if (oooDays) {
    const oooArray = Array.from(oooDays);
    oooArray.forEach((day) => {
      // @ts-ignore
      if (
        isWithinInterval(e as Date, {
          start: new Date(day.start),
          end: addDays(new Date(day.end), 1),
        })
      ) {
        shouldDisableDate = true;
      }
    });
  }
  if (operatingDaysNumerical) {
    if (!operatingDaysNumerical.includes(getDay(new Date(e as Date)))) {
      shouldDisableDate = true;
    }
  }
  return shouldDisableDate;
};

export const getEventClassNames = (eventInfo: EventContentArg): string[] => {
  const { creating, visibility } = eventInfo?.event?.extendedProps || {};
  const classNames = creating ? ["creating"] : [];

  switch (visibility) {
    case "internal":
      return ["internal"];
    case "hold":
      return ["hold", ...classNames];
    case "accessible":
      return ["accessible"];
    default:
      return classNames;
  }
};

interface EnhancedProviderInfoObject extends XOCalProvider {
  serviceType?: string;
}

export interface ProvidersKeyedByProviderId {
  [providerId: string]: EnhancedProviderInfoObject;
}

export interface ClinicAppointmentsKeyedByAppointmentId {
  [apptId: string]: XOCalProviderAppointment;
}

export const getProvidersObjectKeyedById = (
  clinicData?: CalendarStaticDataResponse
) => {
  // constructs a providers object keyed by the unique providerId for quick lookup in components
  // example final shape with a single provider in the object
  // {
  //   "csabamd": { // all info keyed by unique id
  //     appointmentsByModality : (5) [{…}, {…}, {…}, {…}, {…}]
  //     avatarUrl : undefined
  //     bioUrl : "/v1/xop_ctm_profiles/21"
  //     globalId : "csaba-doctor-md"
  //     guild : "/v1/users/ttrcen6n/avatar/csabadoctor."
  //     name : "Csaba Doctor"
  //     providerId : "csabamd"
  //     serviceType : "Physician"
  //   },
  // }

  const providersObject: ProvidersKeyedByProviderId = {};
  clinicData?.clinicDetails?.providersByServiceType?.map(
    (
      providersByServiceTypeArr: CalendarStaticDataResponseClinicDetailsProvidersByServiceType
    ) => {
      // grab this serviceType property so we can later assign to as a prop to our providers object
      const serviceType = providersByServiceTypeArr.serviceType;

      // iterate through providers
      providersByServiceTypeArr.providers?.map((p: XOCalProvider) => {
        const providerId = p.providerId!;
        providersObject[providerId] = { ...p };
        // add serviceType property to each provider for easy lookup
        providersObject[providerId].serviceType = serviceType;
      });
    }
  );
  return providersObject;
};

export const getAppointmentObjectKeyedById = (
  clinicData?: CalendarStaticDataResponse
) => {
  // similarly, construct an appointment types object keyed by unique appointment type (ie: 38000)
  const appointmentObjectKeyedById: ClinicAppointmentsKeyedByAppointmentId = {};
  clinicData?.clinicDetails?.providersByServiceType?.map(
    (
      providersByServiceTypeArr: CalendarStaticDataResponseClinicDetailsProvidersByServiceType
    ) => {
      // iterate through providers
      providersByServiceTypeArr.providers?.map((p: XOCalProvider) => {
        // for each appointment type create an indexed key in our new object ...
        p.appointmentsByModality?.map((appt: XOCalProviderAppointment) => {
          // ... if that key does not already exist
          const apptId = appt.id!;
          if (!Boolean(appointmentObjectKeyedById[apptId])) {
            // assign the appt object as the value of the new key
            appointmentObjectKeyedById[apptId] = { ...appt };
          }
        });
      });
    }
  );
  return appointmentObjectKeyedById;
};

export function capitalizeFirstLetterOfEveryWord(str: string | undefined) {
  if (!Boolean(str)) {
    return;
  }
  const words = (str as string).split(" ");

  return words
    .map((word) => {
      // we don't want to capitalize the "min" in the duration
      // we want something like "Office Visit (30 min)
      if (word === "min)") {
        return word;
      } else {
        return word[0]?.toUpperCase() + word.substring(1);
      }
    })
    .join(" ");
}

export const getIsClinicOpen = (
  date: string | null,
  operatingDaysNumerical: number[],
  clinicTimeZone: string | undefined
) => {
  if (!Boolean(date) || !Boolean(clinicTimeZone)) {
    return false;
  }

  const dateInClinicTZ = utcToZonedTime(new Date(date!), clinicTimeZone!);
  const dateDayOfWeekOneIndexed = getDay(addDays(dateInClinicTZ, 1));
  return operatingDaysNumerical.includes(dateDayOfWeekOneIndexed!);
};

export const formatDateWithTimezone = (
  date: string | Date,
  formatString: string,
  timezone: string
): string => {
  try {
    const dateObj = typeof date === "string" ? new Date(date) : date;
    const zonedDate = utcToZonedTime(dateObj, timezone);
    return formatTz(zonedDate, formatString, { timeZone: timezone });
  } catch (error) {
    return "";
  }
};
