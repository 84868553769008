import React from "react";
import { AppointmentPatientInfoObject } from "shared/fetch/src/models/AppointmentPatientInfoObject";

interface IProps {
  patientInfo: AppointmentPatientInfoObject | undefined;
}

export default function PatientInformation({ patientInfo }: IProps) {
  if (!patientInfo) {
    return null;
  }
  const patientDobAgeGender = `${patientInfo?.dob} | ${patientInfo?.age} | ${patientInfo?.gender} `;
  const { preferredPhone, cellPhone, homePhone } = patientInfo;
  const phoneNumber = preferredPhone || cellPhone || homePhone;

  return (
    <div style={{ display: "flex" }}>
      {patientDobAgeGender}
      {/* if no phone number, do not show */}
      {phoneNumber && (
        <>
          {/* spaces and bullet point */}
          <span style={{ padding: "0px 3px" }}> &#8226; </span>{" "}
          <p>{phoneNumber}</p>
        </>
      )}
    </div>
  );
}
