import React from "react";
import { Drawer, ButtonBase, Button } from "@mui/material";
import classes from "./index.module.css";
import Heading from "components/DynamicHeadings";
import Divider from "components/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { ValuesType } from "./utils";
import Typography from "components/Typography";
import { format } from "date-fns";
import { timezoneOptions } from "shared/utils/timeZone";
import { XOCalProvider } from "shared/api/src/models/XOCalProvider";
import SearchAndScheduleMember from "./SearchAndScheduleMember";
import useScheduleAppointment from "shared/features/xocal/useScheduleAppointment";
import { useSearchParams } from "react-router-dom";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";

const AddAdditionalMemberDrawer = ({
  open,
  setOpen,
  values,
  timezone,
  provider,
  setField,
  handleChange,
  slotId,
  slot,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  values: ValuesType;
  timezone: string;
  provider: XOCalProvider;
  setField: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<any>) => void;
  slotId: string;
  slot: SlotOutput | undefined;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCloseSlot = () => {
    searchParams.delete("member");
    setSearchParams(searchParams);
    setOpen(false);
  };

  const member = searchParams.get("member");

  const getTimezoneName = (tz: any) => {
    return timezoneOptions.find((option) => option.timezone === tz)?.name;
  };

  const { mutateAsync: scheduleAppointment } = useScheduleAppointment();

  const appointmentTypeOfScheduledVisit = !!values?.appointments?.length
    ? values?.appointments[0].appointmentType
    : "";

  const handleScheduleMember = () => {
    // TODO: logic for scheduling a user
    slotId &&
      scheduleAppointment({
        id: slotId,
        scheduleAppointmentCore: {
          patientId: member!,
          // when an additional member is added, we need to use the visit type of the other appointment that is scheduled already
          appointmentType: appointmentTypeOfScheduledVisit,
          reason: values?.reason!,
          // for now all appts scheduled through xocal should create self schedule components,
          // this could change in the future-- without this boolean the system currently will
          // not create a component when booking a visit, which will cause downstream bugs
          selfSchedule: true,
        },
      });
    handleCloseSlot();
    searchParams.delete("member");
    setSearchParams(searchParams);
  };

  return (
    <Drawer
      open={open}
      transitionDuration={400}
      anchor="right"
      classes={{
        paper: classes.drawer,
      }}
      role="dialog"
      aria-labelledby="drawer-title"
      onClose={handleCloseSlot} // TODO: require this prop on our imported Drawer component
      data-testid="slot-action-drawer"
    >
      <div className={classes.topContainer}>
        <div className={classes.titleContainer}>
          <Heading.H appearance="h5" className={classes.text} id="drawer-title">
            Schedule Member
          </Heading.H>
          <ButtonBase
            role="button"
            aria-label={`Close add additional member dialog`}
            onClick={handleCloseSlot}
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
        </div>
        <Divider />
        <Typography appearance="body" className={classes.scheduleMemberText}>
          Schedule a Member to the slot for{" "}
          {values?.startAt &&
            format(new Date(values?.startAt), "eee, MMMM d, yyyy")}{" "}
          @{values?.startAt && format(new Date(values?.startAt), "p")}{" "}
          {getTimezoneName(timezone)} with {provider?.name}
        </Typography>
      </div>
      <div className={classes.scheduleMemberText}>
        <SearchAndScheduleMember
          values={values}
          slot={slot}
          setField={setField}
          handleChange={handleChange}
          additionalMember
          editMember={false}
          timezone={timezone}
        />
      </div>

      <div className={classes.buttonContainer}>
        <Button
          className={classes.createUpdateButton}
          onClick={handleScheduleMember}
          variant="contained"
          disabled={!member || slot?.appointmentTypes?.length === 0}
        >
          Schedule visit
        </Button>
      </div>
    </Drawer>
  );
};

export default AddAdditionalMemberDrawer;
