import React, { useState } from "react";
import { Link } from "components/Button";
import makeStyles from "@mui/styles/makeStyles";
import Heading from "components/DynamicHeadings";
import InsuranceRightImage from "styles/images/insurance-tool/insurance-human.png";
import InsuranceHeader from "./InsuranceHeader";
import InsuranceTypeAutoComplete from "./InsuranceTypeAutoComplete";
import InsuranceForm from "./InsuranceForm";
import { useSelector } from "react-redux";
import { getFeatures } from "shared/features/featureFlags/selectors";

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  container: {
    backgroundColor: "white",
    marginBottom: spacing(3),
    borderRadius: "10px",
    color: palette.appBackground?.darkBlue,
    overflow: "hidden",
  },
  insuranceImg: {
    width: "160px",
    height: "160px",
    alignSelf: "flex-end",
    [breakpoints?.only("xs")]: {
      display: "none",
    },
    [breakpoints?.only("sm")]: {
      display: "none",
    },
  },
  insuranceBody: {
    display: "flex",
    padding: spacing(3),
    justifyContent: "space-between",
  },
  insuranceBodyLeft: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "70%",
    marginRight: spacing(2),
    [breakpoints?.only("xs")]: {
      width: "100%",
    },
    [breakpoints?.only("sm")]: {
      width: "100%",
    },
  },
  insuranceType: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "16px",
    "& :first-child": {
      fontWeight: "700",
    },
  },
  changeButton: {
    textDecoration: "underline",
    color: palette.secondary.main,
    cursor: "pointer",
    marginLeft: "6px",
  },
  insuranceTypeContainer: {
    marginBottom: "24px",
  },
}));

export default function InsuranceOnboardingTool() {
  const featureFlags = useSelector(getFeatures);
  const hasInsuranceOnboarding = featureFlags.hasInsuranceOnboarding();
  const classes = useStyles();
  // initial state should not be selected for the user (at this time) because we do not know what their default insurance type is
  const [insuranceType, setInsuranceType] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);

  if (!hasInsuranceOnboarding) {
    return null;
  }

  return (
    <div className={classes.container}>
      <InsuranceHeader isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      {isExpanded && (
        <>
          <div className={classes.insuranceBody}>
            <div className={classes.insuranceBodyLeft}>
              <div>
                <Heading.H appearance="h6" color="textSecondary">
                  Insurance type
                </Heading.H>
                {!insuranceType ? (
                  <InsuranceTypeAutoComplete
                    setInsuranceType={setInsuranceType}
                  />
                ) : (
                  <div className={classes.insuranceType}>
                    <div>{insuranceType} </div>
                    <Link
                      aria-label="Change insurance type"
                      className={classes.changeButton}
                      onClick={() => setInsuranceType("")}
                      data-e2e="change-insurance-type"
                      underline="hover"
                    >
                      change
                    </Link>
                  </div>
                )}
              </div>
              <InsuranceForm insuranceType={insuranceType} />
            </div>
            <img
              alt="insuranceImg"
              className={classes.insuranceImg}
              src={InsuranceRightImage}
            />
          </div>
        </>
      )}
    </div>
  );
}
