import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import classes from "./index.module.css";
import { ButtonBase, List, ListItem } from "@mui/material";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import useDeleteSlot from "shared/features/xocal/useDeleteSlot";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";
import { ValuesType } from "./utils";
import { format } from "date-fns";
import { useQueryClient } from "react-query";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";

interface IProps {
  deleteBookedSlotConfirmationModalOpen: boolean;
  setDeleteBookedSlotConfirmationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleClose: () => void;
  slotId: string;
  values: ValuesType;
  provider: XOCalProvider | undefined;
  slot: SlotOutput | undefined;
}

const DeleteBookedSlotConfirmationModal = ({
  deleteBookedSlotConfirmationModalOpen,
  setDeleteBookedSlotConfirmationModalOpen,
  handleClose,
  slotId,
  provider,
  values,
  slot,
}: IProps) => {
  const { showSuccessStatusSnackbar } = useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const appointments = (slot && slot.appointments) || [];

  const { mutateAsync: deleteSlot } = useDeleteSlot({
    onSuccess: () => {
      showSuccessStatusSnackbar(
        `Success! The slot for ${provider?.name} on ${
          values.startAt && format(new Date(values.startAt), "MMM d, yyyy")
        } @ ${
          values.startTime
        } has been deleted and scheduled visits were sent to the conflicts queue.`
      );
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
  });

  const handleDeleteSlot = () => {
    slotId && deleteSlot({ id: slotId });
    setDeleteBookedSlotConfirmationModalOpen(false);
    handleClose();
  };

  return (
    <Dialog open={deleteBookedSlotConfirmationModalOpen}>
      {" "}
      <div
        className={classes.deleteBookedSlotConfirmationModal}
        data-testid="booked-cancel-confirm-modal"
      >
        <div
          className={classes.deleteBookedSlotConfirmationModalTitleContainer}
        >
          <DialogTitle
            className={classes.deleteBookedSlotConfirmationModalTitle}
          >
            {" "}
            This slot has {appointments?.length} visit
            {appointments && appointments?.length > 1 && "s"} scheduled
          </DialogTitle>
          <ButtonBase
            className={classes.deleteBookedSlotConfirmationModalCancel}
            role="button"
            aria-label={"Close delete slot modal"}
            onClick={() => setDeleteBookedSlotConfirmationModalOpen(false)}
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
        </div>

        <DialogContent>
          <DialogContentText
            variant={"body2"}
            sx={{ width: "90%" }}
            id="modal-delete-button-description"
          >
            Deleting slots will send scheduled visits to the conflicts queue.
          </DialogContentText>
          <DialogContentText variant={"body2"}>
            <List className={classes.deleteBookedSlotConfirmationModalList}>
              {appointments &&
                appointments.map((appointment, key) => (
                  <ListItem
                    alignItems="flex-start"
                    key={key}
                    sx={{
                      display: "list-item",
                      fontWeight: "bold",
                      paddingLeft: "5px",
                      fontSize: "16px",
                    }}
                  >
                    {appointment.patientInfo?.name}
                  </ListItem>
                ))}
            </List>
          </DialogContentText>
          <DialogActions
            className={classes.deleteBookedSlotConfirmationModalActions}
          >
            <Button
              color={"primary"}
              onClick={handleDeleteSlot}
              aria-label="Delete"
              aria-describedby="modal-delete-button-description"
            >
              Delete
            </Button>
            <Button
              color={"secondary"}
              onClick={() => setDeleteBookedSlotConfirmationModalOpen(false)}
              aria-label={"Go back, do not delete slot"}
            >
              {" "}
              Go back
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default DeleteBookedSlotConfirmationModal;
