// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Modality,
    ModalityFromJSON,
    ModalityToJSON,
} from './Modality';
import {
    SynopsisProvider,
    SynopsisProviderFromJSON,
    SynopsisProviderToJSON,
} from './SynopsisProvider';


/**
 * 
 * @export
 * @interface SynopsisSite
 */
export interface SynopsisSite  {
    /**
     * 
     * @type {number}
     * @memberof SynopsisSite
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SynopsisSite
     */
    name?: string;
    /**
     * 
     * @type {Array<Modality>}
     * @memberof SynopsisSite
     */
    modalities?: Array<Modality>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SynopsisSite
     */
    hoursOfOperation?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<SynopsisProvider>}
     * @memberof SynopsisSite
     */
    providers?: Array<SynopsisProvider>;
}

export function SynopsisSiteFromJSON(json: any): SynopsisSite {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'modalities': !exists(json, 'modalities') ? undefined : (json['modalities'] as Array<any>).map(ModalityFromJSON),
        'hoursOfOperation': !exists(json, 'hours_of_operation') ? undefined : json['hours_of_operation'],
        'providers': !exists(json, 'providers') ? undefined : (json['providers'] as Array<any>).map(SynopsisProviderFromJSON),
    };
}

export function SynopsisSiteToJSON(value?: SynopsisSite): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'modalities': value.modalities === undefined ? undefined : (value.modalities as Array<any>).map(ModalityToJSON),
        'hours_of_operation': value.hoursOfOperation,
        'providers': value.providers === undefined ? undefined : (value.providers as Array<any>).map(SynopsisProviderToJSON),
    };
}


