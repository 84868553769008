import React, { FunctionComponent, ReactElement } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "components/Box";
import Dialog from "..";
import Button from "components/Button";
import DialogTitle from "../DialogTitle";
import Typography from "components/Typography";

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirmClick?: () => void;
  onCancelClick?: () => void;
  title?: ReactElement | string;
  content?: ReactElement | string;
  appearance: string;
  maxWidth?: any;
  confirmButtonLabel?: any;
  cancelButtonLabel?: any;
  className?: string;
  extraContent?: ReactElement | string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    width: 60,
    height: 60,
    marginBottom: theme.spacing(2),
  },
}));

const AlertDialog: FunctionComponent<IProps> = ({
  open,
  onClose,
  onCancelClick,
  onConfirmClick,
  title = "Are you sure?",
  content = "If you delete this, it's gone for good.",
  appearance = "warning",
  maxWidth,
  confirmButtonLabel = "Delete",
  cancelButtonLabel = "Cancel",
  className = "",
  extraContent = null,
}) => {
  const classes = useStyles();

  const isCalculatorPage = appearance === "calculator";

  const renderIcon = () => {
    switch (appearance) {
      case "warning":
        return <WarningIcon className={classes.icon} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      ariaLabelledBy="alert-dialog-title"
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      data-e2e="alert-dialog"
      data-testid="alert-dialog"
      className={className}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DialogTitle
        component="div"
        onClose={onClose}
        style={{
          padding: "10px 16px 0px",
        }}
      />
      <div
        className="iconContainer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "0px 50px 70px 50px",
        }}
      >
        {renderIcon()}
        {title && (
          <Typography component="h1" appearance="h4" id="alert-dialog-title">
            {title}
          </Typography>
        )}
        <Box
          component="p"
          my={2}
          textAlign={isCalculatorPage ? "left" : "center"}
        >
          {content}
        </Box>
        {!isCalculatorPage && (
          <div style={{ marginTop: 10 }}>
            {onConfirmClick && (
              <Button
                data-e2e="alert-dialog-confirm-button"
                data-testid="alert-dialog-confirm-button"
                color="primary"
                onClick={onConfirmClick}
                style={{ marginRight: 10 }}
              >
                {confirmButtonLabel}
              </Button>
            )}
            {onCancelClick && (
              <Button
                data-e2e="alert-dialog-cancel-button"
                data-testid="alert-dialog-cancel-button"
                color="secondary"
                onClick={onCancelClick}
              >
                {cancelButtonLabel}
              </Button>
            )}
          </div>
        )}
        {isCalculatorPage && (
          <div style={{ marginTop: 10 }}>
            {onCancelClick && (
              <Button
                data-e2e="alert-dialog-cancel-button"
                data-testid="alert-dialog-cancel-button"
                color="primary"
                onClick={onCancelClick}
                style={{ marginRight: 10 }}
              >
                {cancelButtonLabel}
              </Button>
            )}
            {onConfirmClick && (
              <Button
                data-e2e="alert-dialog-confirm-button"
                data-testid="alert-dialog-confirm-button"
                color="secondary"
                onClick={onConfirmClick}
              >
                {confirmButtonLabel}
              </Button>
            )}
          </div>
        )}
        {extraContent && extraContent}
      </div>
    </Dialog>
  );
};

export default AlertDialog;
