import React, { useRef } from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Theme, Tooltip } from "@mui/material";
import { Drawer } from "@mui/material";
import Typography from "components/Typography";
import Divider from "components/Divider";
import EocBadge from "components/EocBadge";
import palette from "styles/mui/palette";

import useFormatDate from "shared/utils/useFormatDate";

import Avatar from "components/Avatar";
import clsx from "clsx";
import { AvailableTimeslotsResponseResults } from "shared/fetch/src/models/AvailableTimeslotsResponseResults";
import { timezoneOptions } from "shared/utils/timeZone";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import Button from "components/Button";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import Box from "components/Box";
import IconButton from "components/Button/IconButton";
import Asset from "components/Asset";
import useGetAvailableTimeslots from "shared/features/selfSchedule/enhancements/useGetAvailableTimeslots";
import { useSelector } from "react-redux";
import { GetAvailableTimeslotsSchedulingTypeEnum } from "shared/fetch/src/apis/SlotsApi";
import { getMemberId } from "shared/state/ui/member/selectors";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import FetchMoreOnScroll from "components/FetchMoreOnScroll";
import { scrollPosition$ } from "containers/Routing";
import { ProviderSummarySchema } from "shared/fetch/src/models/ProviderSummarySchema";
import CircularProgress from "components/Progress/CircularProgress";
import { useQueryClient } from "react-query";
import { omit } from "lodash";
import useMobileCheck from "hooks/useMobileCheck";
import { format, parseISO } from "date-fns";
import { State } from "@types";

const useStyles: any = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowY: "scroll",
  },
  profileTitle: {
    color: theme?.palette?.appBackground?.black,
    fontSize: theme.typography.body.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  profileSubTitle: {
    color: theme?.palette?.text?.secondary,
    fontSize: theme.typography.bodySmall.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  apptDetails: {
    color: theme?.palette?.text?.secondary,
    paddingTop: theme.spacing(0.5),
    fontSize: theme.typography.bodySmall.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  modalHeader: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    minHeight: theme.spacing(12.1),
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme?.palette?.appBackground?.main,
    marginTop: theme.spacing(1),
  },
  sticky: {
    marginTop: "32px",
    position: "sticky",
    background: "white",
    top: 0,
    zIndex: 1,
    marginBottom: 16,
  },
  profileName: {
    paddingLeft: theme.spacing(1),
  },
  slotDate: {
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  slotDateBold: {
    fontWeight: "bold",
  },
  slotPill: {
    backgroundColor: `${theme.palette?.tab?.pill?.primary}`,
    fontSize: theme.typography.bodySmall.fontSize,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette?.white,
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette?.blueHover,
    },
  },
  hidden: {
    display: "none",
  },
  drawerTitle: {
    fontSize: theme.typography.body.fontSize,
    left: theme.spacing(2),
    top: theme.spacing(2),
  },
  loadingSpinnerContainer: {
    position: "fixed",
    right: theme.spacing(4),
    bottom: 0,
    width: "100%",
    backgroundColor: "transparent",
    zIndex: 1000,
    height: "60px",
    display: "flex",
    justifyContent: "right",
  },
  spinner: {
    color: theme.palette.appBackground?.blue,
  },
  relative: {
    position: "relative",
  },
  showMore: {
    paddingTop: theme.spacing(1.5),
  },
  timeOfDay: {
    fontSize: theme.typography.bodySmall.fontSize,
  },
  truncated: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: theme.spacing(37.5),
    display: "block",
  },
  tooltip: {
    backgroundColor: `${theme.palette.appBackground?.main} !important`,
    color: `${theme.palette.text.secondary} !important`,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.text.strokes}`,
  },
  badge: {
    marginTop: "4px",
  },
  dateTimezoneContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

const CloseButton = (props: any) => (
  <Box color="text.tertiary" position="absolute" right={-8} top={-8}>
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={(e) => {
        if (props.onClose) {
          props.onClose(e);
        }
      }}
      size="large"
    >
      <Asset
        name="icon-close"
        style={{
          width: 16,
          height: 16,
          display: "inline-block",
          fill: "currentColor",
          textAlign: "center",
        }}
      />
    </IconButton>
  </Box>
);

const useGetMemberTimezone = (memberId: string) => {
  const { accountInfo = {} } = useGetAccountInfo({ id: memberId });
  const { timezone: userPreferredTimezone } = accountInfo;
  return (
    userPreferredTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
};

interface IProps {
  provider: ProviderSummarySchema;
  clinicId: string;
  clinicName: string;
  visitType: any;
  selectedStartDate: string;
  seeMoreAvailability: boolean;
  infiniteScrollMode: boolean;
  setInfiniteScrollMode: any;
  open: boolean;
  onClose: () => void;
  onSelectDate: (slotId: string, slot: SlotOutput) => void;
  appointmentName: string;
  careAccessState?: string;
}

const SlotsDrawer = ({
  provider,
  clinicId,
  clinicName,
  visitType,
  selectedStartDate,
  seeMoreAvailability,
  infiniteScrollMode,
  setInfiniteScrollMode,
  open,
  onClose,
  onSelectDate,
  appointmentName,
  careAccessState,
}: IProps) => {
  const classes = useStyles();
  const formatDate = useFormatDate();
  const parentDiv = useRef(null);

  const startDate = selectedStartDate;

  const memberId = useSelector(getMemberId) || "";
  const userTimezone = useGetMemberTimezone(memberId);
  const {
    services: { serviceLineId },
  } = useSelector((state: State) => state.ui.careAccess);
  const usState = !!careAccessState
    ? careAccessState
    : visitType?.preferredState;
  const timeZoneDisplay =
    timezoneOptions.find((tz) => tz.timezone === userTimezone)?.name ||
    userTimezone;
  const queryClient = useQueryClient();
  const isMobile = useMobileCheck();
  const anchor = isMobile ? "bottom" : "right";
  const drawerWidth = isMobile ? "100%" : "425px";

  enum SlotsItemCountEnum {
    TWO = "2",
    FOUR = "4",
    SEVEN = "7",
  }

  const requestObj = {
    // @ts-ignore: Object is possibly 'null'.
    id: memberId,
    staffGlobalId: provider?.staffGlobalId,
    startDate,
    itemCount: seeMoreAvailability
      ? SlotsItemCountEnum.SEVEN
      : SlotsItemCountEnum.TWO,
    timezone: userTimezone,
    usState,
    appointmentType: visitType.appointmentId,
    schedulingType: GetAvailableTimeslotsSchedulingTypeEnum.SelfSchedule,
    clinicId,
    serviceLineId,
  };

  const queryKey = omit(requestObj, ["startDate"]);

  const {
    results,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching: isLoading,
  } = useGetAvailableTimeslots(requestObj);

  const showMoreHandler = () => {
    fetchNextPage();
    setInfiniteScrollMode(true);
  };

  return (
    <Drawer
      data-e2e="available-slots-drawer"
      anchor={anchor}
      open={open}
      PaperProps={{
        style: { width: drawerWidth, height: isMobile ? "100%" : undefined },
        onScroll: (e: { target: any }) => {
          const target = e.target;
          if (target instanceof Element) {
            if (target.scrollTop && target.clientHeight) {
              scrollPosition$.next(target.scrollTop + target.clientHeight);
            }
          }
        },
      }}
      aria-labelledby="drawer-title"
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.sticky}>
        <Typography
          appearance="h5"
          id="drawer-title"
          className={classes.drawerTitle}
        >
          Choose time
        </Typography>
        <CloseButton
          onClose={() => {
            queryClient.resetQueries({
              queryKey: ["availableTimeslots", queryKey],
              exact: true,
            });
            onClose();
          }}
        />
        <div className={classes.modalHeader}>
          <Avatar
            containerStyle={{ margin: 0 }}
            displayText={provider?.name}
            src={provider?.avatarUrl}
            size="forty"
            isPresentational // this instance is wrapped in an anchor element
          />
          <section>
            <Typography
              appearance="h5"
              className={clsx(classes.profileTitle, classes.profileName)}
            >
              {provider?.name}
            </Typography>
            <Box style={{ display: "flex" }}>
              <Typography
                appearance="body"
                className={clsx(classes.profileSubTitle, classes.profileName)}
              >
                {provider?.guilds ? provider.guilds[0] : ""}
              </Typography>
              {provider.isPcp && (
                <EocBadge
                  className={classes.badge}
                  badge={{
                    abbreviation: "PCP",
                    color: palette?.white,
                    backgroundColor: palette?.appBackground?.navy,
                  }}
                />
              )}
            </Box>
            <Tooltip
              aria-label={`${appointmentName}, ${clinicName}`}
              title={`${appointmentName}, ${clinicName}` || ""}
              placement="bottom"
              className={clsx(classes.apptDetails, classes.profileName)}
              classes={{ tooltip: classes.tooltip }}
            >
              <span className={classes.truncated}>
                {appointmentName}, {clinicName}
              </span>
            </Tooltip>
          </section>
        </div>
      </div>
      <div ref={parentDiv}>
        {isLoading && !isFetchingNextPage && (
          <div className={classes.loadingSpinnerContainer}>
            <CircularProgress className={classes.spinner} />
          </div>
        )}

        {(!isLoading || isFetchingNextPage) &&
          results?.map(
            (result: AvailableTimeslotsResponseResults, index: number) => {
              const hasMorningSlots = result?.slots?.morning?.length;
              const hasAfternoonSlots = result?.slots?.afternoon?.length;
              const isoDate = parseISO(result.tzDate || "");
              const formattedDate = format(isoDate, "EEE MMM d");
              const [weekday, month, day] = formattedDate.toString().split(" ");

              return (
                <div key={index}>
                  {index === 0 ? (
                    <div className={classes.dateTimezoneContainer}>
                      <Typography pt="0" className={classes.slotDate}>
                        {weekday},{" "}
                        <span className={classes.slotDateBold}>
                          {month} {day}
                        </span>
                      </Typography>
                      <Typography appearance="smallBody">
                        {timeZoneDisplay}
                      </Typography>
                    </div>
                  ) : (
                    <Typography className={classes.slotDate}>
                      {weekday},{" "}
                      <span className={classes.slotDateBold}>
                        {month} {day}
                      </span>
                    </Typography>
                  )}
                  <Typography
                    appearance="body"
                    className={
                      hasMorningSlots ? classes.timeOfDay : classes.hidden
                    }
                  >
                    Morning
                  </Typography>
                  {result?.slots?.morning?.map((slot: SlotOutput, idx) => {
                    return (
                      <>
                        <Button
                          key={idx}
                          data-testid="visit-time-slot"
                          className={clsx(classes.relative, classes.slotPill)}
                          onClick={() => {
                            onSelectDate(slot.id?.toString() || "", slot);
                          }}
                          aria-label={formatDate(
                            slot.startAt,
                            "MMM d, yyyy @h:mmaaa",
                            { tz: userTimezone }
                          ).toString()}
                        >
                          {formatDate(slot.startAt, "h:mmaaa", {
                            tz: userTimezone,
                          })}
                        </Button>
                      </>
                    );
                  })}
                  <Typography
                    appearance="body"
                    className={
                      hasAfternoonSlots ? classes.timeOfDay : classes.hidden
                    }
                  >
                    Afternoon
                  </Typography>

                  {result?.slots?.afternoon?.map((slot: SlotOutput, idx) => {
                    return (
                      <Button
                        key={idx}
                        onClick={() => {
                          onSelectDate(slot.id?.toString() || "", slot);
                        }}
                        aria-label={formatDate(
                          slot.startAt,
                          "MMM d, yyyy @h:mmaaa",
                          { tz: userTimezone }
                        ).toString()}
                        className={classes.slotPill}
                        data-testid="visit-time-slot"
                      >
                        {formatDate(slot.startAt, "h:mmaaa", {
                          tz: userTimezone,
                        })}
                      </Button>
                    );
                  })}
                  <Divider
                    style={{
                      width: "100%",
                      paddingTop: "4px",
                    }}
                  />
                </div>
              );
            }
          )}

        {!infiniteScrollMode && !isLoading && !seeMoreAvailability && (
          <div className={classes.showMore}>
            <ButtonStyledAsLink
              aria-label="loadmoreavailability"
              onClick={showMoreHandler}
            >
              Show more availability
            </ButtonStyledAsLink>
          </div>
        )}

        {infiniteScrollMode && (
          <FetchMoreOnScroll
            fetchNextPage={fetchNextPage}
            spinnerContainerClass={classes.loadingSpinnerContainer}
            spinnerClass={classes.spinner}
            parentDiv={parentDiv}
            resultsLength={results?.length}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
          />
        )}
      </div>
    </Drawer>
  );
};

export default SlotsDrawer;
