// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    PatientSearchResultDetails,
    PatientSearchResultDetailsFromJSON,
    PatientSearchResultDetailsToJSON,
} from '../models/PatientSearchResultDetails'
import {
    PatientsSearchRequest,
    PatientsSearchRequestFromJSON,
    PatientsSearchRequestToJSON,
} from '../models/PatientsSearchRequest'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface PatientsSearchPortalRequest {
    patientsSearchRequest?: PatientsSearchRequest;
}


/**
 * Search patients matching critera
 */
function patientsSearchPortalRaw<T>(requestParameters: PatientsSearchPortalRequest, requestConfig: runtime.TypedQueryConfig<T, Array<PatientSearchResultDetails>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/patients`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters || PatientsSearchRequestToJSON(requestParameters.patientsSearchRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(PatientSearchResultDetailsFromJSON), text);
    }

    return config;
}

/**
* Search patients matching critera
*/
export function patientsSearchPortal<T>(requestParameters: PatientsSearchPortalRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<PatientSearchResultDetails>>): QueryConfig<T> {
    return patientsSearchPortalRaw(requestParameters, requestConfig);
}

