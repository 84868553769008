import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import {
  CreateSlotSeriesRequest,
  fetchSlotSeriesApi,
} from "shared/fetch/src/apis/SlotSeriesApi";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";
import { SlotSeriesOutput } from "shared/fetch/src/models/SlotSeriesOutput";

type CreateSlotSeriesMutationFn = (
  requestParameters: CreateSlotSeriesRequest
) => Promise<SlotSeriesOutput>;

const useCreateSlotSeries = (
  config?: UseMutationOptions<SlotSeriesOutput, Error, CreateSlotSeriesRequest>
): UseMutationResult<SlotSeriesOutput, Error, CreateSlotSeriesRequest> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const mutationFn: CreateSlotSeriesMutationFn = (
    requestParameters: CreateSlotSeriesRequest
  ) => {
    return fetchSlotSeriesApi.createSlotSeries(requestParameters);
  };

  const defaultConfig: UseMutationOptions<
    SlotSeriesOutput,
    Error,
    CreateSlotSeriesRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Slot series has been successfully created.");
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to create slot series");
    },
    ...config,
  };

  return useMutation<SlotSeriesOutput, Error, CreateSlotSeriesRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useCreateSlotSeries;
