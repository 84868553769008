import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Button from "components/Button";
import classes from "./index.module.css";
import CalendarWarningIcon from "../XOCal/SlotActionDrawer/CalendarWarningIcon";
import DialogContentText from "components/Dialog/DialogContentText";
import DialogActions from "components/Dialog/DialogActions";

interface DeleteNoteConfirmationModalProps {
  isDeleteNoteConfirmationModalOpen: boolean;
  setIsDeleteNoteConfirmationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleDeleteNoteClick: () => Promise<void>;
  isDeleteNoteLoading: boolean;
}

export default function DeleteNoteConfirmationModal({
  isDeleteNoteConfirmationModalOpen,
  setIsDeleteNoteConfirmationModalOpen,
  handleDeleteNoteClick,
  isDeleteNoteLoading,
}: DeleteNoteConfirmationModalProps) {
  return (
    <Dialog
      open={isDeleteNoteConfirmationModalOpen}
      onClose={() => setIsDeleteNoteConfirmationModalOpen(false)}
      maxWidth="xs"
      aria-labelledby="delete-note-confirmation-modal-title"
      fullWidth
    >
      <div className={classes.deleteModal} data-testid="cancel-confirm-modal">
        <div className={classes.deleteModalTitleContainer}>
          <CalendarWarningIcon />
          <ButtonBase
            className={classes.deleteModalCancel}
            onClick={() => setIsDeleteNoteConfirmationModalOpen(false)}
            aria-label="Close delete modal"
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
        </div>
        <DialogTitle
          id="delete-note-confirmation-modal-title"
          className={classes.deleteModalTitle}
        >
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1" sx={{ color: "black" }}>
            If you delete this, it's gone for good
          </DialogContentText>
          <DialogActions className={classes.deleteNoteModalActions}>
            <Button
              color="primary"
              onClick={handleDeleteNoteClick}
              aria-label="Yes, delete. If you delete it is gone for good."
              isLoading={isDeleteNoteLoading}
              className={classes.deleteButton}
            >
              Delete
            </Button>
            <Button
              color="link-primary"
              onClick={() => setIsDeleteNoteConfirmationModalOpen(false)}
              aria-label="Cancel, do not delete the note"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
}
