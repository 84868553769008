import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import {
  CancelAppointmentRequest,
  fetchCancelAppointmentApi,
} from "shared/fetch/src/apis";
import { VirtualVisitReferralComponentDetailsOutput } from "shared/fetch/src/models/VirtualVisitReferralComponentDetailsOutput";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotsQueryKey } from "./useGetSlots";

type CancelAppointmentMutationFn = (
  requestParameters: CancelAppointmentRequest
) => Promise<VirtualVisitReferralComponentDetailsOutput>;

const useCancelAppointment = (
  config?: UseMutationOptions<
    VirtualVisitReferralComponentDetailsOutput,
    Error,
    CancelAppointmentRequest
  >
): UseMutationResult<
  VirtualVisitReferralComponentDetailsOutput,
  Error,
  CancelAppointmentRequest
> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const mutationFn: CancelAppointmentMutationFn = (
    requestParameters: CancelAppointmentRequest
  ) => {
    return fetchCancelAppointmentApi.cancelAppointment(
      requestParameters
    ) as Promise<VirtualVisitReferralComponentDetailsOutput>;
  };

  const defaultConfig: UseMutationOptions<
    VirtualVisitReferralComponentDetailsOutput,
    Error,
    CancelAppointmentRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Appointment has been successfully canceled.");
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
      // TODO: Invalidate the slots query to refetch the data
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to cancel the appointment.");
    },
    ...config,
  };

  return useMutation<
    VirtualVisitReferralComponentDetailsOutput,
    Error,
    CancelAppointmentRequest
  >(mutationFn, defaultConfig);
};

export default useCancelAppointment;
