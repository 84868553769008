// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSlotSeries
 */
export interface CreateSlotSeries  {
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeries
     */
    clinicId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSlotSeries
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateSlotSeries
     */
    endAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateSlotSeries
     */
    seriesStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateSlotSeries
     */
    seriesEnd?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSlotSeries
     */
    daysActive?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeries
     */
    providerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSlotSeries
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeries
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeries
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSlotSeries
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSlotSeries
     */
    maxOverbook?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeries
     */
    visibility?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotSeries
     */
    restrictedTo?: string;
}

export function CreateSlotSeriesFromJSON(json: any): CreateSlotSeries {
    return {
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'startAt': !exists(json, 'start_at') ? undefined : new Date(json['start_at']),
        'endAt': !exists(json, 'end_at') ? undefined : new Date(json['end_at']),
        'seriesStart': !exists(json, 'series_start') ? undefined : new Date(json['series_start']),
        'seriesEnd': !exists(json, 'series_end') ? undefined : new Date(json['series_end']),
        'daysActive': !exists(json, 'days_active') ? undefined : json['days_active'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'startTime': !exists(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !exists(json, 'end_time') ? undefined : json['end_time'],
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'restrictedTo': !exists(json, 'restricted_to') ? undefined : json['restricted_to'],
    };
}

export function CreateSlotSeriesToJSON(value?: CreateSlotSeries): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinic_id': value.clinicId,
        'start_at': value.startAt === undefined ? undefined : value.startAt.toISOString(),
        'end_at': value.endAt === undefined ? undefined : value.endAt.toISOString(),
        'series_start': value.seriesStart === undefined ? undefined : value.seriesStart.toISOString().substr(0,10),
        'series_end': value.seriesEnd === undefined ? undefined : value.seriesEnd.toISOString().substr(0,10),
        'days_active': value.daysActive,
        'provider_id': value.providerId,
        'appointment_types': value.appointmentTypes,
        'start_time': value.startTime,
        'end_time': value.endTime,
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
        'visibility': value.visibility,
        'restricted_to': value.restrictedTo,
    };
}


