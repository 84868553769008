export const SET_SNACKBAR_SUCCESS = "SET_SNACKBAR_SUCCESS";
export const SET_SNACKBAR_CLEAR = "SET_SNACKBAR_CLEAR";
import { useDispatch } from "react-redux";

// Accessibility: Pop-ups should display for at least 20 seconds
export const AUTO_HIDE_DURATION = 20000;

export const snackbar = (
  state: State = getInitialSnackbarState(),
  action: any
): State => {
  if (action && action.type) {
    const { type, message, appearance, width } = action;
    switch (type) {
      case SET_SNACKBAR_SUCCESS:
        return Object.assign({}, state, {
          open: true,
          message,
          appearance,
          width,
        });

      case SET_SNACKBAR_CLEAR:
        return Object.assign({}, state, {
          open: false,
        });

      default:
        return state;
    }
  }
  return state;
};

type widthType = "auto" | number | undefined;

export const showSnackbar = (
  message: string,
  appearance: "danger" | "success" = "success",
  width: widthType = undefined
) => {
  return { type: SET_SNACKBAR_SUCCESS, message, appearance, width };
};

export const clearSnackbar = () => {
  return { type: SET_SNACKBAR_CLEAR };
};

export interface State {
  message: any;
  open: boolean;
  appearance: "danger" | "success";
  width: widthType;
}

export const getInitialSnackbarState = (): State => {
  return {
    message: null,
    open: false,
    appearance: "success",
    width: undefined,
  };
};

// Status snackbars that don't require interaction do not, as far as I can tell from looking
// at the WCAG, have a specific time they have to be displayed and can be a user annoyance if they stay
// the screen until they are manually dismissed. We can use this hook for our status snackbars and if we
// need to change the dismissal time bc of an a11y ask easily change the length of the setTimeouts
// in this hook
export function useShowStatusSnackbar() {
  const dispatch = useDispatch();
  const showSuccessStatusSnackbar = (message: string) => {
    dispatch(showSnackbar(message, "success"));
    setTimeout(() => dispatch(clearSnackbar()), 5000);
  };
  const showErrorStatusSnackbar = (message: string) => {
    dispatch(showSnackbar(message, "danger"));
    setTimeout(() => dispatch(clearSnackbar()), 5000);
  };

  return { showErrorStatusSnackbar, showSuccessStatusSnackbar };
}
