// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentPatientInfoObject
 */
export interface AppointmentPatientInfoObject  {
    /**
     * 
     * @type {number}
     * @memberof AppointmentPatientInfoObject
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    dob?: string;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentPatientInfoObject
     */
    bornAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    age?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    outsideId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    locked?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    preferredPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentPatientInfoObject
     */
    pronouns?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentPatientInfoObject
     */
    hasConflictQueueReschedulePermission?: boolean;
}

export function AppointmentPatientInfoObjectFromJSON(json: any): AppointmentPatientInfoObject {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'bornAt': !exists(json, 'born_at') ? undefined : new Date(json['born_at']),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'outsideId': !exists(json, 'outside_id') ? undefined : json['outside_id'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'locked': !exists(json, 'locked') ? undefined : json['locked'],
        'preferredPhone': !exists(json, 'preferred_phone') ? undefined : json['preferred_phone'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'hasConflictQueueReschedulePermission': !exists(json, 'has_conflict_queue_reschedule_permission') ? undefined : json['has_conflict_queue_reschedule_permission'],
    };
}

export function AppointmentPatientInfoObjectToJSON(value?: AppointmentPatientInfoObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'dob': value.dob,
        'born_at': value.bornAt === undefined ? undefined : value.bornAt.toISOString(),
        'age': value.age,
        'gender': value.gender,
        'outside_id': value.outsideId,
        'employee_id': value.employeeId,
        'locked': value.locked,
        'preferred_phone': value.preferredPhone,
        'cell_phone': value.cellPhone,
        'home_phone': value.homePhone,
        'pronouns': value.pronouns,
        'has_conflict_queue_reschedule_permission': value.hasConflictQueueReschedulePermission,
    };
}


