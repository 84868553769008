import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Avatar from "components/Avatar";
import ButtonBase from "components/Button/ButtonBase";
import Menu from "components/Menu";
import MenuItem from "components/Menu/MenuItem";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { XOCalProvider } from "shared/fetch/src/models/XOCalProvider";
import OOOorClearModal from "./OOOorClearModal";
import { DAY_VIEW } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      margin: "5px",
      border: `2px solid ${theme?.palette?.calendar?.selectedBackground}`,
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
    },
    button: {
      borderRadius: "8px",
      padding: "5px",
      color: theme?.palette?.appBackground?.darkestGray,
      backgroundColor: theme?.palette?.appBackground?.lightestGray,
      marginRight: "5px",
    },
  })
);

interface Props {
  currentProvider: XOCalProvider | undefined;
}

const ProviderButton = (props: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [oooOrClearModalOpen, setOOOorClearModalOpen] = React.useState(false);
  const [confirmOOOorClearModalOpen, setConfirmOOOorClearModalOpen] =
    React.useState(false);
  const [isClear, setIsClear] = React.useState(false);
  const [searchParams] = useSearchParams();

  const view = searchParams.get("view");
  if (view === DAY_VIEW || !view) {
    return null;
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClearSchedule = React.useCallback(() => {
    setIsClear(true);
    setOOOorClearModalOpen(true);
    handleMenuClose();
  }, []);

  const handleSetOOO = React.useCallback(() => {
    setIsClear(false);
    setOOOorClearModalOpen(true);
    handleMenuClose();
  }, []);

  return (
    <>
      <OOOorClearModal
        isClear={isClear}
        oooOrClearModalOpen={oooOrClearModalOpen}
        setOOOorClearModalOpen={setOOOorClearModalOpen}
        provider={props.currentProvider?.name}
        providerId={props.currentProvider?.providerId}
        confirmOOOorClearModalOpen={confirmOOOorClearModalOpen}
        setConfirmOOOorClearModalOpen={setConfirmOOOorClearModalOpen}
      />
      <div className={classes.buttonContainer}>
        <ButtonBase
          className={classes.button}
          role="button"
          onClick={handleMenuOpen}
          data-testid="provider-button"
          aria-label={`Set out of office or clear schedule for ${props.currentProvider?.name}`}
        >
          <Avatar
            className={classes.avatarContainer}
            src={props.currentProvider?.avatarUrl}
            size={"small"}
            alt={props.currentProvider?.name}
          />
          {props.currentProvider?.name}{" "}
          {Boolean(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </ButtonBase>
        {Boolean(anchorEl) && (
          <Menu
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorEl={anchorEl}
            keepMounted={false}
            transitionDuration={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleClearSchedule}>Clear schedule</MenuItem>
            <MenuItem onClick={handleSetOOO}>Set Out Of Office</MenuItem>
          </Menu>
        )}
      </div>
    </>
  );
};

export default ProviderButton;
