import RepeatIcon from "@mui/icons-material/Repeat";
import TodayIcon from "@mui/icons-material/Today";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SvgIcon } from "@mui/material";
import Divider from "components/Divider";
import MedicalServicesIcon from "components/MedicalServicesIcon";
import Typography from "components/Typography";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import { CalendarStaticDataResponseClinicDetails } from "shared/fetch/src/models/CalendarStaticDataResponseClinicDetails";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { DAY_VIEW } from "../utils";
import RestrictToXOP from "./RestrictToXOP";
import SelectFrequency from "./SelectFrequency";
import SelectNumberOfMembers from "./SelectNumberOfMembers";
import SelectVisibility from "./SelectVisibility";
import SelectVisitTime from "./SelectVisitTime";
import SelectVisitType from "./SelectVisitType/SelectVisitType";
import SlotDateDisplayAndPicker from "./SlotDateDisplayAndPicker";
import classes from "./index.module.css";
import { ValuesType, replaceDashesInDateStringWithSlashes } from "./utils";

interface IProps {
  isSlotCreation: boolean;
  values: ValuesType;
  setField: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<any>) => void;
  site: CalendarStaticDataResponseClinicDetails | undefined;
  timezone: string;
  oooDays: Set<{
    start: string;
    end: string;
    provider: string;
  }>;
  slot: SlotOutput | undefined;
  selectedDate: Date | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  operatingDaysNumerical: Number[];
  hasFullXoCalPermissions: boolean;
  isReadOnly: boolean;
  setIsKeepOrCancelFlow: React.Dispatch<React.SetStateAction<boolean>>;
  isKeepOrCancelFlow: boolean;
  isChangingVisitType: boolean;
  setIsChangingVisitType: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatingSeriesOutOfBookedSlot: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const SettingsTab = ({
  isSlotCreation,
  values,
  setField,
  handleChange,
  site,
  timezone,
  oooDays,
  slot,
  selectedDate,
  setSelectedDate,
  operatingDaysNumerical,
  isReadOnly,
  setIsKeepOrCancelFlow,
  isKeepOrCancelFlow,
  isChangingVisitType,
  setIsChangingVisitType,
  setIsCreatingSeriesOutOfBookedSlot,
}: IProps) => {
  const [searchParams] = useSearchParams();
  const [isDayView, setIsDayView] = React.useState(false);
  const [showNumberOfMembersSection, setShowNumberOfMembersSection] =
    React.useState(false);
  const calendarDate = searchParams.get("date");
  const hoursOfOperation = site?.operatingHours as Record<
    string,
    [string, string]
  >;

  React.useEffect(() => {
    setIsDayView(searchParams.get("view") === DAY_VIEW);
  }, [searchParams]);

  const renderVisibilitySection = () => (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeading}>
        <VisibilityIcon className={classes.drawerIcons} />
        <Typography
          className={classes.sectionTitle}
          id="visibility-radio-group-label"
        >
          Visibility
        </Typography>
      </div>
      <SelectVisibility
        visibility={values?.visibility || SlotVisibilityEnum.Hold}
        handleChange={(event, _value) => {
          if (!isReadOnly) {
            handleChange(event);
          }
        }}
        disabled={isReadOnly}
      />
    </div>
  );

  const renderVisitTypeSection = () => (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeading}>
        <SvgIcon className={classes.drawerIcons}>
          <MedicalServicesIcon />
        </SvgIcon>
        <Typography
          className={classes.sectionTitle}
          id="select-visit-type-label"
          data-testid="visit-type-title"
        >
          {values?.visibility === "hold" ? "Internal hold type" : "Visit type"}
        </Typography>
      </div>
      <SelectVisitType
        selectedVisitTypes={
          typeof values?.appointmentTypes === "string"
            ? []
            : values?.appointmentTypes!
        }
        appointmentType={values?.appointmentType || ""}
        handleChange={handleChange}
        visibility={values?.visibility}
        maxPatients={values?.maxPatients}
        providerId={values?.selectedProviderId}
        isScheduleVisitsTab={false}
        values={values}
        slot={slot}
        maxOverbook={values?.maxOverbook}
        disabled={isReadOnly}
        isKeepOrCancelFlow={isKeepOrCancelFlow}
        setIsKeepOrCancelFlow={setIsKeepOrCancelFlow}
        isChangingVisitType={isChangingVisitType}
        setIsChangingVisitType={setIsChangingVisitType}
        setField={setField}
      />
    </div>
  );

  const renderAdvancedSettings = () => (
    // please god no make this it's own component
    <>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionHeading}>
          <RepeatIcon className={classes.drawerIcons} />
          <Typography
            className={classes.sectionTitle}
            id="repeats-radio-group-label"
          >
            Frequency
          </Typography>
        </div>
        <SelectFrequency
          slot={slot}
          isSlotCreation={isSlotCreation}
          slotDrawerDate={replaceDashesInDateStringWithSlashes(calendarDate)} // will be in "2025/12/14" format
          operatingDaysNumerical={operatingDaysNumerical}
          repeats={values?.repeats}
          setIsCreatingSeriesOutOfBookedSlot={
            setIsCreatingSeriesOutOfBookedSlot
          }
          setField={setField}
          values={values}
          setRepeats={(repeats: boolean) => {
            // TODO: don't think we need this, it just calls setField and makes the code confusing
            if (!isReadOnly) {
              setField("repeats", repeats);
            }
          }}
          daysActive={values?.daysActive}
          setDaysActive={(daysActive: string[]) => {
            if (!isReadOnly) {
              setField("daysActive", daysActive);
            }
          }}
          seriesStartDate={values?.startAt || calendarDate}
          disabled={isReadOnly}
          setIsKeepOrCancelFlow={setIsKeepOrCancelFlow}
        />
      </div>
      <Divider />

      <SelectNumberOfMembers
        values={values}
        handleChange={handleChange}
        disabled={isReadOnly}
        setIsKeepOrCancelFlow={setIsKeepOrCancelFlow}
        isSectionExpanded={showNumberOfMembersSection}
        setIsSectionExpanded={setShowNumberOfMembersSection}
      />
      <Divider />

      <div className={classes.sectionContainerRow}>
        <RestrictToXOP
          value={values?.restrictedTo}
          handleChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </>
  );

  return (
    <div className={classes.section}>
      <div
        className={
          isSlotCreation && !isDayView
            ? classes.currentDateContainerSelectDate
            : classes.currentDateContainer
        }
      >
        <div
          className={isSlotCreation && !isDayView ? classes.iconContainer : ""}
        >
          <TodayIcon className={classes.drawerIcons} />
          {isSlotCreation && !isDayView && (
            <Typography className={classes.sectionTitle}>
              Select date
            </Typography>
          )}
        </div>
        <SlotDateDisplayAndPicker
          isSlotCreation={isSlotCreation}
          isDayView={isDayView}
          calendarDate={calendarDate}
          timezone={timezone}
          oooDays={oooDays}
          values={values}
          setField={setField}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          isReadOnly={isReadOnly}
          operatingDaysNumerical={operatingDaysNumerical}
        />
      </div>

      {renderVisibilitySection()}
      {renderVisitTypeSection()}

      <SelectVisitTime
        values={values}
        handleChange={handleChange}
        timezone={timezone}
        hoursOfOperation={hoursOfOperation}
        isSlotCreation={isSlotCreation}
        disabled={isReadOnly}
      />

      <Divider />

      {renderAdvancedSettings()}
    </div>
  );
};

export default SettingsTab;
