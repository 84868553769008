import { useQuery } from "react-query";
import {
  GetSlotsForDateRangeRequest,
  fetchSlotsApi,
  GetSlotsForDateRangeFlattenEnum,
} from "shared/fetch/src/apis/SlotsApi";
import { GetSlotsForDateOutputWithArray } from "shared/fetch/src/models/GetSlotsForDateOutputWithArray";

export const getFetchSlotsQueryKey = (
  requestParameters: GetSlotsForDateRangeRequest
) => {
  return ["slots", ...Object.entries(requestParameters)];
};

const useGetSlots = (requestParameters: GetSlotsForDateRangeRequest) => {
  // Because we pull several of these request paramaters from search params, values such
  // as "null" as a string will give false positives if checked for truthiness
  // Boolean(null) === false BUT Boolean("null") === true, so we need to be able to handle this case
  const isValidQueryParam = (paramValue: string | undefined) => {
    const isValidValue =
      Boolean(paramValue) &&
      paramValue !== "null" &&
      paramValue !== "undefined";

    return isValidValue;
  };

  const isValidConflictsQuery =
    // the conflicted=true request to GET v1/slots does not require start or end dates
    // it only needs a clinicId
    isValidQueryParam(requestParameters.clinicId) &&
    requestParameters.conflicted === "true";

  return useQuery(
    getFetchSlotsQueryKey(requestParameters),
    // let's default to flatten: true for XOP. this can be overridden by the caller
    () =>
      fetchSlotsApi.getSlotsForDateRange({
        flatten: GetSlotsForDateRangeFlattenEnum.True,
        ...requestParameters,
      }) as Promise<GetSlotsForDateOutputWithArray>,
    {
      enabled:
        (isValidQueryParam(requestParameters.clinicId) &&
          isValidQueryParam(requestParameters.start) &&
          isValidQueryParam(requestParameters.end)) ||
        isValidConflictsQuery,
    }
  );
};

export default useGetSlots;
