import Typography from "components/Typography";
import React from "react";
import XODatePicker from "./DatePicker";
import classes from "./index.module.css";
import { ValuesType } from "./utils";
import { formatDateWithTimezone } from "../utils";
import { replaceDashesInDateStringWithSlashes } from "./utils";
import { format } from "date-fns";

interface IProps {
  isSlotCreation: boolean;
  values: ValuesType;
  setField: (field: string, value: any) => void;
  timezone: string;
  oooDays: Set<{
    start: string;
    end: string;
    provider: string;
  }>;
  selectedDate: Date | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  operatingDaysNumerical: Number[];
  isReadOnly: boolean;
  isDayView: boolean;
  calendarDate: string | null;
}

function DateDisplay({ dateLabel }: { dateLabel: string | undefined }) {
  return (
    <Typography className={classes.text} data-testid="slot-drawer-date">
      {dateLabel}
    </Typography>
  );
}

// TODO: make it more obvious that we are setting the start date of a single slot or slot series
// with this component
export default function SlotDateDisplayAndPicker({
  isSlotCreation,
  isDayView,
  calendarDate,
  timezone,
  oooDays,
  values,
  setField,
  selectedDate,
  setSelectedDate,
  isReadOnly,
  operatingDaysNumerical,
}: IProps) {
  const calendarDateWithSlashesInsteadOfDashes =
    replaceDashesInDateStringWithSlashes(calendarDate);

  const creatingSlotDateLabel = format(
    new Date(calendarDateWithSlashesInsteadOfDashes),
    "E, MMM d, yyyy"
  );
  const updatingSlotDateLabel =
    values.startAt &&
    formatDateWithTimezone(values.startAt, "E, MMM d, yyyy", timezone);

  if (isSlotCreation) {
    if (isDayView && calendarDate) {
      return <DateDisplay dateLabel={creatingSlotDateLabel as string} />;
    }

    return (
      <XODatePicker
        // ariaLabel="Slot date" TODO: this messes up styling but is necessary
        oooDays={oooDays}
        isLastElementInRow={false}
        minDate={new Date()}
        onChangeDate={(newDate: Date) => {
          if (!isReadOnly) {
            // TODO: make this field name more descriptive
            setField("selectedDate", newDate);
            setSelectedDate(newDate);
          }
        }}
        classNames={classes.datePicker}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        operatingDaysNumerical={operatingDaysNumerical}
        disabled={isReadOnly}
      />
    );
  } else {
    return <DateDisplay dateLabel={updatingSlotDateLabel} />;
  }
}
