import { useMutation, useQueryClient } from "react-query";
import { memberPaymentHistoryInfoQueryKey } from "shared/features/payments/paymentsHistory/useGetPaymentsHistory";
import {
  PostPayLaterSchema,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";
import { getDailyScheduleQueryKey } from "./useGetDailySchedule";

const usePostPayLater = (patientId: string, site: string, date: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (postPayLaterSchema: PostPayLaterSchema) => {
      return fetchMemberPaymentsApi
        .generateCharge({ postPayLaterSchema })
        .finally(() => {
          queryClient.invalidateQueries(
            memberPaymentHistoryInfoQueryKey({ id: patientId })
          );
          queryClient.invalidateQueries(
            getDailyScheduleQueryKey({
              site,
              date,
            })
          );
        });
    }
  );

  return {
    generateCharge: mutateAsync,
    isLoading,
  };
};

export default usePostPayLater;
