import { useMutation, useQueryClient } from "react-query";
import {
  UpdateCopayRequest,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";
import { getDailyScheduleQueryKey } from "./useGetDailySchedule";

const useUpdateCopay = (site: string, date: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (requestParams: UpdateCopayRequest) => {
      return fetchMemberPaymentsApi.updateCopay(requestParams).finally(() => {
        queryClient.invalidateQueries(
          getDailyScheduleQueryKey({
            site,
            date,
          })
        );
      });
    }
  );

  return {
    updateCopay: mutateAsync,
    isUpdateCopayLoading: isLoading,
  };
};

export default useUpdateCopay;
