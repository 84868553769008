import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { State } from "@types";
import { setConsentsAgreementStatus } from "shared/state/ui/careAccess/actions";
import { MemberRequiredConsent } from "shared/fetch/src/models/MemberRequiredConsent";
import ConsentModal from "features/selfSchedule/components/ConfirmPreferences/ConsentModal";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Typography from "components/Typography";
import isEmpty from "lodash/isEmpty";

interface ICareAccessConsents {
  consents: MemberRequiredConsent[];
}

const useStyles = makeStyles(() => ({
  wrapper: {
    "& .MuiButtonBase-root": {
      paddingLeft: 0,
    },
    "& p": {
      alignSelf: "center",
    },
  },
  consent: {
    display: "flex",
  },
  button: {
    fontSize: "13.3px",
  },
  checkbox: {
    paddingTop: "5px !important",
    alignSelf: "flex-start",
  },
  description: {
    margin: "5px 0px",
  },
}));

const CareAccessConsents = ({ consents }: ICareAccessConsents) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [selectedConsentLink, setSelectedConsentLink] = useState("");
  const [selectedConsentLabel, setSelectedConsentLabel] = useState("");

  const [checkedConsentsTracker, setCheckedConsentsTracker] =
    useState<any>(null);
  const { finalize } = useSelector((state: State) => state.ui.careAccess);

  useEffect(() => {
    const checks = {};
    if (!checkedConsentsTracker) {
      // use an object to keep track of which consent has been checked
      consents.forEach(
        (c: MemberRequiredConsent, index: number) =>
          (checks[c._for || `for${index}`] = false)
      );
      setCheckedConsentsTracker(checks);
    }
  }, [consents]);

  useEffect(() => {
    if (!isEmpty(checkedConsentsTracker)) {
      // all consents have to be agreed to for user to move forward
      const values = Object.values(checkedConsentsTracker);

      if (values.includes(false) && finalize.agreedToAllConsents) {
        dispatch(setConsentsAgreementStatus(false));
      } else if (!values.includes(false) && !finalize.agreedToAllConsents) {
        dispatch(setConsentsAgreementStatus(true));
      }
    }
  }, [checkedConsentsTracker]);

  const handleConsentAgreement = (
    _e: React.ChangeEvent<HTMLInputElement>,
    consentFor: string
  ) => {
    setCheckedConsentsTracker({
      ...checkedConsentsTracker,
      [`${consentFor}`]: !checkedConsentsTracker[`${consentFor}`],
    });
  };

  const handleWhichConsentToDisplay = (
    _e: any,
    consentLink: string,
    consentLabel: string
  ) => {
    setShowConsentModal(true);
    setSelectedConsentLink(consentLink);
    setSelectedConsentLabel(consentLabel);
  };

  const handleConsentToggle = () => {
    setShowConsentModal(!showConsentModal);
  };

  if (!checkedConsentsTracker) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {consents.map((consent: MemberRequiredConsent, index: number) => (
        <div className={classes.consent} key={index}>
          <Checkbox
            inputProps={{
              "aria-label": `I agree to the ${consent.label}, consent required`,
            }}
            className={classes.checkbox}
            color="secondary"
            checked={checkedConsentsTracker[`${consent._for || `for${index}`}`]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleConsentAgreement(event, consent._for || `for${index}`)
            }
          />

          <Typography
            appearance="smallBody"
            color="textSecondary"
            className={classes.description}
          >
            <span>
              <span>I agree to the </span>
              <Button
                color="link-primary"
                className={classes.button}
                onClick={(event: any) =>
                  handleWhichConsentToDisplay(
                    event,
                    consent.link || "",
                    consent.label || ""
                  )
                }
              >
                {consent.label}
              </Button>
            </span>
            <br />
            <span>Required*</span>
          </Typography>
        </div>
      ))}

      {!!selectedConsentLink && !!selectedConsentLabel && (
        <ConsentModal
          open={showConsentModal}
          toggle={handleConsentToggle}
          link={selectedConsentLink}
          title={selectedConsentLabel}
        />
      )}
    </div>
  );
};

export default CareAccessConsents;
