// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSlotSeries
 */
export interface UpdateSlotSeries  {
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    clinicId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSlotSeries
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    seriesStart?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    seriesEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    endTime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSlotSeries
     */
    daysActive?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    visibility?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSlotSeries
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSlotSeries
     */
    maxOverbook?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSlotSeries
     */
    conflictResolution?: UpdateSlotSeriesConflictResolutionEnum;
}

export function UpdateSlotSeriesFromJSON(json: any): UpdateSlotSeries {
    return {
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'seriesStart': !exists(json, 'series_start') ? undefined : json['series_start'],
        'seriesEnd': !exists(json, 'series_end') ? undefined : json['series_end'],
        'startTime': !exists(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !exists(json, 'end_time') ? undefined : json['end_time'],
        'daysActive': !exists(json, 'days_active') ? undefined : json['days_active'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'conflictResolution': !exists(json, 'conflict_resolution') ? undefined : json['conflict_resolution'],
    };
}

export function UpdateSlotSeriesToJSON(value?: UpdateSlotSeries): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinic_id': value.clinicId,
        'appointment_types': value.appointmentTypes,
        'series_start': value.seriesStart,
        'series_end': value.seriesEnd,
        'start_time': value.startTime,
        'end_time': value.endTime,
        'days_active': value.daysActive,
        'provider_id': value.providerId,
        'visibility': value.visibility,
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
        'conflict_resolution': value.conflictResolution,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateSlotSeriesConflictResolutionEnum {
    Cancel = 'cancel',
    Keep = 'keep',
    Ignore = 'ignore'
}


