import { CalendarStaticDataResponse } from "shared/fetch/src/models/CalendarStaticDataResponse";
import { getAppointmentObjectKeyedById } from "../utils";

export const getAppointmentTypeName = (
  apptTypeId?: string,
  clinicData?: CalendarStaticDataResponse
) => {
  if (!clinicData || !apptTypeId) {
    return "";
  }
  const apptObject = getAppointmentObjectKeyedById(clinicData);
  if (!apptObject?.[apptTypeId]) {
    return apptTypeId;
  }
  return apptObject[apptTypeId].name;
};

interface ComposeTooltipSlotProps {
  offset: [number, number];
}

export const composeTooltipSlotProps = ({
  offset,
}: ComposeTooltipSlotProps) => {
  const tooltipOffsetPosition = offset;
  const props = {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: tooltipOffsetPosition,
          },
        },
      ],
    },
  };
  return props;
};
