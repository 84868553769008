// see https://github.com/stripe/stripe-js/issues/43#issuecomment-643840075 for inspiration
import React, { FunctionComponent } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";

let stripePromise: Promise<Stripe>;
const getStripe = () => {
  if (!stripePromise) {
    // @ts-ignore: Object is possibly 'null'.
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
  }
  return stripePromise;
};

const StripeProvider: FunctionComponent = ({ children }) => {
  return <Elements stripe={getStripe()}>{children}</Elements>;
};

export default StripeProvider;
