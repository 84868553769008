// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayNowSchema
 */
export interface PayNowSchema  {
    /**
     * 
     * @type {boolean}
     * @memberof PayNowSchema
     */
    isDailySchedule?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayNowSchema
     */
    cardToken?: string;
    /**
     * 
     * @type {string}
     * @memberof PayNowSchema
     */
    cardId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayNowSchema
     */
    primary?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayNowSchema
     */
    chargeIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PayNowSchema
     */
    cardLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayNowSchema
     */
    isHsaOrFsa?: boolean;
}

export function PayNowSchemaFromJSON(json: any): PayNowSchema {
    return {
        'isDailySchedule': !exists(json, 'is_daily_schedule') ? undefined : json['is_daily_schedule'],
        'cardToken': !exists(json, 'card_token') ? undefined : json['card_token'],
        'cardId': !exists(json, 'card_id') ? undefined : json['card_id'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'chargeIds': json['charge_ids'],
        'cardLabel': !exists(json, 'card_label') ? undefined : json['card_label'],
        'isHsaOrFsa': !exists(json, 'is_hsa_or_fsa') ? undefined : json['is_hsa_or_fsa'],
    };
}

export function PayNowSchemaToJSON(value?: PayNowSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'is_daily_schedule': value.isDailySchedule,
        'card_token': value.cardToken,
        'card_id': value.cardId,
        'primary': value.primary,
        'charge_ids': value.chargeIds,
        'card_label': value.cardLabel,
        'is_hsa_or_fsa': value.isHsaOrFsa,
    };
}


