import React, { useState, useRef } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Button from "components/Button";

// Define styles
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginLeft: {
      marginLeft: theme.spacing(0.4),
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
      top: "-1px",
    },
  })
);

interface ReadMoreProps {
  children: string;
  maxLength?: number;
  buttonClassName?: string;
  addEllipsis?: boolean;
}

const ReadMore: React.FC<ReadMoreProps> = ({
  children,
  maxLength = 100,
  buttonClassName,
  addEllipsis = false,
}) => {
  const classes = useStyles();
  const [isReadMore, setIsReadMore] = useState(true);
  const readMoreRef = useRef<HTMLButtonElement | null>(null);

  const toggleReadMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (
      (event.key === "Tab" || event.key === "Enter") &&
      !!readMoreRef?.current
    ) {
      // The parent component <ComponentCard /> has a scrollIntoView which spills into See more/See less
      // when they are focused on via keyboard Tab. Stop propagation doesnt seem to be doing anything so Ive set a timeout
      // for See more/See less to jump into view a few seconds after focus.
      setTimeout(() => {
        readMoreRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 10);
    }
  };

  const displayText = isReadMore
    ? children.slice(0, maxLength) +
      (addEllipsis && children.length > maxLength ? "..." : "")
    : children;

  return (
    <p>
      {displayText}
      {children.length > maxLength && (
        <Button
          ref={readMoreRef}
          className={buttonClassName || classes.marginLeft}
          color="link-primary"
          onClick={toggleReadMore}
          onKeyUp={(e) => handleOnKeyUp(e)}
        >
          {isReadMore ? "see more" : "see less"}
        </Button>
      )}
    </p>
  );
};

export default ReadMore;
