import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import {
  CreateOutOfOfficeNotificationRequest,
  fetchProviderOooNotificationApi,
} from "shared/fetch/src/apis";
import { HeadsUpNotification } from "shared/fetch/src/models/HeadsUpNotification";

type SetOOOMutationFn = (
  requestParameters: CreateOutOfOfficeNotificationRequest
) => Promise<HeadsUpNotification>;

const useSetOOO = (
  config?: UseMutationOptions<
    HeadsUpNotification,
    Error,
    CreateOutOfOfficeNotificationRequest
  >
): UseMutationResult<
  HeadsUpNotification,
  Error,
  CreateOutOfOfficeNotificationRequest
> => {
  const { showErrorStatusSnackbar, showSuccessStatusSnackbar } =
    useShowStatusSnackbar();

  const mutationFn: SetOOOMutationFn = (
    requestParameters: CreateOutOfOfficeNotificationRequest
  ) => {
    return fetchProviderOooNotificationApi.createOutOfOfficeNotification(
      requestParameters
    ) as Promise<HeadsUpNotification>;
  };

  const defaultConfig: UseMutationOptions<
    HeadsUpNotification,
    Error,
    CreateOutOfOfficeNotificationRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Provider has been set OOO.");
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to set provider OOO");
    },
    ...config,
  };

  return useMutation<
    HeadsUpNotification,
    Error,
    CreateOutOfOfficeNotificationRequest
  >(mutationFn, defaultConfig);
};

export default useSetOOO;
