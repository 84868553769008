import { UseQueryOptions, useQuery } from "react-query";
import { fetchSlotSeriesApi } from "shared/fetch/src/apis/SlotSeriesApi";
import { SlotSeriesOutput } from "shared/fetch/src/models/SlotSeriesOutput";

interface GetSlotSeriesByIDRequest {
  id?: string;
}

export const getFetchSlotQueryKey = (
  requestParameters: GetSlotSeriesByIDRequest
) => {
  return ["slotSeries", ...Object.entries(requestParameters)];
};

type TQueryKey = ReturnType<typeof getFetchSlotQueryKey>;

const useGetSeriesByID = (
  { id }: GetSlotSeriesByIDRequest,
  options?: Omit<
    UseQueryOptions<SlotSeriesOutput, Error, SlotSeriesOutput, TQueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(
    getFetchSlotQueryKey({ id }),
    () => fetchSlotSeriesApi.getSlotSeriesByID({ id: id! }),
    {
      enabled: !!id,
      ...options,
    }
  );
};

export default useGetSeriesByID;
