import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "components/Dialog/DialogContent";
import Typography from "components/Typography";
import DialogTitle from "components/Dialog/DialogTitle";
import Box from "components/Box";
import Dialog from "components/Dialog";
import IconButton from "components/Button/IconButton";
import Button from "components/Button";
import DialogActions from "components/Dialog/DialogActions";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    body: {
      display: "flex",
      alignItems: "left",
      flexDirection: "column",
      [breakpoints.down("sm")]: {
        minWidth: "100%",
        width: "100%",
      },
    },
    clearAllButton: {
      display: "flex",
      height: "44px",
      padding: "0.78rem 1.5rem",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: spacing(2),
      top: spacing(2),
      color: palette.text.secondary,
      padding: 0,
    },
    dialogTitle: {
      textAlign: "left",
      width: "100%",
      paddingBottom: 0,
    },
    pulse: {
      animation: "$pulse 1.5s infinite",
    },

    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.1)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    cancelButton: {
      border: `${palette.primary.main} 2px solid`,
      display: "flex",
      height: "44px",
      padding: "0.78rem 1.5rem",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: spacing(2),
    },
    container: {
      width: "444px",
      height: "243px",
      paddingTop: "50px",
      [breakpoints.down("sm")]: {
        display: "contents",
      },
    },
  })
);

interface IClearAllDialog {
  isOpen: boolean;
  handleClearAll: () => void;
  onClose: () => void;
}

function ClearAllDialog({ isOpen, onClose, handleClearAll }: IClearAllDialog) {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      ariaLabelledBy="notification-clear-all-dialog-title"
    >
      <IconButton
        aria-label="Notification clear all dialog close button"
        data-e2e="notification-clear-all-modal-close-btn"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        className={classes.container}
      >
        <div className={classes.title}>
          <DialogTitle
            id="notification-clear-all-dialog-title"
            className={classes.dialogTitle}
          >
            Are you sure you want to clear all Heads Up notifications?
          </DialogTitle>
        </div>

        <DialogContent>
          <div className={classes.body}>
            <Typography variant="subtitle2">
              If you clear all notifications they will be gone for good.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions horizontal>
          <Button
            color="primary"
            className={classes.clearAllButton}
            onClick={handleClearAll}
          >
            Yes, clear all
          </Button>
          <Button
            color="secondary"
            className={classes.cancelButton}
            onClick={onClose}
          >
            No, cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ClearAllDialog;
