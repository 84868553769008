import clsx from "clsx";
import Box from "components/Box";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import Typography from "components/Typography";
import React from "react";
import classes from "./index.module.css";

interface IProps {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  activeTab: number;
  isSlotCreation: boolean;
}

export default function SlotActionDrawerTabs(props: IProps) {
  const { isSlotCreation, activeTab, setActiveTab } = props;
  const shouldRenderTabs = !isSlotCreation;

  return (
    <Box className={classes.tabsContainer}>
      {shouldRenderTabs && (
        <Tabs value={activeTab} appearance="pills" justifyContent="flex-end">
          <Tab
            value={0}
            className={clsx(
              classes.tab,
              activeTab === 0 && classes.tabSelected
            )}
            aria-selected={activeTab === 0}
            aria-label="Slot settings"
            onClick={() => setActiveTab(0)}
            label={
              <Typography
                appearance="body"
                className={clsx(
                  classes.tabLabel,
                  activeTab === 0 && classes.tabLabelSelected
                )}
              >
                Slot settings
              </Typography>
            }
          />
          <Tab
            value={1}
            className={clsx(
              classes.tab,
              activeTab === 1 && classes.tabSelected
            )}
            aria-selected={activeTab === 1}
            aria-label="Schedule visits"
            onClick={() => setActiveTab(1)}
            label={
              <Typography
                appearance="body"
                className={clsx(
                  classes.tabLabel,
                  activeTab === 1 && classes.tabLabelSelected
                )}
              >
                Schedule visits
              </Typography>
            }
          />
        </Tabs>
      )}
    </Box>
  );
}
