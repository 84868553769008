import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { FormGroup } from "@mui/material";
import FormControlLabel from "components/Form/FormControlLabel";
import Checkbox from "components/Checkbox";
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";
import Typography from "components/Typography";

interface IValidState {
  error: string;
  state: any;
  setState: (event: any) => void;
}

interface IMethodFields {
  country: IValidState;
  zip: IValidState;
  nickName: IValidState;
  isPrimary: IValidState;
  isFsaHsa: IValidState;
  elementErrors: {
    cardNumber: string;
    cardExpiry: string;
    cardCvc: string;
  };
  showPrimaryButton?: boolean;
  last4?: string;
  label?: string;
  exp?: string;
  hasCountryAndZip?: boolean;
  showFsaHsaButton?: boolean;
  hasConfirmationMsg?: boolean;
  handleElementErrors: (event: any) => void;
  isSubscription?: boolean;
  isConsumer?: boolean;
  isPaymentModal?: boolean;
}

const useStyle = (props?: Partial<IMethodFields>) =>
  makeStyles(({ breakpoints, palette }) => ({
    cardElementLabel: {
      [breakpoints?.down("xs")]: {
        fontSize: "14px",
        lineHeight: "18.2px",
      },
    },
    cardNumberField: {
      backgroundColor: props?.isPaymentModal
        ? palette?.common.white
        : palette?.appBackground?.lightestGrayHover,
      padding: "10px 20px",
      marginBottom: "6px",
      border: props?.isPaymentModal
        ? `1px solid ${palette?.text.mediumDarkGray}`
        : undefined,
      borderBottom: props?.isPaymentModal
        ? undefined
        : `1px solid ${palette?.appBackground?.darkestGray}`,
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: props?.isPaymentModal ? "10px" : 0,
      borderBottomRightRadius: props?.isPaymentModal ? "10px" : 0,
    },
    cardNumberInvalidField: {
      backgroundColor: palette?.danger?.main,
      padding: "10px 20px",
      marginBottom: "5px",
      border: props?.isPaymentModal ? `1px solid ${palette?.redHue}` : 0,
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: props?.isPaymentModal ? "10px" : 0,
      borderBottomRightRadius: props?.isPaymentModal ? "10px" : 0,
    },
    fieldHeader: {
      margin: 0,
      fontSize: "13px",
      color: palette?.text?.secondary,
      [breakpoints?.down("xs")]: {
        fontSize: "11px",
      },
    },
    invalidFieldHeader: {
      margin: 0,
      fontSize: "13px",
      color: palette?.redHue,
      [breakpoints?.down("xs")]: {
        fontSize: "11px",
      },
    },
    fieldError: {
      margin: 0,
      fontSize: "11px",
      color: palette?.redHue,
    },
    expiryCvcWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    addressWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    fieldColumns: {
      width: "48%",
    },
    cardExpiryCvcField: {
      backgroundColor: props?.isPaymentModal
        ? palette?.common.white
        : palette?.appBackground?.lightestGrayHover,
      padding: "10px 20px",
      margin: "6px 0",
      border: props?.isPaymentModal
        ? `1px solid ${palette?.text.mediumDarkGray}`
        : undefined,
      borderBottom: props?.isPaymentModal
        ? undefined
        : `1px solid ${palette?.appBackground?.darkestGray}`,
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: props?.isPaymentModal ? "10px" : 0,
      borderBottomRightRadius: props?.isPaymentModal ? "10px" : 0,
    },
    cardExpiryCvcInvalidField: {
      backgroundColor: palette?.danger?.main,
      padding: "10px 20px",
      margin: "10px 0 5px 0",
      border: props?.isPaymentModal ? `1px solid ${palette?.redHue}` : 0,
      borderBottom: props?.isPaymentModal
        ? undefined
        : `1px solid ${palette?.redHue}`,
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: props?.isPaymentModal ? "10px" : 0,
      borderBottomRightRadius: props?.isPaymentModal ? "10px" : 0,
    },
    multipleFieldsError: {
      color: palette?.redHue,
      fontSize: "11px",
      margin: 0,
      width: "100%",
    },
    nickNameWrapper: {
      border: props?.isPaymentModal
        ? `1px solid ${palette?.text.mediumDarkGray}`
        : undefined,
      borderBottom: props?.isPaymentModal
        ? `1px solid ${palette?.text.mediumDarkGray}`
        : `1px solid ${palette?.appBackground?.darkestGray}`,
      borderTopLeftRadius: props?.isPaymentModal ? "10px" : 0,
      borderTopRightRadius: props?.isPaymentModal ? "10px" : 0,
      borderBottomLeftRadius: props?.isPaymentModal ? "10px" : 0,
      borderBottomRightRadius: props?.isPaymentModal ? "10px" : 0,
    },
    cardNickName: {
      backgroundColor: props?.isPaymentModal
        ? palette?.common.white
        : palette?.appBackground?.lightestGrayHover,
      width: "100%",
      marginTop: "6px",
      [breakpoints?.down("xs")]: {
        width: "100%",
        "& .MuiFormLabel-root": {
          fontSize: "12px !important",
        },
      },
      "& .MuiInputBase-root": {
        backgroundColor: props?.isPaymentModal
          ? palette?.common.white
          : palette?.appBackground?.lightestGrayHover,
      },
      "& .MuiFormLabel-root": {
        marginLeft: "5px",
        fontSize: "15px",
      },
      "& .MuiInputBase-input": {
        marginLeft: "5px",
      },
    },
    cardCheckbox: {
      marginTop: "10px",
      [breakpoints?.down("xs")]: {
        "& .MuiIconButton-label": {
          height: "18px",
          width: "18px",
        },
      },
    },
    cardCountry: {
      width: "100%",
      marginTop: "8px",
    },
    cardZipCode: {
      width: "100%",
      marginTop: "6px",
    },
    cardZipInvalidCode: {
      width: "100%",
      marginTop: "6px",
    },
    confirmationMsg: {
      color: palette?.text?.secondary,
      marginTop: "10px",
    },
    hideLabel: {
      display: "none",
    },
  }))(props);

const inputStyles = {
  base: {
    "::placeholder": {
      color: "#9CA3AA",
      fontSize: "13px",
    },
  },
  invalid: { color: "#CA4004" },
};

const countries = [{ code: "US", label: "United States" }];

const MethodFields: FunctionComponent<IMethodFields> = ({
  country,
  zip,
  nickName,
  isPrimary,
  isFsaHsa,
  elementErrors,
  showPrimaryButton,
  showFsaHsaButton = true,
  handleElementErrors,
  last4 = "",
  exp,
  label = "Set up a payment method",
  hasCountryAndZip = false,
  hasConfirmationMsg = false,
  isSubscription,
  isConsumer,
  isPaymentModal,
}) => {
  const classes = useStyle({ isPaymentModal });

  const getOptionSelected = (option: any, value: any) => {
    if (value === null) {
      return false;
    } else {
      return value.code === option.code;
    }
  };

  const { cardNumber, cardExpiry, cardCvc } = elementErrors;

  const ccPlaceholder = last4
    ? `XXXX XXXX XXXX ${last4}`
    : "XXXX XXXX XXXX XXXX";

  const getCVCPlaceholder = () => {
    if (isPaymentModal) {
      return "Enter CVC";
    } else {
      return "CVC";
    }
  };
  return (
    <>
      <label
        htmlFor="card-element"
        className={
          isPaymentModal ? classes.hideLabel : classes.cardElementLabel
        }
      >
        {label}
      </label>
      <div id="card-element" data-testid="payment-card-container">
        <fieldset>
          <legend className="sr-only">Card Details</legend>
          <div>
            <div
              className={
                cardNumber
                  ? classes.cardNumberInvalidField
                  : classes.cardNumberField
              }
            >
              <p
                className={
                  cardNumber ? classes.invalidFieldHeader : classes.fieldHeader
                }
              >
                Card number
              </p>
              <CardNumberElement
                onChange={handleElementErrors}
                options={{
                  style: inputStyles,
                  placeholder: ccPlaceholder,
                }}
              />
            </div>
            {cardNumber && (
              <p
                data-testid="cardNumber-error-box"
                className={classes.fieldError}
                role="alert"
                aria-live="assertive"
              >
                {cardNumber}
              </p>
            )}
          </div>

          <div className={classes.expiryCvcWrapper}>
            <div className={classes.fieldColumns}>
              <div
                className={
                  cardExpiry
                    ? classes.cardExpiryCvcInvalidField
                    : classes.cardExpiryCvcField
                }
              >
                <p
                  className={
                    cardExpiry
                      ? classes.invalidFieldHeader
                      : classes.fieldHeader
                  }
                >
                  Expiry
                </p>
                <CardExpiryElement
                  onChange={handleElementErrors}
                  options={{
                    style: inputStyles,
                    placeholder: exp,
                  }}
                />
              </div>
              {cardExpiry && (
                <p
                  data-testid="cardExpiry-error-box"
                  className={classes.multipleFieldsError}
                  role="alert"
                  aria-live="assertive"
                >
                  {cardExpiry}
                </p>
              )}
            </div>
            <div className={classes.fieldColumns}>
              <div
                className={
                  cardCvc
                    ? classes.cardExpiryCvcInvalidField
                    : classes.cardExpiryCvcField
                }
              >
                <p
                  className={
                    cardCvc ? classes.invalidFieldHeader : classes.fieldHeader
                  }
                >
                  CVC
                </p>
                <CardCvcElement
                  onChange={handleElementErrors}
                  options={{
                    style: inputStyles,
                    placeholder: getCVCPlaceholder(),
                  }}
                />
              </div>
              {cardCvc && (
                <p
                  data-testid="cvc-error-box"
                  className={classes.multipleFieldsError}
                  role="alert"
                  aria-live="assertive"
                >
                  {cardCvc}
                </p>
              )}
            </div>
          </div>

          {/*Country and Zip Code fields will be added back in version 2.0. Currently hid behind boolean value hasCountryAndZip.*/}
          {hasCountryAndZip && (
            <div className={classes.addressWrapper}>
              <div>
                <Autocomplete
                  id="card-country"
                  disabled
                  className={classes.cardCountry}
                  autoHighlight
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={getOptionSelected}
                  onChange={(event, newValue) => country.setState(newValue)}
                  value={country.state}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="country"
                      label="Country"
                      variant="filled"
                    />
                  )}
                />
              </div>
              <div>
                <TextField
                  id="card-zip-code"
                  className={
                    zip.error ? classes.cardZipInvalidCode : classes.cardZipCode
                  }
                  name="zip"
                  label="Zip code"
                  placeholder="xxxxxx"
                  margin="dense"
                  variant="filled"
                  value={zip.state}
                  onChange={zip.setState}
                />
                {zip.error && (
                  <p
                    className={classes.multipleFieldsError}
                    data-testid="zip-field-error"
                    role="alert"
                    aria-live="assertive"
                  >
                    {zip.error}
                  </p>
                )}
              </div>
            </div>
          )}

          {!isSubscription && (
            <div>
              <TextField
                id="card-nick-name"
                className={classes.cardNickName}
                name="nickName"
                label={
                  isPaymentModal
                    ? "Card nickname - optional"
                    : "Card nickname [optional]"
                }
                placeholder={
                  isPaymentModal ? "Enter card nickname" : "Add card nickname"
                }
                variant="filled"
                value={nickName.state}
                onChange={nickName.setState}
                InputProps={{
                  className: classes.nickNameWrapper,
                  disableUnderline: true,
                }}
              />
            </div>
          )}
        </fieldset>

        <fieldset className={classes.cardCheckbox}>
          <legend className="sr-only">Card Options</legend>
          <FormGroup>
            {showFsaHsaButton && (
              <FormControlLabel
                disabled={isPrimary.state && isConsumer}
                control={
                  <Checkbox
                    name="fsaHsa"
                    color={isPaymentModal ? "secondary" : "primary"}
                    checked={isFsaHsa.state}
                    onChange={isFsaHsa.setState}
                  />
                }
                label="This is a FSA or HSA card"
              />
            )}
            {showPrimaryButton && (
              <FormControlLabel
                disabled={isFsaHsa.state}
                control={
                  <Checkbox
                    name="primaryCheck"
                    color={isPaymentModal ? "secondary" : "primary"}
                    checked={isPrimary.state}
                    onChange={isPrimary.setState}
                  />
                }
                data-testid="primary-payment-checkbox"
                label="Set as default payment method"
              />
            )}
          </FormGroup>
        </fieldset>

        {hasConfirmationMsg && (
          <div className={classes.confirmationMsg}>
            <Typography appearance="body">
              Your membership payment method will also be updated.
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default MethodFields;
