import { useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { SlotVisibilityEnum } from "shared/fetch/src/models/SlotVisibilityEnum";
import useGetCalendarStaticData from "shared/features/xocal/useGetCalendarStaticData";
import { getProvidersObjectKeyedById } from "components/XOCal/utils";
import { capitalizeFirstLetterOfEveryWord } from "../../utils";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { CalendarStaticDataResponseClinicDetailsInternalHoldTypes } from "shared/fetch/src/models/CalendarStaticDataResponseClinicDetailsInternalHoldTypes";
import { XOCalProviderAppointment } from "shared/fetch/src/models/XOCalProviderAppointment";

// ALERT TODO: getting a .find whitescreen sometimes when visit types are changed
// from Scheduled visits tab. Monitor.

// the Internal Hold Type object does not come with an id property, but to normalize
// the Internal Hold Type and Visit Type objects and make our changeEvents more uniform,
// we add an id property identical to the name property in this component
// Example CalendarStaticDataResponseClinicDetailsInternalHoldTypesWithId
// TODO: consider refactor w/o fe data mutation
// {
//   id: "meeting"
//   name: "meeting",
//   modality: "Internal Hold types",
// }
interface CalendarStaticDataResponseClinicDetailsInternalHoldTypesWithId
  extends CalendarStaticDataResponseClinicDetailsInternalHoldTypes {
  id: string | undefined;
}

interface GetAppointmentTypesByVisibilityProps {
  visibility: SlotVisibilityEnum;
  unfilteredAppointmentTypes: XOCalProviderAppointment[] | undefined;
  isScheduleVisitsTab: boolean;
  unfilteredInternalHoldTypes:
    | CalendarStaticDataResponseClinicDetailsInternalHoldTypes[]
    | CalendarStaticDataResponseClinicDetailsInternalHoldTypesWithId[]
    | undefined;
  selectedVisitTypes: string[];
}

export interface OptionType {
  id: string;
  modality: string;
  name: string;
}

export const getAppointmentTypesByVisibility = ({
  visibility,
  unfilteredAppointmentTypes,
  isScheduleVisitsTab,
  unfilteredInternalHoldTypes,
  selectedVisitTypes,
}: GetAppointmentTypesByVisibilityProps) => {
  // remove "no data"/bad config internal types
  const internalHolds = unfilteredInternalHoldTypes?.filter(
    (iht) => iht.name !== "no data"
  );
  const appointmentTypes = unfilteredAppointmentTypes?.filter(
    (apptType) => apptType.name !== "no data"
  );

  if (!Boolean(appointmentTypes) || appointmentTypes?.length === 0) {
    return;
  }
  // if selectedVisitTypes[0] is the id of any appointmentTypes
  const isProviderVisitSelected = appointmentTypes?.some(
    (apptType) => apptType.id === selectedVisitTypes[0]
  );
  const isInternalHoldSelected = internalHolds?.some(
    (iht) =>
      (iht as CalendarStaticDataResponseClinicDetailsInternalHoldTypesWithId)
        .id === selectedVisitTypes[0]
  );
  const isNoVisitTypeSelected = !Boolean(selectedVisitTypes?.length);

  // no internal hold types shown on schedule visits tab
  if (isScheduleVisitsTab) {
    return appointmentTypes;
  }
  // if we get this far the user is on the slot settings tab
  // In slot settings if visibility is NOT hold, we only show
  // provider appointment types
  if (visibility !== "hold") {
    return appointmentTypes;
  }
  // but if the slot visibility is "Hold" we have three cases
  if (visibility === "hold") {
    if (isNoVisitTypeSelected) {
      return [...appointmentTypes!, ...internalHolds!];
    } else if (isInternalHoldSelected) {
      return internalHolds!;
    } else if (isProviderVisitSelected) {
      return appointmentTypes!;
    }
  }
  // fallback return bc Typescript says code path without a return value
  return appointmentTypes;
};

interface UseSelectVisitTypeProps {
  selectedVisitTypes: string[];
  handleChange: (event: any) => void;
  setField: (field: string, value: any) => void;
  providerId?: string | null;
  visibility?: SlotVisibilityEnum;
  maxPatients?: number;
  isScheduleVisitsTab: boolean;
  slot: SlotOutput | undefined;
  appointmentType: string;
  maxOverbook?: number;
  isChangingVisitType?: boolean;
}

export const useSelectVisitType = ({
  selectedVisitTypes,
  handleChange,
  setField,
  providerId,
  visibility = SlotVisibilityEnum.Hold,
  maxPatients,
  isScheduleVisitsTab,
  slot,
  appointmentType,
  maxOverbook,
  isChangingVisitType,
}: UseSelectVisitTypeProps) => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get("clinicId") as string;
  const selectedProviderId = searchParams.get(
    "slotActionDrawerProvider"
  ) as string;

  const [previousVisibility, setPreviousVisibility] = useState(visibility);

  const { data: calendarStaticData } = useGetCalendarStaticData({
    clinicId: clinicId as string,
  });

  const providerIdKey = providerId ?? selectedProviderId;
  const providersObject = useMemo(
    () => getProvidersObjectKeyedById(calendarStaticData),
    [calendarStaticData]
  );
  const slotProvider = providersObject[providerIdKey];

  const appointmentTypes = slotProvider?.appointmentsByModality;
  const internalHoldTypes =
    calendarStaticData?.clinicDetails?.internalHoldTypes?.map((iht) => ({
      ...iht,
      id: iht.name,
    }));

  const hasScheduledVisits = Boolean(slot?.appointments?.length);
  const totalNumberPatients = (maxPatients ?? 0) + (maxOverbook ?? 0);

  const resolvedAppointmentType =
    slot?.appointments?.[0]?.appointmentType ?? appointmentType;

  const [anchorVisit, setAnchorVisit] = useState<string | null>(
    resolvedAppointmentType
  );
  const [anchorVisitDuration, setAnchorVisitDuration] = useState<string | null>(
    null
  );
  const [selectedType, setSelectedType] = useState<string>(
    resolvedAppointmentType
  );

  const isInternalHold = (option: OptionType) => {
    if (option === undefined) {
      return false;
    }
    return Boolean(internalHoldTypes?.some((iht) => option.name === iht.name));
  };

  useEffect(() => {
    if (previousVisibility !== visibility) {
      const isValidAppointmentType = appointmentTypes?.some((apt) =>
        selectedVisitTypes.includes(apt?.id!)
      );

      if (!isValidAppointmentType) {
        handleChange({
          target: {
            name: "appointmentTypes",
            value: [],
          },
        });
        setAnchorVisit(null);
        setAnchorVisitDuration(null);
        setSelectedType("");
      }

      setPreviousVisibility(visibility);
    }
  }, [visibility, previousVisibility, appointmentTypes, selectedVisitTypes]);

  useEffect(() => {
    setSelectedType(resolvedAppointmentType);
  }, [resolvedAppointmentType]);

  useEffect(() => {
    setAnchorVisit(resolvedAppointmentType);
    const anchorVisitType = appointmentTypes?.find(
      (type) => type.id === resolvedAppointmentType
    );
    setAnchorVisitDuration(anchorVisitType?.duration ?? null);
    handleInputChange("duration", anchorVisitType?.duration ?? null);
  }, [resolvedAppointmentType, appointmentTypes]);

  useEffect(() => {
    if (selectedVisitTypes.length === 0) {
      setAnchorVisit(null);
      setAnchorVisitDuration(null);
      handleInputChange("duration", null);
    } else if (selectedVisitTypes.length === 1) {
      setAnchorVisit(selectedVisitTypes[0]);
      const anchorVisitType = appointmentTypes?.find(
        (type) => type.id === selectedVisitTypes[0]
      );
      setAnchorVisitDuration(anchorVisitType?.duration ?? null);
      handleInputChange("duration", anchorVisitType?.duration ?? null);
    }
  }, [selectedVisitTypes, appointmentTypes]);

  const handleInputChange = useCallback(
    // Watch closely, has had an infinite update loop bug
    (name: string, inputValue: string[] | null | string) => {
      handleChange({
        target: {
          name,
          value: inputValue,
        },
      });
    },
    [handleChange]
  );

  const getAppointmentTypeName = useCallback(
    (selectedAppointmentTypeId: string) => {
      const allVisitTypesIncludingInternalHolds = [
        ...(appointmentTypes ?? []),
        ...(internalHoldTypes ?? []),
      ];
      const appt = allVisitTypesIncludingInternalHolds.find(
        (apptType: any) =>
          apptType?.id?.toString() === selectedAppointmentTypeId?.toString()
      );
      return capitalizeFirstLetterOfEveryWord(appt?.name);
    },
    [appointmentTypes, internalHoldTypes]
  );

  const isOptionAnchorVisit = useCallback(
    (option: any) => option?.id?.toString() === anchorVisit?.toString(),
    [anchorVisit]
  );

  const handleAutoCompleteChange = useCallback(
    (_evt: React.SyntheticEvent, newValue: any) => {
      let selectedValue;

      if (isScheduleVisitsTab && newValue) {
        if (Array.isArray(newValue) && newValue.length > 0) {
          if (typeof newValue[0] === "object") {
            selectedValue = newValue[0].id;
          } else {
            selectedValue = newValue[0];
          }
        } else if (typeof newValue === "object") {
          selectedValue = newValue.id;
        } else {
          selectedValue = newValue;
        }
      } else {
        if (newValue && Array.isArray(newValue)) {
          selectedValue = newValue.map((val: any) =>
            typeof val === "object" ? val.id : val
          );
        } else {
          selectedValue = "";
        }
      }

      if (newValue) {
        setSelectedType(selectedValue);

        if (isScheduleVisitsTab) {
          handleChange({
            target: {
              name: "appointmentType",
              value: selectedValue,
            },
          });
        }

        handleChange({
          target: {
            name: "appointmentTypes",
            value: selectedValue,
          },
        });
      }

      // we need to create arrays here because with the multi select on Settings Tabs
      // vs single select on Schedule Visits tab causes either an array or a string for
      // selectVisitType and we need to have a standard way of working with the
      // selectVisitType value
      const selectedValueArray = Array.isArray(selectedValue)
        ? selectedValue
        : [selectedValue];
      const selectedValueAppointmentType = Array.isArray(selectedValue)
        ? selectedValue[0]
        : selectedValue;

      // values.newSLotAppointmentType is used in useSlotActionDrawer to send the
      // correct appointmentType to the API call for scheduleAppointment
      if (!slot?.appointmentType) {
        // if the selectedValue has an array length of over one, we need to use
        // anchorVisit, instead of selectedValue for the appointmentType
        if (selectedValueArray.length > 1) {
          setField("newSlotAppointmentType", anchorVisit);
        } else {
          setField("newSlotAppointmentType", selectedValueAppointmentType);
        }
      }
    },
    [isScheduleVisitsTab, handleChange]
  );

  const isOptionDisabled = useCallback(
    (option: any) => {
      if (isChangingVisitType === undefined) {
        isChangingVisitType = false;
      }
      const isSettingsTabAndIsGroupVisit =
        !isScheduleVisitsTab &&
        selectedVisitTypes?.length === 1 &&
        totalNumberPatients > 1 &&
        !selectedVisitTypes?.includes(option.id);
      const isSettingsTabAndDifferentDurationThanAnchorVisit =
        !isScheduleVisitsTab &&
        !!option.duration &&
        !!anchorVisitDuration &&
        option.duration !== anchorVisitDuration;
      const isInternalHoldTypeAndIsNotCurrentlySelectedType =
        Boolean(selectedVisitTypes.length) &&
        isInternalHold(option) &&
        selectedVisitTypes[0] !== option.id;
      const isAnchorVisitAndMultipleSelectionsHaveBeenMade =
        !isScheduleVisitsTab &&
        isOptionAnchorVisit(option) &&
        selectedVisitTypes?.length > 1 &&
        option.id !== selectedType;
      const hasVisitsScheduledAndNotKeepOrCancelFlow =
        !isScheduleVisitsTab &&
        selectedVisitTypes.length &&
        hasScheduledVisits &&
        !isChangingVisitType &&
        slot?.appointments?.[0].appointmentType !== option.id;

      return (
        hasVisitsScheduledAndNotKeepOrCancelFlow ||
        isSettingsTabAndIsGroupVisit ||
        isSettingsTabAndDifferentDurationThanAnchorVisit ||
        isInternalHoldTypeAndIsNotCurrentlySelectedType ||
        isAnchorVisitAndMultipleSelectionsHaveBeenMade
      );
    },
    [
      isScheduleVisitsTab,
      selectedType,
      selectedVisitTypes,
      totalNumberPatients,
      anchorVisitDuration,
      isOptionAnchorVisit,
    ]
  );

  const appointmentTypesByVisibility = useMemo(
    () =>
      getAppointmentTypesByVisibility({
        visibility,
        unfilteredInternalHoldTypes: internalHoldTypes,
        unfilteredAppointmentTypes: appointmentTypes,
        isScheduleVisitsTab,
        selectedVisitTypes,
      }),
    [
      visibility,
      internalHoldTypes,
      appointmentTypes,
      isScheduleVisitsTab,
      selectedVisitTypes,
    ]
  );

  return {
    anchorVisit,
    selectedType,
    hasScheduledVisits,
    appointmentTypesByVisibility,
    getAppointmentTypeName,
    isOptionAnchorVisit,
    handleAutoCompleteChange,
    isOptionDisabled,
    handleInputChange,
    isInternalHold,
  };
};
