/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    HeadsUpNotification,
    HeadsUpNotificationFromJSON,
    HeadsUpNotificationToJSON,
} from '../models/HeadsUpNotification'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OOONotificationRequestBody,
    OOONotificationRequestBodyFromJSON,
    OOONotificationRequestBodyToJSON,
} from '../models/OOONotificationRequestBody'
import {
    OOONotificationsArray,
    OOONotificationsArrayFromJSON,
    OOONotificationsArrayToJSON,
} from '../models/OOONotificationsArray'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface CreateOutOfOfficeNotificationRequest {
    oOONotificationRequestBody?: OOONotificationRequestBody;
}
export interface DeleteProviderOOONotificationRequest {
    id: string;
}
export interface GetOutOfOfficeNotificationRequest {
    providerIds?: Array<string>;
    clinicIds?: Array<string>;
}


export interface IProviderOooNotificationApi {
      createOutOfOfficeNotification: (requestParameters: CreateOutOfOfficeNotificationRequest) => Promise<HeadsUpNotification>
      deleteProviderOOONotification: (requestParameters: DeleteProviderOOONotificationRequest) => Promise<void>
      getOutOfOfficeNotification: (requestParameters: GetOutOfOfficeNotificationRequest) => Promise<Array<OOONotificationsArray>>
      createOutOfOfficeNotificationWithResponse: (requestParameters: CreateOutOfOfficeNotificationRequest) => Promise<{ value: HeadsUpNotification, response: any}>
      deleteProviderOOONotificationWithResponse: (requestParameters: DeleteProviderOOONotificationRequest) => Promise<{ value: void, response: any}>
      getOutOfOfficeNotificationWithResponse: (requestParameters: GetOutOfOfficeNotificationRequest) => Promise<{ value: Array<OOONotificationsArray>, response: any}>
}

/**
 * no description
 */
export class ProviderOooNotificationApi extends runtime.BaseAPI {

    /**
     * Create OOO notification for provider
     * Create ooo notification for provider
     */
    createOutOfOfficeNotificationRaw = async (requestParameters: CreateOutOfOfficeNotificationRequest): Promise<{ runtime: runtime.ApiResponse<HeadsUpNotification>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/providers/out-of-office-notifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OOONotificationRequestBodyToJSON(requestParameters.oOONotificationRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => HeadsUpNotificationFromJSON(jsonValue)), response };
    }

    /**
     * Create OOO notification for provider
     * Create ooo notification for provider
     */
    createOutOfOfficeNotification = async (requestParameters: CreateOutOfOfficeNotificationRequest): Promise<HeadsUpNotification> => {
        const { runtime } = await this.createOutOfOfficeNotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create OOO notification for provider
     * Create ooo notification for provider
     */
    createOutOfOfficeNotificationWithResponse = async (requestParameters: CreateOutOfOfficeNotificationRequest): Promise<{ value: HeadsUpNotification, response: any}> => {
        const { runtime, response } = await this.createOutOfOfficeNotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Removes a specific Out of Office notification for a provider
     * Delete OOO notification for providers
     */
    deleteProviderOOONotificationRaw = async (requestParameters: DeleteProviderOOONotificationRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProviderOOONotification.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/providers/out-of-office-notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Removes a specific Out of Office notification for a provider
     * Delete OOO notification for providers
     */
    deleteProviderOOONotification = async (requestParameters: DeleteProviderOOONotificationRequest): Promise<void> => {
        const { runtime } = await this.deleteProviderOOONotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Removes a specific Out of Office notification for a provider
     * Delete OOO notification for providers
     */
    deleteProviderOOONotificationWithResponse = async (requestParameters: DeleteProviderOOONotificationRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteProviderOOONotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Get OOO notification for provider
     * Get ooo notification for provider
     */
    getOutOfOfficeNotificationRaw = async (requestParameters: GetOutOfOfficeNotificationRequest): Promise<{ runtime: runtime.ApiResponse<Array<OOONotificationsArray>>, response: any}> => {
                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.providerIds) {
            queryParameters['provider_ids[]'] = requestParameters.providerIds;
        }

        if (requestParameters.clinicIds) {
            queryParameters['clinic_ids[]'] = requestParameters.clinicIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/providers/out-of-office-notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OOONotificationsArrayFromJSON)), response };
    }

    /**
     * Get OOO notification for provider
     * Get ooo notification for provider
     */
    getOutOfOfficeNotification = async (requestParameters: GetOutOfOfficeNotificationRequest): Promise<Array<OOONotificationsArray>> => {
        const { runtime } = await this.getOutOfOfficeNotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get OOO notification for provider
     * Get ooo notification for provider
     */
    getOutOfOfficeNotificationWithResponse = async (requestParameters: GetOutOfOfficeNotificationRequest): Promise<{ value: Array<OOONotificationsArray>, response: any}> => {
        const { runtime, response } = await this.getOutOfOfficeNotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchProviderOooNotificationApi: IProviderOooNotificationApi  = new ProviderOooNotificationApi();
