import { useQuery } from "react-query";
import {
  GetCalendarStaticDataRequest,
  fetchCalendarApi,
} from "shared/fetch/src/apis/CalendarApi";
import { CalendarStaticDataResponse } from "shared/fetch/src/models/CalendarStaticDataResponse";

export const getCalendarStaticDataQueryKey = (
  requestParameters: GetCalendarStaticDataRequest
) => {
  return ["calendarStaticData", ...Object.entries(requestParameters)];
};

const useGetCalendarStaticData = (
  requestParameters: GetCalendarStaticDataRequest
) => {
  return useQuery(
    getCalendarStaticDataQueryKey(requestParameters),
    () =>
      fetchCalendarApi.getCalendarStaticData(
        requestParameters
      ) as Promise<CalendarStaticDataResponse>,
    {
      enabled: !!requestParameters.clinicId,
      staleTime: 600000, // 10mins, to pick up any config changes pushed by devOps
      cacheTime: Infinity,
    }
  );
};

export default useGetCalendarStaticData;
