// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SynopsisProvider
 */
export interface SynopsisProvider  {
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    flat?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    shot?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    show?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    type?: SynopsisProviderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    globalId?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    microsoftTeamsUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    videoConferenceLink?: string;
    /**
     * 
     * @type {string}
     * @memberof SynopsisProvider
     */
    elationId?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SynopsisProvider
     */
    uses?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof SynopsisProvider
     */
    pcpEligible?: boolean;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof SynopsisProvider
     */
    days?: Array<Array<string>>;
}

export function SynopsisProviderFromJSON(json: any): SynopsisProvider {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'flat': !exists(json, 'flat') ? undefined : json['flat'],
        'shot': !exists(json, 'shot') ? undefined : json['shot'],
        'show': !exists(json, 'show') ? undefined : json['show'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'globalId': !exists(json, 'global_id') ? undefined : json['global_id'],
        'microsoftTeamsUserId': !exists(json, 'microsoft_teams_user_id') ? undefined : json['microsoft_teams_user_id'],
        'videoConferenceLink': !exists(json, 'video_conference_link') ? undefined : json['video_conference_link'],
        'elationId': !exists(json, 'elation_id') ? undefined : json['elation_id'],
        'uses': !exists(json, 'uses') ? undefined : json['uses'],
        'pcpEligible': !exists(json, 'pcp_eligible') ? undefined : json['pcp_eligible'],
        'days': !exists(json, 'days') ? undefined : json['days'],
    };
}

export function SynopsisProviderToJSON(value?: SynopsisProvider): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'flat': value.flat,
        'shot': value.shot,
        'show': value.show,
        'type': value.type,
        'user': value.user,
        'global_id': value.globalId,
        'microsoft_teams_user_id': value.microsoftTeamsUserId,
        'video_conference_link': value.videoConferenceLink,
        'elation_id': value.elationId,
        'uses': value.uses,
        'pcp_eligible': value.pcpEligible,
        'days': value.days,
    };
}

/**
* @export
* @enum {string}
*/
export enum SynopsisProviderTypeEnum {
    Physician = 'Physician',
    Chiropractor = 'Chiropractor'
}


